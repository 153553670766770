import $ from "jquery";
import orderListUtil, { processAllOrders, updateOrders, tmplparams, updateTableMates, update, deleteOrders, simpleChangeStateByTableAndMenuItem, resetOrderListUtil, deleteTableOccupations, getTableName, getTableOccupation, isTakeawayTable, playNewAcceptedOrderSound, getOrderById } from "./order-list-util";
import admin, { getProductionLine, getTableOccupations } from "./admin";
import auth, { startpoll, replaceContent, select } from "./auth";
import tableLayout from "./table-layout";
import moment from "moment";

const stateTransitions = {
  left: ["delivering", "cancelled"],
  middle: ["producing"],
  right: ["accepted"],
  delivered: {
    left: [],
    right: ["delivered"]
  },
  delivering: {
    left: [],
    right: ["producing"]
  },
  cancelled: {
    left: [],
    right: ["producing"]
  },
  producing: {
    left: ["delivering", "cancelled"],
    right: ["accepted"]
  },
  accepted: {
    left: ["producing"],
    right: []
  }
};

var updateCycle;
export const stopIntervals = () => {
  clearInterval(updateCycle);
};

export const ready = () => {
  resetOrderListUtil();

  delete localStorage.tableOccupationSelected;

  window.toggleProductionLineSelected = toggleProductionLineSelected;
  window.simpleChangeStateByTableAndMenuItem = simpleChangeStateByTableAndMenuItem;
  window.getTableName = getTableName;
  window.getTableOccupation = getTableOccupation;
  window.moment = moment;
  window.isTakeawayTable = isTakeawayTable;

  orderListUtil.showTableNumber = true;
  orderListUtil.filterOrders = false;
  orderListUtil.states = auth.myStatus.restaurant_settings["tables-overview"].states;

  orderListUtil.processOrdersDone = function () {
    topUpdateHooks();
  };

  updateCycle = setInterval(function () {
    if (typeof orderListUtil.ordersCache != "undefined") {
      processAllOrders(orderListUtil.ordersCache);
      updateOrders([]);
    }
  }, 20000);

  orderListUtil.show = function (order) {
    var show = false;
    localStorage.productionLineSelected.split(",").forEach(productionLineSelected => {
      show = show || typeof order.productionLinesInvolved["pl" + productionLineSelected] != "undefined";
    });
    return show;
  };

  orderListUtil.filter = function (order) {
    var ok = false;
    if (order.defaultProductionLinesIds === "") return false;
    order.defaultProductionLinesIds.split(",").forEach(function (pl) {
      localStorage.productionLineSelected.split(",").forEach(productionLineSelected => {
        if (Number(pl) === Number(productionLineSelected)) {
          ok = ok || true;
        } else {
          ok = ok || getProductionLine(pl).collaboratingProductionLineIds.indexOf(Number(productionLineSelected)) >= 0;
        }
      });
    });
    return ok;
  };

  orderListUtil.isFiltered = () => {
    return true;
  };
  orderListUtil.orderTemplate = data => {
    var t = $(".order-table-box-outer .order-table-box");
    t.data("scrollTop", t.scrollTop());
    t.data("scrollLeft", t.scrollLeft());

    orderListUtil.statesShown.forEach(function (state) {
      var t = $("<div class='tcontent'/>");
      var target = $("#" + state + ".order-table-box .tcontent,#" + state + ".order-table-box.tcontent");
      if (target.length === 0) return;

      data = data.filter(order => orderListUtil.show(order)).sort(function (a, b) {
        const aNumber = a.tableNumber;
        const bNumber = b.tableNumber;
        const aIsScheduled = isTakeawayTable(aNumber);
        const bIsScheduled = isTakeawayTable(bNumber);
        const aTableOccupation = getTableOccupation(a.tableOccupation);
        const bTableOccupation = getTableOccupation(b.tableOccupation);
        var atime = !aIsScheduled ? a.inStateTimeStamp[state] : aTableOccupation.bookedOccupationStart;
        var btime = !bIsScheduled ? b.inStateTimeStamp[state] : bTableOccupation.bookedOccupationStart;
        return atime - btime;
      });
      data.forEach(function (order) {
        $("script#ordersTemplateCancelledAcceptedProducingDeliveringStateTableStyle")
          .tmpl({ da: order, state: state, stateTransitions: auth.myStatus.restaurant_settings.production_lines.stateTransitions, ...tmplparams() })
          .appendTo(t);
      });
      replaceContent(target, $(t));
    });
  };

  orderListUtil.updateOrdersDone = function () {
    topUpdateHooks();
  };

  $("#productionLineMainTemplate")
    .tmpl({ ...tmplparams(), hidePaid: true })
    .appendTo($("#main"));

  $("script#ordersTemplateCancelledAcceptedProducingDeliveringTableStyle")
    .tmpl({ stateTransitions: auth.myStatus.restaurant_settings.production_lines.stateTransitions, ...tmplparams() })
    .appendTo($("#orderList"));

  var name = "";
  auth.myStatus.restaurant_production_lines.forEach(function (line) {
    var selected = false;
    if (typeof localStorage.productionLineSelected != "undefined")
      localStorage.productionLineSelected.split(",").forEach(productionLineSelected => {
        if (Number(productionLineSelected) === line.id) {
          selected = true;
        }
      });
    name += '<button onclick="toggleProductionLineSelected(' + line.id + ',this)" class="btn btn-info ' + (selected ? "active" : "") + '" data-toggle="toggle">' + line.name + "</button>";
  });
  $(".production-line-index-title").html(name);

  select($("#middle"));

  startpoll(serverSideMessageHandler, [
    {
      Restaurant: localStorage.restaurantSelected,
      Order: "*"
    },
    {
      Restaurant: localStorage.restaurantSelected,
      Menu: "*"
    },
    {
      Restaurant: localStorage.restaurantSelected,
      TableOccupation: "*"
    }
  ]);

  admin.getTables(function (tableLayouts) {
    tableLayout.tables = tableLayouts.restaurantTables;
    tableLayouts.restaurantTables.forEach(function (table) {
      tableLayout.tableMap.set(Number(table.number), table);
    });

    getTableOccupations(function (data) {
      orderListUtil.tableOccupations = data;
      updateTableMates();
      admin.getActiveMenuItems(function (categs) {
        admin.categories = categs;

        admin.getUnprocessedOrders([], orders => {
          orderListUtil.ordersCache = orders;
          orderListUtil.processOrders(orders)
        });

      }, localStorage.language);


      $("div#rejectionReason").on("shown.bs.modal", function (event) {
        var modal = $(this);
        var r = $(modal).find("#reason1");
        $(r).trigger("focus");
      });
    });
  });

  $("#orderList").click(event => {
    if (event.isDefaultPrevented()) return;

    if (event.originalEvent.selectionDone === true) return;

    $(".mainrow.selectable.selected").removeClass("selected");
  });
};

const toggleProductionLineSelected = (id, item) => {
  if (!localStorage.productionLineSelected)
    return;
  var pls = localStorage.productionLineSelected.split(",");
  if (pls.indexOf("" + id) !== -1) {
    pls.splice(pls.indexOf("" + id), 1);
    $(item).removeClass("active");
  } else {
    pls.push(id);
    $(item).addClass("active");
  }
  var s = "";
  pls.forEach(pl => {
    s += (s === "" ? "" : ",") + pl;
  });
  localStorage.productionLineSelected = s;
  processAllOrders(orderListUtil.ordersCache);
  updateOrders([]);
};

function serverSideMessageHandler(message) {
  if (message.type === "timeout" && message.message === "true") return;
  if (message.type === "refresh") {
    if (message.message === "order changed" || message.message === "order state changed" || message.message === "new order" || message.message === "new order selected") {
      if (message.data.orders.filter(o => orderListUtil.filter(o) && o.state === "accepted" && getOrderById(o.id).state !== "accepted").length) {
        playNewAcceptedOrderSound();
      }
      if (message.data) update(message.data);
      else admin.getOrder(message.TableOccupation, message.Order, updateOrders);
    } else if (message.message === "order cancelled") {
      if (message.data) {
        deleteOrders(message.Order, true);
        update(message.data);
      } else {
        deleteOrders(message.Order);
      }
    } else if (message.message === "menu changed") {
      admin.getActiveMenuItems(function (categs) {
        admin.categories = categs;
        updateOrders([]);
      }, localStorage.language);
    } else if (message.message === "table unlocked") {
      if (message.data) update(message.data);
      else {
        deleteTableOccupations(message.TableOccupation);
        updateOrders([]);
      }
    } else {
      if (message.data) {
        update(message.data);
      }
      // reload();
    }
  }
}

/*
const reload = () => {
  var id = orderListUtil.selected.attr("id");
  var orderId = orderListUtil.selected.attr("orderId");
  var details = $("tr#" + id + ".detailrow.nothidden").length > 0;
  admin.getUnprocessedOrders(function(data) {
    processOrders(data);
    setSelected(id);
    if (details) {
      _show(orderListUtil.selected, true);
      $("tr#" + id + ".detailrow.nothidden").each(function(ind, row) {
        if ($(row).attr("orderId") === orderId) {
          _show($(row));
        }
      });
    }
  });
};
*/

function topUpdateHooks() {
  $(".orderTable.table-style .order-table-box-outer .order-table-box .mainrow").each((ind, box) => {
    var lastChild = $(box).find("div.order-table-box-content > div:not(.change-state-to) > div:last-child");

    var pos = lastChild.offset();
    var boxpos = $(box).offset();
    var bottom = pos.top - boxpos.top + lastChild.get(0).getBoundingClientRect().height;
    var right = pos.left - boxpos.left + lastChild.get(0).getBoundingClientRect().width;
    var height = $(box).outerHeight();
    var width = $(box).outerWidth();

    if (right > width + 1 || bottom > height + 1) {
      $(box).css("min-width", "calc(" + (20 + Math.floor(right / width) * 20) + "em)");
    }
  });

  var t = $(".order-table-box-outer .order-table-box");
  t.scrollTop(t.data("scrollTop"));
  t.scrollLeft(t.data("scrollLeft"));

  orderListUtil.updateHooks();
}
