import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import LabeledInputStyled from '../widgets/LabeledInputStyled';
import { I18n } from 'react-redux-i18n';
import "../css/customerDeliveryDetails.css"
import { getFeature, getHomeDeliveryTableNumber, getPickupTableNumber, getUsersContaining } from '../js/admin';
import { useTable, usePagination } from "react-table";
import styled from 'styled-components'
import FlexGrowSpace from '../widgets/FlexGrowSpace';
import { DateTimePickerInput, TimePickerInput } from 'react-datetime-range-super-picker'
import 'react-datetime-range-super-picker/dist/index.css'
import moment from "moment";
import { getPaymentMethod } from "../js/tableMatePaid.js";
import LabeledRadioButtonsStyled from '../widgets/LabeledRadioButtonsStyled';
import { Map as MapContainer, Marker, Polygon, TileLayer, useLeaflet as useMapEvents } from 'react-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import LabeledAutoCompleteStyled from '../widgets/LabeledAutocompleteStyled';
import removeAccents from "remove-accents";
import { postcodes } from '../js/postcodes';
import auth, { featureEnabled, get, messageDialog } from '../js/auth';
import { tableOccupations } from '../actions/EatWithMeActions';

const provider = new OpenStreetMapProvider();


const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  width: 100%;
  height: 100%;
  display:flex;
  flex-flow:column;
  

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    height:100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid lightgray;
    flex-grow:1;
    flex-shrink:1;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid lightgray;
      border-right: 1px solid lightgray;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`


function Table({ columns, data, onClick }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data
    }, usePagination)

    if (headerGroups == null)
        return null;

    return (
        <Styles>
            <div className="tableWrap">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps({
                                            className: column.collapse ? 'collapse' : '',
                                        })}
                                    >
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} onClick={() => onClick(row.original)}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps({
                                                    className: cell.column.collapse ? 'collapse' : '',
                                                })}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
            </div>
            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </Styles>
    )

}

function History({ columns, data, onClick }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data
    }, usePagination)

    if (headerGroups == null)
        return null;

    return (
        <Styles>
            <div className="tableWrap">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps({
                                            className: column.collapse ? 'collapse' : '',
                                        })}
                                    >
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} onClick={() => onClick && onClick(row.original)}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps({
                                                    className: cell.column.collapse ? 'collapse' : '',
                                                })}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
            </div>
            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </Styles>
    )

}


var map;

function MyComponent({ t, position }) {
    map = useMapEvents({
        click(event) {
            t.setState({ longitude: event.latlng.lat, latitude: event.latlng.lng });
        },
    })
    if (position[0] && position[1])
        map.map.flyTo(position);
    console.log(position);
    return position[0] && position[1] ? (
        <Marker position={position}>
        </Marker>
    ) : null;
}

const _state = {
    email: "",
    name: "",
    surname: "",
    phone: "",
    postcode: "",
    address: "",
    address_choices: [],
    door: ""
}

class CustomerDeliveryDetails extends Component {

    constructor(props) {
        super(props);
        this.startDate = React.createRef();
        this.targetRef = React.createRef();
        this.state = {
            show: true,
            search: "",
            customers: [],
            focus: undefined,
            startTimeModified: false,
            zoom: 13,
            orderAreas: auth.myStatus.restaurant_settings["enabled-features"].homedelivery.price ? auth.myStatus.restaurant_settings["enabled-features"].homedelivery.price.filter(p => p.area).map(p => JSON.parse(p.area)) : [],
        }
    }



    componentWillUnmount() {
        this.disconnectObserver();
    }

    setupIntersectionObserver() {
        if (this.observer)
            return;
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        this.observer = new IntersectionObserver(this.handleIntersection, options);

        if (this?.targetRef?.current) {
            this.observer.observe(this.targetRef.current);
        }
    }

    disconnectObserver() {
        if (this.observer && this?.targetRef?.current) {
            this.observer.unobserve(this.targetRef.current);
        }
    }

    handleIntersection = (entries) => {
        entries.forEach((entry) => {
            const isVisible = this.state.isVisible || entry.isIntersecting;

            this.setState({ isVisible });

            if (isVisible && this.props.onVisible) {
                this.props.onVisible();
            }
        });
    };

    componentDidMount() {
        this.setupIntersectionObserver();
    }

    componentDidUpdate() {
        this.setupIntersectionObserver();
    }


    static getDerivedStateFromProps(props, state) {
        const tableNumber = props.tableOccupation.restaurantTables[0].restaurantTable.number;
        const timeahead = getPickupTableNumber() === tableNumber ? getFeature("pickup").timeahead : getHomeDeliveryTableNumber() === tableNumber ? getFeature("homedelivery").timeahead : 0;
        const minduration = getPickupTableNumber() === tableNumber ? 0 : getFeature("homedelivery")["min-duration"];
        const payment_methods = getPickupTableNumber() === tableNumber ? getFeature("pickup").payment_method : getHomeDeliveryTableNumber() === tableNumber ? getFeature("homedelivery").payment_method : [];
        const type = getPickupTableNumber() === tableNumber ? "pickup" : getHomeDeliveryTableNumber() === tableNumber ? "homedelivery" : "";
        const allowed_installments_setup = type ? props.myStatus.restaurant_settings["enabled-features"][type].allowed_installments : [];
        const allowed_installments = allowed_installments_setup ? Object.keys(allowed_installments_setup).filter(m => allowed_installments_setup[m]) : [];
        var s = {
            ..._state,
            ...props.values,
            ...state
        }
        s = {
            ...s,
            minduration: minduration,
            timeahead: timeahead,
            date: moment().add("hours", timeahead),
            type: type,
            start: state.start ? state.start : props.values && props.start ? moment(props.start) : moment().add('hour', timeahead),
            end: state.end ? state.end : props.values && props.end ? moment(props.end) : moment().add(timeahead, 'hour').add(minduration, "hour"),
            payment_method: state.payment_method ? state.payment_method : props.values && props.values.payment_method ? props.values.payment_method : undefined,
            payment_methods: payment_methods,
            allowed_installments: allowed_installments,
            installments_strategy: state.installments_strategy ? state.installments_strategy : props.values && props.values.installments_strategy ? props.values.installments_strategy : undefined,
            town: getPickupTableNumber() === tableNumber ? '' : state.town !== undefined ? state.town : props.values && props.values.town ? props.values.town : props.myStatus.restaurant_town,
            country: state.country !== undefined ? state.country : props.values && props.values.country ? props.values.country : props.myStatus.restaurant_country,
            latitude: state.latitude || (props.values && props.values.latitude !== "undefined" && props.values.latitude),
            longitude: state.longitude || (props.values && props.values.longitude !== "undefined" && props.values.longitude),
            door: "" + (state.initialized ? (s.door !== null ? s.door : "") : (props.values && props.values.door ? props.values.door : (s.door !== null ? s.door : ""))),
            initialized: true
        };
        return s;
    }

    componentDidMount = () => {
        this.setState({ focus: "search" })
    }


    render() {

        if (auth.phonenumber) {
            //this.setState({ phone: auth.phonenumber });
            this.onChange({ target: { id: "phone", value: auth.phonenumber } }, undefined, true);
            delete auth.phonenumber;
        }

        return (
            <Modal key="customer-delivery-details" id="customer-delivery-details" show={this.state.show} onHide={this.props.unmount}>
                <ModalHeader>
                    <h2>{I18n.t("admin_local.customer_details")}</h2>
                </ModalHeader>
                <ModalBody key="modalBody">
                    <div key="modalBodyDiv" className="data">
                        {this.state.focus === "date" ?
                            <div className="dateRangePicker">
                                <button style={{ width: "100%" }} type="dismiss" className="btn btn-primary" onClick={this.unfocusDateTime}>{I18n.t("admin_local.next")} </button>
                            </div>
                            : <div style={{ display: "flex", flexFlow: "row", flexBasis: "100%", position: "relative", overflow: "hidden", height: "100%" }}>
                                <div >
                                    <div className="labeled-input table-style" style={{ display: "flex", alignItems: "center", height: "3rem" }}>
                                        <label>{I18n.t("admin_local.datetime")}</label>
                                        <div ref={this.startDate} style={{ display: "flex", alignItems: "center" }}>
                                            <DateTimePickerInput use24hours={true} className="datePicker" format="yyyy-MM-dd           HH:mm" timeFormat="HH:mm" weekStartsOn={1} date={this.state.start.toDate()} onDateTimeUpdate={this.handleFromDateUpdate} />
                                        </div>
                                        {this.state.type !== "pickup" ? (
                                            this.state.startTimeModified ?
                                                <React.Fragment>-<TimePickerInput className="timePicker" format={"HH:mm"} weekStartsOn={1} time={this.state.end.format("HH:mm")} onTimeUpdate={this.handleToDateUpdate} /></React.Fragment>
                                                :
                                                <React.Fragment>-<input onClick={this.setStartDateMessage} style={{ border: "none", outline: "none", fontSize: "1.5rem", height: "2.5em", padding: ".2em 1.2em .2em .5em", borderRadius: "0.417em", backgroundColor: "#f7f7f7", color: "#6c6b6b" }} readOnly value={this.state.end.format("HH:mm")} /></React.Fragment>
                                        ) : null}
                                    </div>
                                    {this.state.payment_methods.length > 0 ?
                                        <LabeledRadioButtonsStyled setValue={this.setValue} key="11" className={(this.state.errorField === 'payment_method' ? 'highlight-3' : '') + ' labeled-input table-style'} id="payment_method" value={this.state.payment_method} type="string" label={I18n.t("admin_local.payment_method")} >
                                            {this.state.payment_methods ? this.state.payment_methods.map(method =>
                                                <div key={method.meth} id={method.meth} value={getPaymentMethod(method.meth).method.name} />
                                            ) : null}
                                        </LabeledRadioButtonsStyled> : null}
                                    {featureEnabled("ordering") && featureEnabled("ordering/menuorder") && this.state.allowed_installments.length ?
                                        <LabeledRadioButtonsStyled setValue={this.setValue} key="12" className={(this.state.errorField === 'installments_strategy' ? 'highlight-3' : '') + ' labeled-input table-style'} id="installments_strategy" value={this.state.installments_strategy} type="string" label={I18n.t("admin_local.installments_strategy")} >
                                            {this.state.allowed_installments.map(method =>
                                                <div key={method} id={method} value={I18n.t("admin_local.allowed_installments." + method)} />
                                            )}
                                        </LabeledRadioButtonsStyled> : null}
                                    <LabeledInputStyled key="2" tabindex="2" className="labeled-input table-style" id="email" value={this.state.email} change={this.onChange} label={I18n.t("local.email")} type="text" />
                                    <LabeledInputStyled key="3" tabindex="3" className="labeled-input table-style" id="surname" value={this.state.surname} change={this.onChange} label={I18n.t("local.surname")} type="text" />
                                    <LabeledInputStyled key="4" tabindex="4" className="labeled-input table-style" id="name" value={this.state.name} change={this.onChange} label={I18n.t("local.first_name")} type="text" />
                                    <LabeledInputStyled key="5" tabindex="5" className="labeled-input table-style" id="user_comment" value={this.state.user_comment} change={this.onChange} label={I18n.t("admin_local.user_comment")} type="text" tooltip={I18n.t("admin_local.tooltip.user_comment")} />
                                    {this.state.type !== "pickup" ?
                                        <React.Fragment>
                                            <LabeledInputStyled key="6" tabindex="6" className="labeled-input table-style" pattern={I18n.t("admin_local.postcode_pattern")} id="postcode" value={this.state.postcode} valid={this.state["postcode_valid"]} change={this.onChange} label={I18n.t("local.postcode")} type="text" />
                                            <LabeledInputStyled key="7" tabindex="7" className="labeled-input table-style" id="town" value={this.state.town} change={this.onChange} label={I18n.t("local.town")} type="text" />
                                            <LabeledAutoCompleteStyled key="8" tabindex="8" onSelect={this.addressSelected} options={this.state.address_choices} placeholder={I18n.t("admin_local.address_placeholder")} pattern={I18n.t("admin_local.address_pattern")} className={(this.state.errorField === 'address' ? 'highlight-3' : '') + ' labeled-input table-style'} id="address" value={this.state.address} valid={this.state["address_valid"]} change={this.onChange} label={I18n.t("local.address")} type="text" tooltip={I18n.t("admin_local.tooltip.address")} />
                                            <LabeledInputStyled key="9" tabindex="9" className="labeled-input table-style" id="door" value={this.state.door} change={this.onChange} label={I18n.t("local.door")} type="text" />
                                            <LabeledInputStyled key="10" tabindex="10" className="labeled-input table-style" id="country" value={this.state.country} change={this.onChange} label={I18n.t("local.country")} type="text" />
                                        </React.Fragment> : null}
                                    <LabeledInputStyled key="13" tabindex="11" id="phone" value={this.state.phone} change={this.onChange} pattern="(^$)|(\/(.*))|((06|\+36|[\/ -]?\+[\/ -]?\d[\/ -]?\d[\/ -]?\d?)[\/ -]?\d[\/ -]?\d[\/ -]?\d[\/ -]?\d[\/ -]?\d[\/ -]?\d[\/ -]?\d[\/ -]?\d[\/ -]?\d?)" className={(this.state.errorField === 'phone' ? 'highlight-3' : '') + ' labeled-input table-style'} label={I18n.t("local.phone")} type="text" />
                                    <LabeledInputStyled key="14" tabindex="12" className="labeled-input table-style" id="comment" value={this.state.comment} change={this.onChange} label={I18n.t("admin_local.booking_comment")} type="text" tooltip={I18n.t("admin_local.tooltip.comment")} />
                                </div>
                                <div ref={this.targetRef}>
                                    {this.state.isVisible && this.state.type !== "pickup" ?
                                        <div style={{ flexBasis: "50%", width: "100%", height: "calc(100% - 20px)" }}>
                                            <MapContainer onclick={event => this.setState({ longitude: event.latlng.lng, latitude: event.latlng.lat })} style={{ width: "100%", height: "100%", margin: "10px" }} center={[this.props.myStatus.restaurant_latitude ? this.props.myStatus.restaurant_latitude : 0, this.props.myStatus.restaurant_longitude ? this.props.myStatus.restaurant_longitude : 0]} zoom={this.state.zoom} scrollWheelZoom={true} attributionControl={false} >
                                                <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" id="mapbox.streets" maxZoom="18" accessToken="pk.eyJ1IjoiY3N6YXN6IiwiYSI6ImNqeHhqMTY1NDAwM2EzbW83aTJqZGlkM3MifQ.QeTnxqdNsSXLkBtBHsgkBQ" />
                                                <MyComponent t={this} position={[this.state.latitude, this.state.longitude]} />
                                                {this.state.orderAreas.map((f, ind) => f.map(f => {
                                                    return f.geometry.coordinates.map(f => {
                                                        return (<Polygon key={ind} positions={f.map(f => [f[1], f[0]])} color="yellow" />)
                                                    });
                                                }))}

                                            </MapContainer>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        }
                    </div>
                    {this.state.focus !== "date" ?
                        <div className="table">
                            {this.state.addresses && this.state.addresses.length ?
                                <Table
                                    data={this.state.addresses}
                                    columns={[
                                        {
                                            Header: I18n.t("admin_local.more_addresses"),
                                            accessor: customer => (customer.postcode ? customer.postcode + " " : "") + (customer.town ? customer.town + ", " : "") + (customer.address ? customer.address : "") + " " + (customer.door ? I18n.t("local.door") + ": " + customer.door : "")
                                        }
                                    ]}
                                    onClick={this.customerAddressSelected}
                                >
                                </Table>
                                :
                                this.state.tableOccupations && this.state.tableOccupations.length ?
                                    <History
                                        data={this.state.tableOccupations}
                                        columns={[
                                            {
                                                Header: I18n.t("admin_local.date"),
                                                accessor: "date"
                                            },
                                            {
                                                Header: I18n.t("admin_local.type"),
                                                accessor: "type"
                                            },
                                            {
                                                Header: I18n.t("admin_local.amount"),
                                                accessor: "amount"
                                            },
                                            {
                                                Header: I18n.t("admin_local.status"),
                                                accessor: "status"
                                            },
                                            {
                                                Header: I18n.t("admin_local.comment"),
                                                accessor: "comment"
                                            }
                                        ]}
                                    >
                                    </History>
                                    :
                                    this.state.customers && this.state.customers.length > 0 ?
                                        <Table
                                            data={this.state.customers}
                                            columns={[
                                                {
                                                    Header: I18n.t("local.email"),
                                                    accessor: "email"
                                                },
                                                {
                                                    Header: I18n.t("local.surname"),
                                                    accessor: "surname"
                                                },
                                                {
                                                    Header: I18n.t("local.first_name"),
                                                    accessor: "name"
                                                },
                                                {
                                                    Header: I18n.t("local.address"),
                                                    accessor: customer => (customer.postcode ? customer.postcode + " " : "") + (customer.town ? customer.town + ", " : "") + (customer.address ? customer.address : "") + " " + (customer.door ? I18n.t("local.door") + ": " + customer.door : "")
                                                },
                                                {
                                                    Header: I18n.t("local.phone"),
                                                    accessor: "phone"
                                                },
                                                {
                                                    Header: I18n.t("admin_local.comment"),
                                                    accessor: "comment"
                                                }
                                            ]}

                                            onClick={this.customerSelected}
                                        >
                                        </Table> : null
                            }
                        </div> : null}
                </ModalBody>
                <ModalFooter>
                    {this.state.type === "pickup" || this.state.type === "homedelivery" ?
                        <button type="dismiss" className="btn btn-danger" onClick={this.props.unmount}>{I18n.t("local.cancel")} </button>
                        :
                        <button type="dismiss" className="btn btn-danger" onClick={this.props.unmount}>{I18n.t("local.cancel")} </button>}
                    <FlexGrowSpace />
                    {!this.state.email ?
                        <button className="btn btn-primary" onClick={this.setCustomerDetailsUpdate}>{I18n.t("admin_local.save_update_address")}</button>
                        : null}
                    <button className="btn btn-primary" onClick={this.setCustomerDetails}>{I18n.t("admin_local.save")}</button>
                </ModalFooter>
            </Modal >
        );
    }

    setValue = (key, value) => {
        const state = {};
        state[key] = value;
        this.setState(state)
    }

    focusDateTime = () => {
        this.setState({ focus: "date" })
    }

    unfocusDateTime = () => {
        this.setState({ focus: "search" })
    }

    setStartDateMessage = () => {
        const instance = this;
        messageDialog(I18n.t('local.warning'), I18n.t('admin_local.first_modify_start_date')).done(() => {
            instance.startDate.current.firstElementChild.firstElementChild.dispatchEvent(new Event('focus'));
        });

    }

    startTimeSet = () => {
        this.setState({ startTimeModified: true });
    }

    handleFromDateUpdate = ({ date }) => {
        if (!moment(date.date).isBefore(this.state.date)) {
            const start = moment(date.date);
            //var end = this.state.end;
            //if (end.isBefore(start) || end.format('YYYY-MM-DD') !== start.format('YYYY-MM-DD')) {
            const end = start.clone().add(this.state.minduration, "hour");
            //}
            this.setState({ start: start, end: end, startTimeModified: true })
        } else if (Number(moment(date.date).format("HH")) < 13 && moment(date.date).isBefore(moment())) {
            const start = moment(date.date).add(12, 'hour');
            const end = start.clone().add(this.state.minduration, "hour");
            this.setState({ start: start, end: end, startTimeModified: true })
        } else {
            const start = moment().add(this.state.timeahead, "hour");
            const end = start.clone().add(this.state.minduration, "hour");
            this.setState({ start: start, end: end, startTimeModified: true })
        }
    }
    handleToDateUpdate = ({ time }) => {
        var end = this.state.start.clone().startOf('day').add(time.hour24, "hour").add(time.minute, "minute");
        console.log(end);
        if (end.isAfter(this.state.start)) {
            this.setState({ end: end })
        } else if (Number(end.format("HH")) < 13 && end.clone().add(12, 'hour').isAfter(this.state.start)) {
            end.add(12, 'hour');
            console.log(end);
            this.setState({ end: end })
        }
    }

    setCustomerDetailsUpdate = () => {
        this._setCustomerDetails(true);
    }
    setCustomerDetails = () => {
        this._setCustomerDetails(false);
    }


    _setCustomerDetails = (update) => {
        const state = { ...this.state, update };
        if (this.state.payment_methods.length > 0 && !this.state.payment_method) {
            this.setState({ errorField: "" });
            setTimeout(() => { this.setState({ errorField: "payment_method" }); }, 100);
        } else if (featureEnabled("ordering") && featureEnabled("ordering/menuorder") && this.state.allowed_installments.length && !this.state.installments_strategy) {
            this.setState({ errorField: "" });
            setTimeout(() => { this.setState({ errorField: "installments_strategy" }); }, 100);
        } else if (this.state.type === "homedelivery" && !this.state.address) {
            this.setState({ errorField: "" });
            setTimeout(() => { this.setState({ errorField: "address" }) }, 100);
            //} else if ((this.state.type === "homedelivery" || this.state.type === "pickup") && !this.state.phone) {
            //            this.setState({ errorField: "" });
            //            setTimeout(() => { this.setState({ errorField: "phone" }) }, 100);
        } else {
            const errors = Object.keys(this.state).filter(k => (/_valid$/.test(k)) && this.state[k] === false);
            if (errors.length) {
                this.setState({ errorField: "" });
                setTimeout(() => { this.setState({ errorField: errors[0].split("_")[0] }) }, 100);
                return;
            }
            delete this.state.customers;
            this.props.onsave(state);
        }
    }

    customerSelected = (row) => {
        row.user_comment = row.comment;
        delete row.comment;
        if (this.state.type === 'pickup') {
            delete row.address;
            delete row.town;
            delete row.postcode;
            delete row.country;
            delete row.door;
        } else {
            get("customerService/getAddresses/" + row.id).done(data => {
                const addresses = data.map(JSON.parse).map(c => {
                    return { address: c.address, door: c.door, latitude: c.latitude, longitude: c.longitude, postcode: c.postcode, town: c.town }
                });
                const addresses2 = [];
                addresses.forEach(a => {
                    if (addresses2.find(b => a.address === b.address && a.door === b.door && a.postcode === b.postcode && a.town === b.town))
                        return;
                    addresses2.push(a);
                })
                this.setState({ id: row.id, addresses: addresses2 });
            });
        }
        const rowcopy = { ...row };
        delete rowcopy.latitude;
        delete rowcopy.longitude;
        this.setState({ ...rowcopy, errorField: undefined, phone_valid: true })
        if (row.longitude && row.latitude)
            this.updateMap(row);
    }

    customerAddressSelected = (row) => {
        if (this.state.type === 'pickup') {
            delete row.address;
            delete row.town;
            delete row.postcode;
            delete row.country;
            delete row.door;
        }
        get("customerService/getBookings/" + this.state.id).done(data => {
            console.log(data);
            this.setState({ ...row, addresses: null, tableOccupations: this.convert(data) });
            if (!row.longitude && !row.latitude)
                this.updateMap(row);
            //this.setState({ addresses: addresses2 });
        });
    }

    convert(tableOccupations) {
        const t = tableOccupations.map(o => {
            const res = {};
            res.date = moment(o.bookedOccupationStart ? o.bookedOccupationStart : o.occupationStart).format("YYYY-MM-DD HH:mm");
            res.d = o.bookedOccupationStart ? o.bookedOccupationStart : o.occupationStart;
            res.amount = o?.tableMatePayments.filter(t => t?.tableMate?.tableMateId == this.state.id).map(p => p.payable).reduce((a, b) => a + b, 0);
            res.type = o?.restaurantTables[0]?.restaurantTable?.name;
            res.status = o.active ? I18n.t("admin_local.active") : res.amount > 0 ? I18n.t("local.paid") : I18n.t("local.cancelled");
            res.comment = o?.bookedProperties?.comment || "";
            return res;
        });
        t.sort((b, a) => a.d - b.d);
        return t;
    }

    onChangeIndex = 0;

    onChange = async (event, valid, completeNumber = false) => {
        const state = { ..._state, ...this.state };
        state.errorField = undefined;
        state.focus = false;
        delete state.id;
        state[event.target.id] = event.target.value;
        state[event.target.id + "_valid"] = valid;
        state.booking = { ...this.state.booking, changed: true };
        if (event.target.id === "town") {
            localStorage.defaultTown = event.target.value;
        }
        /*
    if (this.onChangeTimeout) clearTimeout(this.onChangeTimeout);
    this.onChangeTimeout = setTimeout(() => {
            }, 500);*/
        this.setState(state);
        if (event.target.id === "search" && state.search.length > 2) {
            if (this.getUsersContaining) clearTimeout(this.getUsersContaining);
            this.getUsersContaining = setTimeout(() => {
                this.getUsersContaining = undefined;
                this.onChangeIndex++;
                const index = this.onChangeIndex;
                getUsersContaining(state.search, data => {
                    if (this.onChangeIndex === index) {
                        this.setState({ customers: data, addresses: null, tableOccupations: null });
                        if (data.length === 1 && completeNumber) {
                            this.customerSelected(data[0]);
                        }
                    }
                });
            }, 1000);
        } else {
            if ((event.target.id === "email" || event.target.id === "name" || event.target.id === "surname" || event.target.id === "phone") && ((state.email.length > 0 ? 1 : 0) + (state.name.length > 0 ? 1 : 0) + (state.surname.length > 0 ? 1 : 0) + (state.phone.length > 0 ? 1 : 0))) {
                if (this.getUsersContaining) clearTimeout(this.getUsersContaining);
                this.getUsersContaining = setTimeout(() => {
                    this.getUsersContaining = undefined;
                    const search = state.email + state.name + state.surname + state.phone;
                    if (search.length > 2) {
                        this.onChangeIndex++;
                        const index = this.onChangeIndex;
                        getUsersContaining(state.email, state.name, state.surname, state.phone, data => {
                            if (this.onChangeIndex === index)
                                this.setState({ customers: data, addresses: null, tableOccupations: null });
                            if (data.length === 1 && completeNumber) {
                                this.customerSelected(data[0]);
                            }
                        });
                    }
                }, 1000);
            }
        }
        switch (event.target.id) {
            case "address":
            case "town":
            case "postcode":
                if (this.updateMapTimeout) clearTimeout(this.updateMapTimeout);
                this.updateMapTimeout = setTimeout(() => this.updateMap(state), 300);
                break;
            default:
        }
    }

    address_pattern = new RegExp(removeAccents(I18n.t('admin_local.address_pattern')));
    postcode_pattern = new RegExp(removeAccents(I18n.t('admin_local.postcode_pattern')));

    addressSelected = address => {
        if (address.length > 0 && address[0].x) {
            const state = { longitude: address[0].x, latitude: address[0].y, ...address[0], postcode_valid: true };
            console.log("state", state);
            delete state.id;
            this.setState(state);
            this.updateMap(address[0]);
        }
    }

    updateMap = async (state) => {
        this.updateMapTimeout = undefined;
        if (!map)
            return;
        try {

            const filterResult = (results) =>
                results.map(r => {
                    //const m = removeAccents(r.label).match(pattern);
                    const pp = r.label.split(",");
                    //console.log(pp);
                    if (pp.length < 7) {
                        return { town: pp[0], x: r.x, y: r.y };
                    }
                    var address1 = pp[0].trim();
                    var address2 = pp[1].trim();
                    var address3 = pp[2].trim();
                    var postcode = pp[pp.length - 2].trim();
                    var address = null;
                    const match = removeAccents(state.address.toLowerCase()).match(this.address_pattern);
                    const match1 = removeAccents(address1 + " 1").match(this.address_pattern);
                    const match2 = removeAccents(address2 + " 1").match(this.address_pattern);
                    const match3 = removeAccents(address3 + " 1").match(this.address_pattern);
                    const match4 = removeAccents(postcode).match(this.postcode_pattern);
                    const town = postcodes[pp[pp.length - 1].trim()] ? postcodes[pp[pp.length - 1].trim()][postcode] : "";
                    if (!match4) {
                        return { postcode: postcode, town: town, x: r.x, y: r.y };
                    }
                    if (match1)
                        address = address1;
                    else if (match2)
                        address = address2;
                    else if (match3)
                        address = address3;
                    if (!address)
                        return { postcode: postcode, town: town, x: r.x, y: r.y };
                    if (match && match[4])
                        return { address: address + " " + match[4], postcode: postcode, town: town, x: r.x, y: r.y }
                    else
                        return { address: address, postcode: postcode, town: town, x: r.x, y: r.y }
                }).filter(p => p && (!state.postcode || state.postcode == p.postcode) && (!state.town || state.town == p.town));


            if (this.updateMapTimeout)
                clearTimeout(this.updateMapTimeout);
            this.updateMapTimeout = setTimeout(async () => {
                this.updateMapTimeout = null;
                if (!state.postcode && state.country === "Magyarország" && state.town) {
                    const postcode = Object.keys(postcodes.Hungary).filter(p => postcodes.Hungary[p] === state.town);
                    if (postcode.length === 1) {
                        state.postcode = postcode[0];
                    }
                }
                try {
                    var query = { street: state.address.toLowerCase(), postalcode: state.postcode, city: state.town, town: state.town, country: this.props.myStatus.restaurant_country };
                    var results = await provider.search({ query: query });
                } catch (ex) {
                    results = await provider.search({ query: state.address.toLowerCase() + ", " + state.postcode + " " + state.town + ", " + state.postcode + " , " + this.props.myStatus.restaurant_country });
                }
                var places = filterResult(results);

                if (!places.length) {
                    try {
                        var address = state.address.toLowerCase();
                        var match = address.match(/(.*) (\d+)\.?/);
                        if (match) {
                            address = match[1];
                            var query = { street: address, postalcode: state.postcode, city: state.town, town: state.town, country: this.props.myStatus.restaurant_country };
                            results = await provider.search({ query: query });
                            places = filterResult(results);
                        }
                    } catch (ex) {
                        results = await provider.search({ query: address + ", " + state.postcode + " " + state.town + ", " + state.postcode + " , " + this.props.myStatus.restaurant_country });
                    }
                }

                const res = [];
                places.forEach(p => {
                    if (p !== null && res.filter(r => r.address === p.address && r.postcode === p.postcode).length === 0)
                        res.push(p);
                })
                this.setState({
                    address_choices: res.filter(r => r.address)
                });

                if (places.length > 0) {
                    //this.setState({ longitude: state.longitude || places[0].x, latitude: state.latitude || places[0].y, zoom: 16 })
                    console.log(state.latitude || places[0].y, state.longitude || places[0].x, state);
                    map.map.flyTo([state.latitude || places[0].y, state.longitude || places[0].x], 16);
                }
            }, 1000);
        } catch (ex) {

        }
    };
}

export default CustomerDeliveryDetails;
