import React, { Component } from "react";
import orderListUtil, { updateTableMates, update, updateOrders, deleteOrders, getTableName2 as getTableName, simpleChangeStateByTableAndMenuItem as simpleChangeStateByTableAndMenuItem2, playNewAcceptedOrderSound, initSound } from "../../js/order-list-util";
import { I18n, Translate } from "react-redux-i18n";
import { connect } from "react-redux";
import LogoutButton from "../../components/icon-buttons/LogoutButton";
import LoginButton from "../../components/icon-buttons/LoginButton";
import AICommandBox from "../../js/ai/AICommandBox";
import auth, { getMyImageUrl, startpoll, stopPool } from "../../js/auth";
import EatWithMeText from "../../components/EatWIthMeText";
import MenuItemHeader from "../../components/MenuItemHeader";
import ChangeLanguage from "../../components/icon-buttons/ChangeLanguage";
import UserImageButton from "../../components/icon-buttons/UserImageButton";
import admin, { getTableOccupations } from "../../js/admin";
import "./WaiterScreen.css";
import tableLayout from "../../js/table-layout";
import $ from "jquery";

class WaiterScreen extends Component {
  state = {
    initialized: false,
    soundon: Boolean(localStorage.soundon),
    playSound: false
  };

  componentDidMount() {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;

    if (myStatus && myStatus.restaurant_id) {
      this.setState({ initialized: true });
      orderListUtil.show = () => true;
      this.initialize();
    }
    window.simpleChangeStateByTableAndMenuItem = this.simpleChangeStateByTableAndMenuItem;
    if (this.state.soundon) {
      initSound();
    }

  }

  simpleChangeStateByTableAndMenuItem = (t, previousState, state, timing) => {
    const def = $.Deferred();
    simpleChangeStateByTableAndMenuItem2(t, previousState, state, timing, def);
    def.promise().done(() => {
      orderListUtil.processOrders(orderListUtil.ordersCache, false);
      this.setState({
        orders: orderListUtil.orders
      })
    });
  }

  recover = () => {
    admin.getUnprocessedOrders([], orders => {
      orderListUtil.ordersCache = orders;
      orderListUtil.processOrders(orders);
      this.setState({
        orders: orderListUtil.orders
      })
    })
  }

  initialize = () => {
    orderListUtil.states = ["ordered", "delivering"];
    getTableOccupations((data) => {
      orderListUtil.tableOccupations = data;
      updateTableMates();
      admin.getActiveMenuItems((categs) => {
        admin.categories = categs;
        admin.getUnprocessedOrders([], orders => {
          orderListUtil.ordersCache = orders;
          orderListUtil.processOrders(orders);
          this.setState({
            tableOccupations: data,
            categories: categs,
            orders: orderListUtil.orders
          })
        }, {
          show: function (order) {
            return order.type === "meal";
          },
          done: function () {
          }
        });
      }, localStorage.language);
    });
    startpoll(this.serverSideMessageHandler, [
      {
        Restaurant: localStorage.restaurantSelected,
        Order: "*"
      },
      {
        Restaurant: localStorage.restaurantSelected,
        Menu: "*"
      },
      {
        Restaurant: localStorage.restaurantSelected,
        TableOccupation: "*"
      }
    ], undefined, this.recover);
    this.refreshCycle = setInterval(() => {
      this.setState({ timestamp: new Date().getTime() });
    }, 10000);
  }

  serverSideMessageHandler = (message) => {
    try {
      if (message.type === "timeout" && message.message === "true") return;
      if (message.type === "refresh") {
        if (message.message === "order changed" || message.message === "order state changed" || message.message === "new order" || message.message === "new order selected") {
          if (message.data) update(message.data);
          else admin.getOrder(message.TableOccupation, message.Order, updateOrders);
        } else if (message.message == "table changed" || message.message == "waiter called") {
          if (message.data) {
            update(message.data);
            this.refresh();
          } else admin.getTableOccupation(message.TableOccupation, this.refresh);
        } else if (message.message === "order cancelled") {
          if (message.data) {
            orderListUtil.deleteOrders(message.Order, true);
            update(message.data);
          } else {
            deleteOrders(message.Order);
          }
        }
        this.setState({
          orders: orderListUtil.orders
        })
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  componentDidUpdate() {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;

    if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
      this.setState({ initialized: true });
      orderListUtil.show = () => true;
      this.initialize();
    }
  }

  componentWillUnmount() {
    this.setState({ initialized: false });
    clearInterval(this.refreshCycle);
    stopPool();
    clearInterval(this.playSoundInterval);
  }

  render() {
    const { myStatus } = this.props;
    const { orders } = this.state;
    const now = new Date().getTime();
    if (myStatus && myStatus.restaurant_id && orders) {
      const delivering = orders.tables.filter(t => {
        return t.stateMap.delivering.length > 0;
      }).sort((a, b) => b.inStateDurations.delivering - a.inStateDurations.delivering);
      const ordered = orders.tables.filter(t => {
        return t.stateMap.ordered.length > 0;
      }).sort((a, b) => b.inStateDurations.delivering - a.inStateDurations.delivering);
      const waiterCalled = orderListUtil.tableOccupations && orderListUtil.tableOccupations.filter(to => to.restaurantTables.filter(rt => rt.waiterNeeded).length).map(to => to.restaurantTables.filter(rt => rt.waiterNeeded).map(t => {
        return { ...t, tableOccupation: to.id }
      })).reduce((a, b) => [...a, ...b], []).sort((a, b) => a.waiterNeededSince - b.waiterNeededSince);
      if (!!(delivering.length + ordered.length + waiterCalled.length) !== this.state.playSound) {
        if (!this.state.playSound) {
          playNewAcceptedOrderSound(true);
          this.playSoundInterval = setInterval(playNewAcceptedOrderSound, 20000);
        } else {
          if (this.playSoundInterval) {
            clearInterval(this.playSoundInterval);
            this.playSoundInterval = null;
          }

        }
        this.setState({ playSound: !this.state.playSound });
      }
      return (
        <div id="waiterScreen" style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "0" }}>
          {!auth.isIncludedInFrame ? (
            <div
              className="menuItemHeader"
              style={{ flexShrink: 0, display: "flex", flexFlow: "row", width: "100%", alignItems: "center", height: "50px", justifyContent: "space-between", paddingRight: "30px" }}
            >
              <EatWithMeText />
              <MenuItemHeader {...this.props} header={<Translate value="admin_local.menus.serving" />} />
              <button data-toggle="toggle" onClick={this.toggleSound} id="sound" className={"btn icon " + (this.state.soundon ? 'icon-volume-up' : 'icon-volume-off')}>
              </button>

              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <ChangeLanguage />
                <UserImageButton src={getMyImageUrl()} />
                <div style={{ marginLeft: "10px", width: "70px", display: "flex", justifyContent: "space-between" }}>
                  <LoginButton query="keep=true&redirect=/tablesOverview" />
                  <LogoutButton query="keep=true&redirect=/tablesOverview" />
                </div>
              </div>
            </div>
          ) : (
            ""
          )
          }
          <audio id="front-desk-bells" src="https://console.eatwithme.online/audio/front-desk-bells.wav" preload="auto"></audio>
          <audio id="silent" src="https://console.eatwithme.online/audio/silent.wav" preload="auto"></audio>
          <div id="main" >
            <div class="header">{I18n.t("admin_local.customer_orders")}</div>
            <div class="ordered">
              {ordered.map(o => (
                <div orders={o.orderIds} tableOccupation={o.tableOccupation} tableNumber={o.tableNumber} onClick={this.acceptOrders}>{getTableName(o.tableNumber)}
                  <div class="duration">{formatSeconds(Math.round((now - o.inStateTimeStamp.delivering) / 1000))}</div>
                </div>
              ))}
            </div>
            <div class="header">{I18n.t("admin_local.deliverable_tables")}</div>
            <div class="delivering">
              {delivering.map(o => (
                <div orders={o.orderIds} tableOccupation={o.tableOccupation} tableNumber={o.tableNumber} onClick={this.tableDelivered}>{getTableName(o.tableNumber)}
                  <div class="duration">{formatSeconds(Math.round((now - o.inStateTimeStamp.delivering) / 1000))}</div>
                </div>
              ))}
            </div>
            <div class="header">{I18n.t("admin_local.assistance_needed")}</div>
            <div class="waiterCalled">
              {waiterCalled.map(t => (
                <div tableOccupation={t.tableOccupation} tableNumber={t.restaurantTable.number} onClick={this.waiterCalled}>{t.restaurantTable.name || t.restaurantTable.number}</div>
              ))}
            </div>
          </div >
        </div >
      );
    } else return (<div id="waiterScreen" style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "0" }}>
      {!auth.isIncludedInFrame ? (
        <div
          className="menuItemHeader"
          style={{ flexShrink: 0, display: "flex", flexFlow: "row", width: "100%", alignItems: "center", height: "50px", justifyContent: "space-between", paddingRight: "30px" }}
        >
          <EatWithMeText />
          <MenuItemHeader {...this.props} header={<Translate value="admin_local.menus.serving" />} />
          <button data-toggle="toggle" onClick={this.toggleSound} id="sound" className={"btn icon " + (this.state.soundon ? 'icon-volume-up' : 'icon-volume-off')}>
          </button>

          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <ChangeLanguage />
            <UserImageButton src={getMyImageUrl()} />
            <div style={{ marginLeft: "10px", width: "70px", display: "flex", justifyContent: "space-between" }}>
              <LoginButton query="keep=true&redirect=/tablesOverview" />
              <LogoutButton query="keep=true&redirect=/tablesOverview" />
            </div>
          </div>
        </div>
      ) : (
        ""
      )
      }
      <audio id="front-desk-bells" src="https://console.eatwithme.online/audio/front-desk-bells.wav" preload="auto"></audio>
      <audio id="silent" src="https://console.eatwithme.online/audio/silent.wav" preload="auto"></audio>
      <div id="main" >
      </div >
    </div >);
  }

  tableDelivered = (e) => {
    tableLayout.tableDelivered(e).done(this.refresh);
  }

  acceptOrders = (e) => {
    const tableNumber = $(e.target).attr("tableNumber");
    tableLayout.showSimpleOrderTimeout = setTimeout(() => tableLayout.showSimpleOrder(tableNumber, 'ordered', undefined, true), 400);
    tableLayout.tableAccepted(e, tableNumber).done(this.refresh);
  }

  refresh = () => {
    orderListUtil.processOrders(orderListUtil.ordersCache, false);
    this.setState({
      orders: orderListUtil.orders
    })
  }

  toggleSound = () => {
    this.setState({ soundon: !this.state.soundon });
    localStorage.soundon = !this.state.soundon;
    if (!this.state.soundon) {
      playNewAcceptedOrderSound();
    }
  }

  waiterCalled = (e) => {
    const tableNumber = $(e.target).attr("tableNumber");
    const tableOccupation = $(e.target).attr("tableOccupation")
    tableLayout.waiterCalled(tableNumber, tableOccupation).done(() => {
      orderListUtil.processOrders(orderListUtil.ordersCache, false);
      this.setState({
        orders: orderListUtil.orders
      })
    });
  }

}

function formatSeconds(seconds) {
  // Calculate minutes and remaining seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Format remaining seconds to always have two digits
  const formattedSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

  // Combine minutes and formatted seconds
  return minutes + ':' + formattedSeconds;
}


const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    return {
      restaurant: props.rootReducer.restaurant,
      myStatus: props.rootReducer.myStatus,
      admin_local: props.rootReducer.admin_local,
      local: props.rootReducer.local,
      speechRecognitionOn: props.rootReducer.speechRecognitionOn,
      ai: props.rootReducer.ai,
      speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable
    };
  }
  return {};
};

export default connect(mapStateToProps)(WaiterScreen);
