import $ from "jquery";
import EXIF from "exif-js";
import "./patch/rcrop";
import "./patch/rcrop.min.css";
import auth from "./auth";
// Called when a photo is successfully retrieved
//
var cropImage = null;

export const rcorp = {};

export const onPhotoDataSuccess = (imageData, img, minWidth = 160, minHeight = 160, width = 160, height = 160, cropit = false) => {
  // Get image handle
  //
  var smallImage = typeof img === "undefined" || img.length === 0 ? $("img#smallImage") : img;
  // Unhide image elements
  //
  // Show the captured photo
  // The inline CSS rules are used to resize the image
  //
  if (smallImage.get(0).nodeName === 'TEXTAREA') {
    $(smallImage).val((imageData.startsWith("data:image") ? "" : "data:image/jpeg;base64,") + imageData);
    return;
  } else
    smallImage.prop("src", (imageData.startsWith("data:image") ? "" : "data:image/jpeg;base64,") + imageData);
  if (cropit) {
    if (cropImage != null) {
      cropImage.rcrop("destroy");
      cropImage.removeData("rcrop");
    }
    cropImage = smallImage.rcrop({
      full: true,
      minSize: [minWidth, minHeight],
      preserveAspectRatio: true,
      preview: {
        display: false,
        size: [width, height]
      }
    });
    smallImage.rcrop("crop");
    smallImage
      .parents(".modal")
      .find("#crop")
      .css("display", "block");
    smallImage.parents("#smallImageDiv").removeClass("hidden");
    smallImage
      .parents("#smallImageDiv")
      .find(".icon-trash-empty")
      .removeClass("hidden");
    rcorp.rcrop = true;
  } else {
    smallImage.parents("#smallImageDiv").removeClass("hidden");
    smallImage
      .parents("#smallImageDiv")
      .find(".icon-trash-empty")
      .removeClass("hidden");
  }
};

// A button will call this function
//
export const capturePhotoWithData = (capture, selector, cropit = true) => {
  // Take picture using device camera and retrieve image as base64-encoded
  // string
  if (selector === undefined) selector = "#smallImage";
  // eslint-disable-next-line no-restricted-globals
  if (auth.device.platform === "Android" || (location.host !== null && location.host !== "")) {
    $("div#camera").empty();
    $('<input id="camera" type="file" name="image" accept="image" ' + (capture ? 'capture="environment"' : "") + "></input>").appendTo($($("div#camera").get(0)));
    $("input#camera").trigger("click");

    $("#camera").off("change");
    $("#camera").on("change", function (data) {
      var file = $("input#camera").get(0).files[0];
      //document.createElement("canvas");
      var orientation = EXIF.getTag(this, "Orientation");

      var reader = new FileReader();

      EXIF.getData(file, function () {
        var can = document.createElement("canvas");
        var ctx = can.getContext("2d");
        var thisImage = new Image();
        thisImage.onload = function () {
          try {
            can.width = thisImage.width;
            can.height = thisImage.height;
            ctx.save();
            var width = can.width;
            var styleWidth = can.style.width;
            var height = can.height;
            var styleHeight = can.style.height;
            if (orientation) {
              if (orientation > 4) {
                can.width = height;
                can.style.width = styleHeight;
                can.height = width;
                can.style.height = styleWidth;
              }
              switch (orientation) {
                case 2:
                  ctx.translate(width, 0);
                  ctx.scale(-1, 1);
                  break;
                case 3:
                  ctx.translate(width, height);
                  ctx.rotate(Math.PI);
                  break;
                case 4:
                  ctx.translate(0, height);
                  ctx.scale(1, -1);
                  break;
                case 5:
                  ctx.rotate(0.5 * Math.PI);
                  ctx.scale(1, -1);
                  break;
                case 6:
                  ctx.rotate(0.5 * Math.PI);
                  ctx.translate(0, -height);
                  break;
                case 7:
                  ctx.rotate(0.5 * Math.PI);
                  ctx.translate(width, -height);
                  ctx.scale(-1, 1);
                  break;
                case 8:
                  ctx.rotate(-0.5 * Math.PI);
                  ctx.translate(-width, 0);
                  break;
                default:
              }
            }

            ctx.drawImage(thisImage, 0, 0);
            ctx.restore();
            var dataURL = can.toDataURL();
            // at this point you can save the image away to your back-end using 'dataURL'
            onPhotoDataSuccess(dataURL, $(selector), undefined, undefined, undefined, undefined, cropit);
          } catch (ex) {
            alert(ex);
          }
        };

        // now trigger the onload function by setting the src to your HTML5 file object (called 'file' here)
        thisImage.src = URL.createObjectURL(file);
      });

      reader.readAsDataURL(file);
    });
  } else {
    if (capture) {
      navigator.camera.getPicture(onPhotoDataSuccess, onFail, {
        quality: 50,
        correctOrientation: true,
        destinationType: window.Camera.DestinationType.DATA_URL
      });
    } else {
      navigator.camera.getPicture(onPhotoDataSuccess, onFail, {
        quality: 50,
        correctOrientation: true,
        destinationType: window.Camera.DestinationType.DATA_URL,
        sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY
      });
    }
  }
};

//Called if something bad happens.
//
function onFail(message) {
  alert("Failed because: " + message);
}

export const crop = (img, width = 160, height = 160) => {
  /*
  var srcOriginal = $('#smallImage').rcrop('getDataURL');
  var smallImage = document.getElementById('smallImage');
  smallImage.src = srcOriginal;
  var r = $('#smallImage').rcrop("crop");
  */
  if (rcorp.rcrop !== true)
    return;
  var smallImage = typeof img == "undefined" ? $("img#smallImage") : img;
  var srcOriginal = smallImage.rcrop("getDataURL", width, height);
  var srcBigOriginal = smallImage.rcrop("getDataURL", 1000, 1000);
  smallImage.data("bigImage", srcBigOriginal);
  smallImage.attr("src", srcOriginal);
  var sm = smallImage.get(0);
  smallImage.rcrop("destroy");
  $.removeData(sm, "rcrop");
  $(sm).attr("style", "width:" + height + "px");
  smallImage
    .parents(".modal")
    .find("#crop")
    .css("display", "none");
  rcorp.rcrop = false;
};
