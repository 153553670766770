import React from "react";
import AbstractRestaurantEditor from "./AbstractRestaurantEditor";
import Form from "react-jsonschema-form";
import Buttons from "./Buttons";
import $ from "jquery";
import { onPhotoDataSuccess, crop } from "../../js/camera-util"
import auth from "../../js/auth";

export const uiSchema = {
  invoiceHeading: {
    "ui:widget": "textarea"
  },
  css: {
    "ui:widget": "textarea"
  }
};

class RestaurantEditor extends AbstractRestaurantEditor {
  state = {};

  componentDidMount() {
    crop();
    const instance = this;
    $("body").on("paste", function (event) {

      var items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let index in items) {
        var item = items[index];
        if (item.kind === 'file') {
          var blob = item.getAsFile();
          var reader = new FileReader();
          reader.onload = function (event) {
            instance.setState({ restaurant: { ...instance.state.restaurant, image: event.target.result } });
            onPhotoDataSuccess(event.target.result, $('textarea:focus'));
          }; // data url!
          reader.readAsDataURL(blob);
        }
      }

    })
  }

  componentDidUpdate = () => {
    if (document.getElementById("root_invoiceHeading") && !window.CKEDITOR.instances.root_invoiceHeading) {
      window.CKEDITOR.replace("root_invoiceHeading");
      window.CKEDITOR.instances.root_invoiceHeading.on('change', () => {
        this.setState({ restaurant: { ...this.state.restaurant, invoiceHeading: window.CKEDITOR.instances.root_invoiceHeading.getData() }, changed: true });
      });

    }

  }

  componentWillUnmount() {
    if (window.CKEDITOR.instances.root_invoiceHeading)
      window.CKEDITOR.instances.root_invoiceHeading.destroy();
  }

  render() {
    if (this.state.restaurant) {
      const schema = {
        type: "object",
        properties: {
          globalId: {
            type: "number"
          },
          kubernetesLive: {
            type: "boolean"
          },
          kubernetesBackup: {
            type: "boolean"
          },
          subscription: {
            type: "string"
          },
          instance: {
            type: "string"
          },
          invoiceDueSince: {
            type: "number"
          },
          name: {
            type: "string"
          },
          isActive: {
            title: "active",
            type: "boolean",
            default: true
          },
          test: {
            type: "boolean",
            default: true
          },
          backup: {
            type: "boolean",
            default: false
          },
          baseCurrency: {
            type: "string",
            required: true,
            enum: this.props.myStatus.currencies.map(c => c.name),
            //enumNames: this.props.myStatus.currencies.map(c => c.name)
          },
          languages: {
            type: "string"
          },
          domainprefix: {
            type: "string"
          },
          company: {
            type: "string"
          },
          phone: {
            type: "string"
          },
          postcode: {
            type: "string"
          },
          address: {
            type: "string"
          },
          town: {
            type: "string"
          },
          country: {
            type: "string"
          },
          helmtag: {
            type: "string"
          },
          helmset: {
            type: "string"
          },
          secretKey: {
            type: "string"
          },
          serverUrl: {
            type: "string"
          },
          backupUrl: {
            type: "string"
          },
          barionPOSKey: {
            type: "string"
          },
          invoiceHeading: {
            type: "string"
          },
          css: {
            type: "string"
          }
        }
      };
      return (
        <div style={{ height: "100%", overflow: "hidden", overflowY: "auto" }}>
          <div id="smallImageDiv" style={{ width: "160px", position: "relative", left: "50%", transform: "translateX(-50%)" }}>
            <img alt="" id="smallImage" style={{ maxWidth: "160px", maxHeight: "160px" }} src={auth.server + "/" + auth.war + "/adminService/" + this.state.restaurant.id + "/getRestaurantImage"} className="rounded mx-auto d-block"></img>
          </div>
          <Form onChange={this.onChange} schema={schema} formData={this.state.restaurant} uiSchema={uiSchema} />
          <Buttons restaurant={this.state.restaurant} changed={this.state.changed} handleCancel={() => this.props.handleCancel(this.state.restaurant)} handleSave={this.onSave} />
        </div >
      );
    }
    return null;
  }

  onSave = () => {

    crop();

    const image = document.getElementById('smallImage').src;

    this.props.handleSave({ ...this.state.restaurant, image: image });
  }

  onChange = ({ formData }, e) => {
    this.setState({ restaurant: { ...this.state.restaurant, ...formData }, changed: true });
  };
}

export default RestaurantEditor;
