const admin_local_hu = {
  confirm_customer_deletion: "Biztoson törölni akarja a <b>{0}<b> felhasználót?",
  confirm_role_deletion: "Biztoson törölni akarja a <br><b>{0}</b> felhasználó<br><b>{1}</b> jogosultságát a<br><b>{2}</b>-hoz?",
  do_you_really_want_to_delete_the_menu_category: "Biztoson törölni akarja a menü kategóriát?",
  do_you_really_want_to_delete_the_menu_item: "Biztoson törölni akarja a menü tételt?",
  failed_to_delete_user: "Sikeretelen törlés.<br>Hiba: {0}",
  failed_to_delete_user_anonimize: "A felhasználót nem lehet törölni! Akarja anonimizálni? Neve, emailcíme, jelszava törlődni fog!",
  are_you_sure_you_want_to_delete_the_table: "Biztosan ki akarja törölni a <b>{0}</b> asztalt?",
  are_you_sure_you_want_to_delete_the_room: "Biztosan ki akarja törölni a <b>{0}</b> termet?",
  are_you_sure_you_want_to_delete: "Biztosan ki akarja törölni?",
  failed_to_delete_table_in_use: "Az asztal nem törölhető! Már használatban van! Deaktiválja inkább!",
  failed_to_create_table_not_unique: "Az asztal nem hozható létre! Az asztal száma már használatban van!",
  failed_to_delete_drink_category_in_use: "Az ital kategória nem törölhető! Már használatban van! Deaktiválja inkább!",
  failed_to_delete_drink_in_use: "Az ital nem törölhető! Már használatban van! Deaktiválja inkább!",
  failed_to_delete_meal_category_in_use: "Az étel kategória nem törölhető! Már használatban van! Deaktiválja inkább!",
  failed_to_delete_meal_in_use: "Az étel nem törölhető! Már használatban van! Deaktiválja inkább!",
  failed_to_delete_discount_type_in_use: "A kedvezmény típus nem törölhető! Már használatban van! Deaktiválja inkább!",
  failed_to_delete_label_in_use: "A címke nem törölhető! Már használatban van!",

  table_number_label: "Asztal száma",
  number_of_seats: "Helyek száma",
  shareable: "Megosztható",
  free_seats: "Szabad helyek száma",
  active: "Aktív",
  _active: "Akt.",
  table_editor: "Asztal",

  add: "Új",
  edit: "Módosít",
  delete: "Töröl",
  activate: "Aktivál",
  deactivate: "Deaktivál",
  make_shareable: "Legyen megosztható",
  make_unshareable: "Ne legyen megosztható",
  make_pos: "Legyen kassza",
  make_non_pos: "Ne legyen kassza",
  lock: "Lefoglal",
  new_lock: "+ Foglalás",
  lock_pickup: "Új elvitel",
  lock_homedelivery: "Új házhozszállítás",
  unlock: "Felszabadít",

  tables: "Asztalok",
  enter_table_number: "Adja meg az asztal számát",
  enter_table_seats: "Adja meg a helyek számát",
  table_is_shareable: "Az asztal megosztható",
  table_is_active: "Az asztal aktív",
  table_free_seats: "Szabad helyek száma",

  unit_price: "Egységár",
  _unit_price: "Egy.ár",

  drinks: "Italok",
  drink_name: "Ital neve",
  drink_quantity_type: "Mértékegység",
  drink_available_quantity: "Elérhető kiszerelések",
  drink_quantity_name: "Megnevezés",
  drink_quantity: "Mennyiség",
  drink_price: "Ár",
  drink_editor: "Ital",
  drink_category_name: "Kategória",
  drink_category_editor: "Ital kategória",
  add_category: "Új kategória",
  _add_category: "Új kat.",
  add_item: "Új elem",
  _add_item: "Új elem",

  meals: "Ételek",
  meal_name: "Étel neve",
  meal_quantity_type: "Mértékegység",
  meal_available_quantity: "Elérhető kiszerelések",
  meal_quantity_name: "Megnevezés",
  meal_quantity: "Mennyiség",
  meal_price: "Ár",
  meal_editor: "Étel",
  meal_category_name: "Kategória",
  meal_category_editor: "Étel kategória",
  add_meal_category: "Új kategória",
  add_meal: "Új étel",

  available_quantity: "Elérhető kiszerelések",
  _available_quantity: "Kisz.",

  description: "Bővebb leírás",
  enter_description: "Adjon bővebb leírást, ha szükséges",

  language: "Nyelv",
  default_language: "Alapértelmezett",
  not_defined: "???",
  close: "Bezár",
  close_order: "Lezár",
  save_and_new: "Mentés és Másolat",
  pay: "Fizet",
  save_and_unlock: "Fizet és felszabadít",

  rejection_reason: "Elutasítás indonka",
  typical_rejection_reasons: "Tipikus indokok",
  custom_reason: "Egyedi indok",

  table_number: "{0}. asztal",
  team_number: "{0}. társaság",
  organizer: "Szervező: {0}",
  order_number: "{0}. rendelés",
  oldest_order: "Legrégebbi rendelés: {0}",
  table_occupation_number: "{0}. foglalás",

  orders_tab: "Rendelések",
  by_person_tab: "Személyenként",
  by_timing_tab: "Fogás szerint",
  by_drink_or_meal_tab: "Tétel szerinti bontás",

  inherit: "Örököl",
  meal_timing: "Fogás",
  timing_inherit: "Örököl",
  timing_asap: "Előre",
  timing_appetizer: "Leves",
  timing_maincourse: "Köztes",
  timing_desert: "Főétel",
  timing_last: "Desszert",
  timing: "Fogás",
  timing_null: "",

  production_lines: "Pályák",
  production_line_editor: "Pálya",

  production_line: "Pálya",
  _production_line: "Pálya",
  production_line_waiting: "Függő pálya",
  production_line_length: "Sor",
  production_line_title: "{0} pálya",
  all_production_lines: "Minden pálya",
  default_production_line: "Alapértelmezett pálya",
  _default_production_line: "Pálya",
  production_line_name: "Neve",
  enter_production_line_name: "Adja meg a pálya nevét",
  production_line_is_active: "A pálya aktív",
  are_you_sure_you_want_to_delete_the_production_line: "Biztosan ki akarja törölni a <b>{0}</b> pályát?",
  failed_to_delete_production_line_in_use: "A pálya nem törölhető! Már használatban van! Deaktiválja inkább!",
  failed_to_create_production_line_not_unique: "A pálya nem hozható létre mert nem egyedi!",

  vat_categories: "ÁFA kategóriák",
  vat_category_name: "ÁFA kategória neve",
  vat_category: "ÁFA kategória",
  _vat_category: "ÁFA",
  vat_category_editor: "ÁFA kategória",
  enter_vat_category_name: "Adja meg a ÁFA categória nevét",
  percent_name: "Százalék",
  enter_percent: "Adja meg az ÁFA értékét százalékban (pl. 27)",
  default_vat_category: "ÁFA",
  _default_vat_category: "ÁFA",
  default_takeaway_vat_category: "Elviteles ÁFA",
  _default_takeaway_vat_category: "Elv. ÁFA",

  occupy: "Lefoglal",
  number_of_persons: "Személyek száma",
  unoccupy: "Felszabadít",
  new_order: "Új rendelés",
  submit_new_orders: "Megrendelések rögzítése",
  submit_and_accept_new_orders: "Megrendelések elfogadása",
  submit_and_accept_and_print_new_orders: "Megrendelések nyomtatása",
  submit_and_accept_and_print_and_pay_new_orders: "Fizetés",
  paying: "Számla",
  cancel_paying: "Számla visszavonása",
  ordering_allowed: "Számla visszavonva, rendelés újra megengedett!",
  paid: "Fizetve",

  please_choose_mate_wanting_to_pay: "Mely személyek szeretnének fizetni?",
  please_choose_mate_who_paid: "Mely személyek fizettek?",
  print_invoice: "Számla nyomtatása",

  roles: "Jogosultságok",
  currencies: "Pénznemek",
  payment_methods: "Fizetési módok",

  role: "Jogosultság",
  payment_method: "Fizetési mód",
  _payment_method: "Fiz. mód",
  user: "Felhasználó",
  users: "Felhasználók",
  entity_type: "Entitás típusa",
  entity: "Entitás",
  entities: "Entitások",

  restaurant_editor: "Vendéglátóhely",
  name: "Megnevezés",
  _name: "Név",
  languages: "Nyelvek",
  town: "Helyiség",
  longitude: "Hosszúsági fok",
  latitude: "Szélességi fok",

  exchange_rate: "Váltási árfolyam",
  _exchange_rate: "Árf.",

  please_select_a_restaurant: "Kérem válasszon egy vendéglátóhelyet",

  invoice_heading: "Számla fejléc",
  enter_invoice_heading: "Kérem adja meg a számlák fejlécét html formátumban!",
  settings: "Beállítások",

  not_allowed_order_paid: "Nem megengedett művelet egy kifizetett tétellel!",
  not_allowed_order_is_being_paid: "Nem megengedett művelet egy fizetésre kiválasztott tétellel!",

  more_than_a_day: "> 1 nap",

  active_average_acceptance_duration: "Átlagos rendelés elfogadás",
  active_average_delivering_duration: "Átlagos rendelés átfutás",
  active_max_acceptance_duration: "Leglassabb rendelés elfogadás",
  active_max_delivering_duration: "Leglassabb rendelés átfutás",
  active_min_acceptance_duration: "Leggyorsabb rendelés elfogadás",
  active_min_delivering_duration: "Leggyorsabb rendelés átfutás",

  mandatory: "Kötelezően tartalmazza",
  included: "Alapértelmezetten tartalmazza",
  optional: "Opciók",
  references: "Hivatkozások",
  isTop: "Menün",
  _isTop: "M.",

  failed_to_get_restaurant_layout: "Nincs asztaltérkép feltöltve! Kérem töltsön fel egyet először!",

  qr_code: "QR kód",
  person: "Személy",

  printers: "Nyomtatók",
  printer: "Nyomtató",
  connected_from: "Kapcsolódva",

  collaborating_production_lines: "Együttműködnek",
  short_name: "Rövid név",
  do_you_really_want_to_print_the_orders_until_timing: "Tényleg csak a(z) <b>{0}</b> fogásig szeretné kinyomtatni a rendeléseket?",
  do_you_really_want_to_print_only_the_selected_orders: "Tényleg csak a kijelölt rendelést szeretné kinyomtatni?",
  do_you_really_want_to_reprint_the_orders_until_timing: "Tényleg <b><u>újra</u></b> akarja nyomtatni a rendeléseket a <b>{0}</b> fogásig?",
  do_you_really_want_to_reprint_only_the_selected_orders: "Tényleg <b><u>újra</u></b> akarja nyomtatni a kijelölt rendeléseket?",
  enable_edit_mode: "Asztalelrendezés módosítása",
  disable_edit_mode: "Asztalelrendezés visszaállítása",
  change_table_number: "Asztal módosítása",
  save_layout: "Az asztalelrendezés mentése",
  layout_succesfully_saved: "Az asztalelrendezés sikeresen elmentve!",
  table: "Asztal",
  seat: "Szék",
  rotate_right: "Jobbra forgat (45°)",
  rotate_left: "Balra forgat (45°)",
  duplicate: "Duplikál",
  enter_barion_pos_key: "Adja meg a Barionos titkos azonosítóját (POSKey)",

  printer_server_name: "Nyomtató neve",
  printInvoice: "Számla nyomtatás",
  printTableSlip: "Asztal azonosító",
  printVATInvoice: "ÁFÁs számla nyomtatás",
  printer_actions: "Nyomtatási műveletek",
  format: "Formátum",

  quantity_types: "Kiszerelések",
  serving_type: "Kiszerelés",

  enter_category: "Adja meg a kategória nevét",
  enter_Drink_menuitem: "Adja meg az ital nevét",
  enter_Meal_menuitem: "Adja meg az étel nevét",

  scan_to_pay_table: "Szkennelje a telefonjával,\r\nha látni szeretné a {0}. asztal számláját és,\r\nha online ki szeretné fizetni!",
  scan_to_join_table: "!ESC|4C{0} asztal!LF!LF!ESC|4C!ESC|cASzkenneld és rendelj!LF!ESC|cAés kövesd a fogyasztásodat a telefonodról!",

  print_receipt: "Nyugta nyomtatása",
  choose_customer_for: "Vendég kiválasztása a {0}. személyhez",
  search_pattern: "Keresési minta",
  clear: "Töröl",

  discount_types: "Kedvezmény típusok",
  discount: "Kedvezmény",
  _discount: "Kedv.",
  scope: "Mire",
  when: "Mikor",
  how_much: "Mennyit",
  cumulative: "Kumulatívan",
  categories_or_menuitems: "Menü elemek vagy kategoriák",
  labels: "Cimkék",
  fromDate: "Dátumtól",
  toDate: "Dátumig",
  fromTime: "Időponttól",
  datetime: "Időpont",
  discount_percent: "Százalék",
  discount_amount: "Adott összeg",
  on_day: "A hét napján",
  day1: "Vasárnap",
  day2: "Hétfő",
  day3: "Kedd",
  day4: "Szerda",
  day5: "Csütörtök",
  day6: "Péntek",
  day7: "Szombat",
  per_item: "Darabonként",
  label: "Címke",
  are_you_sure_you_want_to_delete_the_label: "Biztosan ki akarja törölni a <b>{0}</b> címkét?",
  enter_labels: "Válasszon címkéket",
  discounts: "Kedvezmények",
  apply_type_discount: "Kedvezmény érvényesítése",
  manual: "Manuálisan",
  automatic: "Automatikusan",
  to_whom: "Kinek",
  store: "Raktár",
  stores: "Raktárak",
  input_stores: "Bemeneti raktárok",
  output_stores: "Kimeneti raktárok",
  cost: "Nettó beszerzési ár",
  unit_price_estimation: "Javasolt bruttó egység ár",
  cost_estimation: "Nettó beszerzési ár",
  suppliers: "Szállítók",
  supplier: "Szállító",
  incomingStock: "Bevétel",
  incomingStocks: "Bevételek",
  date: "Dátum",
  default_store: "Alapértelmezett raktár",
  invoice_id: "Számlaszám",
  delivery_note_id: "Szállítólevél száma",
  supplier_code: "Szállítói termékkód",
  _supplier_code: "Termékkód",
  product: "Termék",
  quantity: "Mennyiség",
  _quantity: "Menny.",
  quantity_type: "Mértékegység",
  _quantity_type: "Mért.",
  expiry_date: "Lejárat",
  net_unit_price: "Nettó egységár",
  brutto_unit_price: "Bruttó egységár",
  net_price: "Nettó",
  brutto_price: "Bruttó",
  _net_unit_price: "N. e.ár",
  _brutto_unit_price: "B. e.ár",
  _net_price: "Nettó",
  _brutto_price: "Bruttó",
  total_incoming_stock_entries: " <span class='browser-only'> Összesen&nbsp;</span> <span>{0}</span>&nbsp;<span class='browser-only'>tétel</span>",
  entry_count: "Tételek száma",
  _entry_count: "T.",
  incoming_stock_entry_text_input: "Szöveges bevitel",
  are_you_sure_you_want_to_delete_the_incoming_stock: "Biztos benne, hogy törölni szeretné a kijelölt bevétet?",
  are_you_sure_you_want_to_delete_the_inventory: "Biztos benne hogy törölni szeretné a kijelölt leltárt",
  delete_all_order_history: "Az összes rendelés törtlése",

  tableOccupations: "Asztalfoglalások",
  traffic: "Forgalom",
  delete_traffic: "Forgalom törlése",
  until_when_delete_the_traffic: "Meddig szeretné törölni a forgalmat (formátum: YYYY-MM-DD)",
  are_you_sure_you_want_to_delete_traffic_until: "Biztos benne, hogy törölni szeretné a teljes forgalmat eddig: <strong>{0}</strong>",
  menu: "Menü",
  time: "Idő",
  seqnr: "Sorszám",
  _seqnr: "Sorsz.",
  duration: "Időtartam",
  _duration: "Időt.",
  person_count: "Személyek száma",
  amount_paid: "Fizetett",
  tip: "Borravaló",
  _tip: "Borr.",
  cancelled_orders: "Töröltek",
  ordered_by: "Rendelte",
  orders: "Rendelések",
  _orders: "R.sz.",
  drink: "Ital",
  meal: "Étel",
  last_status: "Utolsó állapot",
  _last_status: "Ut. áll.",
  deleted: "Törölt",
  _deleted: "Tör.",
  yes: "Igen",
  no: "Nem",
  order_sequence_number: "Rendelés szám",
  _order_sequence_number: "R. szám",
  is_paid: "Fizetve",
  paid_by: "Ki fizette",
  _paid_by: "Fizette",
  price: "Ár",
  amount_to_pay: "Fizetendő",
  payments: "Fizetések",
  currency: "Pénznem",
  amount: "Összeg",
  replace: "Csere",
  menuitem_ordered: "Mit rendelt",
  daily_closing: "Napi zárás",
  table_occupations: "Asztalok",
  count: "Darab",
  test_system: "Teszt rendszer",
  test_mode: "Teszt üzemmód",
  multiple_physical_printers_try_to_server_the_virtual_server: "Több fizikai nyomtató van konfigurálva a {0} virtuális nyomtatóhoz! A '{1}' és a '{2}'!",
  "The device communications channel could not be opened, check the device and retry.": "A nyomtató nincs bekapcsolva vagy rosszul van bekonfigurálva.",
  pay_himself: "Saját maga fizet",
  unknown_person_pays: "{0}. személy fizet",
  change_table: "Átül más asztalhoz",

  confirm_person_change_to_empty_table: "A {0} személy átül a(z) {0}. számú üres asztalhoz?<br/>Új foglalásként indít, vagy a jelenlegi foglalás részeként?",
  confirm_person_change_to_table: "A {0} személy csatlakozik a(z) {0}. számú már foglalt asztalhoz?",
  confirm_person_change_to_shared_table: "A {0} személy átül a(z) {0}. számú már foglalt asztalhoz?<br/>Csatlakozik a létező foglaláshoz vagy új önálló foglalást indít?",
  confirm_person_change_to_multiple_occupation_table: "A {0} személy átül a(z) {0}. számú már foglalt asztalhoz?",

  new_occupation: "Új foglalásként",
  extend_occupation: "A jelenlegi foglalás részeként",
  joins: "Csatlakozik",
  joins_table1: "Csatlakozik a első {0} fős foglaláshoz",
  joins_table: "Csatlakozik a {0}-ik {1} fős foglaláshoz",
  new_independent_occupation: "Új önálló foglalás",
  target_table_same_and_source: "Cél asztal ugyanaz mint a jelenlegi",
  sitting_at_table: "{0}. asztalnál ül",
  confirm_additional_table: "Hozzáadja a {0}. asztalt a jelenlegi foglaláshoz?",
  confirm_change_to_table: "Mindenki átül a {0}. számú asztalhoz?",
  confirm_table_change_to_shared_table: "A cél asztal egy megosztható asztal. Kérem válasszon az átmozgatási módok között!",
  default: "Alapértelmezett",
  role_names: {
    admin: "Adminisztrátor",
    waiter: "Felszolgáló",
    cook: "Szakács",
    store: "Készletkezelő",
    printerserver: "Nyomtatószerver",
    finance: "Pénzügy",
    courier: "Futár"
  },
  restaurant: "Vendéglátóhely",
  pending: "Függőben",
  suspend_ordering: "Rendelés felvitel felfüggesztése",
  continue_ordering: "Rendelés felvitel folytatása",
  split_invoice: "Számla bontás",
  _split_invoice: "Bontás",
  pays: "Fizet",
  comments: "Megjegyzések",
  invoice_printed: "Számla nyomtatás sikerült",
  room: "Terem",
  move_forward: "Előbbre",
  move_backward: "Hátrább",
  move_top: "Előre",
  move_back: "Hátra",
  all: "Összes",
  isInventoryItem: "Raktár elem",
  isInventoryItem2: "Raktár elem/Alapanyag",
  _isInventoryItem: "R.",
  isInventoryItem3: "Készletezett mennyiség",
  total: "Összesen",
  menuitems: "Termékek",
  menuitem: "Termék",
  item: "Elem",
  _item: "Elem",
  category: "Kategória",
  _category: "Kat.",
  you_need_admin_right_for_this_action: "Adminisztrátori jogosultság szükséges ehhez a művelethez!",
  pricePerCostRatio: "Haszonkulcs",
  price_estimation: "Javasolt bruttó ár",
  add_option: "Új opció",
  option: "Opció",
  _option: "Opc.",
  enter_cost: "Adja meg az anyag költséget",
  enter_price: "Adja meg a árat: pl. 500 vagy 40%",

  checkbox_option: "A kategória opcióiból többet is ki lehet majd választani rendeléskor",
  checkbox_option2: "Önálló opció kategoria. A kategória opcióiból többet is ki lehet majd választani rendeléskor",
  optional_radio_option: "A kategória opcióiból maximum egyet lehet majd választani rendeléskor",
  mandatory_radio_option: "A kategória opcióiból egyet kötelező lesz majd kiválasztani rendeléskor",

  deletion_not_allowed: "Törlés nem lehetséges",
  paid_order_cannot_be_deleted: "Fizetett rendelések nem törölhetőek!",
  order_cannot_be_deleted_while_mate_is_paying: "Fizetés alatt álló személy rendelései nem törölhetőek!<br/>Először állítsa át a személyt nem fizetőnek!",
  order_cannot_be_deleted_while_order_was_already_selected_for_payment_one: "Csak adminisztrátori jogosúltsággal törölhető olyan rendelés, amelyet már egyszer fizetésre lett kijelölve!",
  original_quantity: "Eredeti mennyiség",
  recalculateConsumption: "Raktárkészlet újrakalkulálása",
  recalculateConsumptionAll: "Teljes raktárkészlet újrakalkulálása",
  _recalculateConsumption: "Készlet újrakalk.",
  inventory: "Leltár",
  incoming_stock: "Bevét",
  stock: "Készlet",
  inventory_surplus: "Többlet",
  inventory_shortage: "Hiány",
  minute: "   ",
  hour: "Óra",
  day: "Nap",
  week: "Hét",
  month: "Hónap",
  consumption: "Forgalom",
  price_goal: "Célár",
  today: "Ma",
  yesterday: "Tegnap",
  last_7_days: "Az utolsó 7 nap",
  last_30_days: "Az utolsó 30 nap",
  last_3_months: "Az utolsó 3 hónap",
  this_week: "Ez a hét",
  this_month: "Ez a hónap",
  this_quarter: "Ez a negyedév",
  last_week: "Előző hét",
  last_month: "Előző hónap",
  last_quarter: "Negyedév",
  customRange: "Egyénitartomány",
  failed_to_execute_action: "Hibás műveletet!",
  no_inventory_quantity: "Nincs kiválasztva egy mennyiség sem készletkezeléshez!",
  income: "Bevétel",
  profit_margin: "Árrés",
  _profit_margin: "Árrés",
  there_is_a_loop_in_the_additions: "Nem lehet körkörös hivatkozás a menü elemek között!",

  are_you_sure_you_want_to_delete_the_floorplan: "Biztosan ki akarja törölni a <b>{0}</b> asztaltérképet?",
  floorplans: "Asztaltérképek",
  tooltip_printed: "Nyomtatva",
  tooltip_printfailed: "Nyomtatási hiba!",
  tooltip_partprinted: "Nyomtatási hiba! Nyomtatás ismételt kisérlete folyamatban",
  tooltip_printing: "Nyomtatás folyamatban!",
  tooltip_notprinted: "Nem volt még nyomtatva!",
  controlled_production_lines: "Együtt vált státuszt",
  producing_rules: "Nyomtatás utáni státusz",
  pos_producing_rules: "Nyomtatás utáni státusz kasszánál",
  manualOnly: "Manuálisan",
  afterPrint: "Nyomtatás után",
  install_printer_server: "Nyomtató szerver telepítése",
  failed_to_print_kitchen_slip: "A nyomtatás nem sikerült!",
  failed_to_print_invoice: "A számla nyomtatása nem sikerült!",
  ok: "Ok",
  secret_key: "Titkos kulcs",
  global_id: "Globális azonosító",
  server_url: "WAN url",
  local_url: "LAN url",
  backup_url: "BCK url",
  "enabled-features": "Aktivált funkciók",
  online: "Online",
  _online: "O.",
  visible_online: "Vendégek számára látható",
  book_online: "Vendégek által foglalható",
  make_nonVisibleOnline: "Ne legyen a vendégek által látható",
  make_visibleOnline: "Legyen a vendégek által látható",
  make_nonBookOnline: "Ne legyen a vendégek által foglalható",
  make_bookOnline: "Legyen a vendégek által foglalható",
  mate_already_joined_table_in_this_restaurant: "{0} már csatlakozott a {1} számú asztalhoz!",
  confirm_not_printing: "Biztosan nem akarja kinyomtatni a rendeléseket?",
  save: "Mentés",
  save_and_close: "Mentés & Bezár",
  save_and_open: "Mentés és Teszt",
  table_types: {
    rectangle_two_seats: "Két fős szögletes asztal",
    rectangle_four_seats: "Négy fős szögletes asztal",
    rectangle_six_seats: "Hat fős szögletes asztal",
    circle_four_seats: "Négy fős kerek asztal",
    rounded_four_seats: "Négy fős lekerekített asztal",
    rounded_six_seats: "Hat fős lekerekített asztal",
    person: "Személy",
    persons: "Személyek",
    pool_table: "Billiárd asztal"
  },
  seat_types: {
    normal_chair: "Normál szék",
    wooden_chair: "Fa szék"
  },
  tip_amount_seems_excesive: "Túl nagy összeg! Biztosan folytatja?",
  non_printer_configured: "Nincs nyomtató hozzárendelve",
  disposed_quantity: "Leselejtezett mennnyiség",
  comment: "Megjegyzés",
  entries: "Tételek",
  entry: "Tétel",
  other: "Egyéb",
  created_by: "Létrehozta",
  modified_by: "Módosította",
  start_count: "Elvárt",
  change: "Változás",
  changeit: "Változtatás",
  end_count: "Valós",
  inventory_disposal: "Selejt",
  extra_frame_left: "Bal oldali doboz tartalma",
  extra_frame_right: "Jobb oldali doboz tartalma",
  extra_frame_top: "Felső doboz tartalma",
  extra_frame_bottom: "Alsó doboz tartalma",
  empty: "Üres",
  serving: "Tálaló",
  drinkbar: "Bárpult",
  point_of_sale: "Kassza",
  new_sale: "Új eladás",
  invoice: "Számla",
  payment: "Fizetés",
  for_all: "Mindre",
  for_selected_none: "Kijelöltre",
  for_selected_order: "Kijelölt rendelésre",
  for_selected_tableMate: "Kijelölt személyre",
  cancel: "Megszakítás",
  persons: "Személyek",
  _persons: "Sz.",
  phonenumber: "Telefon",
  company: "Cég neve",
  company_name: "Cég neve",
  address: "Cím",
  city: "Helyiség",
  country: "Ország",
  provide_phonenumber: "Kérem adja meg telefonszámát",
  provide_company: "Kérem adja meg cége, étterme nevét",
  provide_address: "Kérem adja meg étterme címét",
  provide_city: "Kérem adja meg étterme helyiségét",
  provide_country: "Kérem adja meg étterme országát",
  manufacturer: "Gyártó",
  for_store: "Raktárra",
  are_you_sure_you_want_to_delete_the_store: "Biztosan ki akarja törölni a <b>{0}</b> raktárat?",
  stock_movement: "Raktármozgás",
  from_store: "Forrás raktár",
  to_store: "Cél raktár",
  _from_store: "Forrás",
  _to_store: "Cél",
  moved_quantity: "Átmozgatott mennyiség",
  view: "Nézet",
  list: "Lista",
  calendar: "Naptár",
  thanks_for_buying: "Köszönjük, hogy nálunk vásárolt!",
  sale: "Eladás",
  add_room: "Új terem",
  room_editor: "Terem",
  seats: "ülőhely",
  room_tables: "{0} asztal",
  next: "Tovább",
  back: "Vissza",
  room_tables_seats: "{0} asztal {1} ülőhellyel",
  book_a_table: {
    title: "Előzetes asztalfoglalás",
    number_of_seats: "{0} személy",
    seats: "Helyek",
    tables: "Asztalok",
    rooms: "Termek",
    room: "Terem",
    provide_email: "Email",
    provide_name_or_email: "Keres: név v. email",
    provide_phone: "Telefonszám",
    provide_name: "Név",
    provide_comment: "Megjegyzés",
    not_enough_free_tables: "Nincs elég szabad asztal ebben az időpont egy teremben sem!",
    not_free_table: "Nincs szabad asztal ebben az időpont egy teremben sem!"
  },
  activate_booking: "Foglalás aktiválása",
  cancel_booking: "Foglalás törlése",
  cancel_b: "Törlés",
  extensions: "Bővítések",
  table_extentions_placeholder: "+asztal_száma=helyek_száma_összesen pl. +4=8,+4+5=12",
  quantity_must_be_unique: "Az elérhető mennyiségeknek különbözőnek kell lenniük!",
  menus: {
    tableOverview: "Asztaltérkép",
    bar: "Bár",
    serving: "Tálaló",
    productionLines: "Pályák",
    reports: "Riportok",
    stockManagement: "Raktárkészlet kezelés ",
    administration: "Adminisztráció",
    restaurants: "Éttermek",
    users: "Felhasználók",
    currencies: "Pénznemek",
    paymentMethods: "Fizetési módok",
    manage_reports: "Riportok",
    monitoring: "Teljesítmény",
    drinks: "Italok",
    meals: "Ételek",
    tables: "Asztalok",
    VATCategories: "ÁFA kategóriák",
    printers: "Nyomtatók",
    servings: "Kiszerelések",
    discounts: "Kedvezmények",
    labels: "Címkék",
    profile: "Profilom",
    stockList: "Raktárkészlet",
    shoppingList: "Bevásárló lista",
    incomingStocks: "Bevétek",
    stockMovements: "Raktármozgások",
    inventory: "Leltár",
    inventoryScrapping: "Selejtezés",
    suppliers: "Szállítók",
    stores: "Raktárak",
    superAdminMenu: "Szuper admin",
    pointOfSales: "Kasszák",
    tableBooking: "Előzetes asztal foglalások",
    stockReport: "Készlet statisztika",
    orders: "Elvitel<br/>Házhozszállítás",
    orders_quick_action: "Rendelés állapot állítás",
    cashRegisters: "Pénztárgépek",
    bankTerminals: "Bankkártya terminálok",
    scales: "Mérlegek",
    daily_closing: "Napi zárás",
    support: "Támogatás",
    updateSettings: "Frissítéssek",
    barcodes: "Vonalkódos termékek",
    wolt: "Wolt",
    foodpanda: "Foodora",
    falatozz: "Falatozz",
    wolt_administration: "Wolt adminisztráció",
    foodora_administration: "Foodora adminisztráció",
    falatozz_administration: "Falatozz adminisztráció",
    advertisements: "Reklámok",
    products: "Termékek",
    menuitems: "Termékek",
    traffic: "Forgalom",
    todaytraffic: "Napi forgalom",
    comments: "Megjegyzések",
    analytics: "Analitika",
    plans: "Tervek",
    opening_hours: "NTAK Nyitvatartás",
    bi: "Business inteligence",
    mautic: "Hírlevelek",
    send_reseller_fees: "Viszonteladók jutalékának küldése",
    send_reseller_fees_to_me: "Viszonteladók jutalékának küldése csak nekem",
    check_subscriptions: "Előfizetések ellenőrzése",
    waiterScreen: "Pincérhívó"
  },
  cashRegister: "Pénztárgép",
  stable_versions: "Stabil verziók",
  test_versions: "Teszt verziók",
  status: "Státusz",
  test: "Teszt",
  Grid: {
    Pager: {
      Button: {
        backButtonText: "Előző",
        nextButtonText: "Következő"
      },
      Description: {
        no_records: "No %{recordType} Available",
        records_displayed: "Megjelenítve %{firstIndex}-től  %{lastIndex}-ig, Összesen %{total} %{recordType}"
      }
    }
  },
  map: "Térkép",
  basic_data: "Alap adatok",
  restaurant_settings: {
    default_language: "Alapértelmezett nyelv"
  },
  show_deleted: "Törölt elemek is",
  image: "Kép",
  select_a_product: "válasszon terméket",
  select_a_category: "válasszon kategóriát",
  select_a_quantity_type: "válasszon kiszerelést",
  select_a_vat_category: "válasszon áfa kategoriát",
  select_a_store: "válasszon raktárat",
  select_a_supplier: "válasszon beszállítót",
  source_and_target_store_should_be_different: "A forrás- és célraktár ugyanaz!",
  custom_range: "Egyedi tartomány",
  initial_stock: "Induló készlet",
  final_stock: "Záró készlet",
  outgoing_stock: "Raktár mozgás",
  scrapping: "Selejt",
  shortage: "Hiány",
  surplus: "Többlet",
  minStock: "Minimum készlet",
  maxStock: "Maximum készlet",
  total_stock_cost: "N.&nbsp;beszerzési érték",
  total_stock_value: "B.&nbsp;eladási érték",
  initialCost: "Kezdő költség",
  finalCost: "Záró költség",
  averageCost: "Átlag költség",
  initialPrice: "Kezdő ár",
  averagePrice: "Átlag ár",
  finalPrice: "Záró ár",
  minPrice: "Minimum ár",
  maxPrice: "Maximum ár",
  netto_income: "Nettó bevétel",
  vat: "ÁFA",
  brutto_income: "Bruttó bevétel",
  customer_details: "Vevő adatok",
  tomorrow: "Holnap",
  available: "Rendelhető",
  _available: "R",
  order_summary: "Rendelés összesítő",
  product_serving: "Kiszerelés",
  download: "Letöltés",
  upload: "Feltöltés",
  order_no: "Rendelés szám",
  no_matches_found: "Nincs találat",
  could_not_find_this_order: "Nem található a {0} számú rendelés",
  could_not_find_this_order2: "Nem található rendelés",
  open: "Megnyit",
  fix_quantity: "Kötött mennyiség",
  are_you_sure_you_want_to_delete_the_printer: "Biztosan ki akarja törölni a <b>{0}</b> nyomtatót?",
  are_you_sure_you_want_to_delete_the_quantity_type: "Biztosan ki akarja törölni a <b>{0}</b> kiszerelést?",
  are_you_sure_you_want_to_delete_the_vat_category: "Biztosan ki akarja törölni a <b>{0}</b> ÁFA kategóriát?",
  are_you_sure_you_want_to_delete_the_discount_type: "Biztosan ki akarja törölni a <b>{0}</b> kedvezményt?",
  are_you_sure_you_want_to_delete_the_cashregister: "Biztosan ki akarja törölni a <b>{0}</b> pénztár gépet?",
  connection: "Kapcsolat",
  type: "Típus",
  delete_order_history: "Minden eddigi rendelés törlése",
  ready_for_delivery: "Tálalható",
  table_ready_for_delivery: "!ESC|bC!ESC|4CAsztal {0}!LF!ESC|bC!ESC|4CFoglalás {1}!LF!ESC|bC!ESC|4C{2}!LF!ESC|bC!ESC|4CTálald!",
  proportional_addition_tooltip: "Arányos opció. Ha igen: a kalkulált mennyiség az alap elem mennyiségének és az opció mennyiségének a szorzata. Ha nem: csak az opció mennyisége számít.",
  you_are_not_authorized_to_access_this_restaurant_with_an_app: "Az előfizetésed alapján ehhez az egységhez nem engedélyzett a hozzáférés!",
  you_are_not_authorized_to_access_this_feature: "Az előfizetésed alapján ez a funkció nem aktív!",
  please_click_on_a_time_slot_first: "Előszőr jelöld ki a foglalás kezdetét (bökj rá egy idősávra)!",
  barcode: "Vonalkód",
  test_scale: "Mérleg teszt",
  tare_weight: "Önsúly",
  gross_weight: "Összsúly",
  full_inventory: "Teljes leltár",
  all_items: "Mind",
  new: "Új",
  parent_category: "Szülő kategória",
  choose_parent_category: "Válasszon szülő kategóriát",
  save_my_details_for_later_use: "Hozzájárulok ahhoz, hogy a fentiekben megadott adataimat a(z) {0} és az általa használt felhő alapú EatWithMe étterem menedzsment rendszer tárolja és kezelje!",
  saved_my_details_for_later_use: "Hozzájárultam ahhoz, hogy a fentiekben megadott adataimat a(z) {0} és az általa használt felhő alapú EatWithMe étterem menedzsment rendszer tárolja és kezelje!",
  agree: "Hozzájárulok",
  accept_homedelivery_terms: "Elfogadom a házhozszállítási kondíciókat",
  accept_pickup_terms: "Elfogadom a személyes átvételi kondíciókat",
  accept_privacy_terms: "Hozzájárulok a személyes adataim kezeléséhez",
  privacy_policy: "Adatkezelési szabályzat",
  privacy_policy_link: "https://eatwithme.online/adatvedelmi-szabalyzat/",
  door: "Emelet/ajtó",
  backup: "Jelenleg a fő szerver elérhetlen. Ön most a biztonsági másolatot használja.",
  withdraw: "Visszavonom",
  tax_number: "Adószám",
  tax_number_pattern: "(^$)|(^\\d\\d\\d\\d\\d\\d\\d\\d-\\d-\\d\\d$)",
  email: "E-mail",
  postcode: "Irányítószám",
  postcode_pattern: "^\\d\\d\\d\\d$",
  not_empty_pattern: "\\w+",
  email_pattern: "^$|^[^@]+@[^@]+(\\.[^@]+)+$",
  address_pattern: "^((.+ (hrsz|arok|atjaro|dulo|dulout|erdosor|fasor|fordulo|gat|hatarsor|határút|kapu|körönd|körtér|körút|köz|lakótelep|lejáró|lejtő|lépcső|liget|mélyút|orom|ösvény|park|part|pincesor|rakpart|sétány|sikátor|sor|sugárút|tér|udvar|ut|utca|üdülőpart) (\\d+(/\\w)?\\.?))|(/.*))$",
  address_pattern2: "[^,].+ (hrsz|arok|atjaro|dulo|dulout|erdosor|fasor|fordulo|gat|hatarsor|határút|kapu|körönd|körtér|körút|köz|lakótelep|lejáró|lejtő|lépcső|liget|mélyút|orom|ösvény|park|part|pincesor|rakpart|sétány|sikátor|sor|sugárút|tér|udvar|ut|utca|üdülőpart)",
  address_placeholder: "Formátum: közterület neve (út|utca|stb.) szám",
  repay_invoice_dialog: "Biztosan módosítani szeretné a fizetési adatokat?",
  except: "Kivéve",
  first_modify_start_date: "Először az idősáv eljét változtassa meg",
  uncategorized_items: "Kategóriába nem sorolt termékek",
  wolt: {
    reload: "Frissítés a Woltról",
    upload_selected_elements: "Kiválasztott termékek feltöltése a Woltra",
    resync_selected_elements: "Kiválasztott termékek újraszinkronizálása a Woltra",
    delete_selected_elements: "Kiválasztott termékek törlése a Woltról",
    elements_in_sync: "Szinkronban lévő termékek száma",
    elements_in_unsync: "Szinkronból kiesett termékek száma",
    elements_in_to_upload: "Woltról hiányzó termékek száma",
    elements_in_to_delete: "Woltról törlendő termékek száma",
    delete_menu_items: "Termékek törtlése a Woltról",
    delete_category: "Termék kategória törlése a Woltról",
    delete_category_items: "Termék kategóriában nem, de a Wolton szereplő termékek törlése a Woltról",
    sync_category_items: "Termék kategória a Wolton már szereplő termékeinek szinkronizálása a Woltra",
    upload_category_items: "Termék kategória a Wolton még nem szereplő termékeinek feltöltése a Woltra",
    sync_menu_item: "Termék syncronizálása a Woltra",
    delete_menu_item: "Termék törlése a Woltról",
    upload_menu_item: "Termék feltöltése a Woltra",
    upload_category: "Termék kategória feltöltése a Woltra",
  },
  vat_invoice: "ÁFÁ-s számla",
  there_already_exists_a_table_with_this_number: "Ilyen számú asztal már létezik az asztaltérképen",
  access_tokens: "Hozzáférési kódok",
  generate_access_token: "Hozzáférési kód generálása",
  printer_server: "Nyomtató szerver",
  purchase_price: "Nettó beszerzési ár",
  selling_price: "Eladási bruttó ár",
  unit_of_consumption: "Eladási/Felhasználási mértékegység",
  picture: "Kép",
  homedelivery: "Házhozszállítás",
  pickup: "Elvitel",
  onsite: "Helyszíni fogyasztás",
  by_occupation_start: "Rendelési idő szerint",
  by_payment: "Fizetési idő szerint",
  are_you_sure_you_cancel_the_payment: "Biztosan megszakítod a fizetési adatok rögzítését?",
  value_difference: "Értékkülönbözet",
  total_value_difference: "Értékkülönbözet összesen",
  subscription: "Előfizetés",
  print: "Nyomtatás",
  something_strange_happened_resync: "Hmm. Úgy tűnik, hogy valami nem a legfrissebb, szinkronizálunk egyet, aztán próbáld újra a műveletet!",
  payment_due_in: "Fizetési határidő {0} nap múlva lejár",
  payment_was_due_in: "A fizetési határidő {0} napja lejárt!",
  continue_in: "{0} percet várnia kell mielőtt ez a figyelmeztetés eltűnik.",
  profitandloss: {
    open_stock: "Nyitó készlet",
    cost: "Nettó beszerzési ár",
    purchase: "Beszerzés (nettó)",
    shortage: "Többlet/Hiány",
    scrapping: "Selejt",
    closing_stock: "Záró készlet",
    stock_change: "Készlet változás",
    income: "Bevétel (nettó)",
    profit: "Nyeresség (nettó)",
    profitp: "Nyeresség (%)",
    count: "Eladások száma",
    consumption: "Eladott mennyiség",
    minPrice: "Min ár",
    maxPrice: "Max ár",
    avgPrice: "Átlag lista ár",
    avgDiscount: "Átlag kedvezmény",
    avgActualPrice: "Átlag ár",
    minCost: "Min költség",
    maxCost: "Max költség",
    avgCost: "Átlag költség"

  },
  service_fee: "Szervíz díj",
  service_fee_net: "Szervíz díj nettó",
  fiscat_service_fee: "Szerviz dij",
  income_without_cost: "Bevétel költség nélkül?",
  not_used_ingredient: "Nem használt alapanyag",
  disconnected: "Nincs csatlakoztatva",
  error: "Csatlakozási hiba",
  "cover-open": "Nyomtató fedél nyitva",
  "out-of-paper": "Kifogyott a papír",
  refresh_my_data: "Adataim frissítése",
  skin_picker: "Téma választó",
  skins: [
    { id: 'default', name: "Alap" },
    { id: 'fancy', name: "Fancy" },
    { id: 'flat', name: "Sima" },
    { id: 'night', name: "Sötét" },
    { id: 'old', name: "Régi" },
  ],
  draft: "Tervezet",
  detailed: "Részletes nyugta",
  service_fee_vat: "Külön szervízdíj gyűjtők",
  too_many_bluetooth_cashiers: "Csak egy bluetooth pénztárgépet párosítson egyszere!",
  no_bluetooth_cashiers: "Kérem csatlakoztasson vagy párosítson bluetoothon keresztül egy pénztárgépet!",
  no_bluetooth_adapter: "Bluetooth interfész jelenleg nem elérhető!",
  no_serial_cashiers: "Kérem csatlakoztasson usb-soros kábellel egy pénztárgépet!",
  locked: "lezárva",
  addlogin: "További felhasználó beléptetése",
  syncronize_barcodes: "Szinkronizálás",
  download_barcodes: "Letöltés",
  upload_barcodes: "Feltöltés",
  local_product: "Kapcsolt termék",
  name_of_customer: "Adj nevet a vendégnek",
  product_modified_save_it: "Módosított termék!",
  drop: "Elvet",
  opened: "Nyitva",
  opening_time: "Nyitás időpontja",
  planned_closing_time: "Zárás tervezett időpontja",
  open_day: "Nap megnyitása",
  continue_without_open_day: "Folytatás nyitás nélkül",
  timetable: "Nyitvatartás",
  closed: "Zárva",
  failed_to_send_order: "Nem sikerült megrendelni: {0}",
  resend: "Próbáld mégegyszer",
  closed: "Zárva",
  opened: "Nyitva",
  local_addition_tooltip: "Helyszíni fogyasztás esetén alkalmazandó",
  takeaway_addition_tooltip: "Elvitel, házhozszállítás esetén alkalmazandó",
  top_category: "Fő kategória",
  _top_category: "Fő kateg.",
  takeaway: "Elvitel",
  cashier: "Pénztáros",
  download_template: "Feltöltési minta",
  user_missing: "A megadott email címmel nincs regisztrált felhasználó!",
  revert_table_occupation: "Biztosan vissza szeretné nyitni?",
  revert_table_occupation_multi_payment: "Biztosan vissza szeretné nyitni? Több fizető volt, a fizetéseket a fizetés fülön lehet egyenként visszanyitni!",
  revert: "Visszanyitom",
  revert_table_mate_payment: "Visszanyitom és törlöm a fizetési adatokat is!",
  revert_payment: "Biztosan visszavonja a fizetést?",
  no_inventory_quantity_selected: "Raktárelem de nincs készletezett mennyiség kiválasztva!",
  multiple_inventory_quantity_selected: "Biztosan több önállóan készletezett mennyiséget akar beállítani?",
  preorder_possible: "Előrendelhető",
  allowed_installments: {
    start: "Előre",
    by_each: "Folyamatos",
    end: "A végén"
  },
  installments_strategy: "Fizetés ütemezése",
  payer: "Fizető személy",
  couriers: "Futárok",
  courier: "Futár",
  access_denied: "Hozzáférés megtagadva: {0}",
  days: ["Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat", "Vasárnap"],
  do_you_realy_want_to_order_so_many: "Tényleg <strong>{0}</strong> darabot szeretne rendelni?",
  you_cannot_order_so_many: "Nem rendelhet <strong>{0}</strong> darabot egyszerre!",
  day_must_be_open: "NTAK Napnyitás kötelező ehhez a művelethez!",
  ntak: {
    ntak: "NTAK",
    taxid: "Adószám",
    save_ntak_id: "NTAK azonosító mentése",
    ntak_id: "NTAK azonosító",
    cert_request_generate: "Új tanusítvány kérelem generálása",
    cert_request_download: "Tanusítvány kérelem letöltése",
    cert_upload: "Tanusítvány feltöltése",
    key_upload: "Privát kulcs feltöltése",
    cert_request: "Tanusítvány kérelem (csr)",
    test: "Kapcsolat tesztelése",
    cert: "Tanusítvány (cer)",
    key: "Privát kulcs (key)",
    categories: "NTAK",
    status: "NTAK statusz",
    default_ntak_main_category: "NTAK Főkategória",
    _default_ntak_main_category: "Főkategória",
    default_ntak_sub_category: "NTAK Alkategória",
    _default_ntak_sub_category: "Alkategória",
    mainCategoryNotSet: "Fő NTAK főkategória nincs beállítva valamelyik terméknél! Beállítani a kategória szintjén (öröklődni fog) vagy a terméknél lehet!",
    subCategoryNotSet: "Fő NTAK alkategória nincs beállítva valamelyik terméknél! Beállítani a kategória szintjén (öröklődni fog) vagy a terméknél lehet!",
    main_categories: [
      {
        id: 5, name: "Étel", shortName: "Étel",
        sub_categories: [
          { id: 1, name: "reggeli" },
          { id: 2, name: "szendvics" },
          { id: 3, name: "előétel" },
          { id: 4, name: "leves" },
          { id: 5, name: "főétel" },
          { id: 6, name: "köret" },
          { id: 7, name: "savanyúság/saláta", shortName: "Sav./Sal." },
          { id: 8, name: "desszert" },
          { id: 9, name: "snack" },
          { id: 30, name: "főétel körettel" },
          { id: 31, name: "ételcsomag" },
          { id: 35, name: "péksütemény" },
          { id: 36, name: "kóstoló" },
          { id: 10, name: "egyéb" },
        ]
      },
      {
        id: 1, name: "Helyben készített alkoholmentes ital", shortName: "H.a. ital",
        sub_categories: [
          { id: 11, name: "limonádé/szörp/frissen facsart ital", shortName: "lim/szö/friss" },
          { id: 12, name: "alkoholmentes koktél", shortName: "alkm. koktél" },
          { id: 13, name: "tea/forrócsoki" },
          { id: 14, name: "kávé" },
          { id: 32, name: "italcsomag" },
        ]
      },
      {
        id: 2, name: "Nem helyben készített alkoholmentes ital", shortName: "N.h.a. ital",
        sub_categories: [
          { id: 15, name: "víz" },
          { id: 16, name: "rostos üdítő" },
          { id: 17, name: "szénsavas üdítő" },
          { id: 33, name: "szénsavmentes üdítő" },
          { id: 32, name: "italcsomag" },
        ]
      },
      {
        id: 3, name: "Alkoholos ital", shortName: "A. ital",
        sub_categories: [
          { id: 18, name: "koktél" },
          { id: 19, name: "likőr" },
          { id: 20, name: "párlat" },
          { id: 21, name: "sör" },
          { id: 22, name: "bor" },
          { id: 23, name: "pezsgő" },
          { id: 32, name: "italcsomag" },
        ]
      },
      {
        id: 4, name: "Egyéb", shortName: "Egyéb",
        sub_categories: [
          { id: 10, name: "egyéb" },
          { id: 26, name: "kiszállítási díj" },
          { id: 27, name: "környezetbarát csomagolás", shortName: "kör. csom." },
          { id: 28, name: "műanyag csomagolás", shortName: "mű. csomag" },
          { id: 29, name: "kedvezmény" },
          { id: 34, name: "nem vendéglátás" },
        ]
      },
    ]
  },
  more_addresses: "További címei",
  payment_received_by: "Fizettete",
  product_receipe_modified_recalculate_it: "Módosult a recept. Szeretné újrakalkulálni a raktárkészletet visszamenőlegesen?",
  minute: "perc",
  id: "Belső azonosító",
  activate_sound: "Csengő aktiválva",
  barcode_not_found: "Nem ismert vonalkód!",
  linked_product: "Kapcsolt saját termék",
  new_in_central_database: "Letölthető az központi adatbázisból",
  exists_in_central_database: "Létező a központi adatbázisban",
  doesnt_exists_in_central_database: "Nemlétező a központi adatbázisban",
  local_value: "Saját érték",
  global_value: "Központi érték",
  tooltip: {
    set_new_incoming_order: "Új visszaigazolásra/megerősítésre váró rendelés!",
    set_accepted_order: "Visszaigazolt/Megerősített rendelés! Dupla klikkre visszaigazolhatod/megerősítheted a rendelést!",
    set_order_in_production: "A rendelés elkészítés alatt! Dupla klikkre beállíthatod ezt az állapotot!",
    set_order_under_delivering: "A rendelés kiszállítás/kitálalás alatt! Dupla klikkre beállíthatod ezt az állapotot!",
    set_order_delivered: "A rendelés kézbesítve/kitálalva! Dupla klikkre beállíthatod ezt az állapotot!",
    header_new_incoming_order: "Új visszaigazolásra/megerősítésre váró rendelés!",
    header_accepted_order: "Visszaigazolt/Megerősített rendelés! Dupla klikkre visszaigazolhatod/megerősítheted az összes vagy kiválasztott függő rendeléseket!",
    header_order_in_production: "A rendelés elkészítés alatt! Dupla klikkre beállíthatod ezt az állapotot az összes vagy kiválasztott rendelésre!",
    header_order_under_delivering: "A rendelés kiszállítás/kitálalás alatt! Dupla klikkre beállíthatod ezt az állapotot az összes vagy kiválasztott rendelésre!",
    header_order_delivered: "A rendelés kézbesítve/kitálalva! Dupla klikkre beállíthatod ezt az állapotot az összes vagy kiválasztott rendelésre!",
    unlink_courier: "Duppla klikkre a futárhoz rendelés törlése!",
    link_courier: "Dupla klikkre rendeld ehhez a futárhoz a kiválasztott rendeléseket!",
    header_print_receipt: "Duppla klikkre nyomtasd ki az összes még ki nem nyomtatott rendelést vagy kiválasztott rendelést!",
    header_print_invoice: "Duppla klikkre nyomtasd ki az összes vagy kiválasztott rendelés számláját!",
    header_unlock: "Duppla klikkre szabadítsd fel/zárd le az összes felaszabadítható (kiszállított és fizetett) rendelést!",
    print_receipt: "Duppla klikkre nyomtasd ki rendelést!",
    reprint_receipt: "Duppla klikkre nyomtasd ki újra a rendelést!",
    print_invoice: "Dupple klikkre nyomtasd ki a rendelés számláját!",
    unlock: "Duppla klikkre szabadítsd fel/zárd le a rendelést!",
    delivery_time: "Kiszállítás tervezett időpontja",
    pickup_time: "Átvétel tervezett időpontja",
    couriers: "Lehetséges futárok (futár jogosultságú felhasználók). Az adminisztrációban lehet ezt a jogosulságot megadni a már regisztrált felhasználóknak",
    printing: "Nyomtatás folyamatban",
    printfailed: "Sikertelen nyomtatási kísérlet. Dupla klikkre új nyomtatási kísérlet.",
    printed: "Sikeres nyomtatás! Dupla klikkre másolat nyomtatása.",
    unprinted: "A rendelés még nem volt nyomtatva. Dupla klikkre nyomtatás.",
    qrcode: "Dupla klikkre futárblokk nyomtatás.",
    register_payment: "Dupla klikkre fizetési adatok regisztrálása.",
    paid: "Számla fizetve.",
    user_comment: "Adjon megjegyzést az ügyfeléhez. Ez a megjegyzés egy következő rendelésnél is látszani fog!",
    comment: "Adjon megjegyzést ehhez a rendeléshez. Ez a megjegyzés csak ennél a rendelésnél fog látszani!",
    address: "Adja meg a teljes címet, pl. 'Árpád köz 2.'. Amennyiben a rendszer nem találja a címet, egy '/' jellel az elején minden címet elfogad a rendszer, pl. '/Vasút állomás bejáratánál'",
  },
  tablesoverview: {
    tooltip: {
      printing: "Nyomtatás folyamatban",
      printfailed: "Sikertelen nyomtatási kísérlet. Nyomtasd újra.",
      printed: "Sikeresen nyomtatva.",
      unprinted: "A rendelés még nem volt nyomtatva.",
    }
  },
  menuitems: {
    tooltip: {
      expected_profit: "Elvárt haszonkulcs",
      suggested_price: "Ajánlott ár",
      unit_price: "Egy egységre vonatkozó bruttó ár helyszíni fogyasztás esetén",
      is_inventory_item: "Eladáskor nem válaszható kiszerelés. Ez az egység a készletkezelés alapja. Ebben az egységben lehet bevételezni, leltározni.",
      non_inventory_item: "Eladáskor nem válaszható kiszerelés. Normál termék mennyiség. Nem készletkezelési egység.",
      on_menu_is_inventory_item: "Eladáskor válaszható kiszerelés. Ez az egység a készletkezelés alapja. Ebben az egységben lehet bevételezni, leltározni.",
      on_menu_non_inventory_item: "Eladáskor válaszható kiszerelés. Normál termék mennyiség. Nem készletkezelési egység.",
      def_is_inventory_item: "Alapértelmezett de eladáskor nem válaszható kiszerelés. Ez így nem jó. Válasz egy eladható kiszerelést alapértelmezettnek! Ez az egység a készletkezelés alapja. Ebben az egységben lehet bevételezni, leltározni.",
      def_non_inventory_item: "Alapértelmezett de eladáskor nem válaszható kiszerelés. Ez így nem jó. Válasz egy eladható kiszerelést alapértelmezettnek! Normál termék mennyiség. Nem készletkezelési egység.",
      def_on_menu_is_inventory_item: "Eladáskor válaszható, alapértelmezett kiszerelés. Ez az egység a készletkezelés alapja. Ebben az egységben lehet bevételezni, leltározni.",
      def_on_menu_non_inventory_item: "Eladáskor válaszható, alapértelmezett kiszerelés. Normál termék mennyiség. Nem készletkezelési egység.",
      unit_cost: "Nettó beszerzési ár",
      profit_ratio: "Haszonkulcs",
      on_menu: "Főtermék, azaz közvetlenül rendelhető",
      not_on_menu: "Nem főtermék, azaz közvetlenül nem rendelhető, csak más termék opciójaként",
      active: "Aktív termék",
      deactivated: "Deaktivált termék",
      online: "Webshopon látható termék",
      not_online: "Webshopon nem látható termék",
      available_online: "Webshopon rendelhető termék",
      not_available_online: "Webshopon jelenleg nem rendelhető termék",
      default_inventory_item: "Raktárkezelés alá eső termék (alapanyag). Ezt a tulajdonságát a kategóriájától örökli",
      default_not_inventory_item: "Nem raktárkezelt termék. Ezt a tulajdonságát a kategóriájától örökli",
      inventory_item: "Raktárkezelés alá eső termék (alapanyag)",
      not_inventory_item: "Nem raktárkezelt termék (alapanyag)",
      not_free_quantity: "Rendeléskor csak az előre megadott kiszerelések (mennyiségek) közül lehet választani.",
      free_quantity: "Rendeléskor az eladott mennyiség szabadon módosítható (például mérlegelt árunál szükséges)",

      sold_inventory: "Eladott készletezett késztermék, azaz maga az eladott termék egyúttal készletezett alapanyag is",
      sold_inventory_ingredient: "Eladott készletezett késztermék, azaz maga az eladott termék egyúttal készletezett alapanyag valamint más termék alapanyaga is",
      sold_inventory_ingredient_option: "eladott készletezett késztermék-alapanyag-opció, azaz maga az eladott termék egyúttal készletezett alapanyag valamint más termék alapanyaga és opciója is",
      sold_inventory_option: "eladott készletezett késztermék-opció, azaz maga az eladott termék egyúttal készletezett alapanyag valamint más termék opciója is",
      sold_service: "eladott szolgáltatás, azaz maga a termék nem készletezett (nem alapanyag) és nem is tartalmaz más terméket (a recept üres), tehát ez egy szolgáltatás",
      sold_service_ingredient: "eladott szolgáltatás résztermék, azaz maga a termék nem készletezett (nem alapanyag) és nem is tartalmaz más terméket (a recept üres), tehát ez egy szolgáltatás, azonban más termék tartalmazza a receptjében",
      sold_service_ingredient_option: "eladott szolgáltatás résztermék, azaz maga a termék nem készletezett (nem alapanyag) és nem is tartalmaz más terméket (a recept üres), tehát ez egy szolgáltatás, valamint más termék hivatkozik rá a receptjében illetve opcióként",
      sold_service_option: "eladott szolgáltatás résztermék, azaz maga a termék nem készletezett (nem alapanyag) és nem is tartalmaz más terméket (a recept üres), tehát ez egy szolgáltatás, melyre más termék hivatkozik rá opcióként",
      sold_complex_product: "eladott összetett termék, amely más termékekből áll",
      sold_complex_product_option: "eladott összetett termék, amely más termékekből áll, valamint más termék opciója",
      sold_complex_product_ingredient: "eladott összetett résztermék, amely más termékekből áll",
      sold_linked_product: "eladott hivatkozott termék, azaz csak egy termékre hivatkozik",
      sold_linked_product_ingredient: "eladott hivatkozott termék, résztermék, azaz csak egy termékre hivatkozik",
      sold_linked_product_ingredient_option: "eladott hivatkozott termék, résztermék, opciók, azaz csak egy termékre hivatkozik",

      _complex_product: "Saját jogon, közvetlenül nem eladott összetett termék, amely más termékekből áll",
      _complex_product_option: "Saját jogon, közvetlenül nem eladott összetett termék, amely más termékekből áll, valamint más termék opciója",
      _complex_product_ingredient: "Saját jogon, közvetlenül nem eladott összetett termék, amely más termékekből áll, valamint más termék tartalmazza a receptjében",
      _complex_product_ingredient_option: "Saját jogon, közvetlenül nem eladott összetett termék, amely más termékekből áll, valamint más termék hivatkozik rá a receptjében illetve opcióként",
      _linked_product: "Saját jogon, közvetlenül nem eladott hivatkozott (átárazott, speciális mennyisége vagy csak egy másolata egy másik terméknek) termék",
      _linked_product_option: "Saját jogon, közvetlenül nem eladott hivatkozott (átárazott, speciális mennyisége vagy csak egy másolata egy másik terméknek) termék, valamint más termék opciója",
      _linked_product_ingredient: "Saját jogon, közvetlenül nem eladott hivatkozott (átárazott, speciális mennyisége vagy csak egy másolata egy másik terméknek) termék, valamint más termék tartalmazza a receptjében",
      _linked_product_ingredient_option: "Saját jogon, közvetlenül nem eladott hivatkozott (átárazott, speciális mennyisége vagy csak egy másolata egy másik terméknek) termék, valamint más termék hivatkozik rá a receptjében illetve opcióként",
      _service_ingredient: "Saját jogon, közvetlenül nem eladott szolgáltatás, amelyre más termék hivatkozik rá a receptjében",
      _service_ingredient_option: "Saját jogon, közvetlenül nem eladott szolgáltatás, amelyre más termék hivatkozik rá a receptjében illetve opcióként",
      _service__option: "Saját jogon, közvetlenül nem eladott szolgáltatás, amelyre más termék hivatkozik rá opcióként",
      _inventory_ingredient: "Saját jogon, közvetlenül nem eladott készletezett alapanyag, amelyre más termék hivatkozik recepjében",
      _inventory_ingredient_option: "Saját jogon, közvetlenül nem eladott készletezett alapanyag, más termék hivatkozik rá a receptjében illetve opcióként",
      _inventory_option: "Saját jogon, közvetlenül nem eladott készletezett alapanyag, más termék hivatkozik rá opcióként",
      _inventory: "Készletezett de más termék által nem használt/hivatkozott alapanyag",
      _service: "Nem eladott szolgáltatás, amelyet más termék sem hivatkozik be receptként vagy opcióként",
      no_inventory_quantity_selected: "A termék készletezett raktárelem, de nincs készletezési mennyiség kiválasztva.",
      to_many_inventory_quantity_selected: "A termék több készletezési mennyiséget is tartalmzaz, így a rendszer nem nem készletezési mennyiségnél nem tudja eldönteni, hogy melyikből csökkentse a készletet. Válasszon csak egy készletezési mennyiséget"

    },
    sold_inventory: "eladott<br/>készletezett késztermék",
    sold_inventory_ingredient: "eladott<br/>készletezett késztermék-alapanyag",
    sold_inventory_ingredient_option: "eladott<br/>készletezett késztermék-alapanyag-opció",
    sold_inventory_option: "eladott<br/>készletezett késztermék-opció",
    sold_service: "eladott<br/>szolgáltatás",
    sold_service_ingredient: "eladott<br/>szolgáltatás résztermék",
    sold_service_ingredient_option: "eladott<br/>szolgáltatás<br/>résztermék és opció",
    sold_service_option: "eladott<br/>szolgáltatás opció",
    sold_linked_product: "eladott<br/>hivatkozott termék",
    sold_linked_product_ingredient: "eladott hivatkozott<br/>késztermék-alapanyag",
    sold_linked_product_ingredient_option: "eladott hivatkozott<br/>késztermék-alapanyag-opció",
    sold_complex_product: "eladott<br/>összetett termék",
    sold_complex_product_option: "eladott<br/>összetett termék és opció",
    sold_complex_product_ingredient: "eladott<br/>összetett résztermék",
    sold_complex_product_ingredient_option: "eladott<br/>összetett résztermék, opció",
    _complex_product: "nem eladott<br/>összetett termék",
    _complex_product_option: "nem eladott<br/>összetett termék, opció",
    _complex_product_ingredient: "nem eladott<br/>összetett termék, résztermék",
    _complex_product_ingredient_option: "nem eladott<br/>összetett termék, résztermék, opció",
    _linked_product: "nem eladott<br/>hivatkozott termék",
    _linked_product_option: "nem eladott<br/>hivatkozott termék, opció",
    _linked_product_ingredient: "nem eladott<br/>hivatkozott termék, résztermék",
    _linked_product_ingredient_option: "nem eladott<br/>hivatkozott termék, résztermék, opció",
    _service_ingredient: "nem eladott<br/>szolgáltatás<br/>résztermék",
    _service_ingredient_option: "nem eladott<br/>szolgáltatás<br/>résztermék és opció",
    _service_option: "nem eladott<br/>szolgáltatás<br/>opció",
    _inventory: "nem eladott<br/>készletezett<br/>nem használt alapanyag",
    _inventory_ingredient: "nem eladott<br/>készletezett<br/>alapanyag",
    _inventory_ingredient_option: "nem eladott<br/>készletezett<br/>alapanyag és opció",
    _inventory_option: "nem eladott<br/>készletezett<br/>opció",
    _service: "nem eladott<br/>szolgáltatás",
  },
  _settings: {
    hu: "magyar",
    en: "angol",
    states: ["törölt", "kiválasztott", "megrendelt", "visszaigazolt", "folyamatban", "kiszállítás", "kiszállítva"],
    day: "nap",
    minute: "perc",
    days: ["hétfő", "kedd", "szerda", "csütörtök", "péntek", "szombat", "vasárnap"],
    from_hour: "órától",
    to_hour: "óráig",
    email: "e-mail cím",
    payment_method: {
      title: "Lehetséges fizetési módok",
      meth: {
        title: "Mód"
      },
      term: {
        title: "Fizetési feltételek",
        description: "Fizetési feltételek webshop esetén"
      }
    },
    description_header: {
      title: "Webshop fejléc tartalom"
    },
    description_footer: {
      title: "Webshop lábléc tartalom"
    },
    business_day_starts_at: {
      title: "Üzleti nap kezdet",
      description: "Órában"
    },
    business_day_ends_at: {
      title: "Üzleti nap vége",
      description: "Órában, lehet kisebb mint a kezdet, pl. reggel 5 óra, amikor éjfél után ér véget az üzleti nap"
    },
    service_fee: {
      title: "Szervízdíj",
      description: "pl. 12, ha általában 12% szervízdíjjat szeretnénk felszámolni. A szervízdíj alkalmazása illetve annak mértéke a számlanyomtatás állítható be, illetve változtatható"
    },
    timezone: {
      title: "Időzóna",
    },
    compulsory_service_fee: {
      title: "Kötelező szervízdíj",
      description: "A nem kassza típusú asztaloknál automatikusan alkalmazhatjuk a szervízdíj felszámolását. Ez a számla nyomtatása előtt még változtatható."
    },
    compulsory_service_fee_for_pos: {
      title: "Kötelező szervízdíj kassza esetében is",
      description: "Kassza típusú asztaloknál is automatikusan alkalmazhatjuk a szervízdíj felszámolását."
    },
    serviceVat: {
      title: "Különbontott szervízdíj",
      description: "Szervízdíjak különbontása ÁFA körönként számlán, nyugtán."
    },
    "register_tip": {
      title: "Borravaló regisztrálása",
      description: "Amennyiben a fizetett összeg nagyobb mint az fizetendő, akkor a különbözet borravalóként való regisztrálása."
    },
    "printing": {
      title: "Nyomtatási beállítások",
      invoice: {
        title: "Számlakép",
      },
      "showOrderNumber": {
        title: "Rendelés szám mutatása normál asztalnál is.",
        description: "Kasszás rendelésnél alapértelmezett.",
      },
      "showAllComments": {
        title: "Minden megjegyzés mutatása a számlán.",
        description: "Alapértelmezetten csak a költséget módosító megjegyzések látszanak",
      }
    },
    "drinks": {
      title: "Beállítások: italok",
      enabled: {
        title: "Engedélyezve",
      },
      default: {
        title: "Alapértelmezett",
        description: "Alapértelmezetten az italok vagy az ételek jelenjenek meg rendeléskor a pincérnek és a webshopon",
      },
      "label": {
        title: "Megnevezés",
        description: "Milyen megnevezés jelenjen meg a webshopon",
      },
    },
    "meals": {
      title: "Beállítások: ételek",
      enabled: {
        title: "Engedélyezve",
      },
      default: {
        title: "Alapértelmezett",
        description: "Alapértelmezetten az italok vagy az ételek jelenjenek meg rendeléskor a pincérnek és a webshopon",
      },
      "label": {
        title: "Megnevezés",
        description: "Milyen megnevezés jelenjen meg a webshopon",
      },
    },
    "menu": {
      title: "Rendelési felület, termék választó",
      "show_category_image": {
        title: "Képek mutalása termék kategóriáknál",
      },
      "show_menuitem_image": {
        title: "Képek mutalása termékeknél",
      }
    },
    takeaway: {
      title: "Elvitel",
      enabled: {
        title: "Engedélyezve",
      },
      default: {
        title: "Alapértelmezett",
        description: "Kasszák esetén automatikus elvitel bekapcsolása",
      },
      vat: {
        title: "Alapértelmezett ÁFA elvitel esetén",
      },
      price: {
        title: "Külön elviteles ár",
        description: "A termék adatlapon extra elviteles ár megadásának lehetősége",
      },
      containerChargeItem: {
        title: "Betétdíj termék azanosítója",
      }
    },
    timings: {
      title: "Fogások",
      enabled: {
        title: "Engedélyzett",
      },
      enabled_online: {
        title: "Engedélyzett a webshopon helyszíni rendelések esetén",
      },
      langs: {
        title: "Megnevezések nyelveként",
        lang: {
          title: "Nyelv",
        },
        name: {
          title: "Megnevezés",
        },
        name_online: {
          title: "Megnevezés a webshopon",
        },
      }
    },
    "price_calculations": {
      title: "Árkalkuláció logikája",
      rounding: {
        title: "Kerekítés",
        description: "pl. 1 -> egy forintra kerekít, 50 pedig 50 és 100 forintra."
      },
      "lower_price": {
        title: "Árcsökkentés logikája",
        description: "Termék rendelésekor lehetséges alapértelmezett összetevőke/opciókat elvenni/kikapcsolni, illetve cserélni más termékre/opcióra.",
        above: {
          title: "Árcsökkenés, ha",
          dscription: "Az ár akkor csökken, ha az árcsökkenés utáni ár alacsonyabb mint az eredeti ár adott százaléka vagy adott összeg. pl. 100% azt jelenti, hogy bármilyen csökkenés érvényesülni fog."
        },
        measure: {
          title: "Mérték típus"
        }
      },
      "increase_price": {
        title: "Árnövelés logikája",
        above: {
          title: "Árnövelés, ha",
          dscription: "Az ár akkor növekszik, ha az árnövekedés utáni ár magasabb mint az eredeti ár adott százaléka vagy adott összeg. pl. 0% azt jelenti, hogy bármilyen növekény érvényesülni fog."
        },
        measure: {
          title: "Mérték típus"
        }
      }
    },
    enabled_features: {
      homedelivery: {
        zip: {
          title: "Irányítószám",
          description: "Mely irányítószámokat fogadja el a rendszer (regexp)"
        },
        dayends: {
          title: "Napforduló",
          description: "A webshopban hány óráig rendelhessen még aznapra"
        },
        timeahead: {
          title: "Késleltetés",
          description: "Kézi vagy webshopos rendeléskor minimum hány óra múlvára vállalod a kiszállítást"
        },
        "min-duration": {
          title: "Kézbesítési idősáv hossza",
          description: "Milyen hosszú a kiszállítási idősáv órában"
        },
        homedelivery_menuitem: {
          title: "Házhozszállítási díj termék azonosítója",
          description: "Irányítószám alapú házhozszállítási díj alkalmazásánál mely terméket használja a rendszer. Ha nincs ilyen termék még, hozzon létre egy új terméket 'Házhozszállítási díj néven', mentse le, nyissa újra módosításra, jegyezze az azonosítóját, majd írja be ide."
        },
        instantOrderOnly: {
          title: "Csak azonnali rendelések",
          description: "Csak azonnali rendelések fogadása a webshop-ban",
        },
        copy_order_email: {
          title: "Email értesítés",
          description: "Webshopos rendelések leadásáról, státuszváltozásáról ki kapjon email értesítést",
        },
        send_email_on: {
          title: "Email küldés melyik státuszban",
          description: "Webshopos rendelések mely státuszba kerülésekor menjen email értesítés a fenti címekre"
        },
        price: {
          title: "Kiszállítási díj",
          description: "Lehetősége van irányítószám és értékhatár alapján automatikus kiszállítási díj felszámolására",
          name: "Megnevezés",
          zip: "Irányítószám",
          above_amount: "Értékhatártól",
          price: "Kiszállítási díj",
          except: {
            title: "Kivéve",
            description: "Kivéve a következő címkéjű termékeknél"
          },
          only: {
            title: "Csak",
            description: "Csak a következő címkéjű termékekre"
          }

        },
        orderedStatus: {
          title: "Megrendelés utáni státusz",
          description: "A webshopon rendelt tételek kezdeti státusza"
        },
        "available-timeslots": {
          title: "Kiszállítási időszakok",
          description: "Ezekben az időszakokban vállal kiszállítást a webshopon keresztül"
        },
        "terms-and-conditions": {
          title: "Általános szerződési feltételek",
          description: "Webshopos rendelésnél tájékoztassa ügyfeleit a házhozszállítási feltételeiről",
        },
        delays: {
          title: "Kiszállítás vállalt időpontja",
          description: "Wolt/Foodora esetén az visszaigazolt elkészítés/kiszállítás választható időtartamai percben"
        },
        blockWaiterClose: {
          title: "Felszabadítás tiltása pincérnek",
          description: "Csak administrator tudjon házhozszállításos rendelést lezárni."
        },
        printInvoiceOnOrder: {
          title: "Automatikus számlanyomtatás",
          description: "Rendelés elküldése után automatikusan nyomtassa ki a számlát is."
        },
        allowed_installments: {
          title: "Fizetés időpontjának lehetőségei",
          description: "Menü rendelés esetén a fizetés időpontjának lehetőségei",
          start: "Előre fizetés",
          by_each: "Napi fizetés",
          end: "Fizetés a végén",
        },
        user_allowed_installments: {
          title: "Fizetés időpontjának lehetőségei webshopban",
          description: "Menü rendelés esetén a fizetés időpontjának lehetőségei a webshopban",
          start: "Előre fizetés",
          by_each: "Napi fizetés",
          end: "Fizetés a végén",
        },
        "table-number": {
          title: "Asztal szám",
          description: "A házhozszállításos rendeléseket kezelő asztal száma",
        }
      },
      pickup: {
        dayends: {
          title: "Napforduló",
          description: "A webshopban hány óráig rendelhessen még aznapra"
        },
        timeahead: {
          title: "Késleltetés",
          description: "Kézi vagy webshopos rendeléskor minimum hány óra múlvára vállalod az rendelés elkészítését"
        },
        "min-duration": {
          title: "Kézbesítési idősáv hossza",
          description: "Milyen hosszú a kiadási idősáv órában"
        },
        instantOrderOnly: {
          title: "Csak azonnali rendelések",
          description: "Csak azonnali rendelések fogadása a webshop-ban",
        },
        copy_order_email: {
          title: "Email értesítés",
          description: "Webshopos rendelések leadásáról, státuszváltozásáról ki kapjon email értesítést",
        },
        send_email_on: {
          title: "Email küldés melyik státuszban",
          description: "Webshopos rendelések mely státuszba kerülésekor menjen email értesítés a fenti címekre"
        },
        orderedStatus: {
          title: "Megrendelés utáni státusz",
          description: "A webshopon rendelt tételek kezdeti státusza"
        },
        "available-timeslots": {
          title: "Kiszállítási időszakok",
          description: "Ezekben az időszakokban vállalsz elviteles rendelést a webshopon keresztül"
        },
        "terms-and-conditions": {
          title: "Általános szerződési feltételek",
          description: "Webshopos rendelésnél tájékoztassa ügyfeleit az elviteles rendelés feltételeiről",
        },
        delays: {
          title: "Kiszállítás vállalt időpontja",
          description: "Wolt/Foodora esetén az visszaigazolt elkészítés választható időtartamai percben"
        },
        allowed_installments: {
          title: "Fizetés időpontjának lehetőségei",
          description: "Menü rendelés esetén a fizetés időpontjának lehetőségei",
          start: "Előre fizetés",
          by_each: "Napi fizetés",
          end: "Fizetés a végén",
        },
        user_allowed_installments: {
          title: "Fizetés időpontjának lehetőségei webshopban",
          description: "Menü rendelés esetén a fizetés időpontjának lehetőségei a webshopban",
          start: "Előre fizetés",
          by_each: "Napi fizetés",
          end: "Fizetés a végén",
        },
        "table-number": {
          title: "Asztal szám",
          description: "Az elviteles rendeléseket kezelő asztal száma"
        }
      },
      prebooking: {
        defaultDuration: {
          title: "Alapértelmezett időtartam",
          description: "Alapértelmezett időtartam (perc) foglaláskor"
        },
        showBefore: {
          title: "Mutasd ennyivel előtte",
          description: "Az asztaltérképen ennyi perccel előtte mutassa a foglalásokat"
        },
        maxLateDuration: {
          title: "Megengedett késés",
          description: "Ennyi perc ráhagyás van a vendég érkezéséig, mielőtt törlődik a foglalása"
        },
        minBookingMinutes: {
          title: "Minimum időtartam",
          description: "Weben minimum ennyi percre lehet foglalni"
        },
        maxBookingMinutes: {
          title: "Maximum időtartam",
          description: "Weben maximum ennyi percre lehet foglalni"
        },
        defaultBookingMinutes: {
          title: "Alapértelmezett időtartam webshopen",
          description: "Alapértelmezett időtartam (perc) foglaláskor webes foglaláskor"
        },
        slotMinutes: {
          title: "Időtartamok",
          description: "Weben ezek az időtartamok választhatóak ki"
        },
        seats: {
          title: "Személyek száma",
          description: "Weben ezek az személy számok választhatóak ki"
        },
        openingHours: {
          title: "Nyitvatartási idő",
          description: "Nyitvatartási idő a weben",
          slots: {
            title: "Idősáv",
            description: "Nyitvatartási idő sáv (pl: 10:00-től 14:00-ig)"
          }
        }
      },
    },
    "tables_overview": {
      title: "Asztaltérkép működése",
    },
    "tables-overview": {
      "autolock": {
        title: "Automatikus kérpenyő zárolás",
        description: "Egy beállított idő után a képernyő zárolódik és csak gyorskóddal vagy jelszóval lehet feloldani."
      },
      "askPersonCount": {
        title: "Személyek számának bekérése",
        description: "Asztal foglaláskor a vendégek számának bekérése a kosárméret meghatározásához."
      },
      "timeout": {
        title: "Zárolási késleltetés",
        description: "Ennyi másodperc múlva zároljon a képernyő"
      },
      "hideMenuCategoryImage": {
        title: "Kategória kép elrejtése",
        description: "Kategóriák képének elrejtése a rendelési felületen"
      },
      "hideMenuItemImage": {
        title: "Termék kép elrejtése",
        description: "Termékek képének elrejtése a rendelési felületen"
      },
      "bigOrderNumber": {
        title: "Nagy rendelési szám",
        description: "A konyhai blokkon az alapbeállításnál nagyobb rendelési szám nyomtatása"
      },
      "perPersonKitchenReceipt": {
        title: "Személyenként bontott konyhai blokk",
        description: "Például amikor személyenként külön tálcára kell tálalni"
      },
      "bigAdditions": {
        title: "Nagyobb betűméret konyhai blokkon",
        description: "Nagyobb +/- sorok a konyhai blokkon"
      },
      "autoDeliverOnUnlock": {
        title: "Automatikus kiszállítás mód",
        description: "Az asztal felszabadítása után minden nem törölt tétel automatikusan 'kiszállítva' állapotra lesz állítva és a készletről levonva"
      },
      "kitchenQrCode": {
        title: "QR Kód nyomtatása a konyhai/pultos blokkra",
        description: "QR Kód konyhai/pultos kérszerejelentésre használható, megfelelő QR olvasóval beolvasva"
      },
      "unlockOnPayment": {
        title: "Automatikus felszabadítás",
        description: "Fizetés esetén az asztal automatikus felszabadítása, ha már nincs több fizetetlen tétel"
      },
      "lessKioskButtons": {
        title: "Kevés gomb kassza módban",
        description: "Kassza módban a lehető legkevesebb gomb megjelenítése"
      },
      "print_accepted_customer_orders": {
        title: "Automatikus nyomtatás visszaigazoláskor",
        description: "Webshopos rendelésel automatikus nyomtatása visszaigzoláskor"
      },
      "delayed_orders_browser": {
        title: "Késleltetett rendelés feladás böngészőből",
        description: "A rendelések csak megerősítéskor kerülnek elküldésre a szerverre."
      },
      "delayed_orders_mobile": {
        title: "Késleltetett rendelés feladás mobil alkalmazásból",
        description: "A rendelések csak megerősítéskor kerülnek elküldésre a szerverre."
      },
      "pos_delayed_orders_browser": {
        title: "Késleltetett rendelés feladás böngészőből kassza módban",
        description: "A rendelések csak megerősítéskor kerülnek elküldésre a szerverre."
      },
      "pos_delayed_orders_mobile": {
        title: "Késleltetett rendelés feladás mobil alkalmazásból kassza módban",
        description: "A rendelések csak megerősítéskor kerülnek elküldésre a szerverre."
      },
      "always_allow_delete": {
        title: "Törlés engedélyzése",
        description: "Törlés engedélyezése felszolgálói jogosúltság esetére is"
      },
      block_waiter_print_invoice: {
        title: "Számla nyomtatás tiltása",
        description: "Felszolgáló általi számlanyomtatás tiltása"
      },
      block_waiter_register_payment: {
        title: "Fizettetés tiltása",
        description: "Felszolgáló általi fizettettés tiltása"
      },
      block_waiter_print_daily_closing: {
        title: "Gyors forgalmi adatok tiltása",
        description: "Aznapi forgalmi adatok lekérdezésének tiltása a felszolgálóknak",
      },
      waiter_print_daily_closing_full: {
        title: "Teljes gyors forgalmi adatok",
        description: "Teljes forgalmi adatok lekérdezése felszolgálóknak",
      },
      "drinkOrderStatus": {
        title: "Italok státusza megrendeléskor",
        description: ""
      },
      "mealOrderStatus": {
        title: "Ételek státusza megrendeléskor",
        description: ""
      },
      "table-qrcode": {
        title: "Az asztalhoz nyomtatott qr-kód funkiója",
        description: "",
        enumNames: ["nincs", "csak fizetés", "csatlakozás"]
      },
      "auto-table-qrcode": {
        title: "Automatikus qr-kód nyomtatás",
        description: "Az első rendelés kinyomatatásánál automatikusan nyomtassa a qr-kódot is",
      },
      "states": {
        title: "Állapotok szűrése",
        description: "Mely állapotú rendeléseket vegye figyelembe."
      },
      "statesShown": {
        title: "Állapotok",
        description: "Mely státuszokat (oszlopok) mutassa az asztal megnyitásakor"
      },
      "newOrdersStatesShown": {
        title: "Állapotok rendeléskor",
        description: "Mely státuszokat (oszlopok) mutassa új rendelések felvételénél"
      },
      "newOrdersKioskStatesShown": {
        title: "Állapotok kassza módban",
        description: "Mely státuszokat (oszlopok) mutassa kassza módú asztaloknál"
      },
    }
  },
  receipts: "Nyugták",
  receipt: "Nyugta",
  save_update_address: "Mentés és cím frissítése",
  sort_abc: "Sorrendezés",
  hellopay: {
    error: {
      "1": "Ismeretlen vagy egyéb hiba",
      2: "Időtúllépés vagy megszakítás, kártya nem található",
      "3": "Érvénytelen vagy hibás kártya",
      "4": "Nem áll rendelkezésre fedezet a kártyán",
      "5": "Nem a megfelelő kártya lett odahelyezve",
      "6": "Nem sztornózható, mert nem az utolsó tranzakció",
      "7": "Nem HelloPay kártya",
      "8": "Kártya kommunikációs hiba",
      "9": "Tablet kommunikációs hiba",
      "10": "Menüben van a felhasználó",
      "11": "Sikertelen external_identifier validáció",
      "12": "Sikertelen tranzakció betöltés",
      "13": "Nem megfelelő jogosultság",
      "14": "Nem megfelelő állapotban van a terminál (OTP/Unipass nál van a vezérlés)",
    }
  },
  teya: {
    error: {
      "CANCELLING": "Bankkártyás fizetés megszakítása folyamatban",
      "CANCELLED": "Bankkártyás fizetés megszakítva",
      "FAILED": "Sikertelen bankkártyás fizetés",
      "NEW": "Sikertelen bankártyás fizetés. Nincs válasz a termináltól!",
    }
  },
  bizpay: {
    error: {
      "unkown": "Sikertelen bankkártyás fizetés"
    }
  },
  paynance: {
    error: {
      "Transaction aborted": "Tranzakció megszakítva",
      "Transaction canceled by user": "Tranzakció manuálisan megszakítva",
      "Transaction timed out": "Tranzakció időtúllépés miatt megszakítva"
    }
  },
  reprint_receipt: "Újranyomtatja a pénztárgépi nyugtát?",
  cashier_open_amount: "Nyitó készpénz",
  cashier_dayopen: "Napnyitás",
  cashier_dayclose: "Napzárás",
  storno: "Sztornó",
  stornoreason: "Sztornó indoka",
  stornoreason_1: "Ügyfél elállása",
  stornoreason_2: "Téves bevitel",
  stornoreason_3: "Téves fizetőeszköz bevitel",
  stornoreason_4: "Termék nincs készleten",
  stornoreason_5: "Téves bizonylattípus kibocsátása",
  stornoreason_6: "Sikertelen fizetőeszköz használat",
  stornoreason_7: "Téves ügyfél adat/hibás bevitel",
  stornoreason_8: "Próbavásárlás",
  stornoreason_0: "Egyéb",
  ap: "AP Szám",
  receiptNumber: "Nyugta száma",
  customerTaxNum: "Ügyfél adószám",
  customerName: "Ügyfél név",
  customerPostCode: "Ügyfél irányítószám",
  customerTown: "Ügyfél helység",
  customerAddress: "Ügyfél cím",
  dont_print: "Nincs nyomtatás",
  history: "Előzmények",
  action_not_allowed: "Nincs jogosultsága ehhez a művelethez",
  already_paid_reprint_invoice: "Számla fizetve! Tényleg újra ki akarod nyomtatni a számlát?",
  documentation: "Leírás",
  user_comment: "Egyéb vendég információ",
  booking_comment: "Megjegyzés",
  channels: {
    console: "Console",
    webshop_homedelivery: "Webshop",
    webshop_pickup: "Webshop",
    webshop_onsite: "Webshop",
    wolt: "Wolt",
    foodora: "Foodora",
    falatozz: "Falatozz",
    console_homedelivery: "",
    console_pickup: "",
  },
  auto_translate: "Fordítás",
  webshop: "Webshop",
  authenticate: "Authentikáció",
  reauthenticate: "Újraauthentikálás",
  hospitalityUnit: "Vendéglátóegység",
  terminal: "Terminál",
  choose_terminal_to_use: "Válasz bank terminált",
  restart_printerserver: "Újrakapcsolódás a nyomtatókhoz",
  reboot_printerserver: "Nyomtató szerver újraindítása",
  restart_printer: "Újra kell indítani a következő nyomtatót: {0}",
  restart_needed: "Indítsd (újra) a nyomtatót",
  "External HealthCheck: Successful": "Nyomtató rendben",
  "Internal HealthCheck: Successful": "Nyomtató rendben",
  charwidth: "Karakter szélesség",
  the_table_is_not_occupied: "Ez az asztal szabad!",
  do_you_want_to_catch_incoming_calls: "Szeretnéd, hogy az erre a telefonra jövő bejövő hívásokat hívásazonosításra használjuk a házhozszállítás/elviteles adatrögzítésnél? Ha igen, akkor a böngészőben ugyanezzel e felhasználóval lépj majd be!",
  upload_successful: "Sikeres feltöltés",
  pointout: "Pont gyűjtés",
  pointin: "Pont beváltás",
  if: "Ha",
  waiterOrder: "Felszolgáló általi rendelés",
  customerOrder: "Vendég általi rendelés (webshop)",
  localConsumption: "Helyszíni fogyasztás",
  takeawayConsumption: "Elviteles fogyasztás",
  default_cashregister_change: "<strong>{0}</strong> legyen az alapértelmezett pénztárgép ezen a gépen?",
  container_charge: "Betétdíj",
  action_failed: "Sikertelen művelet. Lehetséges, hogy valami nem a legfrissebb, szinkronizálunk egyet, aztán próbáld újra a műveletet!",
  container_charge_onsite: "Helyszíni fogyasztás esetén is",
  id: "Azonosító",
  product_search: "Termékkereső",
  print_qrcode: "QR Kód a blokkon",
  minimum_price: "Az végső ár nem lehet kevesebb az alapárnál még akkor sem, ha csak a rendeléskor kikacsolunk alapértelmezett opciókat. Például pizzáknál, ha el is veszünk egy feltétet, attól még ne legyen olcsóbb a pizza.",
  stockAnalitics: "Alapanyag analitika",
  productAnalitics: "Termék analitika",
  cartsize: "Kosárméret",
  customer_orders: "Új ügyféloldali rendelések",
  deliverable_tables: "Tálalható asztalok",
  assistance_needed: "A felszolgálót kérik",
  fiscal_receipt_already_printed: "Ez az adóügyi nyugta már sikeresen ki lett nyomtatva (nyugtaszám:{0})",
  invoice_count: "Eredeti mennyiség",
  auto_import_incoming_stocks: "Aut. bevételezés",
  auto_commit_imcoming_stocks: "Aut. jóváhagyás",
  invoice_not_found: "Nemlétező számlaszám az Online Számla rendszerében",
  online_szamla_feature: "Az EatWithMe Online Számla modulja jelenleg nincs aktiválva az előfizetésében. A modul képes letöteni és igény szerint automatikusan/félautomatikusan bevételezni az Online Számla rendszerébe beérkező áfás számláit.",
  receipe: "Recept",
  mandatory_receipe: "Összetevők, amiket mindenképpen tartalmaz a termék. Például pizza esetén a pizza buci",
  included_receipe: "Összetevők, amiket tartalmaz a termék, de elvehető, cserélhető rendeléskor. Például cappucino esetén a esetén a tej, amit laktózmentesre cserélhetünk",
  optional_receipe: "További lehetőségek, amivel bővíthető vagy amire cserélhető egy alapértelmezett összetevő rendeléskor.",
  auto_import_incoming_stocks_tooltip: "Automatikus bevét generálása bejövő számlák alapján ennél a szállítónál.",
  auto_commit_incoming_stocks_tooltip: "Automatikus és végleges (nem tervezett állaputó) bevét generálása bejövő számlák alapján ennél a szállítónál, így azonnal módosítja a készletet.",
  no_permission_to_perform_this_action: "Nincs jogosultsága ehhez a művelethez",
  events: "Események",
  lastSale: "Utolsó felhasználás",
  lastBuy: "Utolsó bevét",
  lastInventory: "Utolsó leltár",
  nextInventory: "Következő tervezett letár",
  criticalStock: "Kritikus mennyiség, ami veszélyezteti az üzletmenetet",
  minimumStock: "Minimum mennyiség, amikor tipikusan feltöltjük a készletet",
  refillStock: "Cél mennyiség, amire tipikusan feltöltjük a készletet",
  below_criticalStock: "Kritikus mennyiség alatti termék, ami veszélyezteti az üzletmenetet",
  below_minimumStock: "Minimum mennyiség alatti termék, amikor tipikusan feltöltjük a készletet",
  daysAgo: "{0} napja",
  inDays: "{0} nap múlva",
  monthsAgo: "{0} hónapja",
  inMonths: "{0} hónap múlva",
  yearsAgo: "{0} éve",
  inYears: "{0} év múlva",
  new: "új",
}
export default admin_local_hu;
