import React, { Component } from "react";
import $ from "jquery";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import auth from "../../js/auth";
import LabeledInput from "../../widgets/LabeledInput";
import FlexGrowSpace from "../../widgets/FlexGrowSpace";
import ChangeLanguage from "../icon-buttons/ChangeLanguage";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";

class PasswordForgotten extends Component {
  render() {
    return (
      <Modal className="loginDialog" backdrop="static" keyboard={false} show={true} centered>
        <Modal.Header>
          <Modal.Title>{I18n.t("local.password_forgotten")}</Modal.Title>
          <ChangeLanguage />
        </Modal.Header>

        <Modal.Body>
          <div
            className=""
            style={{
              left: "0px",
              width: "100%",
              zIndex: "5",
              overflow: "hidden",
              paddingLeft: "20px",
              paddingRight: "20px",
              flexFlow: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div>
              <div>{I18n.t("local.password_forgotten_text")}</div>
              <form onSubmit={this.login} className="input-group" style={{ width: "100%", background: "unset" }}>
                <LabeledInput id="email" label={I18n.t("local.email")} placeHolder={I18n.t("local.provide_email")} type="text" ref={node => (this.emailNode = node)} />
                <input
                  type="submit"
                  style={{
                    position: "absolute",
                    left: "-9999px",
                    width: "1px",
                    height: "1px"
                  }}
                  tabIndex="-1"
                />
              </form>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button style={{ margin: "0px" }} id="login" onClick={() => this.props.history.push("login")} className="btn btn-info icon">
            {I18n.t("local.back_to_login")}
          </Button>
          <FlexGrowSpace />
          <Button
            style={{ margin: "0px" }}
            id="register"
            onClick={() => {
              this.register();
            }}
            className="btn btn-primary icon icon-register"
          >
            {I18n.t("local.feedback.sendLabel")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  register() {
    const t = this;
    var email = this.emailNode.inputNode.value;
    if (email === "") {
      auth.hightlightError($(this.emailNode.inputNode));
      return;
    }
    // eslint-disable-next-line no-useless-concat
    auth.getGlobal("authenticationService/sendPasswordResetEmail" + "?email=" + email + "&lang=" + $("html").attr("lang")).done(function (data) {
      if (data === null) {
        alert("Failed to send password reset email!");
      } else if (data.error) {
        auth.messageDialog(I18n.t("local.error_message"), I18n.t(data.error));
      } else if (data.success) {
        auth.messageDialog(I18n.t("local.success_message"), I18n.t("local.password_reset_email_sent2", { email: email })).done(() => {
          t.props.history.push("/login");
        });
      } else {
        auth.messageDialog(I18n.t("local.error_message"), I18n.t("local.failed_to_send_password_reset_email"));
      }
    });
  }
}
const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    return {
      language: props.rootReducer.language
    };
  }
  return {};
};

export default connect(mapStateToProps)(PasswordForgotten);
