import React, { Component } from "react";
import $ from "jquery";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import auth, { ajaxCallStart, ajaxCallEnd, getParameterByName, switchServer, post, messageDialog, setGlobalServer, featureEnabled } from "../../js/auth";
import ChangeLanguage from "../icon-buttons/ChangeLanguage";
import LabeledInput from "../../widgets/LabeledInput";
import { Translate, I18n } from "react-redux-i18n";
import admin, { getMyStatus, hasRole } from "../../js/admin";
import { store } from "../..";
import EatWithMeActions from "../../actions/EatWithMeActions";
import EatWithMeText from "../EatWIthMeText";
import { connect } from "react-redux";
import logo from "../../img/EwM-landscape.svg";

class Login extends Component {
  state = {
    showRegister: false,
    showLogin: true,
    showPasswordReset: false
  };

  componentDidMount = () => {
    //Listen for authorization success
    document.addEventListener('AppleIDSignInOnSuccess', (data) => {
      //handle successful response
    });
    //Listen for authorization failures
    document.addEventListener('AppleIDSignInOnFailure', (error) => {
      //handle error.
    });
  }

  render() {
    const defaultEmail = getParameterByName("user");
    return (
      <React.Fragment>
        <Modal className="loginDialog" backdrop="static" keyboard={false} show={this.state.showLogin} centered size="lg" lang={localStorage.language}>
          <Modal.Header style={{ padding: "10px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", fontSize: "24px" }} >
            <img className="eatwithmetext hide-print" alt="EatWithMe" style={{ position: "relative", left: "50%", transform: "translateX(-50%)", height: "179px", fill: "blue" }} src={logo}></img>
            <ChangeLanguage />
          </Modal.Header>

          <Modal.Body style={{ padding: "10px" }}>
            <div
              className=""
              style={{
                left: "0px",
                width: "100%",
                zIndex: "5",
                overflow: "hidden",
                paddingLeft: "20px",
                paddingRight: "20px",
                flexFlow: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div>
                <form onSubmit={this.login} className="input-group" style={{ width: "100%", background: "unset" }}>
                  <LabeledInput value={defaultEmail} autocomplete="username" formControlName="username" id="email" label={<Translate value="local.email" />} placeHolder={I18n.t("local.provide_email")} type="email" ref={node => (this.emailNode = node)} />
                  <LabeledInput autocomplete="current-password" formControlName="password" id="password" label={<Translate value="local.password" />} placeHolder={I18n.t("local.provide_password")} type="password" ref={node => (this.passwordNode = node)} />
                  <input
                    type="submit"
                    style={{
                      position: "absolute",
                      left: "-9999px",
                      width: "1px",
                      height: "1px"
                    }}
                    tabIndex="-1"
                  />
                </form>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer style={{ padding: "10px" }}>
            <div style={{ display: "flex", flexGrow: "1", justifyContent: "center", alignItems: "center", width: "100%", "flexFlow": "column" }}>
              <Button onClick={() => this.login()} className="btn btn-primary icon icon-login" style={{ marginBottom: "20px" }}>
                <Translate value="local.login" />
              </Button>
              {false ?
                <Button onClick={() => this.facebookLogin()} className="btn btn-primary icon-social icon-facebook">
                  <Translate value="local.facebooklogin" />
                </Button> : null}
              {true ?

                auth.device.platform === "iOS" ?
                  <Button onClick={() => this.appleLogin()} className="btn icon-social icon-apple">
                  </Button> : null

                : null}
            </div>
            <div style={{ display: "flex", flexGrow: "1", justifyContent: "center", alignItems: "center", width: "100%" }}>
              <Button onClick={() => this.props.history.push("register")} className="btn btn-secondary">
                <Translate value="local.need_sign_up" />
              </Button>
              <Button onClick={() => this.props.history.push("passwordForgotten")} className="btn btn-secondary">
                <Translate value="local.forgot_password" />
              </Button>
            </div>
          </Modal.Footer>
        </Modal >
      </React.Fragment >
    );
  }

  getRestaurants() {
    var def = $.Deferred();
    admin.getRestaurants(function (data) {
      var restaurants = data.restaurants;
      if (auth.server !== auth.globalserver && !auth.myStatus.is_global_server) {
        admin.getGlobalRestaurants(
          function (gdata) {
            gdata.restaurants.filter(r => restaurants.filter(rr => rr.globalId === r.globalId).length === 0).map(r => restaurants.push(r));
            def.resolve(restaurants);
          },
          () => {
            auth.ajaxError = false;
            def.resolve(restaurants);
          }
        );
      } else {
        def.resolve(restaurants);
      }
    });
    return def.promise();
  }

  load = () => {
    const t = this;
    getMyStatus(myStatus => {
      store.dispatch(EatWithMeActions.resetMyStatus(myStatus));
      t.getRestaurants().done(restaurants => {
        if (Number(localStorage.restaurantSelected) > 0) {
          admin.getTables(data => {
            store.dispatch(EatWithMeActions.updateRestaurants(restaurants));
            store.dispatch(EatWithMeActions.updateRestaurantTables(data.restaurantTables));
          });
        } else {
          store.dispatch(EatWithMeActions.updateRestaurants(restaurants));
          store.dispatch(EatWithMeActions.updateRestaurantTables([]));
        }
      });

      if (typeof localStorage.redirectUrl !== "undefined") {
        var url = localStorage.redirectUrl;
        localStorage.removeItem("redirectUrl");
        this.props.history.push(url);
        this.props.onLogin();
        return;
      }
      if (myStatus.restaurant_id) {
        const redirectUrl = getParameterByName("redirect", this.props.history.location.search);
        if (redirectUrl !== null && redirectUrl !== "") {
          this.props.history.goBack();
        } else {
          this.props.history.replace({ firstPage: true });
          this.props.history.push("/");
        }
      } else {
        this.props.history.replace({ firstPage: true });
        this.props.history.push("/");
        localStorage.server = auth.server;
      }
      this.props.onLogin();
    });
  };

  loginSuccessHandler = (email, password, data, status, xhr) => {
    if (!data.roles || !data.roles.length) {
      messageDialog(I18n.t("local.error_message"), I18n.t("local.access_denied")).done(() => {
      })
      return;
    }
    localStorage.id = data.customer.id;
    localStorage.email = data.customer.email;
    localStorage.name = data.customer.name;
    localStorage.surname = data.customer.surname;
    localStorage.sessionId = data.sessionId;
    localStorage.type = "eatwithme";
    localStorage.locked = false;
    const t = this;
    if (data.is_global_server === true) {
      setGlobalServer(auth.server);
    }
    if (!t.previousServerSelected && data.roles.filter(r => r.entityType === "Restaurant" && r.entityId).length === 1) {
      t.previousServerSelected = data.roles.find(r => r.entityType === "Restaurant" && r.entityId).entityId;
    }
    const supersetHeaders = {
      "Content-Type": "application/json"
    }
    if ((t.previousServer !== auth.server || t.previousServerSelected > 0) && t.previousServerSelected !== Number(localStorage.restaurantSelected)) {
      getMyStatus(myStatus => {
        admin.getGlobalRestaurants(
          function (gdata) {
            var restaurant = gdata.restaurants.find(r => r.globalId == t.previousServerSelected);
            if (!restaurant) {
              t.previousServerSelected = data.roles.find(r => r.entityType === "Restaurant" && r.entityId).entityId;
              restaurant = gdata.restaurants.find(r => r.globalId == t.previousServerSelected);
            }
            switchServer({ globalId: restaurant.id, serverUrl: restaurant.serverUrl, instance: restaurant.instance ? restaurant.instance : "" }).done(() => {
              if (email && password && hasRole(data.roles, ["admin"])) {
                post("https://superset.eatwithme.online/login/", { "provider": "db", "refresh": false, "username": email, "password": password }, supersetHeaders).fail(() => auth.ajaxError = false)
              }

              t.load();
            });
          },
          () => {
            t.load();
          }
        );
      });
      return;
    }
    if (typeof auth.myStatus != "undefined") {
      auth.myStatus.customers = data.customers;
    }
    if (hasRole(data.roles, ["admin"]) && email && password) {
      post("https://superset.eatwithme.online/login/", { "provider": "db", "refresh": false, "username": email, "password": password }, supersetHeaders).fail(() => auth.ajaxError = false)
    }
    t.load();
  }

  loginFailureHandler = (data) => {
    auth.ajaxError = false;
    if (data.status === 403) {
      auth.messageDialog(I18n.t("local.error_message"), I18n.t("local.login_failed"));
      return;
    } else if (data.status === 401) {
      auth.messageDialog(I18n.t("local.error_message"), I18n.t("local.login_failed"));
      return;
    } else if (data.status === 404) {
      auth.messageDialog(I18n.t("local.error_message"), I18n.t("local.login_failed"));
      return;
    }
    auth.messageDialog(I18n.t("local.error_message"), I18n.t("local.login_failed"));
  }

  saveState = () => {
    this.previousServer = auth.server;
    this.previousServerSelected = auth.myStatus ? auth.myStatus.restaurant_global_id : 0;
    auth.server = auth.globalserver;
    localStorage.server = auth.server;
    localStorage.restaurantSelected = 0;
  }

  login = event => {

    this.saveState();

    localStorage.facebookLogin = false;
    var password = this.passwordNode.inputNode.value;
    var email = this.emailNode.inputNode.value;
    if (password === "Password" || email === "Email") return;
    if (password === "Password" || password === "") {
      auth.hightlightError($(this.passwordNode));
      return;
    }
    if (email === "Email" || email === "") {
      auth.hightlightError($(this.emailNode));
      return;
    }
    localStorage.defaultemail = email;
    localStorage.instance = "eatwithme";
    post(auth.globalserver + "/eatwithme.server/login?instance=eatwithme", "username=" + encodeURIComponent(email.toLowerCase()) + "&password=" + encodeURIComponent(password) + "&redirect=true&keep=true", {}
    )
      .fail(this.loginFailureHandler)
      .done((data) => this.loginSuccessHandler(email, password, data));
  }

  facebookLogin = (code, appServer) => {
    ajaxCallStart("facebook login");
    const t = this;
    window.facebookConnectPlugin.getLoginStatus(function onLoginStatus(result) {
      if (result.status === "connected") {
        localStorage.facebookLogin = true;
        ajaxCallEnd("facebook login");
        t.facebookLoginToServer(result.authResponse.accessToken, appServer);
      } else {
        function fbSuccess(result) {
          localStorage.facebookLogin = true;
          ajaxCallEnd("facebook login");
          t.facebookLoginToServer(result.authResponse.accessToken, appServer);
        }
        function fbError(result) {
          ajaxCallEnd("facebook login");
        }
        window.facebookConnectPlugin.login(["public_profile", "email"], fbSuccess, fbError);
      }
    });
  };

  appleLogin = () => {
    this.saveState();
    //eslint-disable-next-line no-undef
    AppleSignIn.startLogin()
      .then((res) => {
        localStorage.instance = "eatwithme";
        post(auth.globalserver + "/eatwithme.server/login?instance=eatwithme", "username=apple:" + encodeURIComponent(res.authorizationCode + "/true") + "&password=" + encodeURIComponent(res.authorizationCode) + "&redirect=true&keep=true&instance=" + localStorage.instance, {})
          .done((data) => {
            console.log("Successfuly apple login");
            this.loginSuccessHandler(undefined, undefined, data)
          })
          .fail(data => {
            console.log("Unsuccessfuly apple login");
            this.loginFailureHandler(data);
          });
      });
  }

  facebookLoginToServer = (code, appServer) => {
    this.saveState();
    return post(auth.globalserver + "/eatwithme.server/login?instance=eatwithme", "username=facebook:" + encodeURIComponent(code + "/true") + "&password=" + encodeURIComponent(code) + "&redirect=true&keep=true", {})
      .done((data) => this.loginSuccessHandler(undefined, undefined, data))
      .fail(this.loginFailureHandler);
  };
}

const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    return {
      language: props.rootReducer.language
    };
  }
  return {};
};

export const LoginClass = Login;

export default connect(mapStateToProps)(Login);