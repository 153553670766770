import React, { Component } from "react";
import orderListUtil from "../js/order-list-util";
import { Translate } from "react-redux-i18n";
import { connect } from "react-redux";
import { ready, clearData, reload, saveRestaurant, saveRole, activateRestaurantPaymentMethod, saveCurrency, activateCurrency, deleteCurrency, _deleteRole, deleteAccessToken, addAccessToken, saveNTAKID, downloadCSR, uploadCER, testNtak, deleteTraffic } from "../js/admin/restaurants";
import LogoutButton from "../components/icon-buttons/LogoutButton";
import AICommandBox from "../js/ai/AICommandBox";
import auth, { featureEnabled, getMyImageUrl, setRem } from "../js/auth";
import EatWithMeText from "../components/EatWIthMeText";
import MenuItemHeader from "../components/MenuItemHeader";
import ChangeLanguage from "../components/icon-buttons/ChangeLanguage";
import UserImageButton from "../components/icon-buttons/UserImageButton";
import { withRouter } from "react-router-dom";
import { capturePhotoWithData } from "../js/camera-util";


class Restaurants extends Component {
    state = {
        initialized: false,
    };

    static getDerivedStateFromProps(props, state) {
        return state;
    }

    componentDidMount() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;

        if (myStatus && myStatus.restaurant_id) {
            ready(this.props);
            this.setState({ initialized: true });
        }
        auth.minfontsize = 10;
        window.saveRestaurant = saveRestaurant;
        window.saveRole = saveRole;
        window.activateRestaurantPaymentMethod = activateRestaurantPaymentMethod;
        window.saveCurrency = saveCurrency;
        window.activateCurrency = activateCurrency;
        window.deleteCurrency = deleteCurrency;
        window._deleteRole = _deleteRole;
        window.getPhoto = capturePhotoWithData;
        window.capturePhotoWithData = capturePhotoWithData;
        window.deleteAccessToken = deleteAccessToken;
        window.addAccessToken = addAccessToken;
        window.saveNTAKID = saveNTAKID;
        window.downloadCSR = downloadCSR;
        window.uploadCER = uploadCER;
        window.featureEnabled = featureEnabled;
        window.testNtak = testNtak;
        window.deleteTraffic = deleteTraffic;
        setRem();
    }

    componentDidUpdate() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;

        if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
            ready(this.props);
            this.setState({ initialized: true });
        }
    }

    componentWillUnmount() {
        clearData();
        this.setState({ initialized: false });
        delete window.saveRestaurant;
        delete window.saveRole;
        delete window.activateRestaurantPaymentMethod;
        delete window.saveCurrency;
        delete window.activateCurrency;
        delete window.deleteCurrency;
        delete window._deleteRole;
        delete window.deleteTraffic;
    }

    render() {

        const { myStatus } = this.props;
        if (myStatus && myStatus.restaurant_id) {

            return (
                <div id="Restaurants" className="restaurant-actions" style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "0" }}>
                    {!auth.isIncludedInFrame ? (
                        <div className="menuItemHeader">
                            <EatWithMeText />
                            <MenuItemHeader {...this.props} header={<Translate value="admin_local.menus.restaurants" />} />
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <ChangeLanguage />
                                <UserImageButton src={getMyImageUrl()} />
                                <div style={{ marginLeft: "10px", width: "30px", display: "flex", justifyContent: "space-between" }}>
                                    <LogoutButton query="keep=true&redirect=/tablesOverview" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div id="main" ></div>
                    {!auth.isIncludedInFrame ? <AICommandBox /> : ""}
                </div >
            );
        } else return null;
    }

    dateRangeChanged = (event, values) => {
        console.log(values)
        this.setState({
            startDate: values.startDate,
            endDate: values.endDate
        })
        localStorage.admin_stocklist_start = values.startDate._d.getTime();
        localStorage.admin_stocklist_end = values.endDate._d.getTime();
        reload(this.state);
    }

}
const mapStateToProps = (props, state) => {
    if (props.rootReducer.myStatus && props.rootReducer.myStatus.restaurant_id) {
        return {
            restaurant: props.rootReducer.restaurant,
            myStatus: props.rootReducer.myStatus,
            admin_local: props.rootReducer.admin_local,
            local: props.rootReducer.local,
            speechRecognitionOn: props.rootReducer.speechRecognitionOn,
            ai: props.rootReducer.ai,
            speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
        };
    }
    return {};
};

export default connect(mapStateToProps)(withRouter(Restaurants));
