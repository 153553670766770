/* eslint-disable eqeqeq */
/* eslint-disable default-case */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-concat */
/* eslint-disable no-eval */
import $ from "jquery";
import "../tweaked/jquery-contextMenu";
import "bootstrap-4-autocomplete/dist/bootstrap-4-autocomplete";
import ReactDOM from "react-dom";
import React from "react";
import orderListUtil, {
  update,
  updatePrintersStatus,
  getTableOccupation,
  updateOrders,
  printNewOrders,
  getTable,
  updateTableOccupations,
  playNewAcceptedOrderSound,
  updateTableMates,
  processAllOrders,
  getTableOccupationTablesForTable,
  mateInState,
  deleteOrders,
  preProcessOrders,
  updateOrderList,
  setSelected,
  showDetails,
  _show,
  updateAndCloseModal,
  getQuantity2,
  getMenuItemCategory,
  getOrdersPaidBy,
  tmplparams,
  setPrinterForProductionLine,
  unsetPrinterForProductionLine,
  unsetPrinterForPrinterAction,
  setPrinterForPrinterAction,
  resetOrderListUtil,
  addRejectionReason,
  simpleChangeStateByTableAndMenuItem,
  setTakeAway,
  updatePayingMates,
  deleteTableOccupations,
  setTakeAwayOrder,
  toggleSound,
  _keyDownHandler,
  getOrderById,
  getAddresses,
  initSound,
  getTableName,
  playClickSound
} from "./order-list-util";
import admin, {
  getTableMate,
  getMyStatus,
  updatePrices,
  getTableOccupations,
  isAdmin,
  getMenuItem,
  moveOrdersToCustomer,
  cancelOrder,
  printHtml,
  getLocalPrinterSettings,
  getPrinter,
  printPdf,
  unlockTable,
  lockTable,
  getQuantity,
  getDiscount,
  getProductionLine,
  unlockme,
  changeToUser,
  unlock,
  quickChangeToUser,
  lock,
  incrementTableMates,
  activateTableMate,
  unjoinTableMate,
  getMenuCategory,
  getTableMateForCustomer,
  getPickupTableNumber,
  getHomeDeliveryTableNumber,
  resultHandler,
  updateTimeahead,
  settings,
  selectTableOccupationSelected,
  hasRole,
  selectTableNumberSelected,
  getMenuCategoryById
} from "./admin";
import languages, { admin_locals } from "../langs/languages";
import AI from "./ai/ai";
import auth, {
  ajaxCallStart,
  ajaxCallEnd,
  ajaxErrorEnd,
  navigateToLogin,
  startpoll,
  replaceContent,
  setRem,
  isVisible,
  getOrientation,
  select,
  getAjaxCallCount,
  confirmDialog,
  choiceDialog2,
  messageDialog,
  choiceDialog,
  isTop,
  updateCheckBoxesById,
  avoidMultipleClick,
  clickHandler123,
  post,
  loadMyImage,
  updateToolTips,
  getLocale2,
  isDuplicateClickEvent,
  cleanLogin,
  relogin,
  avoidMultipleClick2,
  touchStart,
  touchClick,
  deactivateToggleRadioButton,
  getImageById,
  settingEnabled,
  get,
  scaleMeasure,
  getQuantityLabel,
  getLocale,
  featureEnabled,
  getReportUrl,
  _logError,
  getInputDialog2,
  getInputDialog,
  incomingCallChanged,
  lighten,
  getSetting,
  resetLockTimeout
} from "./auth";
import tableLayout from "./table-layout";
import { showMenuItems, gotoTop, showMenuItemCategory, onsave } from "./chooseMenuItem";
import moment from "moment";
import chooseMenuItems from "./chooseMenuItem";
import SortedSet from "js-sorted-set";
import { customerComparator, getOrderElement, _preProcessOrders, alltimings, allstates } from "./order-grouping";
import { orderMenuItem, editOrder, orderAnother, additionSearchChanged, additionOptionIsIncluded, additionOptionIsChecked, includedInCategoryOption, switchKeyboard, deleteOne, updateBookingComment, updateBookingUserComment } from "./orderMenuItem";
import { _qrcode } from "./qrcode";
import X2JS from "x2js";
import { dayOpen, dayClose, checkPayments, setAmount, amountChanged, selectCurrency, getPaymentMethod, getCurrencyEchangeRate, createNewPartner, showPayments, printReceipt, postPayment, isThereReceiptsToPrint, reprintReceipts, reprintReceipt, storno, registerTip, selectTerminal } from "./tableMatePaid";
import ReactQRCode from "./react-qrcode";
//import BookTable from "./bookTable";
import menuitemutil from "./admin/menuitem";
import { updateFooterButtons as kioskModeUpdateFooterButtons, cancelOrders } from "./point-of-sale";
import { chooseCustomer } from "./choose_customer";
import { I18n } from "react-redux-i18n";
import TableReservationComponent from "../main/tableReservations/TableReservationComponent";
import { Provider } from "react-redux";
import { store } from "..";
import BookingMap from "../components/BookingMap";
import "jquery-typeahead/src/jquery.typeahead.js";
import "jquery-typeahead/dist/jquery.typeahead.min.css";
import "./keyboard";
import { insertCharacter, insertEnter, backspaceCharacter, leftCharacter, rightCharacter, clearInput } from "./keyboard";
import { cashierChanged, reprintInvoice } from "./admin/tableOccupations";
import { red } from "@material-ui/core/colors";
import { ContactsOutlined } from "@material-ui/icons";
import { capturePhotoWithData } from "./camera-util";

const BookTable = {}

SortedSet.find = (sortedSet, item) => {
  const node = sortedSet.findIterator(item).node;
  if (node != null && sortedSet.priv.comparator(node.value, item) == 0) return node.value;
  return null;

};

export const tablesOverview = {};

const { admin_local, local } = languages;

var showTableNumber = false;
if (typeof localStorage.extra_frame_left == "undefined") {
  localStorage.extra_frame_left = "";
  localStorage.extra_frame_right = "";
  localStorage.extra_frame_top = "";
  localStorage.extra_frame_bottom = "";
}

localStorage.removeItem("_editTable");

var command = "";

function startThisPoll() {
  startpoll(serverSideMessageHandler, [
    {
      Restaurant: localStorage.restaurantSelected,
      TableOccupation: "*"
    },
    {
      Restaurant: localStorage.restaurantSelected,
      Menu: "*"
    },
    {
      Restaurant: localStorage.restaurantSelected,
      Settings: "*"
    },
    {
      Restaurant: localStorage.restaurantSelected,
      Printer: "*"
    }
  ], undefined, recoverFromEventSourceError);
}

export const fullReload = (props) => {
  tableLayout.reset();
  $("div#main").empty();
  ready(props);
}

var updateCycle;
var lockCycle;
var history;
var includableFrames;
export const ready = (props, context) => {

  if (auth.device.platform === "Android")
    scaleMeasure("Mérleg");

  initSound();

  tablesOverview.selectedDate = moment().startOf("day").valueOf();

  tableSelectorHash = "";

  document.addEventListener("resume", recoverFromEventSourceError);

  includableFrames = {
    "": { name: admin_local.empty },
    "#/drinkbar": { name: admin_local.drinkbar },
    "#/kitchen": { name: admin_local.serving }
  };


  var def = $.Deferred();

  localStorage.removeItem("productionLineSelected");
  history = props.history;

  resetOrderListUtil();
  ajaxCallStart("processing", 0);

  window.hideEditTable = hideEditTable;
  window.avoidMultipleClick = avoidMultipleClick;
  window.unlockTable = unlockTable;
  window.tableMatePaid = tableMatePaid;
  window.editOrder = editOrder;
  window.isDuplicateClickEvent = isDuplicateClickEvent;
  window.editDiscounts = editDiscounts;
  window.getDiscount = getDiscount;
  window.selectNewOrders = selectNewOrders;
  window.markTableMateForPayment = markTableMateForPayment;
  window.checkPrint = checkPrint;
  window.admin = admin;
  window.update = update;
  window.getTable = getTable;
  window.setAmount = setAmount;
  window.showMenuItemCategory = showMenuItemCategory;
  window.moment = moment;
  window.gotoTop = gotoTop;
  window.orderMenuItem = orderMenuItem;
  window.additionSearchChanged = additionSearchChanged;
  window.submitNewOrders = submitNewOrders;
  window.unlockme = unlockme;
  window.changeToUser = changeToUser;
  window.cleanLogin = cleanLogin;
  window.relogin = relogin;
  window.showqrcode_to_link_teammate = showqrcode_to_link_teammate;
  window.setPrinterForPrinterAction = setPrinterForPrinterAction;
  window.unsetPrinterForProductionLine = unsetPrinterForProductionLine;
  window.printQRCode = printQRCode;
  window.setPrinterForProductionLine = setPrinterForProductionLine;
  window.unsetPrinterForPrinterAction = unsetPrinterForPrinterAction;
  window.avoidMultipleClick2 = avoidMultipleClick2;
  window.printNewOrders = printNewOrders;
  window.unlock = unlock;
  window.getProductionLine = getProductionLine;
  window.touchStart = touchStart;
  window.touchClick = touchClick;
  window.orderAnother = orderAnother;
  window.deleteOne = deleteOne;
  window.pickFrame = pickFrame;
  window.pickSkin = pickSkin;
  window.addRejectionReason = addRejectionReason;
  window.changeLayout = changeLayout;
  window.showTableOccupationList = showTableOccupationList;
  window.getMenuItem = getMenuItem;
  window.getTableMate = getTableMate;
  window.simpleChangeStateByTableAndMenuItem = simpleChangeStateByTableAndMenuItem;
  window.deactivateToggleRadioButton = deactivateToggleRadioButton;
  window.increase = increase;
  window.decrease = decrease;
  window.BookTable = BookTable;
  window.lockTable = lockTable;
  window.afterLockTable = afterLockTable;
  window.menuitemutil = menuitemutil;
  window.onsave = onsave;
  window.setTakeAway = setTakeAway;
  window.setTakeAwayOrder = setTakeAwayOrder;
  window.orderListUtil = orderListUtil;
  window.cancelOrders = cancelOrders;
  window.selectTableOccupation = selectTableOccupation;
  window.addAdditionalTable = addAdditionalTable;
  window.changeToTable = changeToTable;
  window.incrementTableMates = incrementTableMates;
  window.unjoinTableMate = unjoinTableMate;
  window.activateTableMate = activateTableMate;
  window.chooseCustomer = chooseCustomer;
  window.updateTableOccupations = updateTableOccupations;
  window.tableLayout = tableLayout;
  window.ajaxErrorEnd = ajaxErrorEnd;
  window.amountChanged = amountChanged;
  window.cashierChanged = cashierChanged;
  window.checkPayments = checkPayments;
  window.getMenuCategoryOptions = getMenuCategoryOptions;
  window.getMenuCategory = getMenuCategory;
  window.additionOptionIsChecked = additionOptionIsChecked;
  window.additionOptionIsIncluded = additionOptionIsIncluded;
  window.updateOrders = updateOrders;
  window.chooseMenuItems = chooseMenuItems;
  window.showMenuItems = showMenuItems;
  window.settingEnabled = settingEnabled;
  window.tableReadyForDelivery = tableReadyForDelivery;
  window.selectCurrency = selectCurrency;
  window.bookTable = bookTable;
  window.includedInCategoryOption = includedInCategoryOption;
  window.showMap = showMap;
  window.toggleSound = toggleSound;
  window.scaleMeasure = scaleMeasure;
  window.setSelectedScale = setSelectedScale;
  window.createNewPartner = createNewPartner;
  window.getQuantityLabel = getQuantityLabel;
  window.getActiveMenuItems = activeMenuItems;
  window.getActiveMenuCategories = activeMenuCategories;
  window.switchKeyboard = switchKeyboard;
  window.insertCharacter = insertCharacter;
  window.insertEnter = insertEnter;
  window.backspaceCharacter = backspaceCharacter;
  window.leftCharacter = leftCharacter;
  window.rightCharacter = rightCharacter;
  window.clearInput = clearInput;
  window.featureEnabled = featureEnabled;
  window.customerDblClickHandler = customerDblClickHandler;
  window.printDailyClosing = printDailyClosing;
  window.updateTimeahead = updateTimeahead;
  window.settings = settings;
  window.showPayments = showPayments;
  window.printReceipt = printReceipt;
  window.reprintInvoice = reprintInvoice;
  window.reprintReceipts = reprintReceipts;
  window.reprintReceipt = reprintReceipt;
  window.dayOpen = dayOpen;
  window.dayClose = dayClose;
  window.storno = storno;
  window.playClickSound = playClickSound;
  window.getInputDialog2 = getInputDialog2;
  window.getInputDialog = getInputDialog;
  window.updateBookingComment = updateBookingComment;
  window.updateBookingUserComment = updateBookingUserComment;
  window.registerTip = registerTip;
  window.selectTerminal = selectTerminal;
  window.get = get;
  window.sendMessage = auth.sendMessage;
  window.getTableName = getTableName;
  window.capturePhotoWithData = capturePhotoWithData;
  window.incomingCallChanged = incomingCallChanged;
  window.lighten = lighten;
  window.increasePersonCount = increasePersonCount;
  window.decreasePersonCount = decreasePersonCount;
  tableLayout.reset();

  selectTableNumberSelected(0);

  $("script#editTableTemplate").tmpl(props).appendTo("div#main");
  $("script#editRoomTemplate").tmpl(props).appendTo("div#main");

  $("#editTableData").on("shown.bs.modal", () => {
    Object.keys(tableLayout.tableTemplates).forEach(key => {
      const svg = $("#editTableData .svg label#" + key + " svg");
      svg.html(tableLayout.tableTemplates[key].svg);
      const t = svg.find(".eatwithme-table");
      t.off("click");
      t.off("mousedown");
      t.removeClass("eatwithme-table");
      t.removeClass("ui-draggable");
      svg.find(".selectable").removeClass("selectable");
      t.removeClass("ui-draggable-handle");
      const s = svg.find(".eatwithme-seat-number");
      s.off("click");
      s.off("mousedown");
      s.off("remove");
      s.removeClass("eatwithme-seat-number");
      s.removeClass("ui-draggable");
      s.removeClass("ui-draggable-handle");
    })
    const svg = $("#editTableData .svg label svg");
    svg.each((ind, svg) => {
      svg = $(svg);
      svg.parent().parent().off("click");
      const g = svg.find(">g");
      g.find("text").remove();
      const rect = g.get(0).getBBox();
      svg.attr("viewBox", rect.x + " " + rect.y + " " + rect.width + " " + rect.height);
      svg.attr("width", rect.width);
      svg.attr("height", rect.height);
    })

  });

  var searchTimeout = null;

  try {
    tableLayout.reset();

    tablesOverview.context = context;

    orderListUtil.ai = props.ai;

    props.ai.setContext({ page: "tables-overview", tableOccupation: null });
    orderListUtil.processOrdersDone = processOrdersDone;
    orderListUtil.updateOrdersDone = updateOrdersDone;
    orderListUtil.show = show;
    orderListUtil.filter = filter;
    orderListUtil.orderAnother = orderAnother;
    orderListUtil.deleteOne = deleteOne;
    orderListUtil.tablesOverview = true;
    orderListUtil.keyDownHandler = keyDownHandler;
    orderListUtil.hideSelectNewOrders = hideSelectNewOrders;
    delete localStorage._editTable;

    orderListUtil.states = auth.myStatus.restaurant_settings["tables-overview"].states;
    orderListUtil.statesShownOriginal = orderListUtil.statesShown = auth.myStatus.restaurant_settings["tables-overview"].statesShown;
    orderListUtil.hidePaid = auth.myStatus.restaurant_settings["tables-overview"]["hide-paid-state"];

    auth.resize = resize;

    if (typeof auth.myStatus.restaurant_id == "undefined") {
      navigateToLogin();
      return;
    }


    // $('#restaurantStyle').text(auth.myStatus.restaurant_css);

    $("script#tablesOverviewMainTemplate")
      .tmpl({ ...orderListUtil.props, ...tmplparams() })
      .appendTo("div#main");

    $("#editTable #right_pane .keyboard").hide();
    $(".bar").replaceWith($("script#tablesOverviewBarTemplate").tmpl({ includableFrames: includableFrames, ...tmplparams() }));
    loadMyImage();

    tableLayout.constuctor();

    ajaxCallEnd("processing");

    ajaxCallStart("processing2", 0);
    try {
      ajaxCallStart("processing21");
      admin.getTables(function (tableLayouts) {
        try {
          tableLayout.tables = tableLayouts.restaurantTables;
          tableLayouts.restaurantTables.forEach(function (table) {
            tableLayout.tableMap.set(Number(table.number), table);
          });

          orderListUtil.pointOfSales = [];

          tableLayout.tables.forEach(table => {
            if (table.pos == true) orderListUtil.pointOfSales.push(table);
          });

          ajaxCallStart("processing3", 0);
          processRestaurant();

          def.resolve(tablesOverview, tableLayout);
        } finally {
          ajaxCallEnd("processing21");
        }
      });

      //const incomingcall = $("div#incomingcall");
      if (auth.incomingcall)
        $("#incomingcall").addClass("active");

      updateCheckBoxesById($("input#incomingcall"));

      // canvas = new fabric.Canvas('c');
      // canvas.selection = true; // disable group selection

      // initializeLayout();

      $("#editTable #right_pane input#search").keyup(function (e) {
        if (searchTimeout) {
          clearTimeout(searchTimeout);
        }
        searchTimeout = setTimeout(() => {
          searchTimeout = null;
          showMenuItems($(this).val());
        }, 100);
        e.preventDefault();
        e.stopPropagation();
      });
      resize(1);
    } catch (ex) {
      alert(ex);
    }

    $("#language.dropdown-menu").empty();
    auth.myStatus.restaurant_languages.forEach(function (lang) {
      $("#language.dropdown-menu").append('<button onclick="pickLanguage(&quot;' + lang + '&quot;)"  class="dropdown-item">' + lang + "</button>");
    });
    $("button#language").text(localStorage.language);
    admin.getPrintersStatus(updatePrintersStatus);

    function updateCycl() {
      if (orderListUtil.dragged || orderListUtil.drag) return;
      if (orderListUtil.allOrders.tables && orderListUtil.allOrders.tables.find(table => table.stateMap["ordered"].length + $("#block-delivering-tasks .delivering-tasks > *").length > 0)) {
        playNewAcceptedOrderSound();
      }
      if (localStorage._editTable === "true") {
        updateTableOccupationSelector();
        return;
      }

      if (typeof orderListUtil.ordersCache != "undefined" && typeof admin.tableMates != "undefined") {
        if (auth.myStatus.restaurant_id > 0) {
          var selected = $(".selectable.selected");
          orderListUtil.processAllOrdersDone = false;
          processAllOrders(orderListUtil.ordersCache);
          if (orderListUtil.tablesOverview) {
            const now = new Date().getTime();

            var decorationTime = tableLayout.updateTableOccupations();
            //console.log("updateTableOccupations", new Date().getTime() - now);
            !auth.minspeed && (auth.minspeed = 100000);
            !auth.maxspeed && (auth.maxspeed = 0);
            $('#updateLabel').html((auth.speed = new Date().getTime() - now) + " (" + decorationTime + ")");
            auth.minspeed = Math.min(auth.minspeed, auth.speed);
            auth.maxspeed = Math.max(auth.maxspeed, auth.speed);
          }
        }
      }
    }

    //eslint-disable-next-line no-restricted-globals
    updateCycle = setInterval(updateCycl, location.port == 3000 ? 5000 : 10000);


    const pickupNumber = getPickupTableNumber();
    const homeDeliveryNumber = getHomeDeliveryTableNumber();

    const freezelock = $("#freezelock");

    lockCycle = setInterval(function () {
      if (auth.myStatus.restaurant_settings["tables-overview"].timeout) {
        const countdown = Math.max(0, Math.round(auth.myStatus.restaurant_settings["tables-overview"].timeout - (new Date().getTime() - auth.lastEventTimestamp) / 1000));
        if (countdown < auth.myStatus.restaurant_settings["tables-overview"].timeout) {
          freezelock.html("<span>" + countdown + "s</span>");
        } else
          freezelock.html("");
        if (countdown < 5) {
          freezelock.css("background", "red");
          freezelock.css("color", "white");
        } else if (countdown < 10) {
          freezelock.css("background", "orange");
          freezelock.css("color", "");
        } else {
          freezelock.css("background", "");
          freezelock.css("color", "");
        }
      }
      if (freezelock.hasClass("icon-lock")) {
        freezelock.html("");
        freezelock.css("background", "");
        freezelock.css("color", "");
        return;
      }
      if (window.mobileAndTabletcheck()) return;
      if (localStorage.selectNewOrders == "true") return;
      if ($(".modal.markTableMateForPayment.show").length > 0) return;
      if ($("#tableMatePaid.modal.show").length > 0) return;
      if ($(".modal.splitInvoice.show").length > 0) return;
      if (localStorage.tableNumberSelected == pickupNumber || localStorage.tableNumberSelected == homeDeliveryNumber) return;
      if (auth.myStatus && auth.myStatus.restaurant_settings && auth.myStatus.restaurant_settings["tables-overview"].timeout > 0 && new Date().getTime() - auth.lastEventTimestamp > auth.myStatus.restaurant_settings["tables-overview"].timeout * 1000) {
        lock();
        auth.lastEventTimestamp = undefined;
      }
    }, 1000);

  } finally {
    ajaxCallEnd("processing2");
  }

  return def;
}

function recoverFromEventSourceError(recovery = true) {
  ajaxCallStart("processing3", 0);
  processRestaurant(recovery, false);
}

export const changeLayout = id => {
  admin.updateFloorPlan(id, function () {
    auth.myStatus.restaurant_active_floorplan = id;
    if (orderListUtil.tablesOverview) {
      tableLayout.reset();
      tableLayout.initialize();
      $(".floorplan-picker").removeClass("checked");
      $("#" + id + ".floorplan-picker").addClass("checked");
    }
  });
};

var canvas = null;
var activeObject = null;
var editPoints = null;
var editMode = false;

var tableSelectorMap = {}
var tableSelectorHash = "";
var tableSelectorHashTableNumber = ""

function updateTableOccupationSelector(first) {
  if (orderListUtil.noSelector === true)
    return;

  var selector = $("#tableOccupation-selector");
  var selectorulold = $("#tableOccupation-selector > ul");

  if (localStorage.tableNumberSelected != tableSelectorHashTableNumber) {
    tableSelectorHash = "";
    selectorulold.empty();
  }
  tableSelectorHashTableNumber = localStorage.tableNumberSelected;

  //tableSelectorMap = {};

  const now = new Date().getTime();

  const checked = $("#tableOccupation-selector > ul > li input.custom-control-input:checked");

  $("body > *[role='tooltip']").remove();

  var selectorul = $("<ul class='nav nav-tabs.nav-tabs--vertical nav-tabs--left' role='navigation'/>");

  const pickupTableNumber = getPickupTableNumber();
  const homeDeliveryTableNumber = getHomeDeliveryTableNumber();

  if (orderListUtil.occupationsSelected.length > 1 || localStorage.tableNumberSelected == pickupTableNumber || localStorage.tableNumberSelected == homeDeliveryTableNumber) {
    $(selector).show();

    orderListUtil.occupationsSelected.sort(function (a, b) {
      const aNumber = a.restaurantTables[0].restaurantTable.number;
      const bNumber = b.restaurantTables[0].restaurantTable.number;
      const aIsScheduled = aNumber == pickupTableNumber || aNumber == homeDeliveryTableNumber;
      const bIsScheduled = bNumber == pickupTableNumber || bNumber == homeDeliveryTableNumber;
      var atime = a.occupationStart != null && !aIsScheduled ? a.occupationStart : a.bookedOccupationStart;
      var btime = b.occupationStart != null && !bIsScheduled ? b.occupationStart : b.bookedOccupationStart;
      return atime - btime;
    });

    var lastTime = null;

    function sortit(a, b) {
      if (moment(a.date).startOf("day").valueOf() != moment(b.date).startOf("day").valueOf())
        return a.date - b.date;
      if (a.deliveredById && !b.deliveredById)
        return -1;
      else if (!a.deliveredById && b.deliveredById)
        return 1;

      if (a.deliveredById != b.deliveredById) {
        return a.deliveredById - b.deliveredById;
      }

      if (a.deliveredById != null && b.deliveredById != null) {
        if (a.deliveredByIndex != b.deliveredByIndex) {
          return a.deliveredByIndex - b.deliveredByIndex;
        }
      }
      if (a.date != b.date)
        return a.date - b.date;

      return a.tableOccupation - b.tableOccupation;
    }

    var deliveredById = undefined;

    const occupationsShown = [];

    const occupations = orderListUtil.allOrders.tables.filter(t => orderListUtil.occupationsSelected.filter(o => o.id === t.tableOccupation).length);

    orderListUtil.occupationsSelected.filter(o => !occupations.find(oo => oo.tableOccupation === o.id)).forEach(o => {
      const date = o.bookedProperties && o.bookedOccupationStart ? o.bookedOccupationStart : o.occupationStart;
      if (!occupations.find(oo => oo.date == date && oo.tableOccupation == o.tableOccupation))
        occupations.push({
          tableOccupation: o.id,
          date: date,
          price: 0,
          orderIds: [],
          stateMap: {
            accepted: [],
            producing: [],
            delivering: [],
            delivered: [],
            ordered: [],
            cancelled: []
          }
        });
    });

    const occupations2 = {};

    occupations.sort(sortit).forEach(o => {
      //o = getTableOccupation(o.tableOccupation);
      if (occupationsShown.indexOf(o.tableOccupation + "-" + moment(o.date).startOf("day").valueOf()) !== -1) {
        const oo = occupations2[o.tableOccupation + "-" + moment(o.date).startOf("day").valueOf()];
        o.orderIds.forEach(p => oo.orderIds.push(p));
        Object.keys(o.stateMap).forEach(key => {
          oo.stateMap[key] = [...oo.stateMap[key], ...o.stateMap[key]];
        });
        oo.printFailed += o.printFailed;
        oo.beingprinted += o.beingprinted;
        oo.partprinted += o.partprinted;
        oo.printed += o.printed;
        oo.unprinted += o.unprinted;
        return;
      }
      occupationsShown.push(o.tableOccupation + "-" + moment(o.date).startOf("day").valueOf());
      const oo = { ...o };
      if (oo.orderIds)
        oo.orderIds = [...oo.orderIds];
      if (oo.stateMap)
        oo.stateMap = JSON.parse(JSON.stringify(oo.stateMap));
      occupations2[o.tableOccupation + "-" + moment(o.date).startOf("day").valueOf()] = oo;
    });

    const roundFunction = auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"];

    const v = Object.values(occupations2).sort(sortit).map(o => {
      const occupation = getTableOccupation(o.tableOccupation);
      if (!occupation || occupation.occupationStart == null && (now - occupation.bookedOccupationStart) / 60000 > auth.myStatus.restaurant_settings["enabled-features"].prebooking.maxLateDuration) {
        return null;
      }
      return occupation.id + "-" + o.date + "-" + occupation.modificationTimeStamp;
    }).filter(o => o).join(",");

    if (tableSelectorHash !== v) {
      tableSelectorHash = v;

      Object.values(occupations2).sort(sortit).forEach(o => {

        const occupation = getTableOccupation(o.tableOccupation);
        if (!occupation)
          return;
        var now = new Date().getTime();

        if (occupation.occupationStart == null && (now - occupation.bookedOccupationStart) / 60000 > auth.myStatus.restaurant_settings["enabled-features"].prebooking.maxLateDuration) {
          return;
        }

        occupation.restaurantTables.forEach(function (restaurantTableOccupation) {
          var number = restaurantTableOccupation.restaurantTable.number;

          const aIsScheduled = number === pickupTableNumber || number === homeDeliveryTableNumber;
          var start = o.date; //.occupation.occupationStart != null && !aIsScheduled ? occupation.occupationStart : occupation.bookedOccupationStart;
          var end = o.date; //occupation.occupationEnd != null && !aIsScheduled ? occupation.occupationEnd : occupation.bookedOccupationEnd ? occupation.bookedOccupationEnd : occupation.bookedOccupationStart;
          //var occupationStart = occupation.occupationStart != null && !aIsScheduled ? occupation.occupationStart : occupation.bookedOccupationStart;
          //var occupationEnd = occupation.occupationEnd != null && !aIsScheduled ? occupation.occupationEnd : occupation.bookedOccupationEnd ? occupation.bookedOccupationEnd : occupation.bookedOccupationStart;

          if (o.tableOccupation == 13677) {
            console.log(o.tableOccupation, moment(o.date).format("MM/DD"));
          }

          const isPickup = number == pickupTableNumber;
          const isHomeDelivery = number == homeDeliveryTableNumber;
          if (isPickup || isHomeDelivery) {
            if (occupation.occupationStart == null && !occupation.bookedOccupationStart)
              return;
            /*
          if (moment(o.date).startOf("day").valueOf() === moment(occupationStart).startOf("day").valueOf()) {
            start = occupationStart;
            end = occupationEnd;
          }
          */
          } else {
            /*
            start = occupationStart;
            end = occupationEnd;
            */
          }

          if (number == localStorage.tableNumberSelected) {
            if (start) {

              if (lastTime === null || lastTime !== moment(start).format("YYYY-MMM-DD")) {


                const prevInstance = tableSelectorMap["header-" + moment(o.date).startOf("date").valueOf()];
                if (prevInstance) {
                  $(selectorul).append(prevInstance.d1.clone());
                  $(selectorul).append(prevInstance.d2.clone());

                } else {
                  const startOfDay = moment(o.date).startOf("day").valueOf();
                  var $li1 = makeElement("li", { class: "nav-item separator ", date: startOfDay });
                  $($li1).html($("<a id='header" + startOfDay + "'><div class='date' style=''>" + moment(startOfDay).format("YYYY-MMM-DD dddd") + "</div></a>"));

                  var $li = makeElement("li", { class: "nav-item group-functions new-orders accepted producing delivering delivered " + (isPickup ? "pickup" : isHomeDelivery ? "homedelivery" : "") });
                  var groupPart = "";
                  groupPart += "<a class='nav-link'>";
                  groupPart += '<div onClick="event.stopPropagation()" class="custom-control custom-checkbox" style="display: flex;justify-content: center;padding-left:2.5rem !important">' +
                    '<input type="checkbox" class="custom-control-input" id="s' + startOfDay + '" date="' + startOfDay + '"/>' +
                    '<label class="custom-control-label" for="s' + startOfDay + '"></label>' +
                    '</div>'

                  groupPart += "<div state='ordered'  class='t-state new-orders ' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.header_new_incoming_order") + "'/>";
                  groupPart += "<div state='accepted'  class='t-state accepted ' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.header_accepted_order") + "'/>";
                  groupPart += "<div state='producing'  class='t-state producing ' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.header_order_in_production") + "'/>";
                  groupPart += "<div state='delivering'  class='t-state delivering ' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.header_order_under_delivering") + "'/>";
                  groupPart += "<div state='delivered'  class='t-state delivered ' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.header_order_delivered") + "'/>";
                  groupPart += "<div class='seqnr'/>";
                  //if (isHomeDelivery)
                  //  groupPart += "<div class='icon-qrcode' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.qrcode") + "'/>";
                  groupPart += "<div/>"
                  groupPart += "<div class='deliveryUsers' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.couriers") + "'>" + I18n.t("admin_local.couriers") + ":</div>";
                  groupPart +=
                    '<div id="chooseOption" class="deliveryUsers"><ul class="list-group radio-list-box">' +
                    (tablesOverview.context ?
                      tablesOverview.context.props.deliveryUsers.map(user => {
                        return '<input class="courier" type="radio" id="check' +
                          o.date + "-" + user.id +
                          '" name="coption' +
                          o.date +
                          '">' +
                          '<label style="background-color:' + user.backgroundColor + ';color:' + user.color + '" courier="' + user.id + '" class="courier list-group-item" for="check' +
                          o.date + "-" + user.id +
                          '" data-toggle="tooltip" title="' + I18n.t("admin_local.tooltip.link_courier") + '">' + user.name + '</label>'
                      }).join("") : "") +
                    "</ul></div>";
                  groupPart += "<div class='pr print' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.header_print_receipt") + "'/>";
                  groupPart += "<div class='invoice' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.header_print_invoice") + "'/>";
                  groupPart += "<div class='dollar'/>";
                  groupPart += "<div class='unlock' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.header_unlock") + "'/>";
                  groupPart += "</a>";
                  groupPart += "<div style='width: 1.62rem;'/>";

                  $li = $($li);

                  $li.data("date", startOfDay);

                  $li.html($(groupPart));

                  $li.find("input#" + o.date + ".custom-control-input").on("change", event => {
                    $("li[date='" + event.currentTarget.id + "'] input.custom-control-input").prop('checked', event.currentTarget.checked);
                  })

                  $li.attr("id", startOfDay);

                  Object.keys(tableSelectorMap).forEach(key => {
                    if (key.split("-")[0] === "header") {
                      var d = key.split("-")[1];
                      if (moment(Number(d)).format("MM-DD") === "01-26" || moment(Number(d)).format("MM-DD") === "01-27")
                        console.log(">" + moment(Number(d)).format("MM-DD") + " " + $(tableSelectorMap[key].d1).find("a").html());
                    }
                  });

                  tableSelectorMap["header-" + moment(o.date).startOf("date").valueOf()] = { d1: $($li1).clone(), d2: $($li).clone() };
                  $(selectorul).append($li1);
                  $(selectorul).append($li);

                }

              }


              lastTime = moment(start).format("YYYY-MMM-DD");

              const prevInstance = tableSelectorMap[o.date + "-" + o.tableOccupation];
              if (prevInstance && prevInstance.t === occupation.modificationTimeStamp) {
                $(selectorul).append(prevInstance.d);
                if (tablesOverview.selectedDate !== o.date)
                  $(prevInstance.d).addClass("hidden");
                prevInstance.d.data("data", o);
                return;
              }


              var $a = makeElement("a", {
                //href: '#maintab',
                style: "text-align:center;font-weight:bold;transform: unset;top:0%",
                class: "nav-link ",
                id: occupation.id,
                onclick: "selectTableOccupation(" + occupation.id + ")",
                //'data-toggle': 'tab',
                role: "tab",
                "aria-controls": "maintab"
              });
              var text = '<div onClick="event.stopPropagation()" class="custom-control custom-checkbox" style="display: flex;justify-content: center;padding-left:2.5rem !important">' +
                '<input type="checkbox" class="custom-control-input" deliveryId="' + o.deliveredById + '" id="' + o.tableOccupation + "-" + o.date + '" />' +
                '<label class="custom-control-label" for="' + o.tableOccupation + "-" + o.date + '"></label>' +
                '</div>';

              var stateMap = o.stateMap;


              var cancelled = false;
              var clazz = (isPickup ? " pickup" : isHomeDelivery ? " homedelivery" : "");
              var clazz2 = "";
              clazz2 += stateMap && stateMap['accepted'].length > 0 ? " accepted " : "";
              clazz2 += stateMap && stateMap['producing'].length > 0 ? " producing " : "";
              clazz2 += stateMap && stateMap['delivering'].length > 0 ? " delivering " : "";
              clazz2 += stateMap && stateMap['delivered'].length > 0 ? " delivered " : "";
              clazz2 += stateMap && stateMap['ordered'].length ? " new-orders " : "";
              if (!clazz2) {
                if (stateMap && stateMap['cancelled'].length) {
                  clazz2 += " cancelled";
                  cancelled = true;
                }
              }

              clazz += clazz2;

              if (!cancelled) {
                text += "<div state='ordered' class='t-state new-orders' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.set_new_incoming_order") + "'/>";
                text += "<div state='accepted' class='t-state accepted' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.set_accepted_order") + "'/>";
                text += "<div state='producing' class='t-state producing' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.set_order_in_production") + "'/>";
                text += "<div state='delivering' class='t-state delivering' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.set_order_under_delivering") + "'/>";
                text += "<div state='delivered' class='t-state delivered' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.set_order_delivered") + "'/>";
              } else {
                text += "<div state='ordered' class='t-state new-orders'/>";
                text += "<div state='accepted' class='t-state accepted'/>";
                text += "<div state='producing' class='t-state producing'/>";
                text += "<div state='delivering' class='t-state delivering'/>";
                text += "<div state='delivered' class='t-state delivered'/>";
              }

              var cdate = undefined;

              const date = moment(o.date).startOf("day").valueOf();
              const minmax = orderListUtil.allOrders.tables.filter(t => t.tableOccupation === o.tableOccupation).map(t => t.date.valueOf()).reduce((a, b) => { return { min: a.min == null ? b : Math.min(a.min, b), max: a.max == null ? b : Math.max(a.max, b) } }, { min: null, max: null });
              if (occupation.bookedProperties && occupation.bookedProperties.installments_strategy)
                switch (occupation.bookedProperties.installments_strategy) {
                  case "start":
                    if (date === minmax.min) {
                      cdate = undefined;
                    } else {
                      cdate = date;
                    }
                    break;
                  case "by_each":
                    cdate = o.date;
                    break;
                  case "end":
                    if (date === minmax.max) {
                      cdate = undefined;
                    } else {
                      cdate = date;
                    }
                    break;
                }




              const image = orderListUtil.occupationsSelected.length > 5 && occupation.leadCustomerId !== 0 && getTableMate(occupation.leadCustomerId).tableMate && getTableMate(occupation.leadCustomerId).tableMate.hasImage ? "<img src='" + getImageById(getTableMate(occupation.leadCustomerId).tableMate.id) + "'/>" : "";
              if (restaurantTableOccupation.tableMateIds.length)
                clazz += " " + getTableMate(restaurantTableOccupation.tableMateIds[0]) ? getTableMate(restaurantTableOccupation.tableMateIds[0]) && getTableMate(restaurantTableOccupation.tableMateIds[0]).states.reduce((a, b) => a + " " + b.state, "") + " " : "";

              if (occupation && occupation.bookedProperties && occupation.bookedProperties["thirdparty-order-number"])
                text += "<span style='display:flex;align-items:center' class='thirdparty " + occupation.bookedProperties["thirdparty-order-number"] + "'>" + occupation.bookedProperties["thirdparty-order-number"].split(" ")[1] + "</span>";
              else if (occupation.seqnr)
                text += "<span class='seqnr' style='display:flex;flex-flow:column;justify-content:space-between'><span>#" + occupation.seqnr + "</span><span style='font-size:60%'>" + moment(occupation.occupationStart).format("HH:mm") + "</span></span>";
              else
                text += "<span class='icon-clock'>" + "</span>";


              text += image + "<span data-placement='top' data-toggle='tooltip' title='" + (isHomeDelivery ? I18n.t("admin_local.tooltip.delivery_time") : I18n.t("admin_local.tooltip.pickup_time")) + "'>";
              if (start === moment().startOf("day").valueOf())
                text += "<div class='date' style='font-size:100%'>" + admin_local.today + "</div>";
              else if (start === moment().add(-1, 'day').startOf("day").valueOf())
                text += "<div class='date' style='font-size:100%'>" + admin_local.yesterday + "</div>";
              else if (start === moment().add(1, 'day').startOf("day").valueOf())
                text += "<div class='date' style='font-size:100%'>" + admin_local.tomorrow + "</div>";
              else if (moment(start).format("YYYY-MMM-DD") === moment().format("MMM-DD"))
                text += "<div class='date' style='font-size:100%'>" + admin_local.today + " " + moment(start).format("HH:mm") + (end ? "-" + moment(end).format("HH:mm") : "") + "</div>";
              else if (moment(start).format("YYYY-MMM-DD") === moment().add(1, 'day').format("MMM-DD"))
                text += "<div class='date' style='font-size:100%'>" + admin_local.tomorrow + " " + moment(start).format("HH:mm") + (end ? "-" + moment(end).format("HH:mm") : "") + "</div>";
              else if (moment(start).format("YYYY-MMM-DD") === moment().add(-1, 'day').format("MMM-DD"))
                text += "<div class='date' style='font-size:100%'>" + admin_local.yesterday + " " + moment(start).format("HH:mm") + (end ? "-" + moment(end).format("HH:mm") : "") + "</div>";
              else if (moment(start).isAfter(moment().add(1, 'day').startOf("day")))
                text += "<div class='date' style='font-size:100%'>" + moment(start).format("MMM-DD") + "</div>";
              else {
                text += "<div class='date' style='font-size:100%'>" + moment(start).format("HH:mm") + "</div>";
              }
              text += "</span>"
            }

            var amount = getAmountToPay(restaurantTableOccupation.tableMateIds[0], cdate);
            var totalamount = getAmountToPay(restaurantTableOccupation.tableMateIds[0]);
            const roundedAmount = eval(roundFunction);

            amount = getAmountPaid(restaurantTableOccupation.tableMateIds[0], cdate);
            const roundedPaidAmount = eval(roundFunction);

            var deliveryStart = false;
            if (o.deliveredById) {
              if (deliveredById !== o.deliveredById) {
                clazz += " deliveryStart ";
                deliveryStart = true;
                deliveredById = o.deliveredById;
              }
              var courier = tablesOverview.context ? tablesOverview.context.props.deliveryUsers.find(u => u.id === o.courierId) : null;
              if (!courier)
                text += '<div class="courier">' + "<span class='icon-qrcode' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.qrcode") + "'/>" + '<span>' + o.deliveredByName + "</span><span class='icon-cancel' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.unlink_courier") + "'></div>";
              else
                text += '<div class="courier">' + "<span class='icon-qrcode' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.qrcode") + "'/>" + '<span style="background-color:' + courier.backgroundColor + ';color:' + courier.color + '">' + o.deliveredByName + "</span><span class='icon-cancel' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.unlink_courier") + "'/></div>";
            } else {
              if (occupation.bookedProperties && occupation.bookedProperties.expiryDate && o.stateMap && o.stateMap["ordered"].length > 0) {
                const diff = Math.max(0, moment(occupation.bookedProperties.expiryDate).diff(moment(), "seconds"));
                const difffromstart = moment(occupation.bookedProperties.expiryDate).diff(moment(occupation.bookedProperties.createdDate), "seconds");
                const timeup = 100 - (diff / difffromstart * 100);
                const bcolor = timeup < 50 ? "lightgreen" : timeup < 75 ? "orange" : "red";
                const color = timeup < 50 ? "gray" : timeup < 75 ? "gray" : "white";
                text += '<div class="courier" style="color:' + color + ';background:linear-gradient(90deg, ' + bcolor + ' 0%, ' + bcolor + ' ' + timeup + '%, transparent ' + timeup + '%, transparent)" >' + moment((diff * 1000)).format("mm:ss") + '</div>';
              } else
                text += '<div class="courier"/>';
              if (deliveredById) {
                clazz += " deliveryStart ";
              }
              deliveredById = undefined;
            }

            if (occupation.bookedProperties) {
              text += "<span style='display: flex;flex-wrap: wrap;justify-content: center;'>";
              text += occupation.bookedProperties.surname ? occupation.bookedProperties.surname + " " : "";
              text += (occupation.bookedProperties.name ? occupation.bookedProperties.name : occupation.bookedProperties.email ? occupation.bookedProperties.name : "");
              const userCommentText = "<text data-toggle='tooltip' data-placement='top' title='" + I18n.t("admin_local.tooltip.user_comment") + "' class='" + (occupation?.bookedProperties?.user_comment ? 'pulse-infinite comment' : 'icon-comment') + "' onclick='event.stopPropagation(); getInputDialog(\"" + I18n.t("admin_local.comment") + "\", \"" + I18n.t("admin_local.tooltip.user_comment") + "\", $(this).html(), \"text\").done((i,v) => updateBookingUserComment(" + occupation.id + ",v))' >" + (occupation?.bookedProperties?.user_comment ? occupation?.bookedProperties?.user_comment.replace(/\n/g, "<br/>") : '') + "</text > ";
              text += userCommentText;
              if (isHomeDelivery) {
                text += "</span><span style='display: flex;flex-wrap: wrap;justify-content: center;'><a target='new' onclick='event.stopPropagation()' href='https://www.google.com/maps/dir//" + (occupation.bookedProperties.postcode ? occupation.bookedProperties.postcode + "&nbsp;" : "") + (occupation.bookedProperties.town && occupation.bookedProperties.town !== auth.myStatus.restaurant_town ? occupation.bookedProperties.town + "&nbsp;" : "") + (occupation.bookedProperties.address ? occupation.bookedProperties.address : "") + "' class='address'>" + (occupation.bookedProperties.postcode ? occupation.bookedProperties.postcode + "&nbsp;" : "") + (occupation.bookedProperties.town && occupation.bookedProperties.town !== auth.myStatus.restaurant_town ? occupation.bookedProperties.town + "&nbsp;" : "") + (occupation.bookedProperties.address ? occupation.bookedProperties.address : "");
                text += "</a>";
              }
              const commentText = "<text data-toggle='tooltip' data-placement='top' title='" + I18n.t("admin_local.tooltip.comment") + "' class='" + (occupation?.bookedProperties?.comment ? 'pulse-infinite comment' : 'icon-comment') + "' onclick='event.stopPropagation(); getInputDialog(\"" + I18n.t("admin_local.comment") + "\", \"" + I18n.t("admin_local.tooltip.comment") + "\", \"" + (occupation?.bookedProperties?.comment || '') + "\", \"text\").done((i,v) => updateBookingComment(" + occupation.id + ",v))' >&nbsp;" + (occupation?.bookedProperties?.comment || '') + "</text > ";
              if (isHomeDelivery)
                text += commentText;
              if (isPickup || isHomeDelivery)
                text += "</span><span class='phone' style='display: flex;flex-wrap: wrap;justify-content: center;'><a onClick='event.stopPropagation();device.platform===\"iOS\"&&cordova.plugins.phonedialer.call(\"" + occupation.bookedProperties.phone + "\", () => {},() => {}, \"\");' class='phone' target='_system' href='tel://" + (occupation.bookedProperties.phone ? occupation.bookedProperties.phone : "") + "'>" + (occupation.bookedProperties.phone ? occupation.bookedProperties.phone : "") + "</a>";
              if (isPickup)
                text += commentText;

            } else if (occupation.tableMates[0].name) {
              text += "<span class='name'>";
              text += occupation.tableMates[0].name;
              text += "</span>";
              text += "<span  class='address'/>";
              text += "<span  class='phone'/>";
            } else {
              text += "<span class='address'/>";
              text += "<span class='phone'/>";
              text += "<span/>";
            }
            text += "</span>";


            if (roundedAmount !== 0 || o.price > roundedAmount) {
              if (roundedAmount)
                text += "<div class='amount'>" + roundedAmount + " " + auth.myStatus.restaurant_base_currency.name + "</div>";
              else
                if (roundedPaidAmount)
                  text += "<div class='amount' style='text-decoration:line-through; background-color: lightgreen'>" + roundedPaidAmount + " " + auth.myStatus.restaurant_base_currency.name + "</div>";
                else if (totalamount === roundedPaidAmount)
                  text += "<div class='amount' style='text-decoration:line-through; background-color: lightgreen'></div>";
                else
                  text += "<div class='amount' style=''></div>";
              if (occupation.bookedProperties && occupation.bookedProperties.payment_method && getPaymentMethod(occupation.bookedProperties.payment_method)) {
                text += "<div class='payment_method'>" + getPaymentMethod(occupation.bookedProperties.payment_method).method.name + "</div>";
              } else {
                text += "<span class='payment_method'>" + "</span>";
              }
            } else if (roundedAmount > 0) {
              text += "<span>" + I18n.t("local.paid") + "" + "</span><span/>";
              clazz += " paid ";
            } else {
              text += "<span class='amount'/>";
              text += "<span class='payment_method'/>";
            }
            if (occupation.bookedProperties && occupation.bookedProperties.installments_strategy)
              text += "<div class='installments_strategy'>" + admin_local.allowed_installments[occupation.bookedProperties.installments_strategy] + "</div>";
            else
              text += '<div class="installments_strategy"/>';

            if (o.beingprinted) {
              text += "<div class='pr printing cogwheel' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.printing") + "'/>";
              clazz += " beingprinted";
            } else if (o.printFailed) {
              text += "<div class='pr printfailed' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.printfailed") + "'/>";
              clazz += " printfailed";
            } else if (o.unprinted) {
              text += "<div class='pr unprinted' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.unprinted") + "'/>";
            } else if (o.printed) {
              text += "<div class='pr printed' data-placement='top' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.printed") + "'/>";
            } else {
              text += "<div class='pr print-none'/>";
            }


            var allDelivered = false;
            try {
              allDelivered = !stateMap ? false : ((stateMap['accepted'] ? stateMap['accepted'].length : 0) + (stateMap['producing'] ? stateMap['producing'].length : 0) + (stateMap['delivering'] ? stateMap['delivering'].length : 0) + (stateMap['ordered'] ? stateMap['ordered'].length : 0) == 0);

              if (allDelivered) {
                allDelivered = !occupations.filter(oo => oo.tableOccupation === o.tableOccupation).map(o => o.stateMap).filter(stateMap => {
                  return !stateMap ? true : !((stateMap['accepted'] ? stateMap['accepted'].length : 0) + (stateMap['producing'] ? stateMap['producing'].length : 0) + (stateMap['delivering'] ? stateMap['delivering'].length : 0) + (stateMap['ordered'] ? stateMap['ordered'].length : 0) == 0)
                }).length;
              }

            } catch (ex) {
              console.error(ex);
            }

            function unlockit() {
              if (allDelivered || (roundedAmount == 0 && !isHomeDelivery && !isPickup)) {
                text += "<div class='unlock' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.unlock") + "'/>";;
                clazz += " unlock ";
              } else if (!o.orderIds) {
                text += "<div class='unlock' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.unlock") + "'/>";;
                clazz += " unlock ";
              } else
                text += "<div class='col'/>";
            }

            if (occupation.bookedProperties && occupation.bookedProperties.installments_strategy) {
              const date = moment(o.date).startOf("day").valueOf();
              const minmax = orderListUtil.allOrders.tables.filter(t => t.tableOccupation === o.tableOccupation).map(t => moment(t.date.valueOf()).startOf('day').valueOf()).reduce((a, b) => { return { min: a.min == null ? b : Math.min(a.min, b), max: a.max == null ? b : Math.max(a.max, b) } }, { min: null, max: null });


              switch (occupation.bookedProperties.installments_strategy) {
                case "start":
                  if (date === minmax.min) {
                    text += "<div class='invoice " + (getStateIndex(occupation.tableMates[0], "wantsToPay") >= 0 ? "wantsToPay" : "") + " " + (!roundedAmount ? "paid" : "") + "' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.print_invoice") + "'/>";
                    if (roundedAmount !== 0) {
                      text += "<div class='dollar' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.register_payment") + "'/>";
                      text += "<div class='col'/>";
                    } else {
                      if (roundedAmount === totalamount) {
                        text += "<div class='dollar-paid' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.paid") + "'/>";
                        unlockit();
                      } else {
                        if (date === minmax.max)
                          text += "<div class='dollar' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.register_payment") + "'/>";
                        else
                          text += "<div class='icon-attention'/>";
                        text += "<div class='col'/>";
                      }
                    }
                  } else {
                    text += "<div class='icon-up-open'/>";
                    if (roundedAmount !== 0) {
                      text += "<div class='icon-up-open'/>";
                      text += "<div class='col'/>";
                    } else {
                      if (roundedAmount === totalamount) {
                        text += "<div class='dollar-paid' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.paid") + "'/>";
                        unlockit();
                      } else {
                        text += "<div class='icon-attention'/>";
                        text += "<div class='col'/>";
                      }
                    }
                  }
                  break;
                case "by_each":
                  text += "<div class='invoice " + (getStateIndex(occupation.tableMates[0], "wantsToPay") >= 0 ? "wantsToPay" : "") + " " + (!roundedAmount ? "paid" : "") + "' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.print_invoice") + "'/>";
                  if (roundedAmount !== 0) {
                    text += "<div class='dollar' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.register_payment") + "'/>";
                    text += "<div class='col'/>";
                  } else {
                    text += "<div class='dollar-paid' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.paid") + "'/>";
                    unlockit();
                  }
                  break;
                case "end":
                  if (date === minmax.max) {
                    text += "<div class='invoice " + (getStateIndex(occupation.tableMates[0], "wantsToPay") >= 0 ? "wantsToPay" : "") + " " + (!roundedAmount ? "paid" : "") + "' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.print_invoice") + "'/>";
                    if (roundedAmount !== 0) {
                      text += "<div class='dollar' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.register_payment") + "'/>";
                      text += "<div class='col'/>";
                    } else {
                      text += "<div class='dollar-paid' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.paid") + "'/>";
                      unlockit();
                    }
                  } else {
                    text += "<div class='icon-down-open'/>";
                    if (roundedAmount !== 0) {
                      text += "<div class='icon-down-open'/>";
                      text += "<div class='col'/>";
                    } else {
                      text += "<div class='dollar-paid' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.paid") + "'/>";
                      unlockit();
                    }
                  }
                  break;
                default:
                  text += "<div class='invoice " + (getStateIndex(occupation.tableMates[0], "wantsToPay") >= 0 ? "wantsToPay" : "") + " " + (!roundedAmount ? "paid" : "") + "' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.print_invoice") + "'/>";
                  if (roundedAmount !== 0) {
                    text += "<div class='dollar' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.register_payment") + "'/>";
                    text += "<div class='col'/>";
                  } else {
                    if (roundedPaidAmount)
                      text += "<div class='dollar-paid' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.paid") + "'/>";
                    else
                      text += "<div class='col'/>";
                    unlockit();
                  }
              }

            } else {
              if ((roundedAmount || roundedPaidAmount) && clazz2.indexOf("cancelled") === -1)
                text += "<div class='invoice " + (getStateIndex(occupation.tableMates[0], "wantsToPay") >= 0 ? "wantsToPay" : "") + " " + (!roundedAmount ? "paid" : "") + "' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.print_invoice") + "'/>";
              else
                text += "<div class='col'/>";
              if (roundedAmount !== 0) {
                text += "<div class='dollar' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.register_payment") + "'/>";
                text += "<div class='col'/>";
              } else {
                if (roundedPaidAmount)
                  text += "<div class='dollar-paid' data-toggle='tooltip' title='" + I18n.t("admin_local.tooltip.paid") + "'/>";
                else
                  text += "<div class='col'/>";
                unlockit();
              }
            }


            const $newOrders = makeElement("div", {
              onclick: 'event.stopPropagation();selectTableOccupation(' + occupation.id + ');selectNewOrders()',
              class: 'newOrders icon-list-add '
            });


            var $li = makeElement("li", { class: "nav-item " + clazz, refreshtimestamp: occupation.modificationTimeStamp });

            $li.append($a);
            $li.append($newOrders);
            $($a).html(text);
            $li = $($li);
            $li.data("data", o);
            $li.attr("date", moment(o.date).startOf("day").valueOf());
            $li.attr("tableOccupation", o.tableOccupation);
            $li.attr("deliveredById", deliveredById);
            $li.attr("deliveredByIndex", o.deliveredByIndex);
            $li.attr("id", o.date + "-" + o.tableOccupation);

            tableSelectorMap[o.date + "-" + o.tableOccupation] = { t: occupation.modificationTimeStamp, d: $($li).clone() };

            if (tablesOverview.selectedDate !== o.date)
              $li.addClass("hidden");
            $(selectorul).append($li);

          }
        });
      });

      //selectorulold.empty();
      replaceContent(selectorulold, selectorul);

      selectorul = $("#tableOccupation-selector > ul");

      selectorul.find("li input").off("change");
      selectorul.find("li div").off("click");
      selectorul.find("li div").off("dblclick");
      selectorul.find("li.group-functions label.courier").off("dblclick");
      selectorul.find("li.group-functions div.unlock").off("dblclick");
      selectorul.find("li:not(.group-functions) .icon-cancel").off("dblclick");
      selectorul.find("li:not(.group-functions) span.icon-qrcode").off("dbclick");

      selectorul.find("li:not(.group-functions) input.custom-control-input").off("change");
      selectorul.find("li.group-functions input.custom-control-input").on("change", event => {
        if ($(event.currentTarget).attr("date") > 0) {
          $("li:not(.group-functions)[date = '" + $(event.currentTarget).attr("date") + "'] input.custom-control-input").prop('checked', event.currentTarget.checked);
        }
      })
      selectorul.find("li:not(.group-functions) input.custom-control-input").on("change", event => {
        if (event.currentTarget.checked) {
          if ($(event.currentTarget).attr("deliveryId") > 0) {
            $("li:not(.group-functions) input.custom-control-input[deliveryId = '" + $(event.currentTarget).attr("deliveryId") + "']").prop('checked', true);
          }
        }
      })
      selectorul.find("li:not(.group-functions) div.t-state").off("click");
      selectorul.find("li:not(.group-functions) div.t-state").on("click", event => {
        event.stopPropagation();
      });

      selectorul.find("li:not(.group-functions):not(.cancelled) div.t-state").off("dblclick");
      selectorul.find("li:not(.group-functions):not(.cancelled) div.t-state").on("dblclick", event => {
        event.stopPropagation();
        const occupation = $(event.currentTarget).parents("li").data("data");
        selectTableOccupationSelected(occupation.tableOccupation);
        occupation.orderIds && admin.processchangeStateByOrderIds(occupation.tableOccupation, occupation.orderIds.filter(o => getOrderById(o).state != "cancelled"), $(event.currentTarget).attr("state"), data => {
          update(data);
          if ($(event.currentTarget).attr("state") === "accepted") {
          }

        })
      })
      selectorul.find("li:not(.group-functions) div.unprinted").off("click");
      selectorul.find("li:not(.group-functions) div.unprinted").on("click", event => {
        event.stopPropagation();
      });
      selectorul.find("li:not(.group-functions) div.unprinted").off("dblclick");
      selectorul.find("li:not(.group-functions) div.unprinted").on("dblclick", event => {
        event.stopPropagation();
        const occupation = $(event.currentTarget).parents("li").data("data");
        selectTableOccupationSelected(occupation.tableOccupation);
        printNewOrders(occupation.orderIds, undefined, undefined, true);
      })
      selectorul.find("li:not(.group-functions) div.printed,li:not(.group-functions) div.printfailed").off("click");
      selectorul.find("li:not(.group-functions) div.printed,li:not(.group-functions) div.printfailed").on("click", event => {
        event.stopPropagation();
      });
      selectorul.find("li:not(.group-functions) div.printed,li:not(.group-functions) div.printfailed").off("dblclick");
      selectorul.find("li:not(.group-functions) div.printed,li:not(.group-functions) div.printfailed").on("dblclick", event => {
        event.stopPropagation();
        const occupation = $(event.currentTarget).parents("li").data("data");
        selectTableOccupationSelected(occupation.tableOccupation);
        const ids = occupation.orderIds.map(id => getOrderById(id)).filter(o => o.state !== "cancelled" && o.state !== "selected" && o.state !== "ordered").map(o => o.id);
        printNewOrders(ids, undefined, true, true);
      })
      selectorul.find("li:not(.group-functions) div.invoice").off("click");
      selectorul.find("li:not(.group-functions) div.invoice").on("click", event => {
        event.stopPropagation();
      });
      selectorul.find("li:not(.group-functions) div.invoice").off("dblclick");
      selectorul.find("li:not(.group-functions) div.invoice").on("dblclick", event => {
        if (isDuplicateClickEvent(event)) return; avoidMultipleClick(this, event);
        event.stopPropagation();
        if ($(event.currentTarget).data("block"))
          return;
        $(event.currentTarget).data("block", true);
        setTimeout(() => { $(event.currentTarget).data("block", false) }, 10000);
        const o = $(event.currentTarget).parents("li").data("data");
        const occupation = getTableOccupation(o.tableOccupation);
        var date = "";
        if (occupation.bookedProperties && occupation.bookedProperties.installments_strategy == "by_each")
          date = o.date;
        if (getAmountToPay(o.forWhomId) == 0) {
          confirmDialog(I18n.t("local.confirmation"), I18n.t("admin_local.already_paid_reprint_invoice")).done(() => {
            admin.reprintInvoice(occupation.tableMatePayments[0].id, localStorage.language, function (data) {
              if (data === '{"success":"true"}') {
              } else
                printHtml(data, admin.getPrinterForPrinterAction("printInvoice").id);
            });
          });
        } else
          admin.getInvoiceXML(o.tableOccupation, localStorage.tableNumberSelected, o.forWhomId, localStorage.language, 1, date, date, function (data) {
            if (data == '{"success":"true"}') {
              post("adminService/" + localStorage.restaurantSelected + "/markTableMateForPayment", occupation.tableMates.map(m => {
                m.states.push({ state: "wantsToPay", duration: 0 });
                return m;
              })).done(update);
            } else if (data.startsWith("<!DOCTYPE HTML")) {
              printHtml(data, admin.getPrinterForPrinterAction("printInvoice").id);
              post("adminService/" + localStorage.restaurantSelected + "/markTableMateForPayment", occupation.tableMates.map(m => {
                m.states.push({ state: "wantsToPay", duration: 0 });
                return m;
              })).done(update);
            }
          });
      })
      selectorul.find("li:not(.group-functions) div.dollar").off("click");
      selectorul.find("li:not(.group-functions) div.dollar").on("click", event => {
        event.stopPropagation();
      });
      selectorul.find("li:not(.group-functions) div.dollar").off("dblclick");
      selectorul.find("li:not(.group-functions) div.dollar").on("dblclick", event => {
        event.stopPropagation();
        const o = $(event.currentTarget).parents("li").data("data");
        const occupation = getTableOccupation(o.tableOccupation);
        var date = "";
        if (occupation.bookedProperties && occupation.bookedProperties.installments_strategy == "by_each")
          date = o.date;
        selectTableOccupationSelected(o.tableOccupation);
        tableMatePaid(o.forWhomId, date, true, data => {
          update(data);
        });
      })
      selectorul.find("li:not(.group-functions) div.unlock").off("click");
      selectorul.find("li:not(.group-functions) div.unlock").on("click", event => {
        event.stopPropagation();
      });
      selectorul.find("li:not(.group-functions) div.unlock").off("dblclick");
      selectorul.find("li:not(.group-functions) div.unlock").on("dblclick", event => {
        event.stopPropagation();
        const o = $(event.currentTarget).parents("li").data("data");
        unlockTable(o.tableOccupation, localStorage.tableNumberSelected, false, data => {
          update(data);
        })
      })

      selectorul.find("li:not(.group-functions) .courier").off("click");
      selectorul.find("li:not(.group-functions) .courier").on("click", event => {
        event.stopPropagation();
      });
      selectorul.find("li:not(.group-functions) .icon-cancel").off("dblclick");
      selectorul.find("li:not(.group-functions) .icon-cancel").on("dblclick", event => {
        event.stopPropagation();
        const o = $(event.currentTarget).parents("li").data("data");
        const occupation = getTableOccupation(o.tableOccupation);
        var date = "";
        if (occupation.bookedProperties && occupation.bookedProperties.installments_strategy == "by_each")
          date = o.date;
        get("adminService/" + auth.myStatus.restaurant_id + "/assignCourier/" + o.deliveredById + "/" + o.tableOccupation + "?orderIds=" + o.orderIds + "&courier=" + 0).done((data) => {
          update(data);
        })
      })

      selectorul.find("li.group-functions div.unlock").off("dblclick");
      selectorul.find("li.group-functions div.unlock").on("dblclick", event => {
        event.stopPropagation();
        const date = $(event.currentTarget).parents("li").attr("id");
        const occupations = [];
        $('li[date = "' + date + '"] input.custom-control-input:checked').each((ind, li) => {
          occupations.push($(li).parents('li').data("data"));
          li.checked = false;
        })
        if (occupations.length === 0)
          $('li:not(.group-functions):not(.separator)[date = "' + date + '"].unlock').each((ind, li) => {
            occupations.push($(li).data("data"));
          });

        function assign(occupations) {
          const occupation = occupations.shift();
          unlockTable(occupation.tableOccupation, localStorage.tableNumberSelected, false, data => {
            update(data);
            if (occupations.length)
              assign(occupations);
          })
        }
        if (occupations.length)
          assign(occupations);
      });

      selectorul.find("li.group-functions label.courier").off("dblclick");
      selectorul.find("li.group-functions label.courier").on("dblclick", event => {
        event.stopPropagation();
        const date = $(event.currentTarget).parents("li").attr("id");
        const courier = $(event.currentTarget).attr("courier");
        const occupations = [];
        $('li[date = "' + date + '"] input.custom-control-input:checked').each((ind, li) => {
          occupations.push($(li).parents('li').data("data"));
          li.checked = false;
        })
        var delivery = 0;
        function assign(occupations) {
          const occupation = occupations.shift();
          const orderIds = occupation.orderIds;
          if (orderIds) {
            get("adminService/" + auth.myStatus.restaurant_id + "/assignCourier/" + delivery + "/" + occupation.tableOccupation + "?orderIds=" + orderIds + "&courier=" + courier).done((data) => {
              update(data);
              delivery = data.orders[0].deliveredById;
              if (occupations.length)
                assign(occupations);
            })
          } else if (occupations.length)
            assign(occupations);
        }
        if (occupations.length)
          assign(occupations);
      });

      selectorul.find("li:not(.group-functions) span.icon-qrcode").off("dblclick");
      selectorul.find("li:not(.group-functions) span.icon-qrcode").on("dblclick", event => {
        event.stopPropagation();
        const occupations = [];
        const occupation = $(event.currentTarget).parents("li").data("data");
        //occupations.push(occupation);
        const deliveredById = $(event.currentTarget).parents("li").attr("deliveredById");

        $('li[deliveredById = "' + deliveredById + '"]').each((ind, li) => {
          const occupation = $(li).data("data");
          occupations.push(occupation);
        })

        if (occupations.length) {
          var path = encodeURIComponent(auth.myStatus.restaurant_address + "," + auth.myStatus.restaurant_town + ", " + auth.myStatus.restaurant_postcode);
          var text = occupations.map(o => {
            const occupation = getTableOccupation(o.tableOccupation);
            const address = occupation.bookedProperties.address + ", " + occupation.bookedProperties.town + (occupation.bookedProperties.postcode ? ", " + occupation.bookedProperties.postcode : "");
            path += "/" + encodeURIComponent(address.replace(/\//g, " ").replace(/,/g, " "));
            return getAddresses(o.tableOccupation, o.date);
          }).join();
          const printerSettings = getLocalPrinterSettings();
          var printer = auth.myStatus.restaurant_printer_actions.printInvoice.id;
          if (printerSettings.printerActions.pa_printInvoice)
            printer = printerSettings.printerActions.pa_printInvoice;
          path += "/" + encodeURIComponent(auth.myStatus.restaurant_address + "," + auth.myStatus.restaurant_town + ", " + auth.myStatus.restaurant_postcode);
          const url = 'https://www.google.com/maps/dir/' + path + '/@' + auth.myStatus.restaurant_latitude + ',' + auth.myStatus.restaurant_longitude + ',14z';
          const html = "<html><body><div>!ESCqrcode: " + url + "!LF!LF</div><div>" + text + "</div></body></html>";
          //console.log(html);
          post("printerServerClient/" + localStorage.restaurantSelected + "/printerMessage/" + printer, { text: html });
        }
      });

      selectorul.find("li.group-functions div.invoice").off("dblclick");
      selectorul.find("li.group-functions div.invoice").on("dblclick", event => {
        event.stopPropagation();
        const date = $(event.currentTarget).parents("li").attr("id");
        const occupations = [];
        $('li[date="' + date + '"] input.custom-control-input:checked').each((ind, li) => {
          occupations.push($(li).parents('li').data("data"));
          li.checked = false;
        })
        if (!occupations.length) {
          $('li:not(.group-functions):not(.separator)[date = "' + date + '"]').each((ind, li) => {
            occupations.push($(li).data("data"));
          });
        }
        function print(occupations) {
          const occupation = occupations.shift();
          var d = "";
          if (occupation?.bookedProperties?.installments_strategy == "by_each")
            d = date;

          admin.getInvoiceXML(occupation.tableOccupation, localStorage.tableNumberSelected, occupation.forWhomId, localStorage.language, 1, d, date, function (data) {
            if (data == '{"success":"true"}') {
            } else {
              printHtml(data, admin.getPrinterForPrinterAction("printInvoice").id);
            }
            if (occupations.length)
              print(occupations);
          });
        }
        if (occupations.length)
          print(occupations);
      })
      selectorul.find("li.group-functions div.print").off("dblclick");
      selectorul.find("li.group-functions div.print").on("dblclick", event => {
        event.stopPropagation();
        const date = $(event.currentTarget).parents("li").attr("id");
        var occupations = [];
        var selected = $('li[date="' + date + '"] input.custom-control-input:checked');
        selected.each((ind, li) => {
          occupations.push($(li).parents('li').data("data"));
          li.checked = false;
        })
        if (selected.length === 0)
          $('li:not(.group-functions):not(.separator)[date = "' + date + '"]').each((ind, li) => {
            occupations.push($(li).data("data"));
          });
        function print(occupations, reprint) {
          const occupation = occupations.shift();
          const orderIds = occupation.orderIds ? occupation.orderIds.map(id => getOrderById(id)).filter(o => !o.printed || reprint).map(o => o.id).join(",") : undefined;
          if (orderIds) {
            selectTableOccupationSelected(occupation.tableOccupation);
            printNewOrders(orderIds, undefined, reprint, true);
            if (occupations.length)
              print(occupations, reprint);
          } else if (occupations.length)
            print(occupations, reprint);
        }
        occupations = occupations.filter(o => o);
        if (occupations.length) {
          if (!occupations.filter(o => o.unprinted).length) {
            confirmDialog(I18n.t("local.confirmation"), I18n.t("admin_local.do_you_really_want_to_reprint_only_the_selected_orders")).done(() => print(occupations, true));
          } else
            print(occupations);
        }
      })

      selectorul.find("li.group-functions div.t-state").off("dblclick");
      selectorul.find("li.group-functions div.t-state").on("dblclick", event => {
        event.stopPropagation();
        const date = $(event.currentTarget).parents("li").attr("id");
        const occupations = [];
        $('li:not(.cancelled)[date = "' + date + '"] input.custom-control-input:checked').each((ind, li) => {
          occupations.push($(li).parents('li').data("data"));
          li.checked = false;
        })
        if (occupations.length === 0)
          $('li:not(.group-functions):not(.separator)[date="' + date + '"]').each((ind, li) => {
            occupations.push($(li).data("data"));
          });
        function changeState(occupations) {
          const occupation = occupations.shift();
          const state = $(event.currentTarget).attr("state");
          const stateIndex = allstates.indexOf(state);
          const orderIds = occupation.orderIds ? occupation.orderIds.map(id => getOrderById(id)).filter(o => allstates.indexOf(o.state) < stateIndex).map(o => o.id).join(",") : undefined;
          if (orderIds)
            admin.processchangeStateByOrderIds(occupation.tableOccupation, orderIds, state, data => {
              update(data);
              if (occupations.length)
                changeState(occupations);
            })
          else if (occupations.length)
            changeState(occupations);

        }
        console.log(occupations);
        if (occupations.length)
          changeState(occupations);
      })

      var selectedcount = selectorul.find(".nav-item:not(.group-functions):not(.separator)[date = '" + tablesOverview.selectedDate + "']").length;
      if (selectedcount === 0) {
        tablesOverview.selectedDate = moment().startOf("day").valueOf();
        selectedcount = selectorul.find(".nav-item:not(.group-functions):not(.separator)[date = '" + tablesOverview.selectedDate + "']").length;
        if (selectedcount === 0) {
          tablesOverview.selectedDate = selectorul.find(".nav-item:not(.group-functions):not(.separator)").toArray().map(t => $(t).attr("date")).reduce((a, b) => a ? Math.min(a, b) : b, null);
          selectedcount = selectorul.find(".nav-item:not(.group-functions):not(.separator)[date = '" + tablesOverview.selectedDate + "']").length;
          if (selectedcount === 0) {
            tablesOverview.selectedDate = selectorul.find(".nav-item:not(.group-functions):not(.separator)").toArray().map(t => $(t).attr("date")).reduce((a, b) => a ? Math.max(a, b) : b, null);
            selectedcount = selectorul.find(".nav-item:not(.group-functions):not(.separator)[date = '" + tablesOverview.selectedDate + "']").length;
            if (selectedcount === 0) {
              tablesOverview.selectedDate = moment().startOf("day").valueOf();
            }
          }
        }
      }

      selectorul.find(".nav-item:not(.group-functions):not(.separator)[date]").addClass("hidden");
      selectorul.find(".nav-item:not(.group-functions):not(.separator)[date = '" + tablesOverview.selectedDate + "']").removeClass("hidden");
      selectorul.find(".nav-item.separator[date]").removeClass("selected");
      selectorul.find(".nav-item.separator[date = '" + tablesOverview.selectedDate + "']").addClass("selected");
      //selectorul.find("#" + tablesOverview.selectedDate + ".nav-item.group-function").addClass("selected");
      if (first && selectorul.find(".nav-item.separator[date = '" + tablesOverview.selectedDate + "']").get(0))
        selectorul.find(".nav-item.separator[date = '" + tablesOverview.selectedDate + "']").get(0).scrollIntoView();

      selectorul.find(".nav-item.separator").off("click");
      selectorul.find(".nav-item.separator").on("click", (t) => {
        selectorul.find(".nav-item.separator[date = '" + tablesOverview.selectedDate + "']").removeClass("selected");
        selectorul.find(".nav-item:not(.group-functions):not(.separator)[date = '" + tablesOverview.selectedDate + "']").addClass("hidden");
        tablesOverview.selectedDate = $(t.currentTarget).attr("date");
        selectorul.find(".nav-item:not(.group-functions):not(.separator)[date = '" + tablesOverview.selectedDate + "']").removeClass("hidden");
        selectorul.find(".nav-item.separator[date = '" + tablesOverview.selectedDate + "']").addClass("selected");
        setTimeout(() => {
          selectorul.find(".nav-item.separator[date = '" + tablesOverview.selectedDate + "'] + li + li").get(0).scrollIntoView();
          selectorul.find(".nav-item.separator[date = '" + tablesOverview.selectedDate + "']").get(0).scrollIntoView()
        }, 200);
        setTimeout(() => {
          selectorul.find(".nav-item.separator[date = '" + tablesOverview.selectedDate + "'] + li + li").get(0).scrollIntoView();
          selectorul.find(".nav-item.separator[date = '" + tablesOverview.selectedDate + "']").get(0).scrollIntoView()
        }, 500);
        setTimeout(() => {
          selectorul.find(".nav-item.separator[date = '" + tablesOverview.selectedDate + "'] + li + li").get(0).scrollIntoView();
          selectorul.find(".nav-item.separator[date = '" + tablesOverview.selectedDate + "']").get(0).scrollIntoView()
        }, 700);
      })

    } else {
    }

    if (window.location.href.indexOf("pointOfSale") === -1) {
      if (first && orderListUtil.occupationsSelected.filter(o => o.occupationStart).length > 1) {
        if (localStorage.tableNumberSelected == pickupTableNumber || localStorage.tableNumberSelected == homeDeliveryTableNumber) {
          selector.removeClass("selectTableOccupationTile");
          $('#editTable').removeClass("selectTableOccupationTile");
          selector.addClass("selectTableOccupation");
          $('#editTable').addClass("selectTableOccupation");
        } else {
          selector.removeClass("selectTableOccupation");
          $('#editTable').removeClass("selectTableOccupation");
          selector.addClass("selectTableOccupationTile");
          $('#editTable').addClass("selectTableOccupationTile");
        }
      } else if (orderListUtil.occupationsSelected.length > 1 || localStorage.tableNumberSelected == pickupTableNumber || localStorage.tableNumberSelected == homeDeliveryTableNumber) {
      } else {
        selector.removeClass("selectTableOccupationTile");
        $('#editTable').removeClass("selectTableOccupationTile");
        selector.removeClass("selectTableOccupation");
        $('#editTable').removeClass("selectTableOccupation");
      }
    }


    var active = selector.find("a.nav-link.active");
    active.removeClass("active");
    //if (active.length == 0) {
    $(selector.find('a[id = "' + localStorage.tableOccupationSelected + '"]')).addClass("active");
    //}

    if (selectorulold.find("li:not(.group-functions):not(.separator)").length > 6) {
      $('#editTable').addClass("too-many-orders");
    } else {
      $('#editTable').removeClass("too-many-orders");
    }

  } else {
    if (selectorulold.find("li:not(.group-functions):not(.separator)").length > 6) {
      $('#editTable').addClass("too-many-orders");
    } else {
      $('#editTable').removeClass("too-many-orders");
    }

    $(selector).hide();
  }


  checked.each((ind, item) => {
    selectorul.find("li input#" + item.id + ".custom-control-input").prop("checked", true);
  })

  $('#tableOccupation-selector [data-toggle="tooltip"]').tooltip({ trigger: 'hover' });

}

var tableHeadFloated = false;
export const processOrdersDone = function (tables, showEditTableFlag = true, first = true) {
  const now = new Date().getTime();
  updateTableOccupationSelector(first);

  //updateFooterButtons();

  if (!orderListUtil.kioskMode) {
    $("ul#orderListByCustomer").empty();

    var o = orderListUtil.isFiltered() ? orderListUtil.orders : orderListUtil.allOrders;

    var newContent = $("<div/>");

    o.ordersByPayer.forEach(function (order) {
      if (!order.customer)
        order.customer = order.ordersByCustomer[0].customer;
      if (orderListUtil.show(order)) {
        $("script#ordersByPayerTemplate")
          .tmpl({ ...order, ...tmplparams() })
          .appendTo(newContent);
      }
    });

    replaceContent($("ul#orderListByCustomer"), newContent);

    $("ul#orderListByTiming").empty();
    var ordersByTiming = {};
    o.ordersByTiming.forEach(function (order) {
      if (orderListUtil.show(order)) {
        ordersByTiming[order.timing] = order;
      }
    });
    $("script#ordersByTimingTemplate")
      .tmpl({ ...ordersByTiming, ...tmplparams() })
      .appendTo("ul#orderListByTiming");
  }

  //setRem();
  var tableOccupation = getTableOccupation(localStorage.tableOccupationSelected);
  showEditTable();

  orderListUtil.ai.setContext({ tableOccupation: { id: localStorage.tableOccupationSelected, tableNumber: localStorage.tableNumberSelected } });

  setBigTableNumber();

  if (typeof localStorage.atTheTableSelectedTabLeft === "undefined" || ["orders", "byCustomer", "byTiming"].indexOf(localStorage.atTheTableSelectedTabLeft) === -1)
    localStorage.atTheTableSelectedTabLeft = "orders";

  tablesOverview.atTheTableSelectedTab = localStorage.atTheTableSelectedTabLeft;
  var tab = $('span[href="#' + localStorage.atTheTableSelectedTabLeft + '"].nav-link');
  if (tab.length > 0 || isVisible(tab)) {
    tab.tab("show");
  }

  if (typeof localStorage.selectNewOrders != "undefined" && localStorage.selectNewOrders == "true") {
    if (first)
      selectNewOrders();
    var tab = $('span[href="#' + localStorage.atTheTableSelectedTabRight + '"].nav-link');
    if (tab.length > 0 || isVisible(tab)) {
      tab.tab("show");
    }
    tablesOverview.atTheTableSelectedTab = localStorage.atTheTableSelectedTabRight;
  } else {
    var orientation = getOrientation();
    if (orientation == "landscape") $("#left_pane").css("width", "100%");
    else $("#left_pane").css("height", "100%");
    if (typeof localStorage._editTable != "undefined") {
      try {
        $("#left_pane").resizable("destroy");
        $("#right_pane").resizable("destroy");
      } catch (e) { }
    }
  }
  var tab = $('span[href="#' + tablesOverview.atTheTableSelectedTab + '"].nav-link');
  if (tab.length > 0 || isVisible(tab)) {
    tab.tab("show");
  }

  var tab = $("#" + tablesOverview.atTheTableSelectedTab + ".tab-pane");
  select(tab);

  //setTimeout(function () {
  orderListUtil.updateHooks();
  topUpdateHooks();

  updatePrintersStatus();
  updateFooterButtons();
  updateHeadings();


  //});
};

var colGroupClone;

export const selectNewOrders = (force) => {
  if (!force && orderListUtil.occupationsSelected.length == 0) return;
  localStorage.atTheTableSelectedTabRight = settingEnabled("meals/default") ? "meal_menu_tab" : "drink_menu_tab";
  var tab = $('span[href="#' + localStorage.atTheTableSelectedTabRight + '"].nav-link');
  if (tab.length > 0 || isVisible(tab)) {
    tab.tab("show");
  }
  gotoTop();
  $("button#new-order").addClass("hidden");
  $("button#lock").addClass("hidden");
  $("button#unlock").addClass("hidden");
  //$('button#close').addClass('hidden');
  $("button#new-order").addClass("hidden");
  $("#left_pane button#maximize").removeClass("hidden");
  $("button#submit-new-orders").prop("disabled", !userConnected());
  $("button#paying").addClass("hidden");
  $("button#paid").addClass("hidden");
  $("#right_pane").removeClass("hidden");
  localStorage.selectNewOrders = true;
  auth.suppressNetworkError = true;
  auth.networkErrorHandler = {
    buttons: [],
    disconnected: function () {
      //auth.networkErrorHandler.buttons = $("#editTable .modal-footer button:not(:disabled),#editTable .modal-header button:not(:disabled)");
      //auth.networkErrorHandler.buttons.prop && auth.networkErrorHandler.buttons.prop("disabled", true);
    },
    connected: function () {
      //auth.networkErrorHandler.buttons && auth.networkErrorHandler.buttons.prop && auth.networkErrorHandler.buttons.prop("disabled", null);
      //delete auth.networkErrorHandler.buttons;
    }
  };
  orderListUtil.ai.setContext({ selectNewOrders: true });

  orientationchangehandler(true);
  var orientation = getOrientation();
  localStorage.atTheTableSelectedTab = $("#right_pane .tab-pane.active").attr("id");
  select($("#right_pane .tab-pane.active"));
  orderListUtil.statesShown = orderListUtil.kioskMode ? auth.myStatus.restaurant_settings["tables-overview"].newOrdersKioskStatesShown : auth.myStatus.restaurant_settings["tables-overview"].newOrdersStatesShown;

  $("div#orders.tab-pane.container").empty();
  orderListUtil.hidePaid = true;
  $("script#orderListMainTemplate")
    .tmpl(tmplparams())
    .appendTo($("div#orders.tab-pane.container"));
  if (!orderListUtil.kioskMode) orderListUtil.statesDisplayed = ["selected"];

  updateOrders([]).done(() => {
  });
  setBigTableNumber();
  updateFooterButtons();
};

var previousorientation2 = null;
function orientationchangehandler(force) {
  //var orientation = window.innerWidth>window.innerHeight ? "landscape" : "portrait";
  var orientation = getOrientation();
  if ((force == undefined || force == false) && previousorientation2 == orientation /* && $('#left_pane .ui-resizable-handle').length>0*/) {
    return false;
  }


  if (previousorientation2 != orientation) {
    tableLayout.tableOccupationsCache = {};
    tableLayout.tableDecorationCache = {};
  }

  previousorientation2 = orientation;
  if ($("#right_pane").hasClass("hidden")) return false;

  if (orientation == "portrait") {
    $("#editTable #container.left-right").css("flex-flow", "column");
    if ($("#left_pane .ui-resizable-handle").length > 0) {
      $("#left_pane").resizable("destroy");
      $("#right_pane").resizable("destroy");
    }
    // you're in PORTRAIT mode
    $("#left_pane").resizable({
      handles: "s",
      resize: function () {
        localStorage.table_overview_right_pane_height = (($("#container").innerHeight() - $("#left_pane").innerHeight()) * 100) / $("#container").innerHeight();
        $("#left_pane").css("height", 100 - localStorage.table_overview_right_pane_height + "%");
        $("#right_pane").css("height", localStorage.table_overview_right_pane_height + "%");
      }
    });

    $("#right_pane").resizable({
      handles: "n",
      resize: function () {
        localStorage.table_overview_right_pane_height = ($("#right_pane").innerHeight() * 100) / $("#container").innerHeight();
        var h = 100 - localStorage.table_overview_right_pane_height;
        $("#left_pane").css("height", h + "%");
        $("#right_pane").css("top", "0px");
      }
    });
    $("#left_pane").css("width", "100%");
    $("#right_pane").css("width", "100%");
  } else {
    $("#editTable #container.left-right").css("flex-flow", "row");
    if ($("#left_pane .ui-resizable-handle").length > 0) {
      $("#left_pane").resizable("destroy");
      $("#right_pane").resizable("destroy");
    }
    $("#left_pane").resizable({
      handles: "e",
      resize: function () {
        localStorage.table_overview_right_pane = (($("#container").innerWidth() - $("#left_pane").innerWidth()) * 100) / $("#container").innerWidth();
        $("#left_pane").css("width", "calc(100% - " + localStorage.table_overview_right_pane + "%)");
        $("#right_pane").css("width", localStorage.table_overview_right_pane + "%");
      }
    });

    $("#right_pane").resizable({
      handles: "w",
      resize: function () {
        localStorage.table_overview_right_pane = ($("#right_pane").innerWidth() * 100) / $("#container").innerWidth();
        $("#left_pane").css("width", "calc(100% - " + localStorage.table_overview_right_pane + "%)");
        $("#right_pane").css("left", "0px");
      }
    });
    $("#left_pane").css("height", "100%");
    $("#right_pane").css("height", "100%");
  }
  if (orientation == "landscape") {
    if (typeof localStorage.table_overview_right_pane == "undefined") {
      localStorage.table_overview_right_pane = 60;
    }
    $("#left_pane").css("width", "calc(100% - " + localStorage.table_overview_right_pane + "%)");
    $("#right_pane").css("width", localStorage.table_overview_right_pane + "%");
    $("#left_pane").css("height", "100%");
    $("#right_pane").css("height", "100%");
  } else {
    if (typeof localStorage.table_overview_right_pane_height == "undefined") {
      localStorage.table_overview_right_pane_height = 60;
    }
    $("#right_pane").css("height", localStorage.table_overview_right_pane_height + "%");
    $("#left_pane").css("height", "calc(100% - " + localStorage.table_overview_right_pane_height + "%)");
    $("#left_pane").css("width", "100%");
    $("#right_pane").css("width", "100%");
  }
  if (chooseMenuItems.status == "maximized") {
    $("#right_pane").css("height", "100%");
    $("#right_pane").css("width", "100%");
  } else if (chooseMenuItems.status == "minimized") {
    $("#left_pane").css("height", "100%");
    $("#left_pane").css("width", "100%");
  }

  return true;
}

export const hideSelectNewOrders = () => {
  //if (typeof handler == "undefined")
  //	handler = null;
  if (!orderListUtil.kioskMode) $("#timingSelector button.timing.active").removeClass("active");

  orderListUtil.ai.setContext({ selectNewOrders: false });

  if ($("#userSelector button.sticky").length) {
    $("#userSelector button.sticky").removeClass("sticky");
    updateOrders([]);
  }

  $("button#new-order").removeClass("hidden");
  $("button#lock").removeClass("hidden");
  $("button#unlock").removeClass("hidden");
  //$('button#close').removeClass('hidden');
  $("button#new-order").removeClass("hidden");
  $("button#submit-new-orders").addClass("hidden");
  localStorage.selectNewOrders = false;
  $(".selectable.tablemate.btn.btn-primary.active").removeClass("active");
  $(".selectable.tablemate.btn.btn-primary.sticky").removeClass("sticky");

  $("#right_pane").addClass("hidden");
  $("#left_pane button#maximize").addClass("hidden");
  //var orientation = window.innerWidth>window.innerHeight ? "landscape" : "portrait";
  var orientation = getOrientation();
  auth.previousorientation = orientation;
  if (orientation == "landscape") $("#left_pane").css("width", "100%");
  else $("#left_pane").css("height", "100%");
  localStorage.atTheTableSelectedTab = $("#left_pane .tab-content .tab-content .tab-pane.active").attr("id");
  select($("#left_pane .tab-content .tab-content .tab-pane.active"));

  orderListUtil.statesShown = orderListUtil.statesShownOriginal;
  orderListUtil.statesDisplayed = undefined;
  orderListUtil.hidePaid = undefined;

  $("div#orders.tab-pane.container").empty();
  $("script#orderListMainTemplate").tmpl(tmplparams()).appendTo($("div#orders.tab-pane.container"));
  var res = updateOrders([]);
  if (typeof localStorage._editTable != "undefined") {
    try {
      $("#left_pane").resizable("destroy");
      $("#right_pane").resizable("destroy");
    } catch (e) { }
  }
  localStorage.removeItem("selectNewOrders");
  auth.suppressNetworkError = false;
  auth.networkErrorHandler = undefined;
  updateFooterButtons();
  return res;
};

export const submitNewOrders = (accepted, print, type) => {
  var def = $.Deferred();

  //if (getAjaxCallCount() > 0) return def.promise();

  var thereAreOrders = false;
  var thereAreCustomerOrders = false;
  var thereArePrintableOrders = false;
  var printable = 0;
  var orders = [];

  if (typeof orders.tables != "undefined")
    orders.tables.forEach(order => {
      if (localStorage.tableOccupationSelected == order.tableOccupation && localStorage.tableNumberSelected == order.tableNumber) {
        printable += order.unprinted + order.partprinted;
      }
    });

  orderListUtil.ordersCache.forEach(order => {
    if ((order.state == "selected" || (order.state == "accepted" && orderListUtil.kioskMode)) && localStorage.tableOccupationSelected == order.tableOccupationId && localStorage.tableNumberSelected == order.tableNumber) {
      thereAreCustomerOrders |= order.orderedByCustomer == true;
      thereAreOrders |= order.orderedById == localStorage.id || auth.myStatus.roles.find(r => r.role === "admin") !== null;
      if (order.orderedById == localStorage.id || auth.myStatus.roles.find(r => r.role === "admin") !== null) {
        if (typeof type == "undefined" || order.menuItem.type == type) {
          orders.push(order);
        }
      }
    }
  });


  if (orders.filter(o => o.id < 0).length && !auth.delayedOrder()) {
    return def.promise();
  }

  ajaxCallStart("processing30", 0);

  function ok() {
    auth.vibrateOk(500);
    admin.orderSelectedMenuItems(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, accepted, orders, print, function (data) {
      if (orderListUtil.kioskMode) {
        update(data, function (data) {
          if (print) {
            printNewOrders(orders, undefined, undefined, true);
          }
          ajaxCallEnd("processing30");
        });
      } else {
        hideSelectNewOrders().done(function () {
          update(data, function (data) {
            if (print) {
              printNewOrders(orders, undefined, undefined, true);
              try {
                const table = getTable(localStorage.tableNumberSelected);
                if (table.homeDelivery && featureEnabled("homedelivery/printInvoiceOnOrder")) {
                  const occupation = getTableOccupation(localStorage.tableOccupationSelected);
                  admin.getInvoiceXML(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, occupation.tableMates[0].id, localStorage.language, 1, moment().startOf("day"), moment().startOf("day").add(1, "day"), function (data) {
                    if (data == '{"success":"true"}') {
                      post("adminService/" + localStorage.restaurantSelected + "/markTableMateForPayment", occupation.tableMates.map(m => {
                        m.states.push({ state: "wantsToPay", duration: 0 });
                        return m;
                      })).done(update);
                    } else if (data.startsWith("<!DOCTYPE HTML")) {
                      printHtml(data, admin.getPrinterForPrinterAction("printInvoice").id);
                      post("adminService/" + localStorage.restaurantSelected + "/markTableMateForPayment", occupation.tableMates.map(m => {
                        m.states.push({ state: "wantsToPay", duration: 0 });
                        return m;
                      })).done(update);
                    }
                  });
                }
              } catch (ex) { }
              hideAfterPrinted();
            }
            ajaxCallEnd("processing30");
          });
        });
      }
      def.resolve();
    });
  }

  if (thereAreOrders) {
    if (thereAreCustomerOrders) {
      ajaxCallEnd("processing30");
      confirmDialog(local.confirmation, local.do_you_really_want_to_submit_all_new_orders).done(function () {
        if (!print) confirmDialog(local.confirmation, admin_local.confirm_not_printing).done(ok);
        else ok();
      });
    } else {
      if (!print && printable > 0) {
        ajaxCallEnd("processing30");
        confirmDialog(local.confirmation, admin_local.confirm_not_printing).done(ok);
      } else ok();
    }
  } else {
    ajaxCallEnd("processing30");
    if (orderListUtil.kioskMode) {
      def.resolve();
    } else {
      hideSelectNewOrders().done(() => {
        def.resolve();
      });
    }
  }

  return def.promise();
};

function hideAfterPrinted(c) {
  if (typeof c == "undefined") c = 30;
  if (c == 0) return;
  if (typeof localStorage._editTable == "undefined") return;
  setTimeout(function () {
    if (isVisible($("#print-new-orders.print-all"))) {
      hideAfterPrinted(c - 1);
    } else {
      window.hideEditTable();
    }
  }, 250);
}

export const updateOrdersDone = function () {
  updateTableOccupationSelector();

  var o = orderListUtil.isFiltered() ? orderListUtil.orders : orderListUtil.allOrders;

  updateOrderListByCustomer(true);
  updateOrderListByTiming(true);

  //setTimeout(function () {
  updateHeadings();
  updateFooterButtons();

  orderListUtil.updateHooks();
  topUpdateHooks();
  //});
};

export const updateHeadings = () => {
  var heading = $("#editTableNumber");
  var tableOccupationHeading = $("#tableOccupationNumber");

  var tableMateName = $("#tableMate-name");
  tableMateName.html(" " + I18n.t("admin_local.name_of_customer"));

  var tableOccupation = getTableOccupation(localStorage.tableOccupationSelected);

  if (!tableOccupation) {
    tableMateName.addClass("hidden");
  } else {
    tableMateName.removeClass("hidden");
    if (tableOccupation.tableMates.length === 1) {
      if (tableOccupation.tableMates[0].name) {
        if (tableOccupation.tableMates[0].tableMate) {
          const usedPoints = getOrdersPaidBy(tableOccupation.tableMates[0].id).map(o => o.pointin).reduce((a, b) => a + b, 0);
          if (usedPoints > 0)
            tableMateName.html(" <span>" + tableOccupation.tableMates[0].name + "(" + tableOccupation.tableMates[0].tableMate.id + ")" + "</span>" + (tableOccupation.tableMates[0].tableMate.point > 0 ? " <span class='icon-star-filled'>" + tableOccupation.tableMates[0].tableMate.point + (usedPoints ? "-" + usedPoints + "=" + (tableOccupation.tableMates[0].tableMate.point - usedPoints) : "") + "</span>" : ''));
          else
            tableMateName.html(" <span>" + tableOccupation.tableMates[0].name + "(" + tableOccupation.tableMates[0].tableMate.id + ")" + "</span>" + (tableOccupation.tableMates[0].tableMate.point > 0 ? " <span class='icon-star-filled'>" + tableOccupation.tableMates[0].tableMate.point + (usedPoints ? "-" + usedPoints : "") + "</span>" : ''));
        } else
          tableMateName.html(" " + tableOccupation.tableMates[0].name);
      }
    } else {
      if (tableOccupation.leadCustomerId && getTableMate(tableOccupation.leadCustomerId) && getTableMate(tableOccupation.leadCustomerId).name) {
        tableMateName.html(" " + getTableMate(tableOccupation.leadCustomerId).name);
      }
    }
  }

  const customerDetails = $('#editTable #customerDetails');
  customerDetails.find('span').text('');
  if (tableOccupation && tableOccupation.bookedProperties) {
    customerDetails.removeClass("hidden");
    Object.keys(tableOccupation.bookedProperties).forEach(key => {
      const t = customerDetails.find('span#' + key);
      if (t.length > 0)
        t.text(tableOccupation.bookedProperties[key]);
    })
    if (tableOccupation.bookedOccupationEnd) {
      if (moment(tableOccupation.bookedOccupationStart).valueOf() === moment(tableOccupation.bookedOccupationStart).startOf('day').valueOf() && moment(tableOccupation.bookedOccupationEnd).valueOf() === moment(tableOccupation.bookedOccupationEnd).endOf('day').valueOf()) {
        customerDetails.find("#time").html("<div style='padding-left:.5rem'>" + moment(tableOccupation.bookedOccupationStart).format("LL") + " " + local.allDay + "</div>");
      } else {
        customerDetails.find("#time").html("<div style='padding-left:.5rem'>" + moment(tableOccupation.bookedOccupationStart).format("LLLL") + "<br/>" + moment(tableOccupation.bookedOccupationEnd).format("LLLL") + "</div>");
      }
    } else {
      const start = moment(tableOccupation.bookedOccupationStart ? tableOccupation.bookedOccupationStart : tableOccupation.occupationStart);
      if (start.clone().startOf('day').valueOf() == moment().startOf('day').valueOf()) {
        if (start.clone().valueOf() === start.clone().startOf('day').valueOf())
          customerDetails.find("#time").html(admin_local.today + " " + local.allDay);
        else
          customerDetails.find("#time").html(admin_local.today + " " + start.format("LT"));
      } else {
        if (start.valueOf() === start.startOf('day').valueOf())
          customerDetails.find("#time").html(start.format("LL") + " " + local.allDay);
        else
          customerDetails.find("#time").html(start.format("LLLL"));
      }
    }
  } else {
    customerDetails.addClass("hidden");
  }

  var dropDown = $("#editTableNumber.dropdown-menu");
  var caret = $("button#editTableNumber");
  dropDown.empty();
  if (tableOccupation != null) {
    var price = 0;
    var o = orderListUtil.isFiltered() && typeof orderListUtil.orders.count != "undefined" ? orderListUtil.orders : orderListUtil.allOrders;
    o.tables.forEach(function (order) {
      if (orderListUtil.show(order)) {
        price += order.discountedPrice;
      }
    });

    if (orderListUtil.occupationsSelected.length == 1 && orderListUtil.occupationsSelected[0].leadCustomerId > 0) {
      var t = admin.getTableMate(orderListUtil.occupationsSelected[0].leadCustomerId);
      heading.text(getTableName(localStorage.tableNumberSelected));
      tableOccupationHeading.text(String.format(admin_local.table_occupation_number, tableOccupation.seqnr) + " - " + price + " " + auth.myStatus.restaurant_base_currency.name);
    } else {
      heading.text(getTableName(localStorage.tableNumberSelected));
      if (orderListUtil.occupationsSelected.length == 0) tableOccupationHeading.text("");
      else tableOccupationHeading.text(String.format(admin_local.table_occupation_number, tableOccupation.seqnr) + " - " + price + " " + auth.myStatus.restaurant_base_currency.name);
    }

    if (tableOccupation.restaurantTables.length < 2) caret.removeClass("dropdown-toggle");
    else {
      caret.addClass("dropdown-toggle");
      tableOccupation.restaurantTables.forEach(function (table) {
        dropDown.append(
          $(
            '<button onclick="localStorage.tableNumberSelected=' +
            table.restaurantTable.number +
            ';updateOrders([])" class="dropdown-item">' +
            getTableName(table.restaurantTable.number) +
            "</button>"
          )
        );
      });
    }
  } else {
    caret.removeClass("dropdown-toggle");
  }
}

export const updateFooterButtons = () => {
  //const now = new Date().getTime();
  if (orderListUtil.kioskMode) {
    kioskModeUpdateFooterButtons();
    return;
  }

  $("#_byCustomer.nav-item").show();
  $("#_byTiming.nav-item").show();
  var thereAreOrders = false;
  var thereAreCustomerOrders = false;
  var printable = 0;

  if (typeof orderListUtil.ordersCache != "undefined")
    orderListUtil.ordersCache.forEach(order => {
      if (localStorage.tableOccupationSelected == order.tableOccupationId && localStorage.tableNumberSelected == order.tableNumber) {
        if (order.state == "selected") {
          thereAreCustomerOrders |= order.orderedByCustomer == true;
          thereAreOrders |= order.orderedById == localStorage.id || auth.myStatus.roles.find(r => r.role === "admin") !== null;
        }
      }
    });

  if (typeof orderListUtil.orders.tables != "undefined")
    orderListUtil.orders.tables.forEach(order => {
      if (localStorage.tableOccupationSelected == order.tableOccupation && localStorage.tableNumberSelected == order.tableNumber) {
        printable += order.unprinted + order.partprinted;
      }
    });

  var booked = false;
  if (localStorage.tableOccupationSelected != null && localStorage.tableOccupationSelected != "null") {
    var tableOccupation = getTableOccupation(localStorage.tableOccupationSelected);
    if (tableOccupation && tableOccupation.occupationStart == null) booked = true;
  }

  const isPickup = Number(localStorage.tableNumberSelected) === auth.myStatus.restaurant_settings["enabled-features"].pickup["table-number"];
  const isHomeDelivery = Number(localStorage.tableNumberSelected) === auth.myStatus.restaurant_settings["enabled-features"].homedelivery["table-number"];
  if (isPickup) {
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#lock text").html(I18n.t("admin_local.lock_pickup"));
  } else if (isHomeDelivery) {
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#lock text").html(I18n.t("admin_local.lock_homedelivery"));
  } else {
    if (orderListUtil.occupationsSelected.length > 0) {
      $("#editTable >.modal-dialog > .modal-content > .modal-footer button#lock text").html(admin_local.new_lock);
    } else {
      $("#editTable >.modal-dialog > .modal-content > .modal-footer button#lock text").html(admin_local.lock);
    }
  }

  const delays = $("#delays");
  delays.empty();

  if (isHomeDelivery && auth.myStatus.restaurant_settings["enabled-features"].homedelivery.delays) {

    const defaultDelay = auth.myStatus.restaurant_settings["enabled-features"].homedelivery.timeahead;
    auth.myStatus.restaurant_settings["enabled-features"].homedelivery.delays.forEach(delay => {
      const isdefault = defaultDelay == (Math.round(delay / 60 * 100) / 100);
      $('<label id="delay-' + delay + '" level="2" onClick="updateTimeahead(\'homedelivery\', ' + delay + ')" class="selectable btn btn-positive ' + (isdefault ? 'active' : '') + '" style=""><input type="radio" name="options" id="delay-' + delay + '" autocomplete="off" checked=' + isdefault + '>' + delay + '</label>').appendTo(delays);
    });
    $("<span style='color: burlywood;padding-left:1rem'>" + admin_local.minute + "</span>").appendTo(delays);
  }

  $("#editTable #bookingDetails").addClass("hidden");

  if (auth.myStatus.restaurant_settings["tables-overview"].timeout && !isPickup && !isHomeDelivery) {
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#freezelock").removeClass("hidden");
  } else {
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#freezelock").addClass("hidden");
  }

  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#close").attr("disabled", null);
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paying").attr("disabled", null);
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paid").attr("disabled", null);
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#submit-new-orders").attr("disabled", null);
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#submit-and-accept-new-orders").attr("disabled", null);
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#submit-and-accept-and-print-new-orders").attr("disabled", null);

  if (isThereReceiptsToPrint())
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#payments").removeClass("hidden");
  else
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#payments").addClass("hidden");

  if (tableOccupation) {

    $("text#personCount").html(tableOccupation.personCount);

    const payingMates = tableOccupation.tableMates.filter(t => (t.active || t.isActive) && (t.payingMateId === null || t.payingMateId === t.id));
    if (payingMates.length === 1 && getStateIndex(payingMates[0], "wantsToPay") >= 0) {
      $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paying text").html(I18n.t("admin_local.cancel_paying"));
      $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paying").removeClass("btn-primary");
      $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paying").addClass("btn-danger");
    } else {
      $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paying text").html(I18n.t("admin_local.invoice"));
      $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paying").addClass("btn-primary");
      $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paying").removeClass("btn-danger");
    }
  } else {
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paying text").html(I18n.t("admin_local.invoice"));
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paying").addClass("btn-primary");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paying").removeClass("btn-danger");
  }

  var bookingDetails = $("#editTable >.modal-dialog > .modal-content > .modal-footer #bookingDetails");
  if (booked) {
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#submit-and-accept-new-orders").addClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#submit-and-accept-and-print-new-orders").addClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#close").removeClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#submit-and-accept-and-print-new-orders-choice").addClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#submit-and-accept-and-print-new-orders-choice2").addClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#submit-and-accept-and-print-new-orders-drinks").addClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#submit-and-accept-and-print-new-orders-meals").addClass("hidden");
    var bookedProperties = tableOccupation.bookedProperties;
    bookingDetails.removeClass("hidden");
    bookingDetails.find("#time").html(moment(tableOccupation.bookedOccupationStart).format("HH:mm"));
    bookingDetails.find("#endtime").html(moment(tableOccupation.bookedOccupationEnd).format("HH:mm"));
    if (bookedProperties) {
      bookingDetails.find("#name").html(bookedProperties.email);
      bookingDetails.find("#phone").html(bookedProperties.phone);
      var tables = "";
      if (bookedProperties.seats)
        Object.keys(bookedProperties.seats).forEach(table => {
          if (tables != "") tables += "  ";
          tables += '<span class="i_table_51">' + table + '-</span><span class="i_chair_49"></span><span>' + bookedProperties.seats[table] + "</span>";
        });
      bookingDetails.find("#comment").html(bookedProperties.comment);
    }
    bookingDetails.find("#tables").html(tables);
  } else if (thereAreOrders && localStorage.selectNewOrders == "true") {
    bookingDetails.addClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#submit-and-accept-new-orders").removeClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#submit-and-accept-and-print-new-orders").removeClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#submit-and-accept-and-print-new-orders-choice").removeClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#submit-and-accept-and-print-new-orders-choice2").addClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#submit-and-accept-and-print-new-orders-drinks").addClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#submit-and-accept-and-print-new-orders-meals").addClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#close").addClass("hidden");
  } else {
    bookingDetails.addClass("hidden");
    $("#editTable button#submit-and-accept-new-orders").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-new-orders").addClass("hidden");
    $("#editTable button#close").removeClass("hidden");
    $("#editTable button#submit-and-accept-and-print-new-orders-choice").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-new-orders-choice2").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-new-orders-drinks").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-new-orders-meals").addClass("hidden");
  }

  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#qrcode").removeClass("hidden");
  //$("#editTable >.modal-dialog > .modal-content > .modal-footer button#close").removeClass("hidden");
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#cancel").addClass("hidden");
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#submit-and-accept-and-print-and-pay-new-orders").addClass("hidden");

  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#unlock").attr("disabled", null);
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#lock").attr("disabled", null);
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#qrcode").attr("disabled", null);
  if (typeof localStorage.tableOccupationSelected !== "undefined" && localStorage.tableOccupationSelected != null && localStorage.tableOccupationSelected != "null" && getTableOccupation(localStorage.tableOccupationSelected))
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#takeaway").toggleClass("active", getTableOccupation(localStorage.tableOccupationSelected).takeaway);
  else $("#editTable >.modal-dialog > .modal-content > .modal-footer button#takeaway").toggleClass("active", false);

  $("#editTable .nav.nav-tabs.nav-justified").removeClass("hidden");
  if (booked) {
    $("div#editTable .modal-body > div#container").removeClass("hidden");
    $("#editTable .nav.nav-tabs.nav-justified").addClass("hidden");
    $("button#lock").removeClass("hidden");
    $("button#unlock").addClass("hidden");
    $("button#new-order").addClass("hidden");
    $("button#paying").addClass("hidden");
    $("button#paid").addClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#takeaway").attr("disabled", true);
  } else if (orderListUtil.occupationsSelected.length == 0) {
    $("div#editTable .modal-body > div#container").addClass("hidden");
    $("button#unlock").addClass("hidden");
    $("button#new-order").addClass("hidden");
    $("button#paying").addClass("hidden");
    $("button#paid").addClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#takeaway").attr("disabled", true);
  } else {
    $("div#editTable .modal-body > div#container").removeClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#takeaway").attr("disabled", false);
    if (localStorage.selectNewOrders != "true") {
      $("#editTable >.modal-dialog > .modal-content > .modal-footer button#new-order").removeClass("hidden");
      $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paying").removeClass("hidden");
      $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paid").removeClass("hidden");
      $("#editTable >.modal-dialog > .modal-content > .modal-footer button#unlock").removeClass("hidden");
    } else {
      $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paying").addClass("hidden");
      $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paid").addClass("hidden");
      $("#editTable >.modal-dialog > .modal-content > .modal-footer button#new-order").addClass("hidden");
      $("#editTable >.modal-dialog > .modal-content > .modal-footer button#unlock").addClass("hidden");
    }
  }
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#edit-discount").removeClass("hidden");

  if (booked) {
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#edit-discount").addClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#takeaway").addClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#unbook").removeClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#activatebooking").removeClass("hidden");
  } else {
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#unbook").addClass("hidden");
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#activatebooking").addClass("hidden");
  }

  var table = getTable(localStorage.tableNumberSelected);
  var lockNotPossible = (orderListUtil.occupationsSelected.length > 0 && table.shareable != true && !booked) || localStorage.selectNewOrders == "true";
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#lock").toggleClass("hidden", lockNotPossible);
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#unlock").prop("disabled", orderListUtil.occupationsSelected.length == 0 || localStorage.selectNewOrders == "true");
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#new-order").prop("disabled", orderListUtil.occupationsSelected.length == 0);
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#qrcode").prop("disabled", orderListUtil.occupationsSelected.length == 0);

  if (!hasRole(auth.myStatus.roles, ["admin"]) && auth.myStatus.restaurant_settings["tables-overview"].block_waiter_print_invoice)
    $("button#paying").addClass("hidden");
  if (!hasRole(auth.myStatus.roles, ["admin"]) && auth.myStatus.restaurant_settings["tables-overview"].block_waiter_register_payment)
    $("button#paid").addClass("hidden");

  var tableMateChoicesSet = new SortedSet({ comparator: customerComparator });
  var payingTableMatesSet = new SortedSet({ comparator: customerComparator });
  var thereAreNonPayers = false;
  admin.tableMates.forEach(function (tableMateId) {
    var tableMate = getTableMate(tableMateId);
    if (tableMate.tableOccupationId == Number(localStorage.tableOccupationSelected) && tableMate.tableNumber == Number(localStorage.tableNumberSelected)) {
      var t;
      if (tableMate.payingMateId == 0) t = tableMate;
      else t = getTableMate(tableMate.payingMateId);
      if (t == undefined) return;
      if (SortedSet.find(payingTableMatesSet, t) == null) {
        payingTableMatesSet.insert(t);
      }
      if (getStateIndex(t, "wantsToPay") >= 0) {
        if (SortedSet.find(tableMateChoicesSet, t) == null) tableMateChoicesSet.insert(t);
      } else {
        thereAreNonPayers = true;
      }
    }
  });
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#new-order").attr("disabled", !thereAreNonPayers);
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#edit-discount").attr("disabled", !thereAreNonPayers);
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#takeaway").attr("disabled", !thereAreNonPayers);

  var count = getOpenOrdersCount(payingTableMatesSet);
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paying").prop("disabled", orderListUtil.occupationsSelected.length == 0 || count == 0 || localStorage.selectNewOrders == "true");
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#paid").prop("disabled", orderListUtil.occupationsSelected.length == 0 || count == 0 || (localStorage.selectNewOrders == "true" && !orderListUtil.kioskMode));
  $("#editTable >.modal-dialog > .modal-content > .modal-footer button#unlock").prop("disabled", count > 0);


};

export const show = function (order) {
  if (typeof order.tableNumber !== "undefined") {
    return order.tableNumber == localStorage.tableNumberSelected && order.tableOccupation == localStorage.tableOccupationSelected;
  } else {
    return order.customer.tableNumber == localStorage.tableNumberSelected && order.customer.tableOccupationId == localStorage.tableOccupationSelected;
  }
};


export const filter = function (order) {
  if (!orderListUtil.isFiltered())
    return !order.orderedByCustomer || !(order.orderedByCustomer && order.state === "selected");
  const stickyMates = [];
  $("#userSelector button.active.sticky").each((ind, m) => stickyMates.push(Number(m.id)));
  return (order.tableOccupationId == localStorage.tableOccupationSelected && (!stickyMates.length || stickyMates.indexOf(order.forWhomId) >= 0));
  /*
  if (isSelectedFiltered()) {
    if (localStorage.selectNewOrders == "true") return orderListUtil.statesShown.indexOf(order.state) != -1;
    return order.state == "selected";
  } else {
    return true;
  }
  */
};


export const serverSideMessageHandler = message => {
  if (message.type == "timeout" && message.message == "true") return;
  //console.log(message.message, message);
  if (message.type == "show") {
    const key = message.Printer;
    if (!message.message) {
      $("div.messages span[key = '" + key + "'").remove();
    } else {
      const msg = eval(message.message);
      if ($('div.messages span').filter((ind, span) => $(span).attr("msg") == msg).length === 0)
        $("<span key='" + key + "' msg='" + msg + "'>" + msg + "</span>").appendTo($("div.messages"));
    }
    //messageDialog(local.warning,message );
  } else if (message.type == "phoneringing") {
    auth.phonenumber = message.message;
  } else if (message.type == "phoneend") {
    auth.phonenumber = "";
  } else if (message.type == "refresh") {
    if (message.message === "full refresh needed") {
      recoverFromEventSourceError();
    } else if (message.message == "table changed" || message.message == "table added") {
      if (message.data) {
        update(message.data);
      } else admin.getTableOccupation(message.TableOccupation, updateTableOccupations);
    } else if (message.message == "table occupied" || message.message == "table changed") {
      if (message.data) update(message.data);
      else admin.getTableOccupation(message.TableOccupation, updateTableOccupations);
    } else if (message.message == "restart-printer-server") {
      get('http://localhost:8080/printerService/restart').fail(() => auth.ajaxError = false);
    } else if (message.message == "reboot-printer-server") {
      get('http://localhost:8080/printerService/reboot').fail(() => auth.ajaxError = false);
    } else if (message.message == "table unlocked") {
      //if (message.data) update(message.data);
      deleteTableOccupations(message.TableOccupation);
    } else if (
      message.message == "table mate joined" ||
      message.message == "table mate unjoined" ||
      message.message == "table mate state changed" ||
      message.message == "table mate entered state" ||
      message.message == "table edit" ||
      message.message == "table mate cancelled state"
    ) {
      if (message.data) update(message.data);
      else admin.getTableOccupation(message.TableOccupation, updateTableOccupations);
    } else if (message.message == "order changed" || message.message == "new order" || message.message == "new order selected" || message.message == "order state changed") {
      if (message.message == "new order") playNewAcceptedOrderSound();
      if (message.data) {
        if (message.message === "order state changed" && message.TableOccupation) {
          if (getTableOccupation(message.TableOccupation))
            update(message.data);
        } else {
          update(message.data);
        }
      } else {
        admin.getTableOccupation(message.TableOccupation, data => {
          admin.getOrder(message.TableOccupation, message.Order, function (orders) {
            update({ tableOccupations: data, orders: orders });
            if (orderListUtil.tablesOverview) tableLayout.updateTableOccupations();
          });
        });
      }
    } else if (message.message == "order cancelled") {
      if (message.data) {
        deleteOrders(message.Order, true);
        update(message.data);
      } else {
        deleteOrders(message.Order);
        if (orderListUtil.tablesOverview) tableLayout.updateTableOccupations();
      }
    } else if (message.message == "layout switched") {
      auth.myStatus.restaurant_active_floorplan = message.floorplan;
      if (orderListUtil.tablesOverview) {
        tableLayout.reset();
        tableLayout.initialize();
      }
      $(".floorplan-picker").removeClass("checked");
      $("#" + message.floorplan + ".floorplan-picker").addClass("checked");
    } else if (message.message == "settings changed") {
      Object.keys(message.data).forEach(function (key) {
        auth.myStatus[key] = message.data[key];
      });
      auth.setMyStatus(auth.myStatus);
    } else if (message.message == "layout changed") {
      getMyStatus(function (data) {
        if (orderListUtil.tablesOverview) {
          tableLayout.reset();
          tableLayout.initialize();
          tableLayout.updateTableOccupations();
        }
      });
    } else if (message.message == "menu changed") {
      admin.getActiveMenuItems(function (cats) {
        admin.categories = cats;
        //admin.getMyStatus(function (data) {
        updatePrices(admin.categories);
        updateOrders([]);
        updateRightPane();
        //});
      }, localStorage.language);
    } else if (message.message == "printer status changed") {
      admin.getPrintersStatus(updatePrintersStatus);
    }
  }
};

function reload() {
  ajaxCallStart("processing3");
  processRestaurant();
}

export const processRestaurant = (recovery = false, first = true) => {
  if (orderListUtil.tablesOverview) tableLayout.initialize();

  var tableNumbers = $("#c .eatwithme-tableNumber");
  try {
    tableNumbers.each(function (ind, t) {
      try {
        var tableNumber = $(t).attr("id");
        if (tableNumber.indexOf("-") > 0) tableNumber = tableNumber.split("-")[0];
        var t = getTable(tableNumber);
        var table = $("#" + tableNumber + ".eatwithme-table");
        if (table != null) {
          var table2 = getTable(tableNumber);

          if (table2 == null) {
            return;
          } else {
            var tn = $("tspan#" + tableNumber + ".eatwithme-tableNumber");
            tn.html(t.name != "" ? t.name : "a" + tableNumber);
          }

          if (!table2.isActive == true) {
            $(table).addClass("inactive");
          }
          if (table2.shareable == true) {
            $(table).addClass("shareable");
          }
        }
      } catch (ex) {
        alert(ex);
      }
    });
    ajaxCallStart("processing5");
    try {
      admin.resync([], (data) => {
        //getTableOccupations(data => {
        processTableOccupations({ ...data, first });
      }, recovery);
      orderListUtil.updateHooks();
      topUpdateHooks();
    } catch (ex) {
      alert(ex);
    } finally {
      ajaxCallEnd("processing5");
    }
  } catch (ex) {
    alert(ex);
  } finally {
    ajaxCallEnd("processing3");
  }
}

export const resize = r => {
  //if (typeof chooseMenuItems == "undefined") return;
  if (r) {
    $(".movableBox").each(function (ind, trigger) {
      var key = $(trigger).attr("id") + "-" + localStorage.restaurantSelected + "_box_position-" + Math.round(window.innerWidth / 10) + "x" + Math.round(window.innerHeight / 10);
      var left = localStorage[key + "-left"];
      var top = localStorage[key + "-top"];
      if (left) {
        $("#" + $(trigger).attr("id") + ".movableBox").css("left", (left ? left : 50) + "%");
        $("#" + $(trigger).attr("id") + ".movableBox").css("top", (top ? top : 50) + "%");
      }
    });
  }

  orientationchangehandler();
  tableLayout.resize();
};

function updateRightPane() {
  //$("#drink_menu .selectable").remove();
  //$("#meal_menu .selectable").remove();
  $("#timingSelector").replaceWith($("#timingSelectorTemplate").tmpl(tmplparams()));

  const days = admin_local.days.map((day, ind) => '<div d=' + (ind + 1) + ' class="day hidden" style="grid-column-start:' + (ind + 1) + '">' + day + '</div>').join("");

  const drinks = $('<div id="drink_menu" class="menu container" style="position: relative;">' + days + '<div class="fakeMenuItemCategory drink" onclick="gotoTop()"></div></div>');
  const meals = $('<div id="meal_menu" class="menu container" style="position: relative;">' + days + '<div class="fakeMenuItemCategory drink" onclick="gotoTop()"></div></div>');
  admin.categories.activeMenuCategories.forEach(function (category) {
    if (category.entityType == "DrinkCategory" && category.isTop)
      $("#categoryTemplate")
        .tmpl({ ...category, ...tmplparams() })
        .appendTo(drinks);
  });
  admin.categories.activeMenuCategories.forEach(function (category) {
    if (category.entityType == "MealCategory" && category.isTop)
      $("#categoryTemplate")
        .tmpl({ ...category, ...tmplparams() })
        .appendTo(meals);
  });
  const selectedCategories = [];
  $(".selectable.tab-pane.menu.active .menuItemCategory.selected2").each((ind, t) => {
    selectedCategories.push(t.id)
  });
  replaceContent($("#drink_menu"), drinks);
  replaceContent($("#meal_menu"), meals);
  $(".selectable.tab-pane.menu.active").removeClass('categorySelected');
  selectedCategories.forEach(selectedCategory => {
    showMenuItemCategory(selectedCategory);
  });

  $(".menuItemHead").each((ind, e) => e.addEventListener("touchstart", touchStart, {
    capture: true,
    passive: false
  }));
  $(".list-group-item.defaultQuantity").each((ind, e) => e.addEventListener("touchstart", touchStart, {
    capture: true,
    passive: false
  }));

  if (isAdmin(auth.myStatus.roles)) {
    chooseMenuItems.attacheContextMenus();
  }
}

export const processTableOccupations = ({ activeMenuCategories, orders, tableOccupations, first = true }) => {
  const def = $.Deferred();
  orderListUtil.tableOccupations = tableOccupations;
  ajaxCallStart("processing6");
  //admin.resync([], ({ activeMenuCategories, orders }) => {
  try {
    admin.categories = { activeMenuCategories: activeMenuCategories };
    updatePrices(admin.categories);
    if (first)
      updateRightPane();
    ajaxCallStart("processing7");
    try {
      //admin.getUnprocessedOrders([], function (orders) {
      orderListUtil.ordersCache = orders;

      orderListUtil.newOrders.forEach(o => orderListUtil.ordersCache.push(o));

      orderListUtil.ordersCache.sort((a, b) => { return a.id - b.id });

      ajaxCallStart("processing8");
      try {
        updateTableMates()
          .done(function () {
            try {
              updatePayingMates();
              orderListUtil.processAllOrders(orders);
              if (orderListUtil.tablesOverview) tableLayout.updateTableOccupations();
              if ($("#editTable").length > 0 && $("#editTable").css("display") != "none") {
                orderListUtil.processOrders(orderListUtil.ordersCache, undefined, first);
                if (typeof localStorage.selectNewOrders != "undefined" && localStorage.selectNewOrders == "true") {
                  if (first)
                    selectNewOrders();
                  var tab = $('span[href="#' + localStorage.atTheTableSelectedTabRight + '"].nav-link');
                  if (tab.length > 0 || isVisible(tab)) {
                    tab.tab("show");
                  }
                  tablesOverview.atTheTableSelectedTab = localStorage.atTheTableSelectedTabRight;
                }
                updateOrders([]);
              } else {
                if (localStorage.tableNumberSelected != undefined && localStorage.tableNumberSelected != 0 && typeof localStorage._editTable != "undefined") {
                  updateTableMates().done(function () {
                    showEditTable();
                    orderListUtil.processOrders(orderListUtil.ordersCache);
                    if (orderListUtil.tablesOverview) tableLayout.updateTableOccupations();
                  });
                }
              }
              startThisPoll();
              def.resolve();
            } finally {
              ajaxCallEnd("processing8");
            }
          })
          .fail(function (data) {
            console.log(data);
          });
      } finally {
        ajaxCallEnd("processing7");
      }
      //});
    } finally {
    }
  } finally {
    ajaxCallEnd("processing6");
  }
  //}, localStorage.language);
  return def.promise();
};

export const updateTableOccupationsAndSelect = (data, continueToNewOrders) => {
  selectTableOccupation(data.tableOccupation ? data.tableOccupation.id : data.tableOccupations ? data.tableOccupations[0].id : data[0].id);
  updateTableOccupations(data.tableOccupation ? [data.tableOccupation] : data.tableOccupations ? data.tableOccupations : data).done(function () {
    selectTableOccupation(data.tableOccupation ? data.tableOccupation.id : data.tableOccupations ? data.tableOccupations[0].id : data[0].id);
    if (continueToNewOrders == true || (typeof pre_selected_action != "undefined" && tableLayout.pre_selected_action == "pre-lock-order")) selectNewOrders();
  });
  selectTableOccupation(data.tableOccupation ? data.tableOccupation.id : data.tableOccupations ? data.tableOccupations[0].id : data[0].id);
};

export const stopIntervals = () => {
  clearInterval(updateCycle);
  clearInterval(lockCycle);
  document.removeEventListener("resume", recoverFromEventSourceError);

};

function makeElement(tag, attrs) {
  var el = document.createElementNS("http://www.w3.org/1999/xhtml", tag);
  for (var k in attrs) el.setAttribute(k, attrs[k]);
  return el;
}

var dummyf = function () { };

export const resetPreSelection = () => {
  var tables = $(".eatwithme-table.selected").removeClass("selected");
  var tables = $("g.eatwithme-table")
    .parent()
    .parent();
  var deselectedClass = "faded";
  tables.removeClass(deselectedClass);
  $("line.table-connections").removeClass(deselectedClass);
};

var timeoutCount = 0;
var topUpdateHooksExecuted = false;

export const topUpdateHooks = () => {
  var $editTable = $("#editTable > .modal-dialog > .modal-content > .modal-body");

  if (admin.tableMates) {
    var mates = [];
    admin.tableMates.forEach(tableMateId => {
      var tableMate = getTableMate(tableMateId);
      if (tableMate.active == true && tableMate.tableOccupationId == localStorage.tableOccupationSelected && tableMate.tableNumber == localStorage.tableNumberSelected) {
        mates.push(tableMate);
      }
    });
    const stickyMates = $('#userSelector button.active.sticky');
    const stickyIds = [];
    stickyMates.each((ind, m) => stickyIds.push(m.id));
    if (mates.length > 1) $("#userSelector").replaceWith($("#userSelectorTemplate").tmpl({ tableMates: mates, getTableMate: getTableMate, local_name: I18n.t("local.name") }));
    else $("#userSelector").empty();
    stickyIds.forEach(id => {
      const b = $("#userSelector button#" + id);
      b.addClass("active");
      b.addClass("sticky");
      b.find('input').prop('checked', true);
    })
  }
  const now = new Date().getTime();
  topUpdateHooks1();
  topUpdateHooks2();
  topUpdateHooks3();
  topUpdateHooks4();
  topUpdateHooks5();
  topUpdateHooks6($editTable);
  topUpdateHooks7($editTable);
  topUpdateHooks8($editTable);
  topUpdateHooks9();
  topUpdateHooks10();
  topUpdateHooks11();
  topUpdateHooks12();
  topUpdateHooks13();
  //topUpdateHooks14();
  topUpdateHooks15();
  topUpdateHooks16();
  topUpdateHooks17();
  //console.log("topUpdateHooks2", new Date().getTime() - now);
};

function topUpdateHooks1() {
  // orderListUtil.updateHooks();

  $('span[data-toggle="tab"]').unbind("click", dummyf);
  $('span[data-toggle="tab"]').click(dummyf);

  $(".scrollable-y").scroll(function () {
    auth.scrollEventTimestamp = new Date().getTime();
  });
}
function topUpdateHooks2() {
  if ($("button.pre-selected-action").data("hookok") == true) return;

  $("button.pre-selected-action").bind("change", function (event) {
    timeoutCount++;
    var timeoutCount2 = timeoutCount;
    setTimeout(function () {
      if (timeoutCount != timeoutCount2) return;
      $(event.currentTarget).removeClass("active");
      var tables = $(".eatwithme-table.selected").removeClass("selected");
      resetPreSelection();
    }, 3000);
    var tables = $(".eatwithme-table.selected").removeClass("selected");
    if ($(event.currentTarget).hasClass("active")) {
      resetPreSelection();
      return;
    }
    var id = $(event.currentTarget).attr("id");
    var tables = $("g.eatwithme-table")
      .parent()
      .parent();
    var deselectedClass = "faded";
    tables.addClass(deselectedClass);
    switch (id) {
      case "pre-lock":
        var tables = $('g.eatwithme-table:not(.inactive):not(".occupied")');
        tables.parent().parent();
        tables
          .parent()
          .parent()
          .removeClass(deselectedClass);
        tables.addClass("selected");
        var tables = $("g.eatwithme-table.shareable:not(.inactive)");
        tables
          .parent()
          .parent()
          .removeClass(deselectedClass);
        tables.addClass("selected");
        break;
      case "pre-lock-order":
        var tables = $('g.eatwithme-table:not(.inactive):not(".occupied")');
        tables
          .parent()
          .parent()
          .removeClass(deselectedClass);
        tables.addClass("selected");
        var tables = $('g.eatwithme-table.occupied:not(.inactive):not(".shareable"):not(".wantsToPay")');
        tables
          .parent()
          .parent()
          .removeClass(deselectedClass);
        tables.addClass("selected");
        var tables = $('g.eatwithme-table.occupied.shareable:not(.inactive):not(".multiple-occupation"):not(".wantsToPay")');
        tables
          .parent()
          .parent()
          .removeClass(deselectedClass);
        tables.addClass("selected");
        break;
      case "pre-paying":
        var tables = $('g.eatwithme-table.occupied.unpaid:not(.inactive):not(".shareable"):not(".wantsToPay")');
        tables
          .parent()
          .parent()
          .removeClass(deselectedClass);
        tables.addClass("selected");
        var tables = $('g.eatwithme-table.occupied.unpaid.shareable:not(.inactive):not(".wantsToPay"):not(".multiple-occupation")');
        tables
          .parent()
          .parent()
          .removeClass(deselectedClass);
        tables.addClass("selected");
        break;
      case "pre-no-paying":
        var tables = $('g.eatwithme-table.occupied.unpaid.wantsToPay:not(.inactive):not(".shareable")');
        tables
          .parent()
          .parent()
          .removeClass(deselectedClass);
        tables.addClass("selected");
        var tables = $('g.eatwithme-table.occupied.unpaid.wantsToPay.shareable:not(.inactive):not(".multiple-occupation")');
        tables
          .parent()
          .parent()
          .removeClass(deselectedClass);
        tables.addClass("selected");
        break;
      case "pre-paid":
        var tables = $('g.eatwithme-table.occupied.unpaid:not(.inactive):not(".shareable")');
        tables
          .parent()
          .parent()
          .removeClass(deselectedClass);
        tables.addClass("selected");
        var tables = $('g.eatwithme-table.occupied.unpaid.shareable:not(.inactive):not(".multiple-occupation")');
        tables
          .parent()
          .parent()
          .removeClass(deselectedClass);
        tables.addClass("selected");
        break;
      case "pre-unlock":
        var tables = $('g.eatwithme-table.occupied.paid:not(.inactive):not(".shareable")');
        tables
          .parent()
          .parent()
          .removeClass(deselectedClass);
        tables.addClass("selected");
        var tables = $('g.eatwithme-table.occupied.paid.shareble:not(.inactive):not(".multiple-occupation")');
        tables
          .parent()
          .parent()
          .removeClass(deselectedClass);
        tables.addClass("selected");
        var tables = $('g.eatwithme-table.occupied:not(.inactive):not(".shareable"):not(".paid"):not(".unpaid")');
        tables
          .parent()
          .parent()
          .removeClass(deselectedClass);
        tables.addClass("selected");
        var tables = $('g.eatwithme-table.occupied.shareable:not(.inactive):not(".multiple-occupation"):not(".paid"):not(".unpaid")');
        tables
          .parent()
          .parent()
          .removeClass(deselectedClass);
        tables.addClass("selected");
        break;
      default:
        break;
    }
  });
  $("button.pre-selected-action").data("hookok", true);
}
function topUpdateHooks3() {
  $('span[data-toggle="tab"]').unbind("shown.bs.tab");
  $('span[data-toggle="tab"]').on("shown.bs.tab", function (e) {
    if (typeof orderListUtil.doNotSelect != "undefined") {
      orderListUtil.doNotSelect = undefined;
    } else {
      var target = $(e.target).attr("href"); // activated tab
      localStorage.atTheTableSelectedTab = target.substring(1);
      if (localStorage.atTheTableSelectedTab == "drink_menu_tab" || localStorage.atTheTableSelectedTab == "meal_menu_tab") {
        localStorage.atTheTableSelectedTabRight = localStorage.atTheTableSelectedTab;
      } else {
        localStorage.atTheTableSelectedTabLeft = localStorage.atTheTableSelectedTab;
      }
      select($(".tab-pane#" + localStorage.atTheTableSelectedTab));
      orderListUtil.selected = undefined;
      updateLeftTabs();
      gotoTop();
    }
  });
}
function topUpdateHooks4() {
  $(".movableBox.draggable").draggable({
    scroll: false,
    delay: 300,
    start: function (trigger, ui) {
      return trigger.target == trigger.toElement;
    },
    stop: function (trigger) {
      var y =
        (100 *
          $(trigger.target)
            .css("top")
            .split("px")[0]) /
        window.innerHeight;
      var x =
        (100 *
          $(trigger.target)
            .css("left")
            .split("px")[0]) /
        window.innerWidth;
      var key = $(trigger.target).attr("id") + "-" + localStorage.restaurantSelected + "_box_position-" + Math.round(window.innerWidth / 10) + "x" + Math.round(window.innerHeight / 10);
      localStorage[key + "-left"] = x;
      localStorage[key + "-top"] = y;
      $(trigger.target).css("left", x + "%");
      $(trigger.target).css("top", y + "%");
    }
  });
}
function topUpdateHooks5() {
  $("#editTable .menuItem.draggable").draggable({
    scroll: true,
    delay: 300,
    revert: function () {
      return tablesOverview.dropFailed;
    },
    zIndex: 1000,
    stack: ".customer-droppable",
    start: function (trigger) {
      $(this).data("startingScrollTop", $($(this).parents(".scrollable-y")[0]).scrollTop());
      $(".bs-tooltip-top").hide();
      var order = getMenuItem($(this).attr("id"));

      var hiddenElements = $("li.customer-droppable.main").find("li.customer-droppable.ui-droppable.hidden3");
      hiddenElements.addClass("toBeHidden");
      var hiddenElements = $("li.customer-droppable.main").find("li.customer-droppable.ui-droppable");
      hiddenElements.addClass("toBeShown");
      hiddenElements.addClass("hidden3");

      var hiddenElements = $("ul#customersByTiming");
      hiddenElements.addClass("toBeShown");
      hiddenElements.addClass("hidden");

      orderListUtil.drag = true;
      tablesOverview.dropFailed = true;
    },
    drag: function (trigger, ui) {
      var st = parseInt($(this).data("startingScrollTop"));
      ui.position.top -= $($(this).parents(".scrollable-y")[0]).scrollTop() - st;
    },
    stop: function () {
      $(".ui-droppable").css("z-index", "unset");
      var hiddenElements = $("li.customer-droppable.main").find("li.customer-droppable.ui-droppable.toBeShown");
      hiddenElements.removeClass("hidden3");
      hiddenElements.removeClass("shown");
      hiddenElements.removeClass("toBeShown");
      var hiddenElements = $("li.customer-droppable.main").find("li.customer-droppable.ui-droppable.toBeHidden");
      hiddenElements.addClass("hidden3");
      hiddenElements.removeClass("shown");
      hiddenElements.removeClass("toBeShown");

      var hiddenElements = $("ul#customersByTiming.toBeShown");
      hiddenElements.removeClass("toBeShown");
      hiddenElements.removeClass("hidden");

      tablesOverview.drCustomerId = undefined;
      orderListUtil.drag = false;
    }
  });
}

function topUpdateHooks6Start(trigger) {
  $(".tooltip.show.bs-tooltip-bottom").remove();
  var scrollingParent = $($(this).parents(".scrollable-y")[0]);
  $(this).data("startingScrollTop", scrollingParent.scrollTop());
  orderListUtil.drag = true;
  tablesOverview.dropFailed = true;
  select($(trigger.currentTarget));
  this.customerDroppables = [];
  var d = this.customerDroppables;
  var ind = 0;
  var mateId = $(trigger.target).attr("customerId");

  var rect = scrollingParent.get(0).getBoundingClientRect();
  var t = $("#" + mateId + ".selectable.customer.customer-droppable.list-group-item.ui-droppable.main");
  var rectt = trigger.target.getBoundingClientRect();

  var quickDrop = $(
    '<div class="quick-drop" style="display:flex;flex-wrapwrap;position:absolute;z-index:200;left:' +
    (rectt.left - rect.left) +
    "px" +
    ";top:calc(" +
    (rectt.top + rectt.height - rect.top + scrollingParent.scrollTop()) +
    'px - 6rem);"/>'
  );
  quickDrop.appendTo(scrollingParent);
  this.quickDrop = quickDrop;

  getTableOccupation(localStorage.tableOccupationSelected).tableMates.forEach(function (mate) {
    if (mate.id == mateId || mate.active === false) return;

    var paying = mate.payingMateId == mate.id;

    var person = $('<button id="' + mate.id + '" class="customer quick-drop btn badge badge-person"+>' + String.format(local.unknown_person, mate.seqNr) + "</button>");
    person.appendTo(quickDrop);
    d.push(person);
    ind++;
  });
  $(d).droppable({
    scroll: true,
    accept: function (dropElem) {
      if (!isVisible($(this))) return false;
      if ($(dropElem).hasClass("customer")) return false;
      var orderCustomer = $(dropElem).attr("customerId");
      var dropCustomer = $(this).attr("id");
      return orderCustomer != dropCustomer;
    },
    classes: {
      "ui-droppable-hover": "ui-state-hover2",
      "ui-droppable-active": "ui-state-active2"
    },
    greedy: true,
    over: function (dropElem) {
      if (typeof tablesOverview.drCustomerId != "undefined" && tablesOverview.drCustomerId == $(this).attr("id")) return;
      overCustomerDroppable = true;
    },
    out: function (dropElem) {
      overCustomerDroppable = false;
    },
    tolerance: "pointer",
    drop: function (event, ui) {
      overCustomerDroppable = false;
      var orderIds = $(ui.draggable).attr("orderIds");
      var customer = $(this).attr("id");
      var dropCustomerTiming = $(ui.draggable).attr("timing");
      moveOrdersToCustomer(orderIds, customer, orderListUtil.statesShown, dropCustomerTiming, function (data) {
        updateTableOccupations([data.tableOccupation]);
        if (orderListUtil.tablesOverview) tableLayout.updateTableOccupations();
        updateOrders(data.orders);
      });
      tablesOverview.dropFailed = false;
    }
  });
}
function topUpdateHooks6Drag(trigger, ui) {
  trigger.stopPropagation();
  var scrollTop = $($(this).parents(".scrollable-y")[0]).scrollTop();
  var st = parseInt($(this).data("startingScrollTop"));
  ui.position.top -= scrollTop - st;
  var startX = this.startX;
  var startY = this.startY;
  if (Math.abs(trigger.clientX - startX) > 30 && !this.quickDrop) {
    this.customerDroppables.forEach(function (person) {
      $(person).css("visibility", "visible");
    });
  }
  $(".bs-tooltip-top").hide();
}
function topUpdateHooks6Stop(trigger, ui) {
  $(".ui-droppable").css("z-index", "");
  $(".ui-draggable").css("z-index", "");
  if (this.quickDrop) this.quickDrop.remove();
  this.h = false;
  $("#byCustomer.tab-pane .customer[level=2] ul").removeClass("hidden");
  orderListUtil.drag = false;
}

function topUpdateHooks6(o) {
  if (o.find("#byCustomer.tab-pane.active").length === 0)
    return;
  o.find("#byCustomer.tab-pane .byCustomer.draggable").draggable({
    scroll: true,
    delay: 30,
    revert: function () {
      return true;
    },
    stack: ".ui-droppable",
    start: topUpdateHooks6Start,
    drag: topUpdateHooks6Drag,
    stop: topUpdateHooks6Stop
  });
}
function topUpdateHooks7(o) {
  if (o.find("#byCustomer.tab-pane.active").length === 0)
    return;
  o.find("#byCustomer.tab-pane .customer.draggable").draggable({
    scroll: true,
    delay: 300,
    revert: function () {
      return tablesOverview.dropFailed;
    },
    accept: function (dropElement) {
      if (!isVisible($(this))) return false;

      return $(dropElement).hasClass("customer") && $(dropElement).attr("level") == 2;
    },
    stack: ".customer,.quick-drop",
    startX: 0,
    startY: 0,
    h: false,
    top: 0,
    start: function (trigger) {
      var scrollingParent = $($(this).parents(".scrollable-y")[0]);
      $(this).data("startingScrollTop", scrollingParent.scrollTop());
      // $(this).data("startingScrollTop",$(this).parent().scrollTop());
      this.startX = trigger.clientX;
      this.startY = trigger.clientY;
      select($(trigger.currentTarget));
      orderListUtil.drag = true;
      tablesOverview.dropFailed = true;

      this.customerDroppables = [];
      var d = this.customerDroppables;
      var ind = 0;
      var mateId = $(trigger.target).attr("id");
      if (mateId.startsWith("payer")) mateId = mateId.substring(5);
      if (mateId.startsWith("tableMate")) mateId = mateId.substring(9);
      var self = getTableMate(mateId);
      var payingMateId = self.payingMateId;
      var rect = scrollingParent.get(0).getBoundingClientRect();
      var t = $("#" + mateId + ".selectable.customer.customer-droppable.list-group-item.ui-droppable.main");
      var rectt = trigger.target.getBoundingClientRect();
      var quickDrop = $(
        '<div class="quick-drop" style="display:flex;flex-wrap:wrap;position:absolute;z-index:200;left:' +
        (rectt.left + rectt.width - rect.left) +
        "px" +
        ";top:" +
        (rectt.top + rectt.height - rect.top + scrollingParent.scrollTop()) + 'px;"/>'
      );
      quickDrop.appendTo(scrollingParent);
      this.quickDrop = quickDrop;

      if (payingMateId != mateId) {
        var person = $('<button id="' + mateId + '" class="customer quick-drop btn badge badge-person"+>' + admin_local.pay_himself + "</button>");
        person.appendTo(quickDrop);
        d.push(person);
        ind++;
      }

      const mates = [];

      getTableOccupation(localStorage.tableOccupationSelected).tableMates.forEach(function (mate) {
        if (mate.id == mateId || mate.active === false) return;
        if (mates.indexOf(mate.id) !== -1) return;
        mates.push(mate.id);

        var paying = mate.payingMateId == mate.id;

        if (!paying) return;
        if (payingMateId == mate.payingMateId) return;
        if (mate.tableMate != null)
          var person = $(
            '<button style="" id="' + mate.id + '" class="customer quick-drop btn badge badge-person"+>' + String.format(admin_local.unknown_person_pays, mate.seqNr) + " (" + mate.tableMate.name + ")</button>"
          );
        else if (mate.name)
          var person = $(
            '<button style="" id="' + mate.id + '" class="customer quick-drop btn badge badge-person"+>' + String.format(admin_local.unknown_person_pays, mate.seqNr) + " (" + mate.name + ")</button>"
          );
        else var person = $('<button style="" id="' + mate.id + '" class="customer quick-drop btn badge badge-person"+>' + String.format(admin_local.unknown_person_pays, mate.seqNr) + "</button>");
        person.appendTo(quickDrop);
        d.push(person);
        ind++;
      });
      var person = $('<div style="width:100%;flex-grow:1"/>');
      person.appendTo(quickDrop);
      d.push(person);
      var person = $('<button style="margin-top:1rem;" id="change-table" class="customer quick-drop btn badge badge-person"+>' + admin_local.change_table + "</button>");
      person.appendTo(quickDrop);
      d.push(person);
      ind++;
      $(d).droppable({
        scroll: true,
        accept: function (dropElem) {
          if (!isVisible($(this))) return false;
          if (!$(dropElem).hasClass("customer")) return false;
          return true;
        },
        classes: {
          "ui-droppable-hover": "ui-state-hover2",
          "ui-droppable-active": "ui-state-active2"
        },
        greedy: true,
        over: function (dropElem) {
          overCustomerDroppable = true;
          if (typeof tablesOverview.drCustomerId != "undefined" && tablesOverview.drCustomerId == $(this).attr("id")) return;
        },
        out: function (dropElem) {
          overCustomerDroppable = false;
        },
        tolerance: "pointer",
        drop: function (event, ui) {
          overCustomerDroppable = false;
          var id = $(ui.draggable).attr("id");
          var payer = false;
          if (id.startsWith("payer")) {
            id = id.substring(5);
            payer = true;
          }
          if (id.startsWith("tableMate")) id = id.substring(9);
          var payerMateId = $(this).attr("id");
          if (payerMateId == "change-table") {
            tablePicker(function (event) {
              var mate = getTableMate(id);
              var tableId = $(event.currentTarget).attr("id");
              if (tableId == localStorage.tableNumberSelected) {
                messageDialog(local.message, admin_local.target_table_same_and_source);
                return;
              }
              var tableMatesPaid = admin.getTableMatesPaidBy(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, id);
              var tableOccupationTables = getTableOccupationTablesForTable(tableId);
              if (tableOccupationTables.length == 0) {
                // this is an empty table
                choiceDialog(local.confirmation, String.format(admin_local.confirm_person_change_to_empty_table, mate.seqNr, tableId), admin_local.new_occupation, admin_local.extend_occupation).done(function (data) {
                  if (data == 0) {
                    // new occupation
                    admin.createNewTableOccupationForMate(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, id, tableId, payer, function (data) {
                      update(data);
                    });
                  } else {
                    // add to existing occupation
                    admin.changeTableForMate(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, id, tableId, payer, update);
                  }
                });
              } else if (tableOccupationTables.length == 1) {
                // this is an table with existing occupation
                if (getTable(tableOccupationTables[0].restaurantTable.number).shareable) {
                  choiceDialog2(local.confirmation, String.format(admin_local.confirm_person_change_to_shared_table, mate.seqNr, tableId), [
                    admin_local.new_independent_occupation,
                    admin_local.joins,
                    admin_local.extend_occupation
                  ]).done(function (data) {
                    switch (data.number) {
                      case 0:
                        // new occupation
                        admin.createNewTableOccupationForMate(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, id, tableId, payer, update);
                        break;
                      case 1:
                        // joins occupation
                        admin.changeTableOccupationForMate(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, id, tableOccupationTables[0].tableOccupationId, tableId, payer, update);
                        break;
                      case 2:
                        // add to existing occupation
                        admin.changeTableForMate(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, id, tableId, payer, update);
                        break;
                    }
                  });
                } else {
                  choiceDialog2(local.confirmation, String.format(admin_local.confirm_person_change_to_table, mate.seqNr, tableId), [admin_local.yes]).done(function (data) {
                    // joins occupation
                    admin.changeTableOccupationForMate(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, id, tableOccupationTables[0].tableOccupationId, tableId, payer, update);
                  });
                }
              } else {
                var choices = [];
                choices.push(admin_local.new_independent_occupation);
                choices.push(admin_local.extend_occupation);
                tableOccupationTables.forEach(function (table, ind) {
                  var tableOccupation = getTableOccupation(table.tableOccupationId);
                  if (ind == 0) choices.push(String.format(admin_local.joins_table1, tableOccupation.personCount));
                  else choices.push(String.format(admin_local.joins_table, ind + 1, tableOccupation.personCount));
                });
                choiceDialog2(local.confirmation, String.format(admin_local.confirm_person_change_to_shared_table, mate.seqNr, tableId), choices).done(function (data) {
                  switch (data) {
                    case 0:
                      // new occupation
                      admin.createNewTableOccupationForMate(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, id, tableId, payer, update);
                      break;
                    case 1:
                      // add to existing occupation
                      admin.changeTableForMate(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, id, tableId, null, payer, update);
                      break;
                    default:
                      // joins occupation
                      admin.changeTableOccupationForMate(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, id, tableOccupationTables[data - 2].tableOccupationId, tableId, payer, update);
                      break;
                  }
                });
              }
            });
            return;
          } else {
            admin.payForTableMate(id, payerMateId, function (data) {
              /*
              getTableMate(id).payingMateId = payerMateId;
              var tableOccupation = getTableOccupation(localStorage.tableOccupationSelected);
              tableOccupation.tableMates.forEach(function (mate) {
                if (mate.id == id) mate.payingMateId = payerMateId;
              });
              */
              update(data);
              /*
              if (orderListUtil.tablesOverview) tableLayout.updateTableOccupations();
              updateOrders([]);
              */
            });
          }
          tablesOverview.dropFailed = false;
        }
      });
    },
    drag: function (trigger, ui) {
      var scrollTop = $($(this).parents(".scrollable-y")[0]).scrollTop();
      var st = parseInt($(this).data("startingScrollTop"));
      ui.position.top -= scrollTop - st;

      var startX = this.startX;
      var startY = this.startY;
      if (Math.abs(trigger.clientX - startX) > 30 && !this.quickDrop) {
        this.customerDroppables.forEach(function (person) {
          $(person).css("visibility", "visible");
        });
      }
      $(".bs-tooltip-top").hide();
    },
    stop: function () {
      $(".ui-droppable").css("z-index", "unset");
      if (this.quickDrop) this.quickDrop.remove();
      $("#byCustomer.tab-pane .customer[level=2] ul").removeClass("hidden");
      this.h = false;
      $("#byCustomer.tab-pane .customer[level=2] ul").removeClass("hidden");
      $(this).css("transform", "");
      orderListUtil.drag = false;
    }
  });
}
function topUpdateHooks8(o) {
  if (o.find("#byTiming.tab-pane.active").length === 0)
    return;
  o.find("#byTiming.tab-pane .byTiming.draggable").draggable({
    scroll: true,
    delay: 300,
    revert: function () {
      return tablesOverview.dropFailed;
    },
    stack: ".timing-droppable",
    start: function (trigger) {
      $(this).data("startingScrollTop", $($(this).parents(".scrollable-y")[0]).scrollTop());
      this.startX = trigger.clientX;
      this.startY = trigger.clientY;
      select($(trigger.currentTarget));

      $(".bs-tooltip-top").hide();

      orderListUtil.drag = true;
      tablesOverview.dropFailed = true;
    },
    top: 0,
    h: false,
    hiddenItems: null,
    drag: function (trigger, ui) {
      $(".bs-tooltip-top").hide();
    },
    stop: function () {
      this.h = false;
      $(this).css("transform", "");
      if (this.hiddenItems != null) this.hiddenItems.removeClass("hidden");
      orderListUtil.drag = false;
    }
  });
}
function topUpdateHooks9() {
  $("#editTable .byMenuItem.draggable").draggable({
    scroll: true,
    delay: 300,
    revert: "invalid",
    zIndex: 10000,
    stack: ".orderTrash,.menuitem-droppable"
  });
}
function topUpdateHooks10() {
  var droppableHandler;
  $("#editTable .customer-droppable.timing").droppable(
    (droppableHandler = {
      accept: function (dropElem) {
        if (!isVisible($(this))) return false;

        if ($(dropElem).hasClass("menuItem")) return true;

        if ($(dropElem).hasClass("customer")) return false;
        var orderIds = $(dropElem).attr("orderIds");
        var r = true;
        var t = this;
        if (!orderIds)
          return false;
        orderIds.split(",").forEach(function (orderId) {
          var order = getOrderElement(orderId);
          if (!order)
            return;
          var customerId = $(dropElem).attr("customerId");
          var orderTiming = order.timing;
          var dropCustomerId = $(t).attr("customerId");
          var dropCustomerTiming = $(t).attr("timing");
          var r2 = Number(customerId) == Number(dropCustomerId);
          if (r2) {
            if (typeof orderTiming != "undefined") {
              r2 = orderTiming != dropCustomerTiming;
            }
          }
          r = r && r2;
        });
        return r;
      },
      greedy: true,
      tolerance: "pointer",
      classes: {
        "ui-droppable-hover": "ui-state-hover2",
        "ui-droppable-active": "ui-state-active2"
      },
      drop: function (event, ui) {
        if ($(ui.draggable).hasClass("menuItem")) {
          orderMenuItem($(ui.draggable).attr("entityType"), $(ui.draggable).attr("menuItem"), $(ui.draggable).attr("quantity"), true, $(this).attr("id"), $(this).attr("timing"));
          return;
        }
        var orderIds = $(ui.draggable).attr("orderIds");
        var customer = $(this).attr("customerId");
        var dropCustomerTiming = $(this).attr("timing");
        moveOrdersToCustomer(orderIds, customer, orderListUtil.statesShown, dropCustomerTiming, function (data) {
          updateTableOccupations([data.tableOccupation]);
          if (orderListUtil.tablesOverview) tableLayout.updateTableOccupations();
          updateOrders(data.orders);
        });
        tablesOverview.dropFailed = false;
      }
    })
  );
}
function topUpdateHooks11() {
  $("#editTable .byCustomer.droppable").droppable({
    greedy: true,
    over: function (dropElem) { }
  });
}

var overCustomerDroppable = false;
function topUpdateHooks12() {
  var droppableHandler;
  const customers = $("#editTable .customer-droppable.main");
  customers.each((ind, customer) => $(customer).droppable(
    (droppableHandler = {
      scroll: true,
      accept: function (dropElem) {
        if (overCustomerDroppable) return false;
        if (!isVisible($(this))) return false;
        if ($(dropElem).hasClass("customer")) return false;
        var orderCustomer = $(dropElem).attr("customerId");
        var dropCustomer = $(this).attr("id");
        return orderCustomer != dropCustomer;
      },
      classes: {
        "ui-droppable-hover": "ui-state-hover2",
        "ui-droppable-active": "ui-state-active2"
      },
      greedy: true,
      over: function (dropElem) {
        if (typeof tablesOverview.drCustomerId != "undefined" && tablesOverview.drCustomerId == $(this).attr("id")) return;
      },
      out: function (dropElem) { },
      tolerance: "pointer",
      drop: function (event, ui) {
        if (!isVisible($(this))) return false;
        var orderIds = $(ui.draggable).attr("orderIds");
        var customer = $(this).attr("id");
        var dropCustomerTiming = $(ui.draggable).attr("timing");
        moveOrdersToCustomer(orderIds, customer, orderListUtil.statesShown, dropCustomerTiming, function (data) {
          updateTableOccupations([data.tableOccupation], data.orders);
          // tableLayout.updateTableOccupations();
          // updateOrders(data.orders);
        });
        tablesOverview.dropFailed = false;
      }
    })
  ));
}
function topUpdateHooks13() {
  $("#editTable .timing-droppable").droppable({
    accept: function (dropElem) {
      if (!isVisible($(this))) return false;
      var orderTiming = $(dropElem).attr("timing");
      var dropTiming = $(this).attr("id");
      var state = $(dropElem).attr("state");
      if (state == "ordered" || state == "selected" || state == "accepted") return orderTiming != dropTiming;
      return false;
    },
    tolerance: "pointer",
    classes: {
      "ui-droppable-hover": "ui-state-hover2",
      "ui-droppable-active": "ui-state-active2"
    },
    drop: function (event, ui) {
      if ($(ui.draggable).hasClass("menuItem")) {
        orderMenuItem($(ui.draggable).attr("entityType"), $(ui.draggable).attr("menuItem"), $(ui.draggable).attr("quantity"), true, "", $(this).attr("id"));
        return;
      }
      var orderIds = $(ui.draggable).attr("orderIds");
      var customer = $(ui.draggable).attr("customerId");
      var dropCustomerTiming = $(this).attr("id");
      moveOrdersToCustomer(orderIds, customer, orderListUtil.statesShown, dropCustomerTiming, function (data) {
        updateTableOccupations([data.tableOccupation], data.orders);
        // tableLayout.updateTableOccupations();
        // updateOrders(data.orders);
      });
      tablesOverview.dropFailed = false;
    }
  });
}
/*
function topUpdateHooks14() {
  $("#editTable .menuItem-droppable").droppable({
    accept: function(dropElem) {
      if (!isVisible($(this))) return false;
      var customerId =
        $(dropElem)
          .parent()
          .attr("menuItemId") +
        ":" +
        $(dropElem)
          .parent()
          .attr("quantity");
      var dropCustomerId = $(this).attr("menuItemId") + ":" + $(this).attr("quantity");
      return customerId != dropCustomerId;
    },
    tolerance: "pointer",
    classes: {
      "ui-droppable-hover": "ui-state-hover2",
      "ui-droppable-active": "ui-state-active2"
    },
    drop: function(event, ui) {
      var orderId = $(ui.draggable).attr("order");
      var order = getOrderElement(orderId);
      var menuItem = $(this).attr("menuItemId");
      var quantity = $(this).attr("quantity");
      moveOrderToMenuItemAndQuantity(order, menuItem, quantity, orderListUtil.statesShown);
      tablesOverview.dropFailed = false;
    }
  });
}
*/
function topUpdateHooks15() {
  if (!topUpdateHooksExecuted)
    $("#editTable #orderTrash").droppable({
      tolerance: "pointer",
      greedy: true,
      classes: {
        "ui-droppable-hover": "ui-state-hover2",
        "ui-droppable-active": "ui-state-active2"
      },
      accept: function (dropElem, event) {
        if (!isVisible($(this))) return false;
        if (dropElem.hasClass("not-deletable")) return false;
        var r = dropElem.hasClass("customer") || dropElem.hasClass("byCustomer") || dropElem.hasClass("byTiming");
        if (r) {
          var orderIds = dropElem.attr("orderIds");
          if (orderIds != undefined) {
            var ok = true;
            orderIds.split(",").forEach(function (orderId) {
              var order = orderListUtil.getOrderById(orderId);
              if (order.paid) {
                ok = false;
                return;
              }
              var mate = getTableMate(getTableMate(order.forWhomId).payingMateId);
              if (mateInState(mate, "wantsToPay")) ok = false;
            });
            return ok;
          }
        }
        return r;
      },
      drop: function (event, ui) {
        event.stopPropagation();
        event.preventDefault();
        if ($(ui.draggable).hasClass("customer")) {
          var tableMateId = $(ui.draggable).attr("id");
          if (tableMateId.startsWith("payer")) tableMateId = tableMateId.substring(5);
          if (tableMateId.startsWith("tableMate")) tableMateId = tableMateId.substring(9);
          admin.unjoinTableMate(tableMateId, function (data) {
            update(data);
          });
          tablesOverview.dropFailed = true;
        } else {
          var order = $(ui.draggable).attr("orderIds") ? $(ui.draggable).attr("orderIds") : $(ui.draggable).attr("orders");
          cancelOrder(order, orderListUtil.statesShown, function (data) {
            if (data == null) {
              console.log("Failed to cancel orders");
            } else {
              deleteOrders(data.deletedOrders, true);
              updateTableOccupations([data.tableOccupation], data.orders);
              // tableLayout.updateTableOccupations();
              // updateOrders(data.orders);
            }
          });
          tablesOverview.dropFailed = true;
        }
      }
    });
}
function topUpdateHooks16() {
  //if (typeof scrollPosision != "undefined") $(".scrollable").scrollTop(scrollPosision);
}
function topUpdateHooks17() {
  // updateToolTips();
  // $('[data-toggle="tooltip"]').tooltip();

  topUpdateHooksExecuted = true;
}

function getOrderData(id) {
  var o = null;
  orderListUtil.ordersCache.forEach(function (order) {
    if (order.id == id) {
      o = order;
    }
  });
  return o;
}

export const hideEditTable = force => {
  const pickupTableNumber = getPickupTableNumber();
  const homeDeliveryTableNumber = getHomeDeliveryTableNumber();

  if (!$("#tableOccupation-selector-map").hasClass('hidden')) {
    showMap(false);
    return;
  }

  if (!orderListUtil.occupationsSelected || (orderListUtil.occupationsSelected.filter(o => o.occupationStart).length < 2 && localStorage.tableNumberSelected != pickupTableNumber && localStorage.tableNumberSelected != homeDeliveryTableNumber) || $('#tableOccupation-selector').hasClass('selectTableOccupationTile') || $('#tableOccupation-selector').hasClass('selectTableOccupation') || !$("#right_pane").hasClass("hidden")) {
    hideEditTable2(force)
  } else {
    orderListUtil.ai.setContext({ tableOccupation: null });
    $("#orderList").empty();
    $("ul#orderListByCustomer").empty();
    $("ul#orderListByTiming").empty();
    if (localStorage.tableNumberSelected == pickupTableNumber || localStorage.tableNumberSelected == homeDeliveryTableNumber) {
      $('#tableOccupation-selector').addClass('selectTableOccupation');
      $('#editTable').addClass('selectTableOccupation');
    } else {
      $('#tableOccupation-selector').addClass('selectTableOccupationTile');
      $('#editTable').addClass('selectTableOccupationTile');
    }
  }
}

export const hideEditTable2 = force => {
  showMap(false);
  if (typeof force == "undefined") force = false;
  if (typeof localStorage._editTable == "undefined") return;
  if (!isTop($("#editTable"))) return;
  if (!$("#right_pane").hasClass("hidden")) {
    if (force) {
      hideSelectNewOrders();
    } else {
      var thereAreOrders = false;
      if (typeof orderListUtil.orders != "undefined" && typeof orderListUtil.orders.tables != "undefined")
        orderListUtil.ordersCache.forEach(function (order) {
          if (localStorage.tableOccupationSelected == order.tableOccupationId && localStorage.tableNumberSelected == order.tableNumber && order.state === "selected") {
            thereAreOrders = true;
          }
        });

      if (thereAreOrders) {
        choiceDialog2(local.confirmation, local.do_you_really_want_to_quit_pending_orders, [admin_local.suspend_ordering]).done(function () {
          hideSelectNewOrders();
        });
      } else {
        hideSelectNewOrders();
      }
      if (!orderListUtil.kioskMode) {
        return;
      }
    }
  }

  //processAllOrders(orderListUtil.ordersCache);
  $("#editTable").addClass("hidden");
  $("#main-floorplan").removeClass("hidden");
  $("#editTable").removeClass("selectTableOccupation");
  $("#editTable").removeClass("selectTableOccupationTime");
  orderListUtil.ai.setContext({ tableOccupation: null });
  if (!orderListUtil.kioskMode) $("#timingSelector button.timing.active").removeClass("active");
  $("#orderList").empty();
  $("ul#orderListByCustomer").empty();
  $("ul#orderListByTiming").empty();
  if (localStorage._editTable == "true" && localStorage.tableOccupationSelected) {
    get("adminService/" + localStorage.restaurantSelected + "/tableEditEnd/" + localStorage.tableOccupationSelected + "/" + localStorage.tableNumberSelected, undefined, undefined, undefined, false).done(data => update(data)).fail(() => { auth.ajaxError = false });
  }
  delete localStorage._editTable;
  if (orderListUtil.tablesOverview) tableLayout.updateTableOccupations();
};

export const selectTableOccupation = id => {
  if (!id) {
    console.error("This is not good", "selectTableOccupation");
  }
  selectTableOccupationSelected(id);
  var selector = $("#tableOccupation-selector");
  selector.removeClass("selectTableOccupation");
  $('#editTable').removeClass("selectTableOccupation");
  selector.removeClass("selectTableOccupationTile");
  $('#editTable').removeClass("selectTableOccupationTile");

  var id = localStorage.drinkbar_id;
  var details = "true" == localStorage.drinkbar_details;
  var detailWasSelected = localStorage.drinkbar_detailWasSelected;
  var orderId = localStorage.drinkbar_orderId;

  if (orderListUtil.isFiltered()) preProcessOrders(orderListUtil.ordersCache);

  orderListUtil.detailsMode = false;
  var o = orderListUtil.isFiltered() ? orderListUtil.orders : orderListUtil.allOrders;
  updateOrderList(true);

  updateOrders([]);

  setSelected(id, detailWasSelected, orderId);
  if (details) {
    showDetails(true);
    if (detailWasSelected == "detailrow") {
      var r = $("tr#" + id + ".detailrow.nothidden");
      r.each(function (ind, row) {
        if ($(row).attr("orderId") == orderId) {
          _show($(row));
        }
      });
    }
  }
  orderListUtil.updateOrdersDone();

  //updateHeadings();
  //updateFooterButtons();
};

export const clickHandler222 = trigger => {
  resetLockTimeout();
  if (trigger.isDefaultPrevented()) return;
  if ((trigger.type = "focusin")) return;
  if (select(trigger.currentTarget)) trigger.preventDefault();
};

export const markTableMateForPayment = (pay, nomodal, print, paymentMethod) => {

  var modal = $("#markTableMateForPaymentDialog");


  pay = typeof pay == "undefined" ? true : pay;
  nomodal = typeof nomodal == "undefined" ? false : nomodal;
  print = typeof print == "undefined" ? false : print;

  if (orderListUtil.drag) return;

  modal.empty();
  var tableMateChoicesSet = new SortedSet({ comparator: customerComparator });
  var allTableMatesSet = new SortedSet({ comparator: customerComparator });
  var wasPaymentRequest = false;
  admin.tableMates.forEach(function (tableMateId) {
    let tableMate = getTableMate(tableMateId);
    if ((localStorage.tableOccupationSelected == "null" || tableMate.tableOccupationId == localStorage.tableOccupationSelected) && tableMate.tableNumber == localStorage.tableNumberSelected) {
      if (SortedSet.find(allTableMatesSet, tableMate) == null) allTableMatesSet.insert(tableMate);
      var t;
      if (tableMate.payingMateId == null) t = tableMate;
      else t = getTableMate(tableMate.payingMateId);
      if (mateInState(tableMate, "payingOnline")) return;
      t.preselected = mateInState(tableMate, "wantsToPay");
      t.selected = mateInState(tableMate, "paymentRequest");
      wasPaymentRequest = wasPaymentRequest || mateInState(tableMate, "paymentRequest");
      if (SortedSet.find(tableMateChoicesSet, t) == null) tableMateChoicesSet.insert($.extend({}, t));
    }
  });
  var tableMateChoices = [];
  if (tableMateChoices.length > 1) {
    nomodal = false;
  }
  tableMateChoicesSet.forEach(function (v) {
    if (nomodal && !wasPaymentRequest) v.preselected = pay;
    var count = getOrdersToPay(v.id).length;
    var amount = Math.round(getAmountToPay(v.id));
    //var roundFunction = auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"];
    //amount = eval(roundFunction);
    if (count > 0) {
      v.amountToPay = amount;
      tableMateChoices.push(v);
    }
  });

  if (orderListUtil.kioskMode && tableMateChoices.length > 0) {
    admin.getInvoiceXML(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, tableMateChoices[0].id, localStorage.language, 1, "", "", function (data) {
      if (data == '{"success":"true"}') {
        tableMatePaid();
        return;
      } else {
        printHtml(data, admin.getPrinterForPrinterAction("printInvoice").id);
        tableMatePaid();
        return;
      }
    });
    return;
  }

  var allTableMates = [];
  allTableMatesSet.forEach(function (v) {
    allTableMates.push(v);
  });

  if (tableMateChoices.length == 1 && !nomodal) if (!tableMateChoices[0].preselected) tableMateChoices[0].selected = true;

  const tableOccupation = getTableOccupation(localStorage.tableOccupationSelected);

  const payingMates = tableOccupation.tableMates.filter(t => (t.active || t.isActive) && (t.payingMateId === null || t.payingMateId === t.id));
  if (payingMates.length === 1 && getStateIndex(payingMates[0], "wantsToPay") >= 0) {
    tableMateChoices[0].selected = false;
    _markTableMateForPayment(modal, tableMateChoices, paymentMethod, (data) => {
      messageDialog(I18n.t("local.information"), I18n.t("admin_local.ordering_allowed"));
      updateAndCloseModal(data);
    });
    return;
  }

  $("#markTableMateForPaymentTemplate")
    .tmpl({
      tableMates: tableMateChoices,
      ...tmplparams()
    })
    .appendTo(modal);
  var modal = $("#markTableMateForPayment");

  function clickHandler12321(trigger) {
    if (trigger.isDefaultPrevented()) return;
    if (select(trigger.currentTarget)) trigger.preventDefault();
  }

  modal.on("hidden.bs.modal", function () { });

  $("#markTableMateForPayment .selectable").off("click", clickHandler12321);
  $("#markTableMateForPayment .selectable").on("click", clickHandler12321);

  select($("#markTableMateForPayment .selectable[level=2]").get(0));

  $(modal.find("input#service")).focusin(function () {
    if ($(this).val() == 0) $(this).val("");
    $(this).select();
  });
  $(modal.find("input#service")).focusout(function () {
    if ($(this).val() == "") $(this).val("0");
  });

  $("#markTableMateForPayment li.mate.list-group-item").on("click", e => {
    $(e.currentTarget).removeClass("preselected");
  });

  updateCheckBoxesById(modal.find(".mate.list-group-item"));
  if (!nomodal) {
    // updateToolTips();
    modal.modal("show", {
      keyboard: false
    });

    modal.data("tableMates", tableMateChoices);

    checkPrint();
    modal.find(".mate.list-group-item").change(checkPrint);

    $("button#select").click(function (event) {
      avoidMultipleClick(this, event);
      _markTableMateForPayment(modal, tableMateChoices, paymentMethod, updateAndCloseModal);
    });
    $("button#print").click(function (event) {
      avoidMultipleClick(this, event);
      if (event.isDefaultPrevented()) return;
      var language = $(this)
        .find("div#language.btn")
        .text();
      _markTableMateForPayment(modal, tableMateChoices, paymentMethod, function (data) {
        if (data.error) {
          messageDialog(I18n.t("local.error_message"), I18n.t(data.error)).done(r => recoverFromEventSourceError(false));
          return;
        }
        updateAndCloseModal(data);
        tableMateChoices.forEach(function (payingMate) {
          if (getStateIndex(payingMate, "wantsToPay") == -1 || payingMate.preselected) return;
          //for (let i = 0; i < modal.find('#copy').html(); i++) {
          admin.getInvoiceXML(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, payingMate.id, language, modal.find('#copy').html(), "", "", function (data) {
            if (data == '{"success":"true"}') { return }
            for (let i = 0; i < modal.find('#copy').html(); i++)
              printHtml(data, admin.getPrinterForPrinterAction("printInvoice").id)
            return;
          });
          //}
        });
      });
    });
    $("button#splitInvoice").click(function (event) {
      if (tableMateChoices.length == 1) {
        var t = modal.find(".mate.list-group-item.active");
        t.removeClass("active");
        t.removeClass("list-group-item-primary");
      }

      var clickHandler;

      _markTableMateForPayment(modal, tableMateChoices, paymentMethod, data => {
        update(data);
        avoidMultipleClick(this, event);
        modal.modal("hide");
        $("#editTable").addClass("hidden");
        $("#main-floorplan").removeClass("hidden");
        //delete localStorage._editTable;
        $("div.splitInvoice.modal").remove();
        if (event.isDefaultPrevented()) return;
        var language = $(this)
          .find("button#language")
          .text();

        var o = _preProcessOrders(orderListUtil.ordersCache, orderListUtil.statesShown, function (order) {
          return order.tableOccupationId == localStorage.tableOccupationSelected;
        });

        var splitInvoiceModal = $("script#splitInvoice").tmpl({ ...o, ...tmplparams() }).appendTo("body");
        var s = splitInvoiceModal.find(".selectable");
        s.off("click", clickHandler123);
        s.on("click", clickHandler123);

        var draggableHandler;
        $(".modal.splitInvoice .byCustomer.draggable").draggable(
          (draggableHandler = {
            scroll: false,
            delay: 30,
            revert: function () {
              return tablesOverview.dropFailed;
            },
            stack: ".modal.splitInvoice .customer-droppable.main",
            start: function (trigger) {
              tablesOverview.dropFailed = true;
            },
            drag: function (trigger, ui) { },
            stop: function () { }
          })
        );
        $(".modal.splitInvoice .byCustomer.draggable").on("dblclick", (clickHandler = (e) => {
          var customer = $("#rightSide .tab-pane.container.active li.customer").attr("id");
          var sourceCustomer = $(e.currentTarget).parents("li.customer").attr("id");
          if (customer === sourceCustomer) {
            customer = $("#leftSide .tab-pane.container.active li.customer").attr("id");
          }
          var orderIds = $(e.currentTarget).attr("orderIds");
          var dropCustomerTiming = $(e.currentTarget).attr("timing");

          e.stopPropagation();

          if (customer)

            moveOrdersToCustomer(
              orderIds,
              customer,
              orderListUtil.statesShown,
              dropCustomerTiming,
              function (data) {
                update(data);

                updateModal(null, sourcePayingCustomer, targetPayingCustomer)
              }, () => updateModal(null, sourcePayingCustomer, targetPayingCustomer));
        }));
        var droppableHandler;
        $(".modal.splitInvoice .customer-droppable.main").droppable(
          (droppableHandler = {
            scroll: false,
            accept: function (dropElem) {
              if ($(dropElem).hasClass("customer")) return false;
              if (!$(dropElem).hasClass("byCustomer")) return false;
              var orderCustomer = $(dropElem).attr("customerId");
              var dropCustomer = $(this).attr("id");
              return orderCustomer != dropCustomer;
            },
            classes: {
              "ui-droppable-hover": "ui-state-hover2",
              "ui-droppable-active": "ui-state-active2"
            },
            greedy: true,
            over: function (dropElem) {
              if (typeof tablesOverview.drCustomerId != "undefined" && tablesOverview.drCustomerId == $(this).attr("id")) return;
            },
            out: function (dropElem) { },
            tolerance: "pointer",
            drop: function (event, ui) {
              var orderIds = $(ui.draggable).attr("orderIds");
              var customer = $(this).attr("id");
              var dropCustomerTiming = $(ui.draggable).attr("timing");
              tablesOverview.dropFailed = false;

              var sourceLi = $(ui.draggable)
                .parents("li[level = '2']")
                .parent()
                .parent();
              sourcePayingCustomer = sourceLi.attr("id").substring(1);

              var targetLi = $(this);
              targetPayingCustomer = targetLi
                .parent()
                .parent()
                .attr("id")
                .substring(1);

              moveOrdersToCustomer(
                orderIds,
                customer,
                orderListUtil.statesShown,
                dropCustomerTiming,
                function (data) {
                  update(data);

                  updateModal(ui, sourcePayingCustomer, targetPayingCustomer)
                }, () => updateModal(ui, sourcePayingCustomer, targetPayingCustomer));
            }
          })
        );

        var sourcePayingCustomer, targetPayingCustomer;

        function updateModal(ui, sourcePayingCustomer, targetPayingCustomer) {
          var o = _preProcessOrders(orderListUtil.ordersCache, orderListUtil.statesShown, function (order) {
            return order.tableOccupationId == localStorage.tableOccupationSelected && order.paid == false;
          });
          o.ordersByPayer.forEach(ordersByCustomer => {
            if (((!sourcePayingCustomer && !targetPayingCustomer) || ordersByCustomer.customer.id == sourcePayingCustomer || ordersByCustomer.customer.id == targetPayingCustomer) && ordersByCustomer.tableOccupation == localStorage.tableOccupationSelected) {
              orderListUtil.customerCountByPayer = ordersByCustomer.ordersByCustomer.length;
              orderListUtil.payingCustomer = ordersByCustomer.customer.id;
              var r = splitInvoiceModal.find("div#leftSide.tab-content .tab-pane.container#l" + ordersByCustomer.customer.id + " > div");
              r.find("> li").remove();
              ordersByCustomer.ordersByCustomer.forEach(data => {
                var newli = $("script#ordersByCustomerNoEditTemplate")
                  .tmpl({ ...data, ...tmplparams() })
                  .appendTo(r);
              });
              var r = splitInvoiceModal.find("div#rightSide.tab-content .tab-pane.container#r" + ordersByCustomer.customer.id + " > div");
              r.find("> li").remove();
              ordersByCustomer.ordersByCustomer.forEach(data => {
                var newli = $("script#ordersByCustomerNoEditTemplate")
                  .tmpl({ ...data, ...tmplparams() })
                  .appendTo(r);
              });
            }
          });
          if (ui)
            $(ui.draggable).remove();
          $(".modal.splitInvoice .byCustomer.draggable").draggable(draggableHandler);
          $(".modal.splitInvoice .byCustomer.draggable").on("dblclick", clickHandler);
          $(".modal.splitInvoice .customer-droppable.main").droppable(droppableHandler);
          var s = splitInvoiceModal.find(".selectable");
          s.unbind("click", clickHandler123);
          s.click(clickHandler123);
        }


        splitInvoiceModal.on("hidden.bs.modal", function () {
          showEditTable();
          setBigTableNumber();
          localStorage._editTable = true;
          markTableMateForPayment();
          $("div.splitInvoice.modal").remove();
        });
        splitInvoiceModal.on("shown.bs.modal", function () {
          $(splitInvoiceModal.find("div#leftSide.tab-pane .nav-item:not(.disabled) .nav-link").get(0)).click();

          splitInvoiceModal.find("button#print").click(event => {
            avoidMultipleClick(this, event);
            var language = $(this)
              .find("div#language.btn.dropdown-toggle")
              .text();
            var payingMate_id = splitInvoiceModal.find("div#rightSide.tab-pane .nav-link.active").attr("id");
            if (typeof payingMate_id != "undefined")
              admin.getInvoiceXML(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, payingMate_id, language, 1, "", "", function (data) {
                if (data == '{"success":"true"}') { }
                else printHtml(data, admin.getPrinterForPrinterAction("printInvoice").id);
              });
          });
          splitInvoiceModal.find("button#edit-discount").click(event => {
            if (isDuplicateClickEvent(event)) return;
            avoidMultipleClick(this, event);
            var payingMate_id = splitInvoiceModal.find("div#rightSide.tab-pane .nav-link.active").attr("id");
            editDiscounts(Number(payingMate_id), updateModal);
          })
          splitInvoiceModal.find("button#paid").click(event => {
            avoidMultipleClick(this, event);
            var payingMate_id = splitInvoiceModal.find("div#rightSide.tab-pane .nav-link.active").attr("id");
            if (typeof payingMate_id !== "undefined")
              tableMatePaid(payingMate_id, undefined, false, function (data) {
                update(data);
                var o = _preProcessOrders(orderListUtil.ordersCache, orderListUtil.statesShown, function (order) {
                  return order.tableOccupationId == localStorage.tableOccupationSelected && order.paid == false;
                });
                o.ordersByPayer.forEach(ordersByCustomer => {
                  if (ordersByCustomer.customer.id == payingMate_id && ordersByCustomer.tableOccupation == localStorage.tableOccupationSelected) {
                    orderListUtil.customerCountByPayer = ordersByCustomer.ordersByCustomer.length;
                    orderListUtil.payingCustomer = ordersByCustomer.customer.id;
                    var r = splitInvoiceModal.find("div#leftSide.tab-content .tab-pane.container#l" + payingMate_id + " > div");
                    r.find("> li").remove();
                    ordersByCustomer.ordersByCustomer.forEach(data => {
                      var newli = $("script#ordersByCustomerTemplate")
                        .tmpl({ ...data, ...tmplparams() })
                        .appendTo(r);
                    });
                    var r = splitInvoiceModal.find("div#rightSide.tab-content .tab-pane.container#r" + payingMate_id).remove();
                    var r = splitInvoiceModal
                      .find("div#rightSide.tab-pane > ul > li > span#" + payingMate_id)
                      .parent()
                      .remove();
                  }
                });
                $(".modal.splitInvoice .byCustomer.draggable").draggable(draggableHandler);
                $(".modal.splitInvoice .customer-droppable.main").droppable(droppableHandler);
                var s = splitInvoiceModal.find(".selectable");
                s.off("click", clickHandler123);
                s.on("click", clickHandler123);
              });
          });
          splitInvoiceModal.find("button#incrementPayer").click(event => {
            admin.incrementTableMates(0, function (data) {
              update(data);
              var ids = [];
              var existingOnes = $("#rightSide.tab-pane > ul > li > span");
              if (existingOnes.length > 0)
                existingOnes.each((ind, item) => {
                  ids.push($(item).attr("id"));
                });
              data.tableOccupation.tableMates.forEach(mate => {
                if (ids.indexOf("" + mate.id) == -1) {
                  var o = _preProcessOrders(orderListUtil.ordersCache, orderListUtil.statesShown, function (order) {
                    return order.tableOccupationId == localStorage.tableOccupationSelected;
                  });
                  o.ordersByPayer.forEach(ordersByCustomer => {

                    if (
                      ordersByCustomer.customer.id == mate.id &&
                      ordersByCustomer.tableOccupation == localStorage.tableOccupationSelected &&
                      (ordersByCustomer.count == 0 || ordersByCustomer.stateMap.unpaid > 0) &&
                      !mateInState(ordersByCustomer.customer, "wantsToPay")
                    ) {
                      splitInvoiceModal.find("#rightSide.tab-content").append($("script#splitInvoiceRightTabContent").tmpl({ ...ordersByCustomer, ...tmplparams() }));
                    }
                  });
                  o.ordersByPayer.forEach(ordersByCustomer => {
                    if (
                      ordersByCustomer.customer.id == mate.id &&
                      ordersByCustomer.tableOccupation == localStorage.tableOccupationSelected &&
                      (ordersByCustomer.count == 0 || ordersByCustomer.stateMap.unpaid > 0) &&
                      !mateInState(ordersByCustomer.customer, "wantsToPay")
                    ) {
                      var n;
                      splitInvoiceModal.find("#rightSide.tab-pane > ul").append((n = $("script#splitInvoiceRightNav").tmpl({ customer: mate, ...tmplparams() })));
                      $(n)
                        .find(".nav-link")
                        .click();
                    }
                  });
                  $(".modal.splitInvoice .byCustomer.draggable").draggable(draggableHandler);
                  $(".modal.splitInvoice .customer-droppable.main").droppable(droppableHandler);
                }
              });
            });
          });


        });
        splitInvoiceModal.modal("show");
      });
    });
  } else {
    if (!print) {
      _markTableMateForPayment(modal, tableMateChoices, paymentMethod, orderListUtil.edit);
    } else {
      _markTableMateForPayment(modal, tableMateChoices, paymentMethod, function (data) {
        updateAndCloseModal(data);
        tableMateChoices.forEach(function (payingMate) {
          if (getStateIndex(payingMate, "wantsToPay") == -1) return;
          admin.getInvoiceXML(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, payingMate.id, localStorage.language, 1, "", "", function (data) {
            if (data == '{"success":"true"}') { }
            else printHtml(data, admin.getPrinterForPrinterAction("printInvoice").id);
          });
        });
      });
    }
  }
};

export const checkPrint = () => {
  var p = $("#markTableMateForPaymentDialog #print");
  var ok = false;
  $("#markTableMateForPaymentDialog .list-group-item").each((ind, t) => {
    //var t = modal.find("#" + mate.id + ".list-group-item.active");
    if ($(t).hasClass("active")) {
      if ($(t).hasClass("preselected")) {
        return;
      }
      ok |= true;
    }
  });
  p.prop("disabled", !ok);
};

export const printQRCode = (embedded = false) => {
  var qrcode = $("<div/>");
  var url = "";
  var text = [];
  if (auth.myStatus.restaurant_settings["tables-overview"]["table-qrcode"] === "join") {
    // eslint-disable-next-line no-restricted-globals
    url = "https://restaurant.eatwithme.online" + (location.port == 3000 ? ":8444" : "") + "/map.html?r=" +
      auth.myStatus.restaurant_global_id +
      "&t=" +
      localStorage.tableNumberSelected +
      "&m=" +
      getTableOccupation(localStorage.tableOccupationSelected).tableMates[0].id +
      "&q=" +
      getTableOccupation(localStorage.tableOccupationSelected).qrToken +
      "&a=m";
    _qrcode(qrcode, url, String.format(admin_local.scan_to_join_table, localStorage.tableNumberSelected), 200);
    auth.myStatus.restaurant_languages.forEach(function (lang) {
      var l = admin_locals[lang];
      if (l.scan_to_pay_table)
        String.format(l.scan_to_join_table, getTableName(localStorage.tableNumberSelected))
          .split("\r\n")
          .forEach(function (t) {
            text.push(t);
          });
    });
  } else if (auth.myStatus.restaurant_settings["tables-overview"]["table-qrcode"] === "pay-only") {
    var tableMateChoicesSet = new SortedSet({ comparator: customerComparator });
    var allTableMatesSet = new SortedSet({ comparator: customerComparator });
    admin.tableMates.forEach(function (tableMate) {
      if (tableMate.tableOccupationId == localStorage.tableOccupationSelected && tableMate.tableNumber == localStorage.tableNumberSelected) {
        if (SortedSet.find(allTableMatesSet, tableMate) == null) allTableMatesSet.insert(tableMate);
        var t;
        if (tableMate.payingMateId == null) t = tableMate;
        else t = getTableMate(tableMate.payingMateId);
        if (SortedSet.find(tableMateChoicesSet, t) == null) tableMateChoicesSet.insert($.extend({}, t));
      }
    });
    const tableMateChoices = [];
    tableMateChoicesSet.forEach(function (v) {
      tableMateChoices.push(v);
    });
    if (tableMateChoices.length == 1) {
      // eslint-disable-next-line no-restricted-globals
      url = "https://restaurant.eatwithme.online" + (location.port == 3000 ? ":8444" : "") + "/tableMatePaying.html?t=" + getTableOccupation(localStorage.tableOccupationSelected).qrToken + "&m=" + tableMateChoices[0].id;
      _qrcode(qrcode, url, "Scan to pay table " + getTableName(localStorage.tableNumberSelected), 200);
    }
    auth.myStatus.restaurant_languages.split(",").forEach(function (lang) {
      var l = admin_locals[lang];
      if (l.scan_to_pay_table)
        String.format(l.scan_to_pay_table, localStorage.tableNumberSelected)
          .split("\r\n")
          .forEach(function (t) {
            text.push(t);
          });
    });
  }
  var qrcode = $(qrcode).get(0).innerHTML;
  qrcode = qrcode.replace(/"><\/div>/g, 'color:transparent">.</div>');
  var data = { top: { text: text, qrcode: qrcode } };
  var x2js = new X2JS();
  var xmlAsStr = '<?xml version="1.0" encoding="utf-8"?>' + x2js.js2xml(data);
  var encodedUrl = encodeURIComponent(url);

  var printer = getLocalPrinterSettings().printerActions.pa_printTableSlip ? getPrinter(getLocalPrinterSettings().printerActions.pa_printTableSlip).name : auth.myStatus.restaurant_printer_actions.printTableSlip.name;
  var format = getLocalPrinterSettings().printerActions.pa_printTableSlip ? getPrinter(getLocalPrinterSettings().printerActions.pa_printTableSlip).format : auth.myStatus.restaurant_printer_actions.printTableSlip.format;

  if (embedded)
    return {
      reportName: "jointable",
      format: "html",
      lang: localStorage.language,
      query: "__parameterPage=false&url=" + encodedUrl + "&target=" + format,
      message: "join-table-" + localStorage.tableOccupationSelected + "-" + new Date().getTime(),
      xml: xmlAsStr,
      printer: printer
    }

  printPdf(
    "jointable",
    "html",
    localStorage.language,
    "__parameterPage=false&url=" + encodedUrl + "&target=" + format,
    "join-table-" + localStorage.tableOccupationSelected + "-" + new Date().getTime(),
    xmlAsStr,
    printer
  );
};

function updatePaymentMethodsForPayment(modal) {
  modal.find("div[paymentMethod]").removeClass("hidden");
  const cashregister = $("#cashregisters input:checked").prop("id");
  if (typeof cashregister !== "undefined" && Number(cashregister)) {
    const c = auth.myStatus.restaurant_cash_registers.find(c => c.id === Number(cashregister));
    if (c && c.paymentMethods && c.paymentMethods.length) {
      modal.find("div[paymentMethod]").addClass("hidden");
      c.paymentMethods.forEach(p => {
        modal.find("div[paymentMethod = '" + p.id + "']").removeClass("hidden");
      });
      modal.find("div[paymentMethod].hidden input").val(null);
    }
  }
  checkPayments();
}

export const tableMatePaid = (mateId, date, donthide = false, handler) => {

  if (featureEnabled("ntak") && !tablesOverview.context.state.open) {
    messageDialog(I18n.t("local.error_message"), I18n.t("admin_local.day_must_be_open"));
    return;
  }

  if (auth.myStatus && auth.myStatus.customers && auth.myStatus.customers.length > 1)
    unlock().done(() => __tableMatePaid(mateId, date, donthide, handler));
  else __tableMatePaid(mateId, date, donthide, handler)
}

export const __tableMatePaid = (mateId, date, donthide = false, handler) => {
  if (orderListUtil.drag === true) return;



  var modal = $("#tableMatePaidDialog");
  modal.empty();
  var tableMateChoicesSet = new SortedSet({ comparator: customerComparator });
  var allTableMatesSet = new SortedSet({ comparator: customerComparator });
  var atLeastOneMateWasSelected = false;
  if (typeof mateId == "undefined") {
    admin.tableMates.forEach(function (tableMateId) {
      var tableMate = getTableMate(tableMateId);
      if (tableMate.tableOccupationId == localStorage.tableOccupationSelected && tableMate.tableNumber == localStorage.tableNumberSelected) {
        if (SortedSet.find(allTableMatesSet, tableMate) == null) allTableMatesSet.insert(tableMate);
        var t;
        if (tableMate.payingMateId == null) t = tableMate;
        else t = getTableMate(tableMate.payingMateId);
        if (/* getStateIndex(t, 'wantsToPay')!=-1 && */ SortedSet.find(tableMateChoicesSet, t) == null) {
          t.selected = false;
          t.selected = getStateIndex(t, "wantsToPay") != -1;
          tableMateChoicesSet.insert(t);
          atLeastOneMateWasSelected = atLeastOneMateWasSelected || t.selected;
        }
      }
    });
  } else {
    var tableMate = getTableMate(mateId);
    tableMateChoicesSet.insert(tableMate);
  }
  const tableMateChoices = [];
  var allTableMates = [];
  tableMateChoicesSet.forEach(function (v) {
    var amount = getAmountToPay(v.id, date);
    var count = getOrdersToPay(v.id, date).length;
    var exemptOfServiceFee = getAmountToPayExemptOfServiceFee(v.id, date);
    if (count > 0) {
      v.amountToPay = v.service.indexOf("%") != -1 ? (amount + ((amount - exemptOfServiceFee) * (Number(v.service.split("%")[0]))) / 100) : amount + Number(v.service);
      if (auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"]) {
        //var roundFunction = auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"];
        var amount = v.amountToPay;
        //var roundedAmount = eval(roundFunction);
        v.amountToPay = Math.round(amount);
      }
      if (tableMateChoicesSet.length === 1)
        v.selected = !atLeastOneMateWasSelected || v.selected;
      tableMateChoices.push(v);
    }
  });
  const tableOccupation = getTableOccupation(tableMateChoices[0].tableOccupationId);
  allTableMatesSet.forEach(function (v) {
    allTableMates[allTableMates.length] = v;
  });
  var restaurant_payment_methods = [];
  auth.myStatus.restaurant_payment_methods.forEach(function (paymentMethod) {
    if (!paymentMethod.method.isOnline) restaurant_payment_methods.push(paymentMethod);
  });
  window.setAmount = setAmount;
  window.amountChanged = amountChanged;
  $("#tableMatePaidTemplate")
    .tmpl({
      tableMates: tableMateChoices,
      restaurant_payment_methods: restaurant_payment_methods,
      repay: false,
      preselectedPaymentMethod: tableOccupation?.bookedProperties?.payment_method,
      ...tmplparams()
    })
    .appendTo(modal);
  var modal = $("#tableMatePaid.modal");
  loadMyImage();

  if (!orderListUtil.quickActions)
    if (typeof mateId == "undefined") {
      modal.find("button#save-and-unlock").removeClass("hidden");
    } else {
      //modal.find("button#save-and-unlock").addClass("hidden");
    }

  if (donthide)
    modal.find("button#save-and-unlock").addClass("hidden");

  modal.find("button#close").on("click", (item, event) => {
    //if (item.currentTarget.id === "close") {
    modal.hide();
    $(document.body).removeClass("modal-open");
    $(".modal-backdrop").remove();
    return;
    //}
    /*
    confirmDialog(local.confirmation, admin_local.are_you_sure_you_cancel_the_payment).done(() => {
      modal.hide();
      $(document.body).removeClass("modal-open");
      $(".modal-backdrop").remove();
    });
    */
  });

  var clickHandler123321 = function (trigger) {
    if (trigger.isDefaultPrevented()) return;
    if (select(trigger.currentTarget)) trigger.preventDefault();
  };

  $("#tableMatePaid.modal .selectable").unbind("click", clickHandler123321);
  $("#tableMatePaid.modal .selectable").click(clickHandler123321);
  $("#tableMatePaid.modal .selectable li").change(function (trigger) {
    if ($(this).hasClass("active")) {
      $($(this).find("input")[1]).focus();
      $($(this).find("input")[0]).select();
    }
    checkPayments();
  });

  var payments = $("#tableMatePaid.modal div.amountPaid input");
  payments.keyup(function (trigger) {
    checkPayments();
  });
  payments.change(function (trigger) {
    checkPayments();
  });

  $("#tableMatePaid.modal input[pattern != '']").on('keyup', event => {
    var regexp = new RegExp(event.currentTarget.pattern);
    if (event.currentTarget.value.match(regexp) == null) {
      $(event.currentTarget).addClass("is-invalid");
    } else {
      $(event.currentTarget).removeClass("is-invalid");
    }
    checkPayments();
  });

  modal.on("shown.bs.modal", function () {
    modal.find('[data-toggle="dropdown"]').dropdown();
    var v = $($("#tableMatePaid.modal input")[0]);
    v.focus();
    v.select();
  });

  select($("#tableMatePaid.modal .selectable[level=2]").get(0));

  updateCheckBoxesById(".mate.list-group-item");
  // updateToolTips();

  $("#tableMatePaid.modal .selectable li > input").on('change', trigger => {
    checkPayments();
  });


  modal.modal("show", {
    keyboard: false
  });
  $("#tableMatePaid.modal .vat-details input#name").on('blur', () => {
    setTimeout(() => {
      $("#tableMatePaid.modal form div.typeahead__container.result").removeClass('result')
    }, 100);
  });

  $("#tableMatePaid.modal .vat-details #name").typeahead({
    order: "desc",
    dynamic: true,
    backdrop: true,
    minLength: 3,
    searchOnFocus: true,
    template: (query, item) => {
      return "<div onmousedown='event.preventDefault();event.stopPropagation()' onmouseup='event.preventDefault();event.stopPropagation()' style='background:yellow;z-index:1000000' onclick='$(this).parent().parent().click();event.preventDefault();event.stopPropagation();'>" + item.display + "(" + item.address.postCode + " " + item.address.city + ", " + item.address.address + ")</div>";
    },
    callback: {
      onSubmit: (node, a, item, event) => {
        const p = $(node).parents('.vat-details');
        p.find('#partner_id').val(item.id);
        p.find('#tax_number').val(item.taxcode);
        p.find('#postcode').val(item.address.postCode);
        p.find('#city').val(item.address.city);
        p.find('#address').val(item.address.address);
        p.find('#email').val(item.emails.length ? item.emails[0] : "");
        p.find('#tax_number').prop('disabled', true);
        p.find('#postcode').prop('disabled', true);
        p.find('#city').prop('disabled', true);
        p.find('#address').prop('disabled', true);
        p.find('#email').prop('disabled', true);
        p.find("input[pattern != '']").removeClass("is-invalid");
        if (item.id)
          p.parent().find("#create-vat-partner").addClass("hidden");
        else
          p.parent().find("#create-vat-partner").removeClass("hidden");
        checkPayments();
      },
      onClickAfter: (node, a, item, event) => {
        const p = $(node).parents('.vat-details');
        p.find('#partner_id').val(item.id);
        p.find('#tax_number').val(item.taxcode);
        p.find('#postcode').val(item.address.postCode);
        p.find('#city').val(item.address.city);
        p.find('#address').val(item.address.address);
        p.find('#email').val(item.emails.length ? item.emails[0] : "");
        p.find('#tax_number').prop('disabled', true);
        p.find('#postcode').prop('disabled', true);
        p.find('#city').prop('disabled', true);
        p.find('#address').prop('disabled', true);
        p.find('#email').prop('disabled', item.id !== null);
        p.find("input[pattern != '']").removeClass("is-invalid");
        if (item.id)
          p.parent().find("#create-vat-partner").addClass("hidden");
        else
          p.parent().find("#create-vat-partner").removeClass("hidden");
        checkPayments();
      },
      onCancel: (node, item, event) => {
        const p = $(node).parents('.vat-details');
        p.find('#partner_id').val("");
        p.find('#tax_number').val("");
        p.find('#postcode').val("");
        p.find('#city').val("");
        p.find('#address').val("");
        p.find('#email').val("");
        p.find('#tax_number').prop('disabled', false);
        p.find('#postcode').prop('disabled', false);
        p.find('#city').prop('disabled', false);
        p.find('#address').prop('disabled', false);
        p.find('#email').prop('disabled', false);
        checkPayments();
      }
    },
    source: {
      ajax: (query) => {
        return {
          type: "GET",
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          url: auth.server + "/eatwithme.server/billingo/" + localStorage.restaurantSelected + "/partners/find/" + query,
          //url: "https://app.billingo.hu/ceginfo/search?find=" + query,
          callback: {
            done: function (data) {
              if (data.data)
                return data.data.map(d => { return { ...d, display: d.name } });
              return [];
            }
          }
        }
      }
    }
  });

  var cashRegister = $("#cashregisters input:checked").prop("id");
  cashRegister = cashRegister ? cashRegister : 0;

  modal.find('.i_cash_register input').on('change', event => {
    if (typeof localStorage.cashregister == "undefined")
      localStorage.cashregister = event.currentTarget.id;
    updatePaymentMethodsForPayment(modal);
  });

  modal.find('.i_cash_register label').on('dblclick', event => {

    const cashregister = auth.myStatus.restaurant_cash_registers.find(c => c.id == $(event.currentTarget).find("input").prop("id"));

    if (cashregister) {
      confirmDialog(local.confirmation, String.format(admin_local.default_cashregister_change, cashregister.name)).done(() => {
        localStorage.cashregister = $(event.currentTarget).find("input").prop("id");
        updatePaymentMethodsForPayment(modal);
      })
    }

  });

  updatePaymentMethodsForPayment(modal);


  $("#tableMatePaid.modal #partner-id").on('change', event => {

  })


  $("#tableMatePaid.modal button#save").click(function (event) {
    avoidMultipleClick(this, event);
    avoidMultipleClick($("#tableMatePaid.modal button#save-and-unlock"), event);

    function ff() {
      _tableMatePaid({

        modal, tableMateChoices, tableOccupation: localStorage.tableOccupationSelected, date, handler: function (data) {
          $("#tableMatePaid.modal").modal("hide");
          $("#tableMatePaid.modal").hide();

          const tableOccupation = localStorage.tableOccupationSelected;
          typeof handler != "undefined" ? handler(data) : updateAndCloseModal(data);


          var allDelivered = true;
          data.orders.forEach(order => {
            if (order.state != "delivered") allDelivered = false;
          });

          const tableNumber = Number(localStorage.tableNumberSelected);
          const isTakeaway = tableNumber === auth.myStatus.restaurant_settings["enabled-features"].pickup["table-number"] || tableNumber === auth.myStatus.restaurant_settings["enabled-features"].homedelivery["table-number"];

          if (orderListUtil.kioskMode && !isTakeaway) {
            if (allDelivered && tableOccupation === localStorage.tableOccupationSelected)
              update(
                data,
                unlockTable(tableOccupation, localStorage.tableNumberSelected, false, function (data) {
                  update(data);
                  if (!donthide)
                    window.hideEditTable(false);
                  if (orderListUtil.occupationsSelected.length === 0) {
                    lockTable(localStorage.tableNumberSelected, 1, afterLockTable);
                  }
                })
              );
            else {
              if (orderListUtil.occupationsSelected.length === 0) {
                lockTable(localStorage.tableNumberSelected, 1, afterLockTable);
              }
            }
          } else {
          }
        }
      });
    }

    if ($(event.target).hasClass("btn-danger")) confirmDialog(local.confirmation, admin_local.tip_amount_seems_excesive).done(ff);
    else ff();
  });
  $("#tableMatePaid.modal button#save-and-unlock").click(function (event) {
    avoidMultipleClick(this, event);
    avoidMultipleClick($("#tableMatePaid.modal button#save"), event);
    function ff() {
      _tableMatePaid({
        modal, tableMateChoices, tableOccupation: localStorage.tableOccupationSelected, date, handler: function (data) {
          $("#tableMatePaid.modal").modal("hide");
          $("#tableMatePaid.modal").hide();
          orderListUtil.occupationsSelected = [];

          typeof handler != "undefined" ? handler(data) : updateAndCloseModal(data);

          update(
            data,
            () => {
              unlockTable(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, false, function (data) {
                if (!data.error && $('.modal.splitInvoice.show').length) {
                  delete localStorage._editTable;
                  $('.modal.splitInvoice').addClass("hidden");
                  $('.modal.splitInvoice').removeClass("show");
                }
                update(data);
                const pickupTableNumber = getPickupTableNumber();
                const homeDeliveryTableNumber = getHomeDeliveryTableNumber();
                const isScheduled = localStorage.tableNumberSelected == pickupTableNumber || localStorage.tableNumberSelected == homeDeliveryTableNumber;
                if (orderListUtil.kioskMode && orderListUtil.occupationsSelected.length == 0) {
                  lockTable(localStorage.tableNumberSelected, 1, afterLockTable);
                } else {
                  if (!donthide)
                    window.hideEditTable(false);
                }
              })
            });
        }
      });
    }
    if ($(event.target).hasClass("btn-danger")) confirmDialog(local.confirmation, admin_local.tip_amount_seems_excesive).done(ff);
    else ff();
  });
};

function _markTableMateForPayment(modal, tableMateChoices, paymentMethod, handler) {
  var tableMateChoices2 = [];
  tableMateChoices.forEach(function (mate) {
    var t = modal.find("#" + mate.id + ".list-group-item");
    if (t.hasClass('active')) {
      if (!$(t).hasClass("preselected")) mate.preselected = false;
      mate.service = t.find("input#service").val();
      var stateIndex = getStateIndex(mate, "wantsToPay");
      if (stateIndex == -1) {
        mate.states.push({ state: "wantsToPay", reference: paymentMethod, duration: 0 });
      }
      var stateIndex = getStateIndex(mate, "paymentRequest");
      if (stateIndex != -1) {
        mate.states.splice(stateIndex, 1);
      }
    } else {
      var stateIndex = getStateIndex(mate, "wantsToPay");
      if (stateIndex != -1) {
        mate.states.splice(stateIndex, 1);
      }
    }
    mate = Object.assign({}, mate);
    delete mate.selected;
    delete mate.amountToPay;
    delete mate.tableMate;
    delete mate.state;
    delete mate.count;
    delete mate.preselected;
    delete mate.payable;
    delete mate.paid;
    tableMateChoices2.push(mate);
  });
  post("adminService/" + localStorage.restaurantSelected + "/markTableMateForPayment", tableMateChoices2).done(handler);
}

var dev = 3;
var devPrefix = "/dev/ttyS";
var devs = [
  "/dev/ttyS3",
  "/dev/ttyS7"
]

export const _tableMatePaid = ({ modal, tableMateChoices, tableOccupation, date, orders, handler }) => {

  ////eslint-disable-next-line no-undef
  //if (SerialUSB) {
  //eslint-disable-next-line no-undef
  const serial = window.serial;
  //}

  tableOccupation = tableOccupation ? tableOccupation : localStorage.tableOccupationSelected;
  if (!tableOccupation) {
    console.error("Strange, tableOccupation is null on tableMatePaid", "");
    return;
  }
  var tableMatePayments = tableMateChoices.length == 0 ? [auth.myStatus.tableMate.id] : [];
  var count = 0;

  if (!modal[0].querySelectorAll(".list-group-item.active").length) {
    console.error("Strange, pay pushed but no active customer selected", "");
    console.error("Modal length:" + modal.length, "");
    console.error("Modal list-group-item length:" + modal[0].querySelectorAll(".list-group-item").length, "");
  }


  modal[0].querySelectorAll(".list-group-item.active").forEach(function (t, ind) {
    const invoice = $(t).find('.vat-details-block.show').length === 1;
    const oldPaymentId = tableMateChoices[ind].paymentId;
    var tableMatePayment = {
      tableMate: Number($(t).attr("id")),
      tableOccupation: tableOccupation,
      orders: orders || getOrdersToPay(Number($(t).attr("id")), date),
      payments: [],
      qrcode: invoice ? "" : $(t).attr('qrcode'),
      id: oldPaymentId,
      invoice: invoice,
      print_invoice: $(t).find('.icon-print.active').length >= 1,
      partner: $(t).find('.vat-details input#partner_id').val(),
      tax_number: $(t).find('.vat-details input#tax_number').val(),
      name: $(t).find('.vat-details input#name').val(),
      address: $(t).find('.vat-details input#address').val(),
      postcode: $(t).find('.vat-details input#postcode').val(),
      email: $(t).find('.vat-details input#email').val(),
      city: $(t).find('.vat-details input#city').val(),
    };
    var payments = $(t).find('div.amountPaid:not(".hidden") input');
    var amount = 0;
    var cashRegister = modal.find("#cashregisters label.active input").prop("id");
    cashRegister = cashRegister ? cashRegister : null;

    /*    if (invoice) {
          tableMatePayment.payments.push({
            paymentMethod: 3,
            amount: Number($(t).find(".vat-details-block").attr("amountToPay")),
            currency: auth.myStatus.restaurant_base_currency.id,
            exchangeRate: 1,
            cashRegister: cashRegister,
            terminal: null
          });
        } else*/
    payments.each(function (ind, payment) {
      var paymentMethodButton = $(payment)
        .prevAll("div.input-group-prepend")
        .find("button");
      var paymentCurrencyButton = $(payment)
        .nextAll("div.input-group-append")
        .find("button");
      amount += Number($(payment).val()) * getCurrencyEchangeRate($(paymentCurrencyButton).attr("value"));
      tableMatePayment.payments.push({
        paymentMethod: Number($(paymentMethodButton).attr("value")),
        amount: Number($(payment).val()),
        currency: $(paymentCurrencyButton).attr("value"),
        exchangeRate: $(paymentCurrencyButton).parent().parent().find("input").attr("exchangeRate"),
        cashRegister: invoice ? null : cashRegister,
        terminal: paymentMethodButton.parent().parent().attr("paymentMethodType") === "teya" ? localStorage.teya_terminal : null
      });
    });
    const a = amount;
    if (!(typeof auth.myStatus.restaurant_settings['register-tip'] === "undefined" || auth.myStatus.restaurant_settings['register-tip'])) {
      var done = false;
      tableMatePayment.payments.forEach(payment => {
        const currency = auth.myStatus.restaurant_currencies.find(c => c.currency.id == payment.currency);
        const roundFunction = currency ? currency.currency.javaScriptRoundFunction : auth.myStatus.restaurant_base_currency.javaScriptRoundFunction;
        const exchangeRate = currency ? currency.exchangeRate : 1;
        if (done) return;
        const paymentMethod = getPaymentMethod(payment.paymentMethod);
        if ("cash" === paymentMethod.method.type) {
          done = true;
          payment.amount = Math.round(payment.amount - (a - tableMateChoices[ind].payable) / exchangeRate);

          var amount = payment.amount;
          // eslint-disable-next-line no-eval
          if (false && roundFunction)
            payment.amount = eval(roundFunction.replace("amount", payment.amount));
        }
      })
      //if (tableMatePayment.qrcode)
      //tableMatePayment.qrcode += "|" + a;
    }

    tableMatePayments.push(tableMatePayment);
  });
  var cashRegister = modal.find("#cashregisters label.active input").prop("id");
  cashRegister = cashRegister ? cashRegister : 0;

  const opts = {
    baudRate: 9600,
    //dtr: true,
    //rts: true,
    sleepOnPause: false
  }

  const c = auth.myStatus.restaurant_cash_registers.find(c => c.id === Number(cashRegister));

  if (auth?.myStatus?.restaurant_cash_registers?.length && (!c || !cashRegister)) {
    try {
      console.error("Strange that there is no cash register selected! c=" + !!c + " cashRegister=" + !!cashRegister + " c=" + $("#cashregisters label.active input").prop("id"));
    } catch (ex) { }
  }
  if (cashRegister && !auth?.myStatus?.restaurant_cash_registers?.length) {
    console.error("Strange that there is a cash register selected but not found in the settings!");
  }

  if (c && c.type === "jota_m")
    opts.parity = 1;

  if (c && (c.format === "android_http" || c.format === "android" || c.format === "android_bluetooth" || c.format === "android_rs232") && (auth.device.platform === "android" || auth.device.platform === "Android")) {
    console.log("Serial request permission");
    if (c.format === "android_bluetooth") {

      //eslint-disable-next-line no-undef
      if (typeof bluetoothSerial === "undefined") {
        console.error("Bluetooth serial is not available");
        messageDialog(I18n.t("local.warning"), I18n.t("admin_local.no_bluetooth_adapter"));
        return;
      }


      //eslint-disable-next-line no-undef
      bluetoothSerial.list(success => {
        const serial = success.filter(c => c.name.startsWith("ECR-"));
        if (serial.length === 1) {
          //eslint-disable-next-line no-undef
          bluetoothSerial.connect(serial[0].address, connectSuccess => {
            console.log("Bluetooth serial working", connectSuccess)
            //eslint-disable-next-line no-undef
            bluetoothSerial.disconnect(function success() {
              postPayment(c, tableOccupation, tableMatePayments, false, handler);
            }, function error(error) {
              messageDialog(I18n.t("local.warning"), I18n.t("admin_local.no_bluetooth_cashiers"));
              console.error("Bluetooth Serial failed to close", error);
            })
          }, connectFailure => {
            console.error("Bluetooth serial not", connectFailure)
          });
        } else {
          if (serial.length > 1)
            messageDialog(I18n.t("local.warning"), I18n.t("admin_local.too_many_bluetooth_cashiers"));
          else
            messageDialog(I18n.t("local.warning"), I18n.t("admin_local.no_bluetooth_cashiers"));
        }
      }, failure => {
        console.error("Bluetooth serial error", JSON.stringify(failure));
        messageDialog(I18n.t("local.warning"), I18n.t("admin_local.no_bluetooth_cashiers"));
      });
    } else if (c.format === "android") {
      console.error("Check serial permission");
      //eslint-disable-next-line no-undef
      serial.requestPermission(function success(s) {
        setTimeout(
          //eslint-disable-next-line no-undef
          serial.open(opts, function success(f) {
            //eslint-disable-next-line no-undef
            //serial.close(function success(f) {
            //console.error("Serial closed: " + f);
            postPayment(c, tableOccupation, tableMatePayments, false, handler);
            //}, function error() {
            //  console.error("Serial failed to close");
            //messageDialog(I18n.t("local.warning"), I18n.t("admin_local.no_serial_cashiers"));
            //})
          }, function error(error) {
            console.error("Serial failed to open(2): " + JSON.stringify(error));
            messageDialog(I18n.t("local.warning"), I18n.t("admin_local.no_serial_cashiers"));
          }), 1000);
      }, function error(error) {
        console.error("Serial denied: " + JSON.stringify(error));
        messageDialog(I18n.t("local.warning"), I18n.t("admin_local.no_serial_cashiers"));
      });
    } else if (c.format === "android_rs232") {
      try {
        //eslint-disable-next-line no-undef
        uart.list(result => {
          dev = result;
          postPayment(c, tableOccupation, tableMatePayments, false, handler, dev);
        }, error => {
          console.error("UART list error " + JSON.stringify(error));
          dev = "/dev/ttyS3";
          postPayment(c, tableOccupation, tableMatePayments, false, handler, dev);
        })
      } catch (ex) {
        console.error("Problem with uart", ex)
      }

    }
  } else {
    postPayment(c, tableOccupation, tableMatePayments, false, handler);
  }


}

export const increase = event => {
  var li = $(
    $(event.currentTarget)
      .parents("li.mate")
      .get(0)
  );
  if (!li.hasClass("active")) return;
  li.attr("count", Number(li.attr("count")) + 1);
  li.find("span.times").text("x " + li.attr("count"));
  li.find("span.times").removeClass("hidden");
  event.stopPropagation();
};

export const decrease = event => {
  var li = $(
    $(event.currentTarget)
      .parents("li.mate")
      .get(0)
  );
  if (!li.hasClass("active")) return;
  if (li.attr("min") == null || li.attr("count") > li.attr("min")) li.attr("count", Number(li.attr("count")) - 1);
  else {
    li.click();
  }
  li.find("span.times").text("x " + li.attr("count"));
  event.stopPropagation();
};

var orderIndex = 0;

export const getMenuItemAdditions = (baseMenuItem, search, type, takeaway = undefined, additions, x) => {
  orderIndex = 0;
  var set2 = new SortedSet({
    comparator: function (a, b) {
      return a.value.localeCompare(b.value);
    }
  });
  var included = [];
  var set = new SortedSet({
    comparator: function (a, b) {
      if (included.indexOf(a.id) >= 0 && included.indexOf(b.id) < 0) return -1;
      if (included.indexOf(b.id) >= 0 && included.indexOf(a.id) < 0) return 1;
      var r = a.orderIndex - b.orderIndex;
      if (r == 0) return 0;
      return r;
    }
  });
  if (type == "optional") {
    _getMenuItemAdditions(baseMenuItem, search, set2, baseMenuItem.menuItemAdditions, "included", 0, takeaway);
    _getMenuCategoryAdditions(baseMenuItem, search, set2, baseMenuItem.menuCategoryAdditions, "included", 0);
    set2.forEach(function (e) {
      //if (included.indexOf(e.id) < 0) included.push(e.id);
    });
  }
  _getMenuItemAdditions(baseMenuItem, search, set, baseMenuItem.menuItemAdditions, type, 0, takeaway);
  _getMenuCategoryAdditions(baseMenuItem, search, set, baseMenuItem.menuCategoryAdditions, type, 0);

  /*
   * if (type=='optional') getAllMenuItemAdditions(baseMenuItem, search, set,
   * categories.activeMenuCategories, type);
   */


  if (type === "included" && additions) {
    additions.forEach(a => {
      const menuItem = getMenuItem(a.split("-")[0]);
      const resplus = getMenuItemAdditions(menuItem, search, type, takeaway, []);

      resplus.forEach(r => set.insert(r));
    })
  }

  var result = [];
  set.forEach(function (e) {
    if (type != "optional" || included.indexOf(e.id) == -1) {
      if (result.indexOf(e) < 0) result.push(e);
    }
  });

  return result;
};

export const activeMenuCategories = (category) => {
  if (!category) return [];
  return category.activeMenuCategories ? category.activeMenuCategories : category.children ? category.children.filter(c => !c.availableQuantities) : [];
}

export const activeMenuItems = (category, menuItem, sub = false) => {
  // if menuItem has menuitem options from the same category, then only those options should be retured
  if (!category) return [];
  var result = category.activeMenuItems ? category.activeMenuItems : category.children.filter(c => c.availableQuantities);
  if (sub) {
    if (category.activeMenuCategories) {
      category.activeMenuCategories.forEach(c => {
        const r = activeMenuItems(c, menuItem);
        r.forEach(rr => !result.find(e => e.id == rr.id) && result.push(rr));
      });
    } else if (category.children) {
      category.children.filter(c => !c.availableQuantities).forEach(c => {
        const r = activeMenuItems(c, menuItem);
        r.forEach(rr => !result.find(e => e.id == rr.id) && result.push(rr));
      });
    }
  }

  var explicitOptionalMenuItems = [];
  var m = menuItem;
  while (m) {
    m.menuItemAdditions.forEach(a => explicitOptionalMenuItems.push(a));
    m = m.menuCategoryId ? getMenuCategory(m.menuCategoryId) : null;
  }

  if (menuItem && explicitOptionalMenuItems.filter(a => a.additionType === "optional" && result.find(m => m.id === a.additionId)).length) {
    //limit the category menu items if items from it is listed explicitly as well
    result = result.map(m => { const a = explicitOptionalMenuItems.filter(a => a.additionType === "optional" || a.additionType === "included").find(a => a.additionId === m.id); return a ? { ...m, availableQuantities: m.availableQuantities.filter(q => q.quantity == a.quantity) } : null }).filter(m => m);
  }
  return result.filter(a => a.isActive);
}

function getAllMenuItemAdditions(baseMenuItem, search, set, categories, type, order) {
  categories.forEach(function (category) {
    getAllMenuItemAdditions(baseMenuItem, search, set, activeMenuCategories(category), type);
    activeMenuItems(category).forEach(function (item) {
      item.availableQuantities.forEach(function (q) {
        // quantityLabel = q.label;
        var i = { id: item.id, value: item.id + "-" + parseFloat(q.quantity), text: getLocale(item.name) };
        if (!set.contains(i)) set.insert({ id: item.id, orderIndex: order, visible: true, quantityType: getLocale(item.quantityType), value: item.id + "-" + parseFloat(q.quantity), text: getLocale(item.name) });
      });
    });
  });
}

function _getMenuItemAdditions(baseMenuItem, search, set, categories, type, order = 0, takeaway) {
  if (categories != undefined)
    categories.forEach(function (addition) {
      if (typeof takeaway !== "undefined" && addition.local !== addition.takeaway) {
        if (addition.local && takeaway) {
          return;
        }
        if (addition.takeaway && !takeaway) {
          return;
        }
      }
      if (typeof type != "undefined" && type != addition.additionType) return;
      var regexp = new RegExp(search, "ig");
      var item = getMenuItem(addition.additionId);
      if (item.type == "deleted") return;
      if (regexp.test(getLocale(item.name))) {
        var quantity = getQuantity2(addition.quantity, item.quantityType);
        quantity = (quantity != "" ? " " : "") + quantity;
        var quantityLabel = "";
        item.availableQuantities.forEach(function (q) {
          if (q.quantity == addition.quantity) quantityLabel = q.label;
        });
        var i = { id: item.id, orderIndex: orderIndex++, value: item.id + "-" + parseFloat(addition.quantity), text: getLocale(item.name), proportional: addition.proportional };
        if (!set.contains(i)) set.insert({ id: item.id, local: addition.local, takeaway: addition.takeaway, orderIndex: order + addition.order, visible: true, quantityType: getLocale(item.quantityType), value: item.id + "-" + parseFloat(addition.quantity), text: getLocale(item.name), proportional: addition.proportional });
      }
    });
  if (baseMenuItem.menuCategoryId) {
    var parent = getMenuItemCategory(baseMenuItem.menuCategoryId);
    if (parent !== null)
      _getMenuItemAdditions(parent, search, set, parent.menuItemAdditions, type, (order + 1) * 1000, takeaway);
  }
}

function _getMenuCategoryAdditions(baseMenuItem, search, set, categories, type, order = 0) {
  if (categories != undefined)
    categories.forEach(function (addition) {
      if (typeof type != "undefined" && type != addition.additionType) return;
      if (addition.optionType && addition.optionType != "multiple") return;
      var regexp = new RegExp(search, "ig");
      var item = getMenuItemCategory(addition.additionId);
      if (item && regexp.test(getLocale(item.name))) {
        getMenuItemsForCategory(item).forEach(function (item) {
          if (item.availableQuantities.length == 0) {
            var i = { id: item.id, visible: true, orderIndex: order + addition.order, quantityType: getLocale(item.quantityType), value: item.id + "-1", text: getLocale(item.name) };
            if (!set.contains(i)) set.insert(i);
          } else {
            item.availableQuantities.forEach(function (quantity) {
              if (quantity.onMenu) {
                var i = { id: item.id, visible: true, orderIndex: order + addition.order, quantityType: getLocale(item.quantityType), value: item.id + "-" + parseFloat(quantity.quantity), text: getLocale(item.name) };
                if (!set.contains(i)) set.insert(i);
              }
            });
          }
        });
      }
    });
  if (baseMenuItem.menuCategoryId) {
    var parent = getMenuItemCategory(baseMenuItem.menuCategoryId);
    if (parent)
      _getMenuCategoryAdditions(parent, search, set, parent.menuCategoryAdditions, type, (order + 1) * 1000);
  }
}

export const getMenuCategoryOptions = (baseMenuItem) => {
  var result = [];
  _getMenuCategoryOptions(baseMenuItem, result, baseMenuItem.menuCategoryAdditions);
  result = result.filter(c => getMenuCategory(c.additionId)).filter(c => getMenuCategory(c.additionId).isActive);
  if (result)
    return result.sort((a, b) => a.order - b.order);
  else return [];
};
function _getMenuCategoryOptions(baseMenuItem, set, categoryAdditions) {
  if (categoryAdditions != undefined)
    categoryAdditions.forEach(function (addition) {
      if (addition.optionType && addition.optionType != "multiple") {
        set.push(addition);
        return;
      }
    });
  if (baseMenuItem.menuCategoryId) {
    var parent = getMenuItemCategory(baseMenuItem.menuCategoryId);
    if (parent)
      _getMenuCategoryOptions(parent, set, parent.menuCategoryAdditions);
  }
}

function getMenuItemsForCategory(category) {
  var items = [];
  activeMenuItems(category).forEach(function (item) {
    items.push(item);
  });
  return items;
}

function getMenuItems(baseMenuItem, search) {
  var regexp = new RegExp(search, "ig");
  var set = new Set();
  var menuItem = _getMenuItems(baseMenuItem, regexp, set, activeMenuCategories(admin.categories));
  if (menuItem == "") {
    return null;
  }
  var result = [];
  set.forEach(function (e) {
    result[result.length] = e;
  });
  return result;
}

function _getMenuItems(baseMenuItem, regexp, set, categories) {
  categories.forEach(function (item) {
    if (item.isTop == true) {
      if (typeof item.activeMenuItems == "undefined") {
        if (item.type == baseMenuItem.type && regexp.test(item.name)) set.add({ value: item.id, text: getLocale(item.name) });
      } else {
        if (item.activeMenuCategories && item.activeMenuCategories.length > 0) _getMenuItems(baseMenuItem, regexp, set, item.activeMenuCategories);
        if (item.activeMenuItems && item.activeMenuItems.length > 0) _getMenuItems(baseMenuItem, regexp, set, item.activeMenuItems);
        if (item.children && item.children.length > 0) _getMenuItems(baseMenuItem, regexp, set, item.children);
      }
    }
  });
}

var tableNumber = "";

function keyDownHandler(e) {
  resetLockTimeout();
  if (e.isDefaultPrevented()) return;
  if (_keyDownHandler(e)) return true;
  if (e.isDefaultPrevented()) return;
  // eslint-disable-next-line no-fallthrough
  switch (e.which) {
    case 96: // 0
    case 97: // 1
    case 98: // 2
    case 99: // 3
    case 100: // 4
    case 101: // 5
    case 102: // 6
    case 103: // 7
    case 104: // 8
    case 105: // 9
      e.which -= 96 - 48;
    // eslint-disable-next-line no-fallthrough
    case 48: // 0
    case 49: // 1
    case 50: // 2
    case 51: // 3
    case 52: // 4
    case 53: // 5
    case 54: // 6
    case 55: // 7
    case 56: // 8
    case 57: // 9
      tableNumber += "" + (e.which - 48);
      var t = tableNumber;
      setTimeout(function () {
        if (t == tableNumber) {
          if (t.startsWith(0)) {
            //quickChangeToUser(tableNumber.substring(1));
          } else if (localStorage.locked == "true") {
            quickChangeToUser(tableNumber);
          } else {
            $("#c svg:visible .eatwithme-table.selectable.selected").removeClass("selected");
            var selected = $("#c svg:visible #" + tableNumber + ".eatwithme-table.selectable");
            select(selected);
          }
          tableNumber = "";
        }
      }, 1000);
      break;
    case 32: // space
      if (e.target.localName != "input") {
        var selected = $(
          $(".selectable.selectedTop")
            .find(".selectable.selected")
            .get(0)
        );
        if (selected.hasClass("list-group-item")) {
          if (typeof selected != "undefined" && selected.parents(".list-group.checked-list-box").length > 0) {
            selected.click();
            e.preventDefault();
          }
        } else if (selected.parents(".btn-group-toggle").length > 0) {
          selected.click();
          e.preventDefault();
        }
      }
      break;
    case 13: // enter
      if (typeof localStorage._editTable !== "undefined")
        return;
      if (e.target.localName != "input" && tableNumber != "") {
        if (tableNumber.startsWith(0)) {
          quickChangeToUser(tableNumber.substring(1));
        } else if (localStorage.locked == "true") {
          quickChangeToUser(tableNumber);
        } else {
          if (localStorage.locked != "true") {
            $("#c svg:visible .eatwithme-table.selectable.selected:not(.inactive)").removeClass("selected");
            var selected = $("svg").find("#" + tableNumber + ".eatwithme-table.selectable");
            select(selected);
          }
        }
        tableNumber = "";
      }
      if (e.target.localName != "input" && localStorage.locked != "true") {
        var id = $("#c svg:visible .eatwithme-table.selectable.selected:not(.inactive)").attr("id");
        if (id != undefined) {
          selectTableNumberSelected(id);
          updateTableMates().done(function () {
            orderListUtil.processOrders(orderListUtil.ordersCache);
            if (orderListUtil.tablesOverview) tableLayout.updateTableOccupations();
            var tab = $("#" + localStorage.atTheTableSelectedTab + ".tab-pane");
            if (tab == undefined || !isVisible(tab)) {
              tab = $("#left_pane .tab-content .tab-content .tab-pane").get(0);
              localStorage.atTheTableSelectedTab = $(tab).attr("id");
            }
            if (selected.attr("tableOccupations") == null) {
              tableLayout.pre_selected_action = "pre-lock-order";
              lockTable(localStorage.tableNumberSelected, 1, updateTableOccupationsAndSelect);
            }
            select(tab);
          });
        }
      }
      break;
    case 37: // left
    case 38: // up
      if (localStorage.locked == "true") return;
      if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
        var selected = $("#c svg:visible .selectable.selected");
        var id = selected.length == 0 ? 2 : selected.attr("id");
        var i = 1;
        for (i = Number(id) - 1; i >= 0; i--) {
          var selected = $("#c svg:visible #" + i + ".eatwithme-table.selectable");
          if (selected.length != 0) break;
        }
        select(selected);
      }
      break;
    case 40: // down
    case 39: // right
      if (localStorage.locked == "true") return;
      if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
        var selected = $("#c svg:visible .selectable.selected");
        var id = selected.length == 0 ? 0 : selected.attr("id");
        if (orderListUtil.tablesOverview)
          for (i = Number(id) + 1; i <= tableLayout.tables.length; i++) {
            var selected = $("#c svg:visible #" + i + ".eatwithme-table.selectable");
            if (selected.length != 0) break;
          }
        select(selected);
      }
      break;
    default:
  }
  return e.target.localName != "input";
}
function order2(id) {
  var orders = [];
  orderListUtil.ordersCache.forEach(function (order) {
    if (order.state != "selected") {
      order = $.extend(true, {}, order);
      var forWhom = getTableMate(order.forWhomId);
      if (forWhom.payingMateId == id) {
        orders.push(order);
        order.quantityLabel = getQuantity(order);
        order.addition = true;
        var d = order.discountComment;
        order.discountComment = "";
        if (d != null && d != "")
          d.split(";").forEach(function (dis) {
            var dis2 = dis.split(",");
            order.discountComment += admin_local.discount + ": " + getDiscount(dis2[0]).name + " " + "\r\n";
          });
        order.orderId = order.id;
        order.menuItem = order.menuItem != null ? getMenuItem(order.menuItem.id) : {};
        order.childOrders.forEach(function (childorder) {
          childorder.menuItem = getMenuItem(childorder.menuItem.id);
          childorder.orderId = order.id;
          orders.push(childorder);
        });
      }
    }
  });
  return orders;
}

export const getOrdersByTableNumber = (tableOccupation, tableNumber, state) => {
  if (orderListUtil.ordersCache) {
    return orderListUtil.ordersCache.filter(order => order.tableOccupationId == tableOccupation && order.tableNumber == tableNumber && (!state || state == order.state));
  }
  return [];
};

export const getAmountsToPay = set => {
  var amount = 0;
  set.forEach(function (t) {
    amount += getAmountToPay(t.id ? t.id : t);
  });
  return amount;
};

export const getOpenOrdersCount = set => {
  var count = 0;
  set.forEach(function (t) {
    count += getOrdersToPay(t.id ? t.id : t).length;
  });
  return count;
};

export const getAmountToPayExemptOfServiceFee = (id, date) => {
  var amount = 0;
  const containerChargeItem = getSetting("takeaway.containerChargeItem");
  var mates = [];
  getOrdersPaidBy(id, date).filter(o => o.menuItem.id == containerChargeItem).map(o => getOrderById(o.id)).forEach(function (order) {
    if (order.paid != true && order.state != "selected" && order.state != "ordered" && order.state != "cancelled") {
      amount += Math.round(order.fullPrice - order.discount);
      if (mates.indexOf(order.forWhomId) < 0) mates.push(order.forWhomId);
    }
  });
  /*mates.forEach(function (id) {
    amount -= getTableMate(id).discount;
  });*/
  return amount;
};

export const getAmountToPay = (id, date) => {
  var amount = 0;
  var mates = [];
  getOrdersPaidBy(id, date).map(o => getOrderById(o.id)).forEach(function (order) {
    if (order.paid != true && order.state != "selected" && order.state != "ordered" && order.state != "cancelled") {
      amount += Math.round(order.fullPrice - order.discount);
      if (mates.indexOf(order.forWhomId) < 0) mates.push(order.forWhomId);
    }
  });
  mates.forEach(function (id) {
    amount -= getTableMate(id).discount;
  });
  return amount;
};

export const getOrdersToPay = (id, date) => {
  var orders = [];
  getOrdersPaidBy(id, date).map(o => getOrderById(o.id)).forEach(function (order) {
    if (order.paid != true && order.state != "selected" && order.state != "ordered" && order.state != "cancelled") {
      orders[orders.length] = order.id;
    }
  });
  return orders;
}

export const getAmountsPaid = set => {
  var amount = 0;
  set.forEach(function (t) {
    amount += getAmountPaid(t.id ? t.id : t);
  });
  return amount;
};

function getAmountPaid(id, date) {
  var amount = 0;
  getOrdersPaidBy(id, date).forEach(function (order) {
    if (order.paid == true) {
      amount += (order.addition == true ? 1 : -1) * order.fullPrice;
    }
  });
  return amount;
}

export const getOrdersPaid = (id) => {
  var orders = [];
  getOrdersPaidBy(id).forEach(function (order) {
    if (order.paid == true) orders[orders.length] = order.id;
  });
  return orders;
}

export const getStateIndex = (mate, s) => {
  var index = -1;
  mate.states.forEach(function (state, ind) {
    if (state.duration == 0 && state.state == s) index = ind;
  });
  return index;
};

const showqrcode_to_link_teammate = id => {
  var tableOccupation = getTableOccupation(localStorage.tableOccupationSelected);
  var tableOccupationQRCode = tableOccupation.qrToken;
  var target = $("#editTable div#qrcode" + id);
  if (isVisible(target)) {
    $("#editTable ul#qrcode" + id + " li").show();
    $("#editTable div#qrcode" + id).hide();
    ReactDOM.unmountComponentAtNode(target.get(0));
    return;
  }
  $("#editTable ul.qrcode").show();
  $("#editTable div.qrcode").hide();
  $("#editTable ul#qrcode" + id + " li").hide();
  $("#editTable div#qrcode" + id).show();
  var url = "https://restaurant.eatwithme.online" + (auth.server.indexOf(":8444") > 0 ? ":8444" : "") + "/map.html?r=" + auth.myStatus.restaurant_global_id + "&t=" + localStorage.tableNumberSelected + "&m=" + id + "&q=" + tableOccupationQRCode + "&a=m";
  console.log(url);
  if (ReactDOM.findDOMNode(target.get(0)) !== null) ReactDOM.unmountComponentAtNode(target.get(0));
  ReactDOM.render(<ReactQRCode url={url} size={Math.min(target.get(0).getBoundingClientRect().height, target.get(0).getBoundingClientRect().width)} />, target.get(0));

  target.click(function () {
    $("#editTable ul#qrcode" + id + " li").show();
    $("#editTable div#qrcode" + id).hide();
    ReactDOM.unmountComponentAtNode(target.get(0));
  });

  /*
  var qrcode = $("div#qrcode" + id)
    .empty()
    .qrcode({
      // render method: 'canvas', 'image' or 'div'
      render: "image",
 
      // version range somewhere in 1 .. 40
      minVersion: 15,
      maxVersion: 30,
 
      // error correction level: 'L', 'M', 'Q' or 'H'
      ecLevel: "H",
 
      // offset in pixel if drawn onto existing canvas
      left: 0,
      top: 0,
 
      // size in pixel
      size: 1000,
 
      // code color or image element
      fill: "#000",
 
      // background color or image element, null for transparent background
      background: "#ffffff",
 
      text: url,
 
      radius: 0.0,
 
      // quiet zone in modules
      quiet: 0,
 
      // modes
      // 0: normal
      // 1: label strip
      // 2: label box
      // 3: image strip
      // 4: image box
      mode: 1,
 
      mSize: 0.06,
      mPosX: 0.5,
      mPosY: 0.7,
 
      label: String.format(String.format(local.unknown_person, getTableMate(id).seqNr)),
      fontname: "sans",
      fontcolor: "#000",
 
      image: null
    });
  qrcode.find("img").css("height", "100%");
  qrcode.find("img").css("width", "100%");
  $("li#payer" + id)
    .get(0)
    .scrollIntoView({ behavior: "instant", block: "start", inline: "nearest" });
  qrcode.find("img").click(function() {
    $("#editTable ul#qrcode" + id).show();
    $("#editTable div#qrcode" + id).hide();
  });
  */
};

function userConnected() {
  var occupation = getTableOccupation(localStorage.tableOccupationSelected);
  return occupation ? occupation.connected : false;
}

export const afterLockTable = data => {
  updateTableOccupationsAndSelect(data, true);
  if (Number(localStorage.tableNumberSelected) === getPickupTableNumber() || Number(localStorage.tableNumberSelected) === getHomeDeliveryTableNumber()) {
    setTakeAway();
  }
};

var searchActive = false;
var lastSearch = "";
export const filterAdditions = search => {
  if (search == "") {
    $("div.additionsBlock .list-group-item.optional").removeClass("hidden");
    return;
  }
  var items = $("div.additionsBlock .list-group-item.optional").addClass("hidden");
  var items = $("div.additionsBlock .list-group-item.optional span:contains('" + search + "')");
  items.parent().removeClass("hidden");
};

export const userChanged = () => {
  $("#quickAccessBar").empty();
  $("script#quickAccessTemplate")
    .tmpl(tmplparams())
    .appendTo("#quickAccessBar");
  $("div#locked").hide();
  $(".bar").replaceWith($("script#tablesOverviewBarTemplate").tmpl({ includableFrames: includableFrames, ...tmplparams() }));
  ////$(".bar").replaceWith($("script#barTemplate").tmpl(tmplparams()));
  $(".movableBox .user-img").attr("src", "");
  loadMyImage();
};

export const updateLeftTabs = () => {
  updateOrderListByCustomer(false);
  updateOrderListByTiming(false);
  updateOrderList(false);
  orderListUtil.updateHooks();
  topUpdateHooks();
};

function updateOrderListByCustomer(force) {
  var o = orderListUtil.isFiltered() && typeof orderListUtil.orders.count != "undefined" ? orderListUtil.orders : orderListUtil.allOrders;
  var block = $("ul#orderListByCustomer");
  if (($('#editTable:not(.hidden)').length > 0 && $('#editTable #byCustomer.tab-pane.active').length > 0) && (block.hasClass("outdated") || force)) {
    var newContent = $("<div/>");

    o.ordersByPayer.forEach(function (order) {
      if ($("#userSelector button.active.sticky").length && order.count === 0)
        return;
      if (orderListUtil.show(order))
        if (order.customer && (getTableMate(order.customer.id).active == true || order.count > 0))
          $("script#ordersByPayerTemplate")
            .tmpl({ ...order, ...tmplparams() })
            .appendTo(newContent);
    });

    var target = $("#editTable div.customerQRCode");
    target.each((ind, target) => {
      if (ReactDOM.findDOMNode(target) !== null) ReactDOM.unmountComponentAtNode(target);
    });

    replaceContent(block, newContent);
    block.removeClass("outdated");
    updateToolTips();
  } else {
    block.addClass("outdated");
  }
}

function updateOrderListByTiming(force) {
  var o = orderListUtil.isFiltered() && typeof orderListUtil.orders.count != "undefined" ? orderListUtil.orders : orderListUtil.allOrders;
  var block = $("ul#orderListByTiming");
  if (($('#editTable:not(.hidden)').length > 0 && $('#editTable #byTiming.tab-pane.active').length > 0) && (block.hasClass("outdated") || force)) {
    var newContent = $("<div/>");
    var ordersByTiming = {};
    o.ordersByTiming.forEach(function (order) {
      if (orderListUtil.show(order)) {
        ordersByTiming[order.timing] = order;
      }
    });
    $("script#ordersByTimingTemplate")
      .tmpl({ ...ordersByTiming, ...tmplparams() })
      .appendTo(newContent);
    replaceContent(block, newContent);
    block.removeClass("outdated");
    updateToolTips();
  } else {
    block.addClass("outdated");
  }
}

export const editDiscounts = (tableMate, handler) => {
  var modal = $("#editDiscountDialog");
  modal.empty();
  var orderIds = [];
  var tableMateIds = [];
  var selectedItem = "none";
  if (typeof localStorage.atTheTableSelectedTabLeft === "undefined" || localStorage.atTheTableSelectedTabLeft === "orders" || !orderListUtil.tablesOverview) {
    var orders = $(orderListUtil.selected).find("div.order.orderTableItem");
    orderIds = orders.attr("orders") != null ? orders.attr("orders").split(",") : [];
    if (orderIds.length > 0)
      selectedItem = "order";
    else {
      var orders = $(".byCustomer.menuItem-order.selected");
      orderIds = orders.attr("orderids") != null ? orders.attr("orders").split(",") : [];
      if (orderIds.length > 0)
        selectedItem = "order";
    }
  } else if (!tableMate && localStorage.atTheTableSelectedTabLeft === "byCustomer") {
    var orders = $(".byCustomer.menuItem-order.selected");
    orderIds = orders.attr("orderids") != null ? orders.attr("orderids").split(",") : [];
    if (orderIds.length > 0)
      selectedItem = "order";
    else
      $('#byCustomer.tab-pane.container.active .customer.list-group-item.selected').each((ind, t) => {
        if ($(t).attr("id").startsWith("payer"))
          var id = Number($(t).attr("id").substring(5));
        else
          var id = Number($(t).attr("id"));
        tableMateIds.push(id);
        selectedItem = "tableMate";
      })
  } else if (tableMate) {
    tableMateIds.push(tableMate);
    selectedItem = "tableMate";
  }
  $("#editDiscountsTemplate")
    .tmpl({ selectedItem: selectedItem, ...tmplparams() })
    .appendTo(modal);
  var modal = $("#editDiscounts");
  var tableOccupation = getTableOccupation(localStorage.tableOccupationSelected);
  tableOccupation.discounts.forEach(function (discount) {
    modal.find("#discountsBlock .list-group-item#" + discount.id).addClass("active");
    modal.find("#discountsBlock .list-group-item#" + discount.id + " input").prop("checked", true);
    modal.find("#discountsBlock .list-group-item#" + discount.id + " span.glyphicon").removeClass("glyphicon-unchecked");
    modal.find("#discountsBlock .list-group-item#" + discount.id + " span.glyphicon").addClass("glyphicon-check");
  });
  tableOccupation.tableMates.forEach(tableMate => {
    if (tableMateIds.indexOf(tableMate.id) === -1) {
      return;
    }
    tableMate.discounts.forEach(discount => {
      modal.find("#selectedDiscountsBlock .list-group-item#" + discount.id).addClass("active");
      modal.find("#selectedDiscountsBlock .list-group-item#" + discount.id + " input").prop("checked", true);
      modal.find("#selectedDiscountsBlock .list-group-item#" + discount.id + " span.glyphicon").removeClass("glyphicon-unchecked");
      modal.find("#selectedDiscountsBlock .list-group-item#" + discount.id + " span.glyphicon").addClass("glyphicon-check");

    });
  })
  if (selectedItem === "none") {
    modal.find("#selectedDiscountsBlock .list-group-item").addClass("disabled");
  }
  updateCheckBoxesById(modal.find("li.list-group-item"));
  orderListUtil.ordersCache.forEach(order => {
    if (orderIds.indexOf("" + order.id) === -1) return;
    if (order.tableOccupationId != localStorage.tableOccupationSelected) return;
    if (order.discounts)
      order.discounts.forEach(discount => {
        modal.find("#selectedDiscountsBlock .list-group-item#" + discount.id).addClass("active");
        modal.find("#selectedDiscountsBlock .list-group-item#" + discount.id + " input").prop("checked", true);
        modal.find("#selectedDiscountsBlock .list-group-item#" + discount.id + " span.glyphicon").removeClass("glyphicon-unchecked");
        modal.find("#selectedDiscountsBlock .list-group-item#" + discount.id + " span.glyphicon").addClass("glyphicon-check");
      });
  });
  modal.find("button#save").click(function () {
    var discounts = {};
    var orderDiscounts = {};
    const tableMateDiscounts = {};

    orderListUtil.ordersCache.forEach(order => {
      if (orderIds.indexOf("" + order.id) !== -1) return;
      if (order.tableOccupationId != localStorage.tableOccupationSelected) return;
      if (order.discounts)
        order.discounts.forEach(discount => {
          if (orderDiscounts[discount.id] == undefined) orderDiscounts[discount.id] = { discountId: discount.id, discountTarget: "Order", ids: [] };
          orderDiscounts[discount.id].ids.push(order.id);
        });
    });
    tableOccupation.tableMates.forEach(tableMate => {
      if (tableMateIds.indexOf(tableMate.id) === -1) {
        tableMate.discounts.forEach(discount => {
          const tmid = tableMate.id;
          if (tableMateDiscounts[discount.id])
            tableMateDiscounts[discount.id].ids.push(tmid);
          else
            tableMateDiscounts[discount.id] = { discountId: discount.id, discountTarget: "TableMate", ids: [tmid] };
        });
        return;
      }
    })


    getTableOccupation(localStorage.tableOccupationSelected).tableMates.forEach(tableMate => {
    });
    modal.find("#selectedDiscountsBlock .list-group-item.active").each(function (ind, t) {
      if (selectedItem === "order") {
        if (!orderDiscounts[Number($(t).attr("id"))]) orderDiscounts[Number($(t).attr("id"))] = { discountId: $(t).attr("id"), discountTarget: "Order", ids: [] };
        orderIds.forEach(id => {
          orderDiscounts[Number($(t).attr("id"))].ids.push(id);
        });
      } else if (selectedItem === "tableMate") {
        if (!tableMateDiscounts[Number($(t).attr("id"))]) tableMateDiscounts[Number($(t).attr("id"))] = { discountId: $(t).attr("id"), discountTarget: "TableMate", ids: [] };
        tableMateIds.forEach(id => {
          tableMateDiscounts[Number($(t).attr("id"))].ids.push(id);
        });
      }
    });
    modal.find("#discountsBlock .list-group-item.active").each(function (ind, t) {
      var v = {};
      discounts[$(t).attr("id")] = { discountId: $(t).attr("id"), discountTarget: "TableOccupation", ids: [] };
    });
    discounts = [...Object.values(discounts), ...Object.values(orderDiscounts), ...Object.values(tableMateDiscounts)];

    admin.updateDiscountsForTableOccupation(localStorage.tableOccupationSelected, discounts, function (data) {
      $("div.modal-backdrop.fade.show").hide();
      updateAndCloseModal(data, handler);
    });
  });
  modal.modal("show", {
    keyboard: false,
  });

  modal.on("hidden.bs.modal", function () {
    $("#edit-discount").attr("disabled", null);
  });
};

export const showTableOccupationList = () => {
  localStorage.tableOccupations_fromDate = moment()
    .startOf("day")
    .toDate()
    .getTime();
  localStorage.tableOccupations_toDate = moment()
    .endOf("day")
    .toDate()
    .getTime();
  localStorage.removeItem("tableOccupations_tableNumber");
  localStorage.tableOccupations_active = false;
  // eslint-disable-next-line no-restricted-globals
  history.push("/reportsMenu/tableOccupations");
};

function addAdditionalTable(handler) {
  tablePicker(function (event) {
    var tableId = $(event.currentTarget).attr("id");
    if (tableId == localStorage.tableNumberSelected) {
      messageDialog(local.message, admin_local.target_table_same_and_source);
      return;
    }
    var tableOccupationTables = getTableOccupationTablesForTable(tableId);
    if (tableOccupationTables.length == 0 || getTable(tableId).shareable) {
      // this is an empty table
      choiceDialog2(local.confirmation, String.format(admin_local.confirm_additional_table, tableId), [admin_local.yes]).done(function (data) {
        admin.addAdditionalTable(localStorage.tableOccupationSelected, tableId, update);
      });
    }
  });
}

function changeToTable(handler) {
  tablePicker(function (event) {
    var tableId = $(event.currentTarget).attr("id");
    if (tableId == localStorage.tableNumberSelected) {
      messageDialog(local.message, admin_local.target_table_same_and_source);
      return;
    }
    var tableOccupationTables = getTableOccupationTablesForTable(tableId);
    if (tableOccupationTables.length == 0) {
      // this is an empty table
      choiceDialog2(local.confirmation, String.format(admin_local.confirm_change_to_table, tableId), [admin_local.yes]).done(function (data) {
        admin.changeToTable(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, tableId, function (data) {
          selectTableNumberSelected(tableId);
          update(data);
        });
      });
    } else if (tableOccupationTables.length >= 1) {
      // this is an table with existing occupation
      if (getTable(tableOccupationTables[0].restaurantTable.number).shareable) {
        choiceDialog2(local.confirmation, String.format(admin_local.confirm_table_change_to_shared_table, tableId), [admin_local.new_independent_occupation, admin_local.joins, admin_local.extend_occupation]).done(
          function (data) {
            switch (data) {
              case 0:
                // new occupation
                console.log("new occupation")
                admin.createNewTableOccupationForMate(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, -1, tableId, true, update);
                break;
              case 1:
                // joins occupation
                admin.changeTableOccupationForMate(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, -1, tableOccupationTables[0].tableOccupationId, tableId, true, update);
                break;
              case 2:
                // add to existing occupation
                admin.changeTableForMate(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, -1, tableId, true, update);
                break;
            }
          }
        );
      } else {
        choiceDialog2(local.confirmation, String.format(admin_local.confirm_table_change_to_table, tableId), [admin_local.yes]).done(function (data) {
          // joins occupation
          admin.changeTableOccupationForMate(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, -1, tableOccupationTables[0].tableOccupationId, tableId, true, update);
        });
      }
    }
  });
}

export const showEditTable = () => {
  $("#freezelock").removeClass("icon-lock");
  $("#freezelock").addClass("icon-lock-open");
  $("#editTable").length && $("#editTable").removeClass("hidden");
  $("#main-floorplan").addClass("hidden");
  $("tbody#orderList").addClass("outdated");
  if (localStorage._editTable != "true" && localStorage.tableOccupationSelected)
    get("adminService/" + localStorage.restaurantSelected + "/tableEditStart/" + localStorage.tableOccupationSelected + "/" + localStorage.tableNumberSelected, undefined, undefined, undefined, false).fail(() => { auth.ajaxError = false });
  localStorage._editTable = true;
};

function tablePicker(tableClickHandler) {
  var handler = function (event) {
    event.preventDefault();
    event.stopPropagation();
    showEditTable();
    shown = false;
    setBigTableNumber();
    $("#c svg[visible='true'] g.eatwithme-table").unbind("click", handler);
    $("body").unbind("keydown", tableKeyDown);
    tableClickHandler(event);
  };
  var tableKeyDown = null;
  var shown = true;
  tableKeyDown = function (event) {
    event.preventDefault();
    event.stopPropagation();
    shown = false;
    showEditTable();
    setBigTableNumber();
    localStorage._editTable = true;
    $("#c svg[visible='true'] g.eatwithme-table").unbind("click", tableClickHandler);
    $("body").unbind("keydown", tableKeyDown);
  };
  $("#editTable").addClass("hidden");
  $("#main-floorplan").removeClass("hidden");
  delete localStorage._editTable;
  $("#c svg[visible='true'] g.eatwithme-table").each(function (ind, item) {
    var handlers = $._data(item, "events");
    var clickHandlers = [];
    handlers.click.forEach(function (click) {
      clickHandlers.push(click.handler);
    });
    const $item = $(item);
    $(item).off();
    $(item).on("click", handler);
    clickHandlers.forEach(function (click) {
      $(item).click(click);
    });

    var handlers = $._data($("body").get(0), "events");
    var keyDownHandlers = [];
    handlers.keydown.forEach(function (keydown) {
      keyDownHandlers.push(keydown.handler);
    });
    $("body").unbind("keydown");
    $("body").keydown(tableKeyDown);
    keyDownHandlers.forEach(function (keydown) {
      $("body").keydown(keydown);
    });
    setTimeout(function () {
      if (shown) {
        showEditTable();
        setBigTableNumber();
        localStorage._editTable = true;
        $("#c [visible='true'] g.eatwithme-table").unbind("click", handler);
        $("body").unbind("keydown", tableKeyDown);
        $("#c [visible='true'] g.eatwithme-table").unbind("click", tableClickHandler);
        $("body").unbind("keydown", tableKeyDown);
      }
    }, 6000);
  });
}

export const setBigTableNumber = () => {
  var table = getTable(localStorage.tableNumberSelected);
  if (table == undefined) return;
  var t = table.name ? table.name : "" + table.number;
  var tt = $("#editTable .tableNumber");
  tt.html(t);
  if (t.length <= 3) tt.css("font-size", "30vmin");
  else if (t.length <= 6) tt.css("font-size", "20vmin");
  else tt.css("font-size", "15vmin");
};

export const pickFrame = (side, frame) => {
  localStorage["extra_frame_" + side] = frame;
  // eslint-disable-next-line no-restricted-globals
  location.reload();
};

export const pickSkin = (skin) => {
  localStorage.skin = skin;
  document.getElementById("root").setAttribute('theme', localStorage.skin);
  // eslint-disable-next-line no-restricted-globals
  location.reload();
};

const tableReadyForDelivery = (t) => {
  const timing = $(t).attr('timing');
  const tableOccupation = $(t).attr('tableOccupation');
  const tableNumber = $(t).attr('tableNumber');
  const tableName = getTable(tableNumber).name ? getTable(tableNumber).name : tableNumber;
  const text = "<html><body><div>" + String.format(I18n.t("admin_local.table_ready_for_delivery"), tableName, tableOccupation, getLocale2(auth.myStatus.restaurant_settings.timings[timing].langs).name) + "!LF!LF!LF</div></body></html>";
  var o = /*orderListUtil.orders ? orderListUtil.orders :*/ orderListUtil.allOrders;
  var pls = [];
  o.tables.forEach(function (order) {
    if (orderListUtil.show(order)) {
      if (order.timing !== timing)
        return;
      order.timingOrder = alltimings.indexOf(order.timing);
      for (var pl in order.productionLinesInvolved) {
        if (pl == "pl") continue;
        if (pls.indexOf(pl.id) < 0) {
          pls.push(Number(order.productionLinesInvolved[pl].id));
        }
      }
    }
  });
  var printerOptions = [];
  Object.keys(getLocalPrinterSettings().productionLines).forEach(function (key) {
    printerOptions.push({ productionLine: Number(key.substring(3)), printer: getLocalPrinterSettings().productionLines[key] });
  });
  const printers = [];
  auth.myStatus.restaurant_production_lines.filter(pl => pls.indexOf(pl.id) !== -1).forEach(pl => {
    console.log(pl);
    const po = printerOptions.find(po => po.productionLine === pl.id);
    if (po)
      printers.indexOf(po.printer) === -1 && printers.push(po.printer);
    else
      printers.indexOf(pl.printer.id) === -1 && printers.push(pl.printer.id);
  })
  printers.forEach(printer => {
    post("printerServerClient/" + localStorage.restaurantSelected + "/printerMessage/" + printer, { text: text });
  })
}

const bookTable = () => {
  $("#bookTable").removeClass("hidden");
  ReactDOM.render(<Provider store={store}><TableReservationComponent onClose={closeBookTable} /></Provider>, $("#bookTable").get(0));
}

const closeBookTable = () => {
  ReactDOM.unmountComponentAtNode($("#bookTable").get(0));
  $("#bookTable").addClass("hidden");
}

export const showMap = (show) => {
  if (show) {
    $("#tableOccupation-selector").addClass('hidden');
    $("#tableOccupation-selector-map").removeClass('hidden');
    ReactDOM.render(<BookingMap myStatus={auth.myStatus} deliveryUsers={tablesOverview.context ? tablesOverview.context.props.deliveryUsers : []} tableOccupations={orderListUtil.occupationsSelected} />, $('#tableOccupation-selector-map').get(0));
  } else {
    $("#tableOccupation-selector-map").addClass('hidden');
    $("#tableOccupation-selector").removeClass('hidden')
    if ($('#tableOccupation-selector-map').get(0))
      ReactDOM.unmountComponentAtNode($('#tableOccupation-selector-map').get(0));
  }
}

export const setSelectedScale = (scale) => {
  localStorage.selected_scale = scale;
  $('#orderMenuItem #selectedScale').html(scale);
}

function customerDblClickHandler(event, id) {
  event.stopPropagation();
  selectNewOrders();
  const i = $('#userSelector button#' + id);
  if (i.hasClass("sticky")) {
    i.triggerHandler("change");
    i.removeClass("active");
    i.removeClass("sticky");
    i.find("input").prop('checked', false);
    updateOrders([]);
  } else {
    i.triggerHandler("change");
    i.addClass("active");
    i.addClass("sticky");
    i.find("input").prop('checked', true);
    updateOrders([]);
  }

}

export const printDailyClosing = () => {
  const start = moment().startOf("day");
  const end = moment();
  if (auth.myStatus.restaurant_settings["business-day-starts-at"] > auth.myStatus.restaurant_settings["business-day-ends-at"] && end.hour() < auth.myStatus.restaurant_settings["business-day-ends-at"]) {
    start.add(-1, "day");
  }
  start.add("hour", auth.myStatus.restaurant_settings["business-day-ends-at"]);
  var search = {
    fromDate: start.valueOf(),
    toDate: end.valueOf(),
    labels: []
  }

  function print(datas) {

    var data = {
      date: start.format("YYYY-MM-DD"),
      tables: 0,
      orders: 0,
      cancelledOrders: 0,
      personCount: 0,
      consumption: 0,
      discount: 0,
      payable: 0,
      paid: 0,
      tip: 0,
      serviceFee: 0,
      serviceFeeNet: 0,
      payments: [],
      deliveries: [],
      vats: [],
      menuItems: [],
      types: []
    }

    datas.forEach(d => {
      data.tables += d.occupationCount;
      data.orders += d.orderCount;
      data.cancelledOrders += d.deletedOrderCount;
      data.personCount += d.persons;
      data.consumption += d.price + d.pending;
      data.discount += d.discount + d.tm_discount;
      data.payable = data.consumption - data.discount;
      data.paid += d.paid;
      data.tip += d.tip;
      data.serviceFee += d.serviceFee;
      data.serviceFeeNet += d.serviceFeeNet;


      d.payments.forEach(p => {
        var payment = data.payments.find(pp => p.receivedById === pp.receivedById && p.cashRegister === pp.cashRegister && p.paymentMethod === pp.method && p.currency === pp.currency);
        if (!payment) {
          data.payments.push({
            cashier: p.receivedByName,
            receivedById: p.receivedById,
            cashRegister: p.cashRegister,
            method: p.paymentMethod,
            currency: p.currency,
            amount: p.amount,
            exchangeRate: p.exchangeRate,
            paymentCount: p.paymentCount
          })
        } else {
          payment.amount += p.amount;
          payment.paymentCount += p.paymentCount;
        }

      });

      d.deliveries.forEach(p => {
        var payment = data.deliveries.find(pp => p.receivedById === pp.receivedById && p.cashRegister === pp.cashRegister && p.paymentMethod === pp.method && p.currency === pp.currency);
        if (!payment) {
          data.deliveries.push({
            cashier: p.receivedByName,
            receivedById: p.receivedById,
            cashRegister: p.cashRegister,
            method: p.paymentMethod,
            currency: p.currency,
            amount: p.amount,
            exchangeRate: p.exchangeRate,
          })
        } else {
          payment.amount += p.amount;
        }

      });


      Object.keys(d.drinksIncome).forEach(key => {
        const p = {
          vat: key,
          amount: d.drinksIncome[key]
        }
        var vat = data.vats.find(pp => p.vat === pp.vat && pp.type === "drink");
        if (!vat) {
          data.vats.push({
            vat: p.vat,
            type: "drink",
            amount: p.amount
          })
        } else {
          vat.amount += p.amount;
        }
      });

      Object.keys(d.mealsIncome).forEach(key => {
        const p = {
          vat: key,
          amount: d.mealsIncome[key]
        }
        var vat = data.vats.find(pp => p.vat === pp.vat && pp.type === "meal");
        if (!vat) {
          data.vats.push({
            vat: p.vat,
            type: "meal",
            amount: p.amount
          })
        } else {
          vat.amount += p.amount;
        }
      });

      var type = data.types.find(pp => pp.name === d.type);
      if (!type) {
        data.types.push({
          name: d.type,
          amount: d.paid,
          count: d.occupationCount,
          entry: d.orderCount,
          payable: d.price + d.pending,
          discount: d.discount + d.tm_discount,
        })
      } else {
        type.amount += d.paid;
        type.count += d.occupationCount;
        type.payable += d.price + d.pending;
        type.discount += d.discount + d.tm_discount;
      }

    });

    data.payments.sort((a, b) => {
      if (a.cashier !== b.cashier) {
        return a.cashier.localeCompare(b.cashier);
      } else if (a.cashRegister === b.cashRegister) {
        return a.method - b.method;
      } else {
        return a.cashRegister - b.cashRegister
      }
    })

    data.deliveries.sort((a, b) => {
      if (a.cashier !== b.cashier) {
        return a.cashier.localeCompare(b.cashier);
      } else if (a.cashRegister === b.cashRegister) {
        return a.method - b.method;
      } else {
        return a.cashRegister - b.cashRegister
      }
    })

    data.payments.forEach(t => {
      t.method = auth.myStatus.restaurant_payment_methods.find(p => p.method.id == t.method).method.name;
      t.currency = auth.myStatus.restaurant_base_currency.id === t.currency ? auth.myStatus.restaurant_base_currency.name : auth.myStatus.restaurant_currencies.find(p => p.currency.id == t.currency).currency.name;
      t.amount = Math.round(t.amount).toLocaleString();
      t.exchangeRate = t.exchangeRate !== 1 ? t.exchangeRate.toLocaleString() : "";
      t.cashRegister = t.cashRegister ? auth.myStatus.restaurant_cash_registers.find(p => p.id == t.cashRegister).name : "";
    });
    data.deliveries.forEach(t => {
      t.method = auth.myStatus.restaurant_payment_methods.find(p => p.method.id == t.method).method.name;
      t.currency = auth.myStatus.restaurant_base_currency.id === t.currency ? auth.myStatus.restaurant_base_currency.name : auth.myStatus.restaurant_currencies.find(p => p.currency.id == t.currency).currency.name;
      t.amount = Math.round(t.amount).toLocaleString();
      t.exchangeRate = t.exchangeRate !== 1 ? t.exchangeRate.toLocaleString() : "";
      t.cashRegister = t.cashRegister ? auth.myStatus.restaurant_cash_registers.find(p => p.id == t.cashRegister).name : "";
    });


    data.vats.forEach(t => {
      t.type = admin_local[t.type] ? admin_local[t.type] : t.type;
      t.amount = Math.round(t.amount).toLocaleString();
    });

    data.types.forEach(t => {
      t.name = t.name ? t.name : "onsite"
      t.name = admin_local[t.name] ? admin_local[t.name] : t.name;
      t.count = t.count.toLocaleString();
      t.payable = Math.round(t.payable).toLocaleString();
      t.amount = Math.round(t.amount).toLocaleString();
      t.discount = Math.round(t.discount).toLocaleString();
    });

    data.tables = data.tables.toLocaleString();
    data.orders = data.orders.toLocaleString();
    data.cancelledOrders = data.cancelledOrders.toLocaleString();
    data.personCount = Math.round(data.personCount).toLocaleString();
    data.consumption = Math.round(data.consumption).toLocaleString();
    data.discount = Math.round(data.discount).toLocaleString();
    data.payable = Math.round(data.payable).toLocaleString();
    data.paid = Math.round(data.paid).toLocaleString();
    data.tip = Math.round(data.tip).toLocaleString();
    data.serviceFee = Math.round(data.serviceFee).toLocaleString();
    data.serviceFeeNet = Math.round(data.serviceFeeNet).toLocaleString();
    var x2js = new X2JS();
    var xmlAsStr = '<?xml version="1.0" encoding="utf-8"?><root>' + x2js.js2xml(data) + "</root>";
    var file = "dailyClosing_" + localStorage.id + (new Date().getTime());
    var printer = getLocalPrinterSettings().printerActions.pa_printInvoice ? getPrinter(getLocalPrinterSettings().printerActions.pa_printInvoice).name : auth.myStatus.restaurant_printer_actions?.printInvoice?.name
    if (!printer) printer = "local";
    printPdf("dailyClosing", "html", localStorage.language, "../birt/preview?__report=" + getReportUrl('dailyClosing') + "&__format=html&__locale=" + localStorage.language, file, xmlAsStr, printer);

  }

  post("adminService/" + localStorage.restaurantSelected + "/filterDayClosing?lang=" + localStorage.language + "&quick=" + !auth.myStatus.restaurant_settings["tables-overview"].waiter_print_daily_closing_full, search).done(print);
}


function increasePersonCount() {
  get("adminService/" + localStorage.restaurantSelected + "/incrementPersonCount/" + localStorage.tableOccupationSelected).done(update)
}

function decreasePersonCount() {
  get("adminService/" + localStorage.restaurantSelected + "/decrementPersonCount/" + localStorage.tableOccupationSelected).done(update)
}
