import djvi from "djvi";

export const changeLanguage = (local, admin_local, language, country) => {
  return { local: local, admin_local: admin_local, language: language, country: country };
};

export const dispatchChangeLanguage = dispatch => {
  return (local, admin_local, language, country) => {
    dispatch({ type: "changeLanguage", ...changeLanguage(local, admin_local, language, country) });
  };
};

export const resetActiveRestaurant = restaurant => {
  return { type: "resetActiveRestaurant", restaurant };
};

//superadmin
export const restaurantSelected = restaurant => {
  return { type: "restaurantSelected", restaurant };
};

export const updateRow = (stateKey, row) => {
  return { type: "updateRow", row: row, stateKey: stateKey };
};

export const deleteRow = ({ stateKey, row, rowIndex }) => {
  return { type: "deleteRow", row: row, stateKey: stateKey, rowIndex: rowIndex };
};

export const deleteOrderHistory = ({ stateKey, row, rowIndex }) => {
  return { type: "deleteOrderHistory", row: row, stateKey: stateKey, rowIndex: rowIndex };
};

export const updateRestaurants = restaurants => {
  return { type: "updateRestaurants", restaurants };
};

export const updateDeliveryUsers = deliveryUsers => {
  return { type: "updateDeliveryUsers", deliveryUsers: deliveryUsers };
};

export const updateRestaurantTables = tables => {
  var pointOfSales = [];
  tables.forEach(table => {
    if (table.pos === true) pointOfSales.push(table);
  });

  return { type: "updateRestaurantTables", tables: tables, pointOfSales: pointOfSales };
};

export const resetMyStatus = myStatus => {
  return { type: "resetMyStatus", myStatus: myStatus };
};

const schema = {
  type: "object",
  properties: {
    globalId: {
      type: "number"
    },
    name: {
      type: "string"
    },
    languages: {
      type: "string"
    },
    isActive: {
      title: "active",
      type: "boolean",
      default: true
    },
    test: {
      type: "boolean",
      default: true
    },
    baseCurrency: {
      type: "string",
      required: true
    },
    address: {
      type: "string"
    },
    town: {
      type: "string"
    },
    country: {
      type: "string"
    },
    secretKey: {
      type: "string"
    },
    serverUrl: {
      type: "string"
    },
    localUrl: {
      type: "string"
    },
    barionPOSKey: {
      type: "string"
    },
    invoiceHeading: {
      type: "string"
    },
    css: {
      type: "string"
    }
  },
  required: ["css", "invoiceHeading", "isActive", "test"]
};
const env = new djvi();
env.addSchema("settings", { settings: schema });

export const addNewRestaurant = ({ stateKey }) => {
  const newRestaurant = env.instance("settings#/settings");
  newRestaurant.languages = "hu,en";
  return { stateKey, row: newRestaurant };
};

export const speechRecognitionOn = {
  type: "speechRecognitionOn"
};
export const speechRecognitionOff = {
  type: "speechRecognitionOff"
};
export const speechRecognitionAvailable = {
  type: "speechRecognitionAvailable"
};
export const setSpeechCommand = command => {
  return { type: "setSpeechCommand", command: command };
};
export const logout = {
  type: "logout"
};
export const daynight = on => {
  return {
    type: "daynight",
    daynight: on
  }
};
export const orders = orders => {
  return { type: "orders", orders: orders };
};
export const tableOccupations = tableOccupations => {
  return { type: "tableOccupations", tableOccupations: tableOccupations };
};

const EatWithMeActions = {
  restaurantSelected: restaurantSelected,
  changeLanguage: changeLanguage,
  updateRow: updateRow,
  deleteRow: deleteRow,
  deleteOrderHistory: deleteOrderHistory,
  addNewRestaurant: addNewRestaurant,
  updateRestaurants: updateRestaurants,
  updateRestaurantTables: updateRestaurantTables,
  updateDeliveryUsers: updateDeliveryUsers,
  resetMyStatus: resetMyStatus,
  resetActiveRestaurant: resetActiveRestaurant,
  speechRecognitionAvailable: speechRecognitionAvailable,
  speechRecognitionOff: speechRecognitionOff,
  speechRecognitionOn: speechRecognitionOn,
  setSpeechCommand: setSpeechCommand,
  logout: logout,
  orders: orders,
  tableOccupations: tableOccupations,
  daynight: daynight
};

export default EatWithMeActions;
