import React, { Component } from "react";
import orderListUtil from "../js/order-list-util";
import { Translate } from "react-redux-i18n";
import { connect } from "react-redux";
import { ready, clearData, reload, saveInventoryDisposal, deleteInventoryDisposal, addNewEntry, deleteEntry } from "../js/admin/inventory-scrapping";
import LogoutButton from "../components/icon-buttons/LogoutButton";
import AICommandBox from "../js/ai/AICommandBox";
import auth, { getMyImageUrl, setRem } from "../js/auth";
import EatWithMeText from "../components/EatWIthMeText";
import MenuItemHeader from "../components/MenuItemHeader";
import ChangeLanguage from "../components/icon-buttons/ChangeLanguage";
import UserImageButton from "../components/icon-buttons/UserImageButton";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { isInventoryItem } from "../js/admin";
import MyDateRangePicker from "../components/MyDateRangePicker";


class InventoryScrapping extends Component {
    state = {
        initialized: false,
        startDate: moment(Number(localStorage.admin_stocklist_start)),
        endDate: moment(Number(localStorage.admin_stocklist_end)),
    };

    static getDerivedStateFromProps(props, state) {
        return state;
    }

    componentDidMount() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;
        if (myStatus && myStatus.restaurant_id) {
            this.setState({ initialized: true });
            ready(this.props);
        }
        auth.minfontsize = 10;
        setRem();
        window.saveInventoryDisposal = saveInventoryDisposal;
        window.deleteInventoryDisposal = deleteInventoryDisposal;
        window.addNewEntry = addNewEntry;
        window.isInventoryItem = isInventoryItem;
        window.deleteEntry = deleteEntry;
    }

    componentDidUpdate() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;

        if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
            ready(this.props).done(() => {
                this.setState({ initialized: true });
            });
        }
    }

    componentWillUnmount() {
        clearData();
        this.setState({ initialized: false });
        delete window.saveInventoryDisposal;
        delete window.deleteInventoryDisposal;
        delete window.addNewEntry;
        delete window.deleteEntry;
    }

    render() {
        const { myStatus } = this.props;
        if (myStatus && myStatus.restaurant_id) {

            return (
                <div id="InventoryScrapping" className="stock-actions" style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "100%" }}>
                    {!auth.isIncludedInFrame ? (
                        <div className="menuItemHeader">
                            <EatWithMeText />
                            <MenuItemHeader {...this.props} header={<Translate value="admin_local.menus.inventoryScrapping" />} />
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <ChangeLanguage />
                                <UserImageButton src={getMyImageUrl()} />
                                <div style={{ marginLeft: "10px", width: "25px", display: "flex", justifyContent: "space-between" }}>
                                    <LogoutButton query="keep=true&redirect=/tablesOverview" />
                                </div>
                            </div>
                        </div>
                    ) : (
                            ""
                        )}
                    <div style={{ display: "flex", flexFlow: "column", justifyItems: "center", alignItems: "center", paddingLeft: "10px", paddingRight: "10px", flexGrow: 0, flexShrink: 0 }}>
                        <div style={{ display: "flex", flexWrap: "nowrap" }}>
                            <MyDateRangePicker startDate={this.state.startDate} endDate={this.state.endDate} onApply={this.dateRangeChanged}>
                            </MyDateRangePicker>
                        </div>
                    </div>
                    <div id="main"></div>
                    {!auth.isIncludedInFrame ? <AICommandBox /> : ""}
                </div >
            );
        } else return null;
    }

    dateRangeChanged = (event, values) => {
        this.setState({
            startDate: values.startDate,
            endDate: values.endDate
        })
        localStorage.admin_stocklist_start = values.startDate._d.getTime();
        localStorage.admin_stocklist_end = values.endDate._d.getTime();
        reload(this.state);
    }

}
const mapStateToProps = (props, state) => {
    if (typeof localStorage.admin_stocklist_start === "undefined") {
        localStorage.admin_stocklist_start = new Date().getTime();
        localStorage.admin_stocklist_end = new Date().getTime();
    }

    if (props.rootReducer.myStatus && props.rootReducer.myStatus.restaurant_id) {
        return {
            restaurant: props.rootReducer.restaurant,
            myStatus: props.rootReducer.myStatus,
            admin_local: props.rootReducer.admin_local,
            local: props.rootReducer.local,
            speechRecognitionOn: props.rootReducer.speechRecognitionOn,
            ai: props.rootReducer.ai,
            speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
        };
    }
    return {};
};

export default connect(mapStateToProps)(withRouter(InventoryScrapping));
