/* eslint-disable */
import { get, post, executeLongJob, getLocale, getQuantityLabel2, confirmDialog } from "./auth";
import { resultHandler, getMenuItem, getMenuCategory } from "./admin";
import { I18n } from "react-redux-i18n";
import menuitemutil from "./admin/menuitem";
import admin from "./admin";

var utils = {};

utils.getMenuItems = function (handler) {
  get("adminService/" + localStorage.restaurantSelected + "/getMenuItems", localStorage.restaurantSelected).done(function (data) {
    if (data == null) {
      console.log("Failed to get meal categories!");
    } else {
      handler(data);
    }
  });
};

utils.getOrder = function (tableOccupation, order, handler) {
  get("adminService/" + localStorage.restaurantSelected + "/getOrder?tableOccupation=" + tableOccupation + "&drinkOrder=" + order + "&lang=" + localStorage.language, localStorage.restaurantSelected).done(function (data) {
    if (data == null) {
      console.log("Failed to get drink orders!");
    } else {
      handler(data);
    }
  });
};

utils.activateMenuCategory = function (id, active, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/activateMenuCategory/" + id + "?active=" + active).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

utils.activateMenuItem = function (id, active, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/activateMenuItem/" + id + "?active=" + active).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

utils.recalculateConsumption = function (id, quantity, from = "", handler, errorHandler) {
  executeLongJob(
    I18n.t("admin_local.recalculateConsumption") + ": " + getLocale(getMenuItem(id).name) + " " + getQuantityLabel2(getMenuItem(id).entityType, quantity, getMenuItem(id).quantityType),
    "adminService/" + localStorage.restaurantSelected + "/recalculateConsumption?menuitem=" + id + "&quantity=" + quantity + "&lang=" + localStorage.language + "&from=" + from,
    false
  ).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};

function modifyMenuItem(
  parentCategoryId,
  { id,
    barcodes,
    action,
    shortName,
    manufacturer,
    name,
    description,
    image,
    bigImage,
    quantityType,
    cost,
    unitPrice,
    availableQuantities,
    timing,
    productionLines,
    vatCategory,
    takeawayVatCategory,
    isActive,
    isTop,
    online,
    available,
    inventoryItem,
    freeQuantity,
    menuItemAdditions,
    menuCategoryAdditions,
    labels,
    pricePerCostRatio,
    color,
    mandatory_volume,
    places,
    preorder,
    toDate,
    fromDate,
    mainCategory,
    subCategory,
    mainCategoryId,
    subCategoryId,
    woltImage,
    foodpandaImage,
    falatozzImage,
    minimumPrice
  },
  handler,
  errorHandler,
  recalculateInventory = false
) {
  var production_lines_q = "";
  productionLines.forEach(function (pl) {
    production_lines_q += "&productionLineId=" + (pl.id ? pl.id : pl);
  });
  menuItemAdditions.forEach(function (a) {
    if (a.type == "meal_addition") a.addition.type = "meal";
    else a.addition.type = "drink";
  });
  menuCategoryAdditions.forEach(function (a) {
    if (a.type == "mealcategory_addition") a.addition.type = "mealcategory";
    else a.addition.type = "drinkcategory";
  });
  availableQuantities.forEach(q => {
    delete q.selected;
  });
  var entityType = getMenuItem(id).entityType;
  vatCategory = vatCategory && vatCategory.id ? vatCategory.id : 0;
  takeawayVatCategory = takeawayVatCategory && takeawayVatCategory.id ? takeawayVatCategory.id : 0;
  executeLongJob(
    "",
    "adminService/" + localStorage.restaurantSelected + "/modify" + entityType + "/" + parentCategoryId + "/" + id + "?" + production_lines_q + "&vatCategoryId=" + vatCategory + "&takeawayVatCategoryId=" + takeawayVatCategory + "&action=" + action + "&recalculateInventory=" + recalculateInventory,
    false,
    {
      type: entityType.toLowerCase(),
      id: id,
      barcodes: barcodes,
      shortName: shortName,
      manufacturer: manufacturer,
      name: name,
      description: description,
      imageSrc: image,
      bigImageSrc: bigImage,
      quantityType: quantityType,
      cost: cost,
      unitPrice: unitPrice,
      availableQuantities: availableQuantities,
      timing: timing,
      isActive: isActive,
      isTop: isTop,
      online: online,
      available: available,
      inventoryItem: inventoryItem,
      freeQuantity: freeQuantity,
      menuItemAdditions: menuItemAdditions,
      menuCategoryAdditions: menuCategoryAdditions,
      labels: labels,
      pricePerCostRatio: pricePerCostRatio,
      color: color,
      mandatory_volume: mandatory_volume,
      settings: places,
      preorder,
      toDate,
      fromDate,
      mainCategory: mainCategory === undefined ? mainCategoryId : mainCategory,
      subCategory: subCategory === undefined ? subCategoryId : subCategory,
      woltImageSrc: woltImage,
      foodpandaImageSrc: foodpandaImage,
      falatozzImageSrc: falatozzImage,
      minimumPrice
    }
  ).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
utils.modifyMenuItem = modifyMenuItem;

function addMenuItem(
  parentCategoryId,
  { barcodes,
    shortName,
    manufacturer,
    name,
    description,
    image,
    bigImage,
    quantityType,
    cost,
    unitPrice,
    availableQuantities,
    timing,
    productionLines,
    vatCategory,
    takeawayVatCategory,
    isActive,
    isTop,
    online,
    available,
    inventoryItem,
    freeQuantity,
    menuItemAdditions,
    menuCategoryAdditions,
    labels,
    pricePerCostRatio,
    color,
    mandatory_volume,
    places,
    preorder,
    toDate,
    fromDate,
    mainCategory,
    subCategory,
    woltImage,
    foodpandaImage,
    falatozzImage,
    minimumPrice
  },
  handler,
  errorHandler
) {
  var production_lines_q = "";
  productionLines.forEach(function (pl) {
    production_lines_q += "&productionLineId=" + (pl.id ? pl.id : pl);
  });
  vatCategory = vatCategory.id ? vatCategory.id : vatCategory;
  takeawayVatCategory = takeawayVatCategory && takeawayVatCategory.id ? takeawayVatCategory.id : takeawayVatCategory;
  post("adminService/" + localStorage.restaurantSelected + "/add" + menuitemutil.entityType + "/" + parentCategoryId + "?" + production_lines_q + "&vatCategoryId=" + vatCategory + "&takeawayVatCategoryId=" + takeawayVatCategory, {
    barcodes: barcodes,
    type: menuitemutil.entitytype,
    shortName: shortName,
    manufacturer: manufacturer,
    name: name,
    description: description,
    imageSrc: image,
    bigImageSrc: bigImage,
    quantityType: quantityType,
    cost: cost,
    unitPrice: unitPrice,
    availableQuantities: availableQuantities,
    timing: timing,
    isActive: isActive,
    isTop: isTop,
    online: online,
    available: available,
    inventoryItem: inventoryItem,
    freeQuantity: freeQuantity,
    menuItemAdditions: menuItemAdditions,
    menuCategoryAdditions: menuCategoryAdditions,
    labels: labels,
    pricePerCostRatio: pricePerCostRatio,
    color: color,
    mandatory_volume: mandatory_volume,
    settings: places,
    preorder,
    toDate,
    fromDate,
    mainCategory,
    subCategory,
    woltImageSrc: woltImage,
    foodpandaImageSrc: foodpandaImage,
    falatozzImageSrc: falatozzImage,
    minimumPrice
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
utils.addMenuItem = addMenuItem;

function modifyMenuCategory(
  {
    id,
    name,
    description,
    image,
    timing,
    productionLines,
    vatCategory,
    takeawayVatCategory,
    isActive,
    isTop,
    online,
    available,
    inventoryItem,
    menuItemAdditions,
    menuCategoryAdditions,
    labels,
    pricePerCostRatio,
    color,
    settings,
    fromDate,
    toDate,
    preorder,
    mainCategory,
    subCategory,
    mainCategoryId,
    subCategoryId
  },
  handler,
  errorHandler
) {
  var production_lines_q = "";
  productionLines.forEach(function (pl) {
    production_lines_q += "&productionLineId=" + (pl.id ? pl.id : pl);
  });
  menuItemAdditions.forEach(function (a) {
    if (a.type == "meal_addition") a.addition.type = "meal";
    else a.addition.type = "drink";
  });
  menuCategoryAdditions.forEach(function (a) {
    if (a.type == "mealcategory_addition") a.addition.type = "mealcategory";
    else a.addition.type = "drinkcategory";
  });
  vatCategory = vatCategory && vatCategory.id ? vatCategory.id : 0;
  takeawayVatCategory = takeawayVatCategory && takeawayVatCategory.id ? takeawayVatCategory.id : 0;
  var entityType = getMenuCategory(id).entityType;
  post("adminService/" + localStorage.restaurantSelected + "/modify" + entityType + "/" + id + "?" + production_lines_q + "&vatCategoryId=" + vatCategory + "&takeawayVatCategoryId=" + takeawayVatCategory, {
    type: entityType.toLowerCase(),
    id: id,
    name: name,
    description: description,
    imageSrc: image,
    timing: timing,
    isActive: isActive,
    isTop: isTop,
    online: online,
    available: available,
    inventoryItem: inventoryItem,
    menuItemAdditions: menuItemAdditions,
    menuCategoryAdditions: menuCategoryAdditions,
    labels: labels,
    pricePerCostRatio: pricePerCostRatio,
    color: color,
    settings: settings,
    fromDate,
    toDate,
    preorder,
    mainCategory: mainCategory === undefined ? mainCategoryId : mainCategory,
    subCategory: subCategory === undefined ? subCategoryId : subCategory
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
utils.modifyMenuCategory = modifyMenuCategory;

function updateMenuItemOrders(ids, handler, errorHandler) {
  post("adminService/" + localStorage.restaurantSelected + "/update" + menuitemutil.entityType + "CategoryOrders", ids).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
utils.updateMenuItemOrders = updateMenuItemOrders;

function addMenuCategory(
  parentCategoryId,
  {
    name,
    description,
    image,
    timing,
    productionLines,
    vatCategory,
    takeawayVatCategory,
    isActive,
    isTop,
    online,
    available,
    inventoryItem,
    menuItemAdditions,
    menuCategoryAdditions,
    labels,
    pricePerCostRatio,
    color,
    settings,
    fromDate,
    toDate,
    preorder,
    mainCategory,
    subCategory
  },
  handler,
  errorHandler
) {
  var production_lines_q = "";
  productionLines.forEach(function (pl) {
    production_lines_q += "&productionLineId=" + (pl.id ? pl.id : pl);
  });
  takeawayVatCategory = takeawayVatCategory && takeawayVatCategory.id ? takeawayVatCategory.id : 0;
  vatCategory = vatCategory && vatCategory.id ? vatCategory.id : 0;
  post("adminService/" + localStorage.restaurantSelected + "/add" + menuitemutil.entityType + "Category/" + parentCategoryId + "?" + production_lines_q + "&vatCategoryId=" + vatCategory + "&takeawayVatCategoryId=" + takeawayVatCategory, {
    type: menuitemutil.entitytype + "category",
    name: name,
    description: description,
    imageSrc: image,
    timing: timing,
    isActive: isActive,
    isTop: isTop,
    online: online,
    available: available,
    inventoryItem: inventoryItem,
    menuItemAdditions: menuItemAdditions,
    menuCategoryAdditions: menuCategoryAdditions,
    labels: labels,
    pricePerCostRatio: pricePerCostRatio,
    color: color,
    settings: settings,
    fromDate,
    toDate,
    preorder,
    mainCategory,
    subCategory
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
utils.addMenuCategory = addMenuCategory;

utils.deleteMenuCategory = function (id, handler, errorHandler) {
  var entityType = getMenuCategory(id).entityType;
  confirmDialog(I18n.t("local.confirmation"), I18n.t("admin_local.do_you_really_want_to_delete_the_menu_category")).done(function () {
    get("adminService/" + localStorage.restaurantSelected + "/delete" + entityType + "/" + id).done(function (data) {
      resultHandler(data, handler, errorHandler);
    });
  });
};

utils.deleteMenuItem = function (id, handler, errorHandler) {
  var entityType = getMenuItem(id).entityType;
  confirmDialog(I18n.t("local.confirmation"), I18n.t("admin_local.do_you_really_want_to_delete_the_menu_item")).done(function () {
    get("adminService/" + localStorage.restaurantSelected + "/delete" + entityType + "/" + id).done(function (data) {
      resultHandler(data, handler, errorHandler);
    });
  });
};

function setMenuItemParentCategory(id, parentCategoryId, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/set" + menuitemutil.entityType + "ParentCategory/" + id + "?parentCategoryId=" + parentCategoryId).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
utils.setMenuItemParentCategory = setMenuItemParentCategory;

function setMenuCategoryParentCategory(id, parentCategoryId, handler, errorHandler) {
  get("adminService/" + localStorage.restaurantSelected + "/set" + menuitemutil.entityType + "CategoryParentCategory/" + id + "?parentCategoryId=" + parentCategoryId).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
utils.setMenuCategoryParentCategory = setMenuCategoryParentCategory;

utils.getMenuCategoryProperty = function (id, property) {
  var image = utils._getMenuItemCategoryProperty(id, admin.categories.activeMenuCategories ? admin.categories.activeMenuCategories : admin.categories.children, property);
  if (image == "") {
    return null;
  }
  return image;
};

utils._getMenuItemCategoryProperty = function (id, categories, property) {
  var val = null;
  categories.forEach(function (menuCategory) {
    if (menuCategory.entityType != menuitemutil.entityType || val != null) return;
    if (menuCategory.id == id) {
      if (menuCategory[property] != "") val = menuCategory[property];
      else val = "";
    } else {
      if (menuCategory.activeMenuCategories && menuCategory.activeMenuCategories.length > 0) val = utils._getMenuItemCategoryProperty(id, menuCategory.activeMenuCategories, property);
      else if (menuCategory.children && menuCategory.children.length > 0) val = utils._getMenuItemCategoryProperty(id, menuCategory.children, property);
    }
  });
  return val;
};

utils.downloadMenuItems = (handler) => {
  get("restaurantService/" + localStorage.restaurantSelected + "/download" + menuitemutil.entityType + "s", localStorage.restaurantSelected).
    done(function (data) {
      if (data == null) {
        console.log("Failed to get categories!");
      } else {
        handler(data);
      }
    });
}


export default utils;
